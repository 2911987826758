export class BusquedaFiltrosModel {
    motivo: string;  
    fecha: string;  
    promotor: string;  
    dni: string;  
    pcn: string;  
    nombre: string;
    refnaps: string;
    cuil: string;
}

export class Vendor{
    id: string;
    name: string;
}

//DATA LIST
export class ValidationAccionListModel {

    constructor(){
        this.data = new  Array<ValidationAccionModel>();
    }

    data: Array<any>;
}

export class ValidationAccionModel {

    area: string;
    estado: string;
    subestado: string;
    nextStep: string;
}

import {User} from "./user.model";

export class Session {
  constructor(){
    this.user = new  User;
  }
  public logout: boolean;
  public login: boolean;
  public token: string;
  public user: User;
  public renewPassword: boolean;
  public mfa: number;
  public mfaType: number;
}

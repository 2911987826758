import { Injectable } from '@angular/core';
import * as $ from 'jquery';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Environment } from '../../config/environment';
import { Config } from '../../config/config';
import { config } from 'process';
import { of } from 'rxjs';
import { MockStatic } from 'src/app/config/mock.static';


@Injectable({
  providedIn: "root",
})
export class InternalBridgerStatusService {


    constructor(private http: HttpClient, private Conf: Config) { }


    public getData(){

      let httpOptions = {
        withCredentials: false
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }

      let url = this.Conf.ENDPOINT_API + 'internal/typeTwoList';
      
      return this.http.get(url, httpOptions); 

    }

    public denegadoBridger(data:any) {
  
      
      let httpOptions = {
        withCredentials: false
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }

      const params = new HttpParams() .set('pcn', data.pcn);
  

      let url = this.Conf.ENDPOINT_API + 'internal/denegadoBridger?' + params.toString();
      let request = null;
      

      return this.http.post(url, request, httpOptions);        

    }    


  

}
import { Injectable } from '@angular/core';
import * as $ from 'jquery';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Environment } from '../../config/environment';
import { Config } from '../../config/config';
import { Observable, of } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ModifyUserService {
//Componente de plantilla Telemarketing

    constructor(private http: HttpClient, private Conf: Config) { }

    public getUserList(){

      let url = this.Conf.ENDPOINT_API + 'user';
      let wc = (Environment.CREDENTIALS) ? true : false;
      
      return this.http.get(url, {observe: "response",withCredentials: wc})

    }
    public changePassword(data){

      let url = this.Conf.ENDPOINT_API + 'user/changePassword';
      let request = Object.assign({}, data);
      let headers = new HttpHeaders({
        'Content-Type': 'application/json',
      })
      let wc = (Environment.CREDENTIALS) ? true : false;
      
      return this.http.post(url, request, {headers: headers, observe: "response",withCredentials: wc})
        
    }

    public changeMfaType(data){

      let url = this.Conf.ENDPOINT_API + 'accessToken/change-mfa-type';
      let request = Object.assign({}, data);
      let headers = new HttpHeaders({
        'Content-Type': 'application/json',
      })
      let wc = (Environment.CREDENTIALS) ? true : false;
      
      return this.http.post(url, request, {headers: headers, observe: "response",withCredentials: wc})
        
    }

}
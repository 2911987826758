export class Environment {

    public static IDP: boolean = true;
 
    /*
    *   indica el enviroment
    *   local, test, prod.
    */

    public static ENV = "test";

    /*
    *   indica si se envian credenciales
    *   de cookies del balancer
    */

    public static CREDENTIALS = true;


    /* 
    *   Muestra o oculta el header
    */

    public static SHOW_HEADER = true;


    /*
    *   Muestra o oculta el botón de volver
    */

    public static SHOW_BACK_BUTTON = false;

    public static DataTableLanguageSetting: any;

    public static CalendarLanguageSetting: any;

	
    public static RegExEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


    public static MOKCS = ["DOCRECOVERY","","",""];

    

}

export enum CRUD {
    CREATE = "CREATE",
    READ = "READ",
    UPDATE = "UPDATE",
    DELETE = "DELETE",
  }



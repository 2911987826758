import {  Component, OnInit } from '@angular/core';
import { LoadingComponent } from '../shared/loading/loading.component';
import { AlertComponent, AlertModel, ALERT_TYPE } from '../shared/alert/alert.component';

import { ProfessionsService } from 'src/app/services/professions/professions.service';
import { ProfessionModel } from './models/models';

import { ɵAnimationGroupPlayer } from '@angular/animations';
import { Environment } from 'src/app/config/environment';
import { ReturnStatement } from '@angular/compiler';
import { Utils } from 'src/app/commons/utils';
import { SessionStorageService } from 'src/app/auth/services/session.service';
import { ExcelExportService } from 'src/app/services/excelExport/excel.export.service';

@Component({
  selector: 'app-professions',
  templateUrl: './professions.component.html',
  styleUrls: ['./professions.component.css']
})
export class ProfessionsComponent implements OnInit {

  public title: string = "Administrador de Profesiones";
  public titleUser: string;
  public es: any;
  public showResult : boolean;
  public tablereports = null;
  public showNew : boolean; 
  public entityCurrent: ProfessionModel = new ProfessionModel;
  
  public loading: boolean;
  public alertMessage: AlertModel = new AlertModel;
  public alertConfirmEvent: Function;

  public validateShow: boolean =  false;
  public validateMessage: string;
  public isUpdate:boolean = false;


  public resultData = [];
  


  constructor(private professionsService: ProfessionsService,
              private excelExportService: ExcelExportService,
              private sessionStorageService: SessionStorageService) { 
      
              this.sessionStorageService.rolValidate("config");
  }



  ngOnInit() {
    this.setCalendarLocale();
    this.loadResultData();   

  }

  loadResultData(){
    
    this.loading = true;

    this.professionsService.getData().subscribe(
      data => {
        if (data['data']) {
          this.resultData = data['data'];
        }
        else {
          this.resultData = [];
          console.error(data);
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title,true);
        }

        this.setupTable();     
      },
      error => {
        this.resultData = [];
        this.setupTable();     
        console.error(error);
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title,true);
      }
    );

  }

  onConfirmEvent(confirm:any){

    if(confirm.confirm){
      this.delete();
    }else{
      this.entityCurrent = null;
    }
  }

  delete(){
      this.loading = true;

      let request = {
        "codigo" : this.entityCurrent.codigo
      };


      this.professionsService.delete(request).subscribe(
        data => {
          if (data && data["code"] && data["code"] == "0") {
            
            this.loading = false;
            this.entityCurrent = new ProfessionModel();
            this.alertMessage = new AlertModel("Los datos se guardaron con exito",this.title,true);
            this.loadResultData();
          }
          else {
            this.loading = false;
            console.error(data);
            this.alertMessage = new AlertModel("Hubo un inconveniente al eliminar la información, inténtalo nuevamente.",this.title,true);
          }
          
        },
        error => {
          this.loading = false;
          console.error(error);
          this.alertMessage = new AlertModel("Hubo un inconveniente al eliminar la información, inténtalo nuevamente.",this.title,true);
        }
      );    




  }

  deleteConfirm(codigo){    
    this.entityCurrent =  new ProfessionModel();
    this.entityCurrent.codigo = codigo;
    this.alertConfirmEvent = this.onConfirmEvent;

    this.alertMessage = new AlertModel("Está seguro de eliminar esta Profesión?",this.title,true, "CONFIRM");
  }

  new(codigo){
    
    this.entityCurrent = new ProfessionModel();
    this.showNew = true;
    this.showResult = false;

    if(codigo == null){
      this.titleUser = "Nueva Profesión";
    } else{
      this.isUpdate = true;
      this.titleUser = "Modificar Profesión";
      this.entityCurrent = Object.assign({}, this.resultData.find(m => { return m.codigo == codigo}));
    }

    this.validateShow  =  false;
    this.validateMessage = "";

  }
 
  newCancel(){
    this.isUpdate = false;
    this.showResult = true;
    this.showNew = false;
  }

  newSave(){   

    if(!this.isUpdate){
      this.newCreate();
    } else{
      this.newUpdate();
    }
  }

  newCreate(){    
    
    if(this.resultData.some( c=>c.codigo ==this.entityCurrent.codigo )){
      this.alertMessage = new AlertModel("La profesión que se intenta crear ya existe.",this.title,true);
      this.loading = false;    
      return;
    }

    if(!this.newValidate())
        return;       


    this.loading = true;

    this.professionsService.create(this.entityCurrent).subscribe(
      data => {

        if (data && data["code"] && data["code"] == "0") {
          this.showNew = false; 

          this.entityCurrent=  new ProfessionModel();
          this.alertMessage = new AlertModel("Los datos se guardaron con exito",this.title,true);
          this.loadResultData();
        }
        else if (data && data["code"] && data["code"] == "100") {
          
          this.alertMessage = new AlertModel("Hubo un inconveniente: " + data["message"],this.title,true);
          this.loading = false;    
          return;      
        } else{
          this.alertMessage = new AlertModel("Hubo un inconveniente al crear la información, inténtalo nuevamente.",this.title,true);
          this.loading = false;   
        }

      },
      error => {
        console.error(error);
        this.alertMessage = new AlertModel("Hubo un inconveniente al crear la información, inténtalo nuevamente.",this.title,true);
        this.loading = false; 
      }
    );
    
    
  }

  newUpdate(){      


    if(!this.newValidate())
        return;        

    this.loading = true;

    this.professionsService.update(this.entityCurrent).subscribe(
      data => {

        if (data && data["code"] && data["code"] == "0") {
          this.showNew = false;      
          this.isUpdate = false;

          this.entityCurrent=  new ProfessionModel();
          this.alertMessage = new AlertModel("Los datos se guardaron con exito",this.title,true);
          this.loadResultData();
        }
        else if (data && data["code"] && data["code"] == "100") {
          
          this.alertMessage = new AlertModel("Hubo un inconveniente: " + data["message"],this.title,true);
          this.loading = false;    
          return;      
        } else{
          this.alertMessage = new AlertModel("Hubo un inconveniente al actualizar la información, inténtalo nuevamente.",this.title,true);
          this.loading = false;   
        }

        

      },
      error => {
        console.error(error);
        this.alertMessage = new AlertModel("Hubo un inconveniente al actualizar la información, inténtalo nuevamente.",this.title,true);
        this.loading = false; 
      }
    );
    
    
  }  


  newValidate():boolean{

    let validations = "";
    


    if(Utils.isNullOrEmpty(this.entityCurrent.codigo))
      validations+="Debe completar el campo código. "


    if(Utils.isNullOrEmpty(this.entityCurrent.descripcion))
        validations+="Debe completar el campo descripción. "

    if(validations != "") {
        this.validateShow  =  true;
        this.validateMessage = validations;

        return false;
      }

    this.validateShow  =  false;
    this.validateMessage = "";
    return true;
  }

  backToResults(){
    this.showResult = true;
    this.loading = false;

  }

  setDateFormat(value:Date){

     return Utils.setDateFormat(value);
  }


  upload(event){
        
    let fileList: FileList = event.target.files;

    if(fileList.length == 0) {      
      this.alertMessage = new AlertModel("Seleccione solo un archivo.", this.title, true, ALERT_TYPE.WARNING);
      return;
    }

    let file: File = fileList[0];


    var extension = file.name.split(".").pop().toLowerCase();
    var extensiones = ["txt"];
   
    if(extensiones.indexOf(extension) === -1){
      this.alertMessage = new AlertModel("Seleccione un archivo con extención .txt ", this.title, true, ALERT_TYPE.WARNING);
      return;
   }else{
      this.loading = true;
 
      this.professionsService.import(file).subscribe(
        data => {
          if (data['code']== 0) {
            
            this.loadResultData();
            this.alertMessage = new AlertModel("La carga se procesó con exito: " + data['message'], this.title, true, ALERT_TYPE.SUCCESS);
          }
          else {
            let message  = data['message'] ? data['message'] :"Hubo un inconveniente al obtener la información, inténtalo nuevamente.";
            this.alertMessage = new AlertModel(message, this.title, true, ALERT_TYPE.WARNING);
            this.loading = false;
          }
          
        },
        error => {
          console.error("Error");
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true, ALERT_TYPE.ERROR);
          this.loading = false;
        }
      );   


    }

    $("#importFile").val('');
  }

  export(){

    let reportDataExport = new Array<any>();

    this.resultData.forEach(item => {      
      reportDataExport.push({
        "Código":item.codigo,
        "Descripción":item.descripcion,
              
      });
    }); 

    this.excelExportService.exportAsExcelFile(reportDataExport, this.title);

  }

  private setupTable(){

    this.showResult = true;
    this.loading = false;

    if (this.tablereports != null) {
      this.tablereports.clear().destroy();
    } 
    
    $(document).ready( () => {
        this.tablereports =   $("#result-table").DataTable({

        searching: true, 
        paging: true, 
        
        lengthChange: false,
        pageLength: 10,
        pagingType: 'full_numbers',
        columnDefs: [
          { targets: 'no-sort', orderable: false },
        
        ],        
        language: Environment.DataTableLanguageSetting
       });


    });

  }  

  setCalendarLocale(){

    this.es = Environment.CalendarLanguageSetting;
  }

  

}


import { AbstractType, Component, OnInit } from '@angular/core';
import { LoadingComponent } from '../shared/loading/loading.component';
import { AlertComponent, AlertModel } from '../shared/alert/alert.component';
import { v4 as uuidv4 } from 'uuid';

import { ReportStatusBusquedaModel } from './models/models';

import { ɵAnimationGroupPlayer } from '@angular/animations';
import { Environment } from 'src/app/config/environment';
import { ReturnStatement } from '@angular/compiler';
import { SessionStorageService } from 'src/app/auth/services/session.service';
import { Roles } from 'src/app/auth/models/roles.static';
import { Utils } from 'src/app/commons/utils';
import { ProductosList } from 'src/app/models/lists.model';
import { ExcelExportService } from 'src/app/services/excelExport/excel.export.service';
import { VendorFilterModel } from '../precheck/models/models';
import { BehaviorSubject } from 'rxjs';
import { ReportStatusService } from 'src/app/services/reportStatus/reportstatus.service';
declare var $ : any;


@Component({
  selector: 'app-reportstatus',
  templateUrl: './reportstatus.component.html',
  styleUrls: ['./reportstatus.component.css']
})
export class ReportStatusComponent implements OnInit {

  public title: string = "Reporte de Status";
  public busquedafiltros: ReportStatusBusquedaModel = new ReportStatusBusquedaModel();
  public es: any;
  public showResult : boolean;
  public tablereports = null;
  public showNewAgencie : boolean; 
  
  public loading: boolean;
  public alertMessage: AlertModel = new AlertModel;

  public validateShow: boolean =  false;
  public validateMessage: string;

  public estadosList: any = {data:[]};  
  public resultData = [];

  

  constructor(private excelExportService: ExcelExportService,
              private reportStatusService: ReportStatusService,
              private sessionStorageService: SessionStorageService) {                 
               this.sessionStorageService.rolValidate("reportStatus"); 
  }

  ngOnInit() {
    this.setCalendarLocale(); 
    
   
  }

 

  loadResultData(){

    if(!this.validationSearch())
      return;

    this.loading = true;

    let request = { 
      "fecha_captura_inicio": Utils.parseEmptyNull(this.busquedafiltros.fecha_captura_inicio),
      "fecha_captura_fin": Utils.parseEmptyNull(this.busquedafiltros.fecha_captura_fin),
      "fecha_modificacion_inicio": Utils.parseEmptyNull(this.busquedafiltros.fecha_modificacion_inicio),
      "fecha_modificacion_fin": Utils.parseEmptyNull(this.busquedafiltros.fecha_modificacion_fin),
    }; 

    this.reportStatusService.getReportStatus(request).subscribe(
      data => {

        if (data['data'] && data['code'] == "0") {

          this.resultData = data['data'];
          this.resultData.sort((a, b)=> a.canal.localeCompare(b.canal));  
          
          this.resultData.forEach(e=> {
            e.uuid = uuidv4();

            let rowspanlen =this.resultData.filter(s=>s.canal == e.canal).length;
            let rowspanset = this.resultData.filter(s=>s.canal == e.canal && s.rowspan).length;

            e.rowspanshow = true;
            if(rowspanlen > 1){

              if(rowspanset == 0)
                e.rowspan = this.resultData.filter(s=>s.canal == e.canal).length;
              else
                e.rowspanshow = false;                
            } else {
              e.rowspan = 1;
            }
            
            
          });

        

          this.setupTable();             

        }
        else {
          this.loading = false;
          console.error(data);
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title,true);
        }
      },
      error => {
        this.loading = false;
        console.error(error);
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title,true);
      }
    );

  }

 
  validationSearch():boolean{

  

  let captura_set = !Utils.isNullOrEmpty(this.busquedafiltros.fecha_captura_inicio) &&
                      !Utils.isNullOrEmpty(this.busquedafiltros.fecha_captura_fin);

  let modificacion_set = !Utils.isNullOrEmpty(this.busquedafiltros.fecha_modificacion_inicio) &&
                         !Utils.isNullOrEmpty(this.busquedafiltros.fecha_modificacion_fin);     

  if(!captura_set && !modificacion_set){
    this.alertMessage = new AlertModel("Se necesita una Fecha Captura o Fecha Modificación.",this.title,true);
    return false;
  } 

  let captura_all = (Utils.isNullOrEmpty(this.busquedafiltros.fecha_captura_inicio) &&
                    !Utils.isNullOrEmpty(this.busquedafiltros.fecha_captura_fin)) ||
                    (!Utils.isNullOrEmpty(this.busquedafiltros.fecha_captura_inicio) &&
                    Utils.isNullOrEmpty(this.busquedafiltros.fecha_captura_fin));    


  let modificacion_all = (Utils.isNullOrEmpty(this.busquedafiltros.fecha_modificacion_inicio) &&
                         !Utils.isNullOrEmpty(this.busquedafiltros.fecha_modificacion_fin)) ||
                         (!Utils.isNullOrEmpty(this.busquedafiltros.fecha_modificacion_inicio) &&
                          Utils.isNullOrEmpty(this.busquedafiltros.fecha_modificacion_fin)); 


  if(captura_all){
    this.alertMessage = new AlertModel("Se necesitan ambas fecha para Fecha Captura o Fecha Modificación.",this.title,true);
    return false;
  }    
                
  if(modificacion_all){
    this.alertMessage = new AlertModel("Se necesitan ambas fecha para Fecha Captura o  Fecha Modificación.",this.title,true);
    return false;
  }    

   let cap_days =  Utils.dateDiffBetween(this.busquedafiltros.fecha_captura_inicio, this.busquedafiltros.fecha_captura_fin, "days");
   let modif_days = Utils.dateDiffBetween(this.busquedafiltros.fecha_modificacion_inicio, this.busquedafiltros.fecha_modificacion_fin,"days");

   if(captura_set)
      if(cap_days < 0){
        this.alertMessage = new AlertModel("Fecha Captura Inicio no puede ser menor que Fecha Captura Fin.",this.title,true);
        return false;
      }    


    
    if(modificacion_set)
      if(modif_days < 0){
        this.alertMessage = new AlertModel("Fecha Modificación Inicio no puede ser menor que  Fecha Modificación Fin.",this.title,true);
        return false;
      }   

    return true;
  }


  backToResults(){
    this.showResult = true;
    this.loading = false;

  }

  setDateFormat(value:Date){
     return Utils.setDateFormat(value);
  }

  setupTable(){

    this.showResult = true;
    this.loading = false;

  }
   
  exportar (){

    let reportDataExport = new Array<any>();

    this.resultData.forEach(item => {      
      reportDataExport.push({
        "Canal":item.canal,
        "Subcanal":item.subcanal,
        "Precheck Aprobado":item.aceptado,
        "Precheck Rechazado":item.rechazado,
        "Pendiente":item.pendiente,
        "No contacto":item.no_contacto,
        "Contacto":item.contacto,
        "No Venta":item.no_venta,
        "Venta":item.venta,
        "Downgrade pendiente":item.downgrade,
        "Recovery":item.falta_info,
        "cuentas nuevas":item.cuentas_nuevas,
        "Enviado a Easy":item.aeasy,
        "Aprobado lanas":item.aprobado_lanas,
        "Pactado":item.pactado,
        "Entregado":item.entregado,
              
      });
    }); 

    this.excelExportService.exportAsExcelFile(reportDataExport, this.title);

  }

  setCalendarLocale(){
    this.es = Environment.CalendarLanguageSetting;
  }



  public  showSubRow(id){
    $(".div-sub-row").not("#tr-" + id).hide();
    $("#tr-" + id).toggle(500);
  }




}

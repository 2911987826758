import { Component, OnInit } from '@angular/core';
import { DocRecoveryService } from '../../services/docrecovery/docrecovery.service';
import { BaseComponent } from '../shared/base.component';
import { AlertModel, ALERT_TYPE } from '../shared/alert/alert.component';
import { SessionStorageService } from 'src/app/auth/services/session.service';
import { BusquedaFiltrosModel,SourceCodeModel } from './models/models';
import { Environment } from '../../config/environment';
import { Utils } from 'src/app/commons/utils';
import { CommonDataService } from 'src/app/services/commons/commondata.service';
import { AplicantModel} from 'src/app/models/aplicante.model';

import { AccionAplicanteList, MotivosAplicanteList } from 'src/app/models/lists.model';

declare var $ : any;


@Component({
  selector: 'app-sourcecode-assignment',
  templateUrl: './sourcecodeAssignment.component.html',
  styleUrls: ['./sourcecodeAssignment.component.css'],
})

export class SourcecodeAssignmentComponent extends BaseComponent implements OnInit {

  public loading: boolean;
  public showResult : boolean;
  public alertConfirmEvent: Function;
  public alertMessage: AlertModel = new AlertModel;
  public busquedafiltros: BusquedaFiltrosModel = new BusquedaFiltrosModel;
  public es: any;
  public title: string = "ASIGNACIÓN DE SOURCECODE";
  public scData = [];
  public tablereports = null;
  public rolConsulta : boolean=true;
  public showAplicanteDetalles : boolean;
  public aplicanteCurrent: AplicantModel = new AplicantModel;
  public accionAplicanteList: AccionAplicanteList = new AccionAplicanteList();
  public motivosAplicanteList: MotivosAplicanteList = new MotivosAplicanteList();
  public loadingMotivo: boolean; 
  public validationsShow: boolean; 
  public sourceCodeModel: SourceCodeModel = new SourceCodeModel();
  public ngSelectSc: string = "";
  public ngSelectCh: string = "";
  public ngSelectCiclo: string = "";
  public ngSelectDescr: string = "";
  public ngSelectDc: string = "";
  public ngSelectSubCh: string = "";
  public sourcecodesListForApplicant: Array<any> = new Array<any>();

  public ngAccion: string = "";
  public ngMotivo: string = "";
  public showTitleSuples: boolean = false;
  public validateMessage: string;

  public messagetrackingshow:  boolean;

  constructor(private sessionStorageService: SessionStorageService,
    private sourcecodeAssignmentService: DocRecoveryService,
    private commonDataService: CommonDataService) { 
    super();
    this.sessionStorageService.rolValidate("docrecovery");
    this.loading = true;
  }
  
  ngOnInit(): void {
    this.setCalendarLocale();
    this.validationAccionLoad();
    this.loading = false;
    this.rolesAutorization();
  }

  setCalendarLocale(){
    this.es = Environment.CalendarLanguageSetting;
  }

  public rolesAutorization(){
    let rolesSession = this.sessionStorageService.getCurrentUser().roles
      //console.log(rolesSession)
    if (rolesSession.includes("AMX_CONSULTA")){
      this.rolConsulta=false
    }
  }

  loadReportResults(){
    
    if(Utils.dateIsValid(this.busquedafiltros.fechaDesde) &&
      Utils.dateIsValid(this.busquedafiltros.fechaHasta)){
      let cap_days =  Utils.dateDiffBetween(this.busquedafiltros.fechaDesde, this.busquedafiltros.fechaHasta, "days");

      if(cap_days < 0){
        this.alertMessage = new AlertModel("Fecha: Desde debe ser mayor que Hasta.",this.title,true,ALERT_TYPE.WARNING);
        return;
      }  
    }
    this.loadReportRequest();
    return true;
  }

  loadReportRequest(){
    this.loading = true;
    let request = { 
        "fechaDesde": Utils.parseEmptyNull(this.busquedafiltros.fechaDesde),
        "fechaHasta": Utils.parseEmptyNull(this.busquedafiltros.fechaHasta),
        "filterSCNull":"S"
    };         

    this.sourcecodeAssignmentService.getApplicantsReportByArea(request).subscribe(
      data => {
        if (data['data']) {

          this.scData = data['data'];
 
          this.setupTable();

        }
        else {
          console.error(data);
          this.loading = false;
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true,ALERT_TYPE.WARNING);
        }
      },
      error => {
        console.error(error);
        this.loading = false;
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true, "ERROR");
      }
    );

    return true;
  }

  showAplicante(pcn:string){
    this.loading = true;
    this.resetValues();
    let request = {
      "pcn":pcn
    };

    this.sourcecodeAssignmentService.getApplicantDataByArea(request).subscribe(
      data => {

        if (data && data['code'] == "100"){
          this.alertMessage = new AlertModel(data['message'] , this.title, true);
          this.loading = false;
          return;
        }

        if (data && data.data) {

          if(data.data.length == 0){
            this.loading = false;
            this.alertMessage = new AlertModel("No se pudieron obtener los datos.", this.title, true, "ERROR");   
            return;
          }
          this.showAplicanteDetalles = true;
          window.scroll(0,0);
          var resp = data.data[0];

          /////
          this.aplicanteCurrent = Object.assign(this.aplicanteCurrent, resp);

          this.aplicanteCurrent.adicionales = this.aplicanteCurrent.adicionales.filter(a=>{

            if(!Utils.dateIsValid(a.fecha_nacimiento)){
              a.fecha_nacimiento = null;
            }

            return !Utils.isNullOrEmpty(a.numero_documento);
          });

          for(var i = 0; i < this.aplicanteCurrent.adicionales.length; i++){
            this.aplicanteCurrent.adicionales[i].index = i;
          }

          this.showTitleSuples = ( this.aplicanteCurrent.adicionales.length )? true : false;

          this.aplicanteCurrent.detalles.cantidad_suples = this.aplicanteCurrent.adicionales.length.toString();
          if(Utils.isNullOrEmpty(this.aplicanteCurrent.detalles.hora_rellamado))
               this.aplicanteCurrent.detalles.hora_rellamado = "";
          
          this.aplicanteCurrent.observaciones.observacion = "";
          /////

          this.showResult = false
          this.loading = false;
          this.setupTootip();

          this.getSourceCodeRecovery( request );

          this.commonDataService.pingApplicantHoldTimerStart(pcn);
        }
        else {
          console.error(data);
          this.loading = false;
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true, "ERROR");          
        }
      },
      error => {
        console.error(error);
        this.loading = false;
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true, "ERROR");
      }
    );      
    
    return true;
  
  }

  refactorObservationPaste(event:ClipboardEvent){ 
    this.aplicanteCurrent.observaciones.observacion=this.sanitizedText(this.aplicanteCurrent.observaciones.observacion); 
   }
   sanitizedText(text:string):string{
     return text.replace(/[^a-zA-Z0-9.$, ]/g,'')
   }
   refactorObservation(evento:any){
    const entrada= String.fromCharCode(evento.keyCode);
    const expresion=/[^a-zA-Z0-9.$, ]/g;
  
    if(expresion.test(entrada)){
      evento.preventDefault();
    }
   
  }

  public getSourceCodeRecovery(data:any){
    this.loading = true;
 
    this.sourcecodeAssignmentService.getSourceCodeRecovery(data).subscribe(
      data => {
        if (data) {
          this.sourceCodeModel = data;
          this.loading = false;
        }
        else {
          console.error(data);
          this.loading = false;
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true,ALERT_TYPE.WARNING);
        }
      },
      error => {
        console.error(error);
        this.loading = false;
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true, "ERROR");
      }
    );

    return true;
  }

  rellenar(item:any){
    if(item != "Seleccionar"){  
      this.ngSelectCh = this.sourceCodeModel.comercializadora;
      this.ngSelectCiclo = this.sourceCodeModel.ciclo;
      this.ngSelectSubCh = this.sourceCodeModel.subChannel;
      this.ngSelectDescr = this.sourceCodeModel.descripcion;
      this.sourcecodesListForApplicant = [];
    
      if( !Utils.isNullOrEmpty( this.sourceCodeModel.delivery1 ) && !Utils.isNullOrEmpty( this.sourceCodeModel.delivery2 ) ){
          this.sourcecodesListForApplicant.push( this.sourceCodeModel.delivery1 );
          this.sourcecodesListForApplicant.push( this.sourceCodeModel.delivery2 );
          this.ngSelectDc = this.sourceCodeModel.delivery1;
      }

    }else{
      console.log("campo vacio")
    }
  }

  setupTable(){

    this.showResult = true;
    this.loading = false;

    if (this.tablereports != null) {
      this.tablereports.clear().destroy();
    } 
    
    $(document).ready( () => {
      this.tablereports =   $("#result-table").DataTable({

      searching: true, 
      paging: true, 
      
      lengthChange: false,
      pageLength: 10,
      pagingType: 'full_numbers',
      columnDefs: [
        { targets: 'no-sort', orderable: false }
      ],        
      language: Environment.DataTableLanguageSetting
      
      });

    });

  }  

  export(){

    try{
      if(!this.rolConsulta){
        this.alertMessage=new AlertModel("No tiene permisos para exportar",this.title,true,"WARNING");
        return;
      }
    }catch{}

    let reportDataExport = new Array<any>();

    this.scData.forEach(item => {      
      reportDataExport.push({
        "NOMBRE Y APELLIDO":item.nombreapellido,
        "PCN":item.pcn,
        "CUIL": item.cuit,
        "FECHA":item.fecha,
        "ULTMA MODIFICACION":item.fecha_modificacion,
        "ESTADO":item.estado,
              
      });
    }); 

    this.commonDataService.exportAsExcelFile(reportDataExport, "ASIGNACION_DE_SOURCECODE");

  }  

  validationAccionLoad(){

    this.sourcecodeAssignmentService.getValidationAccion().subscribe(
      data => {
        if (data['data']) {
          this.accionAplicanteList.data = data['data'];
        }
        else {
          console.error(data);
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true);
        }
      },
      error => {
        console.error(error);
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true);
      }
    );

    return true;

  }

  aplicanteAccionChange(item){

    this.loadingMotivo = true;
    let request = {"parent" : item}
    this.ngMotivo = "";

    this.sourcecodeAssignmentService.getValidationMotivo(request).subscribe(
      data => {
        if (data['data']) {
          this.motivosAplicanteList.data = data['data'];

          let motivo = this.motivosAplicanteList.data.find(a=>a.key == this.aplicanteCurrent.validacion.motivo);
          if(motivo == null){
            this.aplicanteCurrent.validacion.motivo = "";
          }


          this.motivosAplicanteList.data.sort((a, b)=> a.key?.localeCompare(b.key));  
          
          //this.aplicanteMotivoChange(this.aplicanteCurrent.validacion.motivo);
            
        }
        else {
          console.error(data);
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true);
        }
        this.loadingMotivo = false;
      },
      error => {
        console.error(error + "😬");
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true);
      }
    );    

    return true;
  }

  backToResults(){
    this.showAplicanteDetalles = false;
    this.showResult = true;
    this.loading = false;

    this.resetValues();

    if(!Utils.isNullOrEmpty(this.aplicanteCurrent.solicitud_tarjeta.pcn)){
      this.commonDataService.pingApplicantHoldTimerStop();
      this.commonDataService.releaseApplicantHold(this.aplicanteCurrent.solicitud_tarjeta.pcn).subscribe();
    }

  }

  resetValues(){
    this.ngSelectSc = "";
    this.ngSelectCh = "";
    this.ngSelectCiclo = "";
    this.ngSelectSubCh = "";
    this.ngSelectDescr = "";
    this.ngSelectDc = "";
    this.sourcecodesListForApplicant = [];
    this.motivosAplicanteList.data = [];
    this.ngAccion = "";
    this.ngMotivo = "";
  }

  public validationsForm():boolean{
    let validations = "";
    let arrayErrors = [];

    if( Utils.isNullOrEmpty(this.ngSelectSc) )
      arrayErrors.push( "Debe seleccionar Sourcecode.");

    if( Utils.isNullOrEmpty(this.ngAccion) )
      arrayErrors.push( "Debe seleccionar una Acción.");

    if( Utils.isNullOrEmpty(this.ngMotivo) )
      arrayErrors.push( "Debe seleccionar un Motivo.");
   
    if( arrayErrors.length ) {

      for( var i = 0; i < arrayErrors.length; i++ ){
        var conc = ( i == 0 )? "- " : "<br> -";
        validations += conc + arrayErrors[ i ];
      }

        this.validateMessage = validations;
        this.alertMessage = new AlertModel(this.validateMessage,this.title,true,"WARNING");
        return false;
    }
    
    this.validateMessage = "";
    return true;

  }

  confirmSave(){

    if(!this.rolConsulta){
      this.alertMessage = new AlertModel("No tiene permisos para guardar",this.title, true, "WARNING");
      return;
    } 

    if(!this.validationsForm()){
      return;
    }
    
    this.alertConfirmEvent = this.onConfirmEvent;
    this.alertMessage = new AlertModel("Confirma que desea registrar la información?",this.title,true, "CONFIRM");	

  }

  onConfirmEvent(confirm:any){

    if(confirm.confirm){
      this.saveDocRecoveryAplicante();
    }
  }

  saveDocRecoveryAplicante(){

    this.validationsShow = false;      

    let aplicante: any = new Object;

    aplicante.usuario = this.sessionStorageService.getCurrentUser()?.username;    
    aplicante.promotor = this.sessionStorageService.getCurrentUser()?.promotor;
    
    aplicante.solicitud_tarjeta = this.aplicanteCurrent.solicitud_tarjeta;
    aplicante.entrega_documentacion = this.aplicanteCurrent.entrega_documentacion;
    aplicante.datos_personales = this.aplicanteCurrent.datos_personales;
    aplicante.datos_contacto = this.aplicanteCurrent.datos_contacto;
    aplicante.datos_laborales = this.aplicanteCurrent.datos_laborales;
    aplicante.adicionales = this.aplicanteCurrent.adicionales;
    aplicante.observaciones = this.aplicanteCurrent.observaciones;
    aplicante.validacion = this.aplicanteCurrent.validacion;    
    aplicante.detalles = this.aplicanteCurrent.detalles;

    aplicante.solicitud_tarjeta.application_source = this.ngSelectSc;
    aplicante.detalles.delivery_code = this.ngSelectDc;
    aplicante.validacion.accion = this.ngAccion;
    aplicante.validacion.motivo = this.ngMotivo;

    //console.log(aplicante);
    this.saveAplicante(aplicante);
  }

  public saveAplicante(aplicante) {

    this.loading = true;    

    this.sourcecodeAssignmentService.saveApplicantDataByArea(aplicante).subscribe(
      data => {
        if (data != null && data['code'] == "0"){
          
          this.loading = false;          
          this.showAplicanteDetalles = false;
          this.showResult = true;          
          
          this.alertMessage = new AlertModel("Los datos se guardaron con exito.", this.title, true, "SUCCESS");
          this.loadReportResults();
          window.scroll(0, 0);   
        }
        else {
          console.error(data);
          this.loading = false;
          this.alertMessage = new AlertModel("Hubo un inconveniente al guardar la información, inténtalo nuevamente.", this.title, true, ALERT_TYPE.WARNING);
        }

        this.commonDataService.pingApplicantHoldTimerStop();
        this.commonDataService.releaseApplicantHold(this.aplicanteCurrent.solicitud_tarjeta.pcn).subscribe();
		
      },
      error => {
        this.commonDataService.pingApplicantHoldTimerStop();
        this.commonDataService.releaseApplicantHold(this.aplicanteCurrent.solicitud_tarjeta.pcn).subscribe();
		

        console.error(error);
        this.loading = false;
        this.alertMessage = new AlertModel("Hubo un inconveniente al guardar la información, inténtalo nuevamente.", this.title, true, ALERT_TYPE.WARNING);
      }
    );     
    
    return true;
  }

  messageTrackingShow(){
    this.messagetrackingshow = true;
  }

}
import { Component, OnInit } from '@angular/core';
import { LoadingComponent } from '../shared/loading/loading.component';
import { AlertComponent, AlertModel, ALERT_TYPE } from '../shared/alert/alert.component';

import { ParametersService } from 'src/app/services/parameters/parameters.service';
import { ParametersModel } from './models/models';

import { Environment } from 'src/app/config/environment';
import { Utils } from 'src/app/commons/utils';
import { SessionStorageService } from 'src/app/auth/services/session.service';
import { ExcelExportService } from 'src/app/services/excelExport/excel.export.service';

@Component({
  selector: 'app-parameters',
  templateUrl: './parameters.component.html',
  styleUrls: ['./parameters.component.css']
})
export class ParametersComponent implements OnInit {

  public title: string = "Administrador de Parametros";
  public titleCRUD: string;
  public es: any;
  public showResult : boolean;
  public tablereports = null;
  public showNew : boolean; 
  public entityCurrent: ParametersModel = new ParametersModel;
  
  public loading: boolean;
  public alertMessage: AlertModel = new AlertModel;
  public alertConfirmEvent: Function;

  public validations: Array<any> = new Array<any>();
  public validationsShow: boolean; 

  public validateShow: boolean =  false;
  public validateMessage: string;
  public isUpdate:boolean = false;
  public isUpdateValor:boolean = false;


  public resultData = [];
  


  constructor(private parametersService: ParametersService,
              private excelExportService: ExcelExportService,
              private sessionStorageService: SessionStorageService) { 
      
              this.sessionStorageService.rolValidate("parameters");
  }



  ngOnInit() {
    this.setCalendarLocale();
    this.loadResultData();   

  }

  loadResultData(){
    
    this.loading = true;

    this.parametersService.getData().subscribe(
      data => {
        if (data['data']) {
          this.resultData = data['data'];

        }
        else {
          this.resultData = [];
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title,true);
        }

        this.setupTable();             
      },
      error => {
        this.resultData = [];
        this.setupTable();             

        console.error(error);
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title,true);
      }
    );

  }

  onConfirmEvent(confirm:any){

    if(confirm.confirm){
      this.delete();
    }else{
      this.entityCurrent = null;
    }
  }

  delete(){
      this.loading = true;

      let request = {
        "nombre" : this.entityCurrent.nombre,
        "codigo" : this.entityCurrent.codigo
      };


      this.parametersService.delete(request).subscribe(
        data => {
          if (data && data["code"] && data["code"] == "0") {
            
            this.loading = false;
            this.entityCurrent = new ParametersModel();
            this.alertMessage = new AlertModel("Los datos se guardaron con exito",this.title,true);
            this.loadResultData();
          }
          else {
            this.loading = false;
            console.error(data);
            this.alertMessage = new AlertModel("Hubo un inconveniente al eliminar la información, inténtalo nuevamente.",this.title,true);
          }
          
        },
        error => {
          this.loading = false;
          console.error(error);
          this.alertMessage = new AlertModel("Hubo un inconveniente al eliminar la información, inténtalo nuevamente.",this.title,true);
        }
      );    




  }

  deleteConfirm(id){    
    this.entityCurrent =  new ParametersModel();
    this.entityCurrent = Object.assign({}, this.resultData.find(m => { return m.id == id}));
    this.alertConfirmEvent = this.onConfirmEvent;

    this.alertMessage = new AlertModel("Está seguro de eliminar este parámetro?",this.title,true, "CONFIRM");
  }

  new(id){
    
    this.entityCurrent = new ParametersModel();
    this.showNew = true;
    this.showResult = false;

    if(id == null){
      this.titleCRUD = "Nuevo Parámetro";
    } else{
      this.isUpdate = true;
      this.titleCRUD = "Modificar Parámetro";
      this.entityCurrent = Object.assign({}, this.resultData.find(m => { return m.id == id}));
      if(this.entityCurrent.nombre == 'SOURCECODE'){
        this.isUpdateValor=true;
      }else{
        this.isUpdateValor=false;
      }
    }

    this.validateShow  =  false;
    this.validateMessage = "";

  }
 
  newCancel(){
    this.isUpdate = false;
    this.showResult = true;
    this.showNew = false;
  }

  newSave(){   

    if(!this.isUpdate){
      this.create();
    } else{
      this.update();
    }
  }

  create(){    
    
    if(this.resultData.some( c=>c.codigo ==this.entityCurrent.codigo )){
      this.alertMessage = new AlertModel("El parámetro que se intenta crear ya existe.",this.title,true);
      this.loading = false;    
      return;
    }

    if(!this.validationsForm()){
      this.validationsShow = true;
      this.alertMessage = new AlertModel("Existen campos no válidos, por favor revisarlos.",this.title, true, "WARNING");
      return;
    }   


    this.loading = true;

    this.parametersService.create(this.entityCurrent).subscribe(
      data => {

        if (data && data["code"] && data["code"] == "0") {
          this.showNew = false; 

          this.entityCurrent=  new ParametersModel();
          this.alertMessage = new AlertModel("Los datos se guardaron con exito",this.title,true);
          this.loadResultData();
        }
        else if (data && data["code"] && data["code"] == "100") {
          
          this.alertMessage = new AlertModel("Hubo un inconveniente: " + data["message"],this.title,true);
          this.loading = false;    
          return;      
        } else{
          this.alertMessage = new AlertModel("Hubo un inconveniente al crear la información, inténtalo nuevamente.",this.title,true);
          this.loading = false;   
        }

      },
      error => {
        console.error(error);
        this.alertMessage = new AlertModel("Hubo un inconveniente al crear la información, inténtalo nuevamente.",this.title,true);
        this.loading = false; 
      }
    );
    
    
  }

  update(){      

    if(!this.validationsForm()){
      this.validationsShow = true;
      this.alertMessage = new AlertModel("Existen campos no válidos, por favor revisarlos.",this.title, true, "WARNING");
      return;
    }    

    this.loading = true;

    let request = Object.assign({}, this.entityCurrent);
    request.activo = request.activo  ?? false;

    this.parametersService.update(request).subscribe(
      data => {

        if (data && data["code"] && data["code"] == "0") {
          this.showNew = false;      
          this.isUpdate = false;

          this.entityCurrent=  new ParametersModel();
          this.alertMessage = new AlertModel("Los datos se guardaron con exito",this.title,true, ALERT_TYPE.SUCCESS);
          this.loadResultData();
        }
        else if (data && data["code"] && data["code"] == "100") {
          
          this.alertMessage = new AlertModel("Hubo un inconveniente: " + data["message"],this.title,true);
          this.loading = false;    
          return;      
        } else{
          this.alertMessage = new AlertModel("Hubo un inconveniente al actualizar la información, inténtalo nuevamente.",this.title,true);
          this.loading = false;   
        }

        

      },
      error => {
        console.error(error);
        this.alertMessage = new AlertModel("Hubo un inconveniente al actualizar la información, inténtalo nuevamente.",this.title,true);
        this.loading = false;
      }
    );
    
    
  }  

  
  validationsForm(){
    this.validations = new Array<any>();

    if(Utils.isNullOrEmpty(this.entityCurrent.valor))
      this.validations.push({"parametro.valor" : "este campo es requerido."});

    if(Utils.isNullOrEmpty(this.entityCurrent.nombre))
      this.validations.push({"parametro.nombre" : "este campo es requerido."});

    if(Utils.isNullOrEmpty(this.entityCurrent.codigo))
      this.validations.push({"parametro.codigo" : "este campo es requerido."});



    if(!Utils.isNullOrEmpty(this.entityCurrent.maxlenght)){
      
      var maxlength = parseInt(this.entityCurrent.maxlenght);

      if(this.entityCurrent.valor.length > maxlength){
        this.validations.push({"parametro.valor" : "este campo tiene un largo maximo de " + maxlength + "."})
      }

      

    }

    if(!Utils.isNullOrEmpty(this.entityCurrent.regex)){
      
      let pattern = this.entityCurrent.regex;
      let regex = new RegExp(pattern, 'g');

      if(!regex.test(this.entityCurrent.valor)){
        if(Utils.isNullOrEmpty(this.entityCurrent.regexmessage)){
          this.validations.push({"parametro.valor" : "este campo debe cumplir con el formato correcto."});
        }else{
          this.validations.push({"parametro.valor" : "verifique este campo: " + this.entityCurrent.regexmessage});
        }
        
      }      

    }


    return this.validations.length == 0;
  }

  public validationsMessage(name):string{
    if(!this.validationsShow)
      return null;

    this.validationsForm();

    let validation = this.validations.find(v => v[name] != null);

    if(validation){
      return validation[name];
    }
      
    return  null;
  }

  backToResults(){
    this.showResult = true;
    this.loading = false;

  }

  setDateFormat(value:Date){

     return Utils.setDateFormat(value);
  }



  export(){

    let reportDataExport = new Array<any>();

    this.resultData.forEach(item => {      
      reportDataExport.push({
        "nombre": item.nombre,
        "Código":item.codigo,
        "Valor":item.valor,
        "Descripción":item.descripcion,
        "Activo":item.activo ? "SI" : "NO",
              
      });
    }); 

    this.excelExportService.exportAsExcelFile(reportDataExport, this.title);

  }

  ifLabel(valor){    
    return !Utils.isNullOrEmpty(valor);
  }

  private setupTable(){

    this.showResult = true;
    this.loading = false;

    if (this.tablereports != null) {
      this.tablereports.clear().destroy();
    } 
    
    $(document).ready( () => {
        this.tablereports =   $("#result-table").DataTable({

        searching: true, 
        paging: true, 
        //info: false,
        lengthChange: false,
        pageLength: 10,
        pagingType: 'full_numbers',
        columnDefs: [
          { targets: 'no-sort', orderable: false },
          //{ width: "70px", targets: 6 }
        ],        
        language: Environment.DataTableLanguageSetting
       });


    });

  }  

  setCalendarLocale(){

    this.es = Environment.CalendarLanguageSetting;
  }

  

}


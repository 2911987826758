import { Injectable } from '@angular/core';
import * as $ from 'jquery';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Environment } from '../../config/environment';
import { Config } from '../../config/config';


@Injectable({
  providedIn: 'root'
})
export class InternalReportAgenciesService {


    constructor(private http: HttpClient, private Conf: Config) { }

    public getInternalData(data){

      let url = this.Conf.ENDPOINT_API + 'internal/getReportStatusSolic';
      let request = Object.assign({}, data);
      let headers = new HttpHeaders({
        'Content-Type': 'application/json',
      })
      let wc = (Environment.CREDENTIALS) ? true : false;
      
      return this.http.post(url, request, {headers: headers, observe: "response",withCredentials: wc})
        
    }

    public getListProvince(){

      let url = this.Conf.ENDPOINT_API + 'getProvincias';
      let headers = new HttpHeaders()
      let wc = (Environment.CREDENTIALS) ? true : false;
      
      return this.http.get(url, {observe: "response",withCredentials: wc})

    }
    
    public getAllPromotores(){

      let url = this.Conf.ENDPOINT_API + 'getPromotores';
      let headers = new HttpHeaders()
      let wc = (Environment.CREDENTIALS) ? true : false;
      
      return this.http.get(url,  {observe: "response",withCredentials: wc})
        
    }
}
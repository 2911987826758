import { Injectable } from '@angular/core';
import * as $ from 'jquery';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Environment } from '../../config/environment';
import { Config } from '../../config/config';

@Injectable({
    providedIn: 'root'
  })

  export class ParametersIncomeNseService {


    constructor(private http: HttpClient, private Conf: Config) { }

    getCabParametersIncomeNseList(){
      
      let httpOptions = {
        withCredentials: false
      };
  
      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }
  
      let url = this.Conf.ENDPOINT_API + 'getParamNosisCab';  
  
      return this.http.get(url, httpOptions);
    }


    getParamIncNseDetByCab( data ){
      
      let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      };
  
      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }
  
      let url = this.Conf.ENDPOINT_API + 'getParamNosisDetByCab';  
      let request = Object.assign({}, data);
  
      return this.http.post(url,request, httpOptions);
    }

    updateMatrix( arrayParamNosisDetail ){
      
      let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      };
  
      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }
  
      let url = this.Conf.ENDPOINT_API + 'updateParamNosis';  
      let request = Object.assign([], arrayParamNosisDetail);
  
      return this.http.post(url,request, httpOptions);
    }

    uploadMassiveNormalizePatterns(file: File, instanciaId:string, tipoParam:string, isNew:string) {

      let formData:FormData = new FormData();
      let filename = file.name;
      formData.append('file', file, filename);
      formData.append('instanciaId', instanciaId);
      formData.append('tipoParam', tipoParam);
      formData.append('isNew', isNew);

      let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          //'Content-Type': 'multipart/form-data',
          'Accept': 'application/json'
        })
      };

      
      let url = this.Conf.ENDPOINT_API + 'uploadParamNosis';

      return this.http.post(url, formData, httpOptions);

    }
    
    createNewSetParams(data) {

      let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      };
  
      if (Environment.CREDENTIALS) {
        httpOptions.withCredentials = true;
      }
  
      let url = this.Conf.ENDPOINT_API + "createParamNosisComplete";
      let request = Object.assign({}, data);
  
      return this.http.post(url, request, httpOptions);
    }


    deleteParamNosis( data:string ){

      let formData:FormData = new FormData();
      formData.append('instanciaId', data);

      let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          'Accept': 'application/json'
        })
      };

      
      let url = this.Conf.ENDPOINT_API + 'deleteParamNosis';

      return this.http.post(url, formData, httpOptions);
    }
}
import { Component, OnInit } from '@angular/core';
import { BusquedaFiltrosModel, CustomerDetailModel,InternalCustomerDetailModel,InternalSourceCode,ProductoObj } from './models/models';

import { AlertModel } from '../../component/shared/alert/alert.component';
import { InternalCustomerDetailService } from '../../services_internal/internalCustomerDetail/internalCustomerDetail.service'
import { CommonDataService } from 'src/app/services/commons/commondata.service';
import { Utils } from 'src/app/commons/utils';
import { SessionStorageService } from 'src/app/auth/services/session.service';
import { ProductosList } from 'src/app/models/lists.model';

@Component({
  selector: 'app-internalCustomerDetail',
  templateUrl: './internalCustomerDetail.component.html',
  styleUrls: ['./internalCustomerDetail.component.css']
})


export class InternalCustomerDetailComponent {
  public es: any;
  public titulo: string = "CONSULTA ESTADO SOLICITUD SOCIO";
  public loading: boolean;
  public alertMessage: AlertModel = new AlertModel;
  public dataResult: CustomerDetailModel = new CustomerDetailModel;
  public internalCustomerDetailModel: InternalCustomerDetailModel = new InternalCustomerDetailModel;
  public showResult : boolean;
  public showSuples: boolean;
  public cssShow: string;
  public tablereports = null;
  public productosList: ProductosList = new ProductosList();

  public busquedafiltros: BusquedaFiltrosModel = new BusquedaFiltrosModel; 

  constructor(private internalCustomerDetailService: InternalCustomerDetailService,
              private sessionStorageService: SessionStorageService,
              private commonDataService: CommonDataService) { 

                this.sessionStorageService.rolValidate("internalConsultas");
   }

    ngOnInit() {
      this.loadProductos();
    }
  loadProductos(){

    this.commonDataService.getProductsAndId().subscribe(
      data => {
        if (data['code'] == "0" && data['data']) {      
          
          this.productosList.data = Object.assign([], data['data']);

        }
        else {
          console.error(data);
        }

        
      },
      error => {
        console.error(error);
      }
    );

  }

  loadResultCustomerDetail(){   

    if(Utils.isNullOrEmpty(this.busquedafiltros.pcn) && 
       Utils.isNullOrEmpty(this.busquedafiltros.dni) &&
       Utils.isNullOrEmpty(this.busquedafiltros.nombre)
       && Utils.isNullOrEmpty(this.busquedafiltros.cuil)) {
      
      this.alertMessage = new AlertModel( "Se requiere completar al menos un filtro.",this.titulo, true,'WARNING');
      return;
    }

    this.showResult = false;
    this.loading = true;
    this.internalCustomerDetailModel.lastObservation = "";
    let request = { 
        "pcn":this.busquedafiltros.pcn == "" ? null : this.busquedafiltros.pcn, 
        "dni":this.busquedafiltros.dni == "" ? null : this.busquedafiltros.dni,
        "cuil":this.busquedafiltros.cuil == "" ? null : this.busquedafiltros.cuil,
        "nombreapellido":this.busquedafiltros.nombre == "" ? null : this.busquedafiltros.nombre, 
        "matriz": this.sessionStorageService.currentSession.user.matriz
    };
    //let request = {"pcn":"20230704000054INT"}
    let arrayTipoVentas = {"SUP":"Venta Isolada","RCO":"Reverse Companion","CON":"Conversion (Upgrade)","COM":"Companion"}
    this.internalCustomerDetailService.getCustomerDetailData(request).subscribe(
      response => {

        if( response.status == 200 && response.body != null){
          var data = response.body;
          this.internalCustomerDetailModel = Object.assign(this.internalCustomerDetailModel, data);
          this.internalCustomerDetailModel.tipoVenta = ( typeof arrayTipoVentas[ this.internalCustomerDetailModel.tipoVenta ] != "undefined" ) ? arrayTipoVentas[ this.internalCustomerDetailModel.tipoVenta ] : this.internalCustomerDetailModel.tipoVenta;
          if( this.internalCustomerDetailModel.nombreCompleto != null ){
            this.internalCustomerDetailModel.nombreCompleto = this.internalCustomerDetailModel.nombreCompleto.toLowerCase();
          }
          if( this.internalCustomerDetailModel.internalSourceCode == null ){
            this.internalCustomerDetailModel.internalSourceCode = new InternalSourceCode();
          }
          let prod = this.productosList.data.find(p=> (p.key??"") == parseInt( this.internalCustomerDetailModel.informedProduct ) );

          if( this.internalCustomerDetailModel.tipoVenta == "Venta Isolada" && this.internalCustomerDetailModel.intSocioData != null ){
            prod = this.productosList.data.find(p=> (p.key??"") == parseInt( this.internalCustomerDetailModel.intSocioData.producto ) );
          }

          if(prod){
            this.internalCustomerDetailModel.productoObj = prod;
          }else{
            this.internalCustomerDetailModel.productoObj = new ProductoObj();
          }
          // console.log( this.internalCustomerDetailModel );
          this.showResult = true;
          
          if( this.internalCustomerDetailModel.intObservaciones.length ){
            this.internalCustomerDetailModel.intObservaciones.sort(function(a, b) {
              var textA = a.id;
              var textB = b.id;
              return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
            });
            this.internalCustomerDetailModel.lastObservation = this.internalCustomerDetailModel.intObservaciones[0].observacion;
          }
          
          if( this.internalCustomerDetailModel.intPersonaSuples.length ){
            for( var i = 0; i < this.internalCustomerDetailModel.intPersonaSuples.length; i++ ){
              var objSuple = this.internalCustomerDetailModel.intPersonaSuples[ i ]
              objSuple.index = i+1;

              if( objSuple.nombreCompleto == "" || objSuple.nombreCompleto == null ){
                objSuple.nombreCompleto = objSuple.nombre1 + " " +objSuple.apellido;
              }

              if( objSuple.denegado != null && objSuple.denegado ){
                objSuple.denegado = "Denegado";
              }else{
                objSuple.denegado = "Aprobado";
              }
              if( objSuple.tipoDoc == "" || objSuple.tipoDoc == null ){
                objSuple.tipoDoc = "DNI";
              }

              var value = objSuple.idProd;
              let prod = this.productosList.data.find(p=> (p.key??"") == parseInt( value ) );
              if(( this.internalCustomerDetailModel.tipoVenta == "Reverse Companion" 
                || this.internalCustomerDetailModel.tipoVenta == "Conversion (Upgrade)" )
                && this.internalCustomerDetailModel.canalArchivo == "Telemarketing" 
                && this.internalCustomerDetailModel.productoObj.data.prod == "50"
                && objSuple.plasticColorType != "00" ){
                  prod = this.productosList.data.find(p=> (p.data.plasticColorType??"") == objSuple.plasticColorType );
                }
                
              if( prod ){
                objSuple.prod = prod.data.prod; 
                objSuple.prodName = prod.data.prodName; 
              }
              

            }
            console.log( this.internalCustomerDetailModel );
            this.showSuples = true;
            this.cssShow = "";
          }else{
            this.showSuples = false;
            this.cssShow = "show";
          }
        }else if( response.status == 204 ){
          this.alertMessage = new AlertModel( "No se encontraron registros con los datos ingresados.",this.titulo, true);
        }else{
          this.alertMessage = new AlertModel( "Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.titulo, true,"WARNING");
        }

        this.loading = false;
        
      }, err => {
        this.alertMessage = new AlertModel( "Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.titulo, true,"WARNING");
        this.loading = false;
        throw err;
      }
    
    );

  }

  refactorObservationPaste(event:ClipboardEvent){ 
    this.dataResult.observacion=this.sanitizedText(this.dataResult.observacion); 
   }
   sanitizedText(text:string):string{
     return text.replace(/[^a-zA-Z0-9.$, ]/g,'')
   }

   refactorObservation(evento:any){
    const entrada= String.fromCharCode(evento.keyCode);
    const expresion=/[^a-zA-Z0-9.$, ]/g;
  
    if(expresion.test(entrada)){
      evento.preventDefault();
    }
   
  }

  }

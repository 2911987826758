import { Injectable } from '@angular/core';
import * as $ from 'jquery';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Environment } from '../../config/environment';
import { Config } from '../../config/config';
import { config } from 'process';
import { of } from 'rxjs';
import { MockStatic } from 'src/app/config/mock.static';


@Injectable({
  providedIn: 'root'
})
export class PreCheckService {


    constructor(private http: HttpClient, private Conf: Config) { }

    public getPrecheckData(filters){

      //return of(MockStatic.PRECHECK_getPrecheckData)

      let httpBody;

      let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }



      let url = this.Conf.ENDPOINT_API + 'getprecheck';

    
      return this.http.post(url, filters, httpOptions);   

    }

    public getVendorsList(){

    

      let httpBody;

      let httpOptions = {
        withCredentials: false
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }



      let url = this.Conf.ENDPOINT_API + 'getVendorsList';

    
      return this.http.get(url, httpOptions);   

    }    


}
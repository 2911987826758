import { Injectable } from '@angular/core';
import * as $ from 'jquery';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Environment } from '../../config/environment';
import { Config } from '../../config/config';
import { config } from 'process';
import { Observable, of } from 'rxjs';
import { MockStatic } from 'src/app/config/mock.static';


@Injectable({
  providedIn: 'root'
})
export class InternalMassiveDeliveryService {

    
    constructor(private http: HttpClient, private Conf: Config) { }


    aprobacionMasivaFileUpload(file: File, usuario:string) {

      let formData:FormData = new FormData();
      let filename = file.name;
      //let filename = file.name;
      formData.append('file', file, filename);
      formData.append('usuario', usuario);      

      let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          //'Content-Type': 'multipart/form-data',
          'Accept': 'application/json'
        })
      };

      
      let url = this.Conf.ENDPOINT_API + 'procesamientoMasivo';

      return this.http.post(url, formData, httpOptions);

    }

    aprobacionMasivaInternalDeliveryFileUpload(file: File, usuario:string) {

      let formData:FormData = new FormData();
      let filename = file.name;
      //let filename = file.name;
      formData.append('file', file, filename);
      formData.append('usuario', usuario);      

      let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          //'Content-Type': 'multipart/form-data',
          'Accept': 'application/json'
        })
      };

      
      let url = this.Conf.ENDPOINT_API + 'intprocesamientoMasivo';

      return this.http.post(url, formData, httpOptions);

    }


    aprobacionMasivaFileDownload(data) {

      let httpOptions = {
        withCredentials: false,
        responseType:'text',
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      };
      
      let url = this.Conf.ENDPOINT_API + 'downloadProMasivo';
      let request = Object.assign({}, data);

      return this.http.post(url, request, httpOptions as any);     

    }

    
    public getListIngresoArchivo(filters) {
      let url = this.Conf.ENDPOINT_API + 'internal/listIngresoArchivo';
      return this.http.post(url, filters);   
    }
}
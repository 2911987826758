export class BusquedaFiltrosModel {
    fechaCapturaInicio: string;
    fechaCapturaFin: string;
    producto: string;
    promotor: string;
    canalArchivo: string;
    tipoVenta: string;
    estado: string;
}

export class Vendor{
    id: string;
    name: string;
}


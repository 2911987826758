import { AbstractType, Component, ElementRef, OnInit, AfterContentInit,
  AfterViewChecked, AfterViewInit, OnDestroy, ViewEncapsulation } from '@angular/core';

import { LoadingComponent } from '../shared/loading/loading.component';
import { PreCheckService } from '../../services/precheck/precheck.service';
import { VendorFilterModel, PreCheckFilterModel, FuentesFilterModel } from './models/models';
import * as _ from 'lodash';
import { observeOn } from 'rxjs/operators';
import { Environment } from 'src/app/config/environment';
import { ExcelExportService } from 'src/app/services/excelExport/excel.export.service';
import { AlertModel, ALERT_TYPE } from '../shared/alert/alert.component';
import { SessionStorageService } from 'src/app/auth/services/session.service';
import { Roles } from 'src/app/auth/models/roles.static';
import { Utils } from 'src/app/commons/utils';
import { User } from 'src/app/auth/models/user.model';
import { CommonDataService } from 'src/app/services/commons/commondata.service';
import { ProductosList } from 'src/app/models/lists.model';
import { BaseComponent } from '../shared/base.component';

@Component({
  selector: 'app-precheck',
  templateUrl: './precheck.component.html',
  styleUrls: ['./precheck.component.css']
})

export class PreCheckComponent extends BaseComponent  implements OnInit {

   
  public title = "STATUS DE PROCESAMIENTO DE PRECHECK";
  public es: any;
  public showResult : boolean;
  public loading: boolean;
  public precheckDataList = [];
  public vendorsLisButtonText: string = "AGENCIAS";  
  public vendorsListEnabled: boolean;  
  public vendorsList :Array<VendorFilterModel> = new Array<VendorFilterModel>();  
  public productosList :ProductosList = new ProductosList();
  public precheckFilter : PreCheckFilterModel = new PreCheckFilterModel();
  public tablereports = null;
  public alertMessage: AlertModel = new AlertModel;
  public setDateFormat: Function = Utils.setDateFormat;
  public vendorRoles = [ Roles.AMX_ADMIN, Roles.AMX_MANAG, Roles.AMX_RIESGO_ADM];

  fechaProcesamiento : Date;
  shortFormCheck: boolean;
  longFormCheck: boolean;
  facebookCheck: boolean;
  mgmCheck: boolean;
  todasFuentesCheck: boolean;
  fuentesProcesadasLabel: string;
  vendorsLabel: string;

  constructor(private precheckService: PreCheckService,
              private commonDataService: CommonDataService,
              private sessionStorageService: SessionStorageService,
              private excelExportService: ExcelExportService) { 
                super();
                this.sessionStorageService.rolValidate("precheck");
              }

  ngOnInit() {
    this.showResult=false;
    this.setCalendarLocale();
    this.loadVendors();   
    this.loadProductos();



    let user = this.sessionStorageService.getCurrentUser();

    if(this.sessionStorageService.hasRolesUser(this.vendorRoles))    
      this.vendorsListEnabled =  true;
    else {
      this.vendorsListEnabled =  false;
      this.vendorsLisButtonText = Utils.isNullOrEmpty(user.agencia) ? this.vendorsLisButtonText : user.agencia;
    }

  }


  showVariblesSalida(value):boolean{
    let estados = ["DATO RECHAZADO POR CRITERIO NOSIS",
                   "DATO RECHAZADO POR CRITERIO VRZ"
                  ];  

    if(Utils.isNullOrEmpty(value))
        return false;

    return estados.includes(value);

  }

  getDetalleParse(variables_salida: any){
    let detalleReturn = [];

    for(var key in variables_salida)
    {       
       if(variables_salida[key])
       {

          if(key == "cb_derog"){
            detalleReturn.push({"key":"tenure", "value":variables_salida[key]});
            continue;
          }

          detalleReturn.push({"key":key, "value":variables_salida[key]});
       }
    }

    return detalleReturn;
  }

  uncheckTodasFuentes(checkvalue: boolean) {

    if(this.todasFuentesCheck && !checkvalue){
      this.todasFuentesCheck = false;


    }
  }

  seleccionarTodasFuentes(){
   
    this.shortFormCheck = this.todasFuentesCheck;
    this.longFormCheck = this.todasFuentesCheck;    
    this.facebookCheck = this.todasFuentesCheck;
    this.mgmCheck = this.todasFuentesCheck;

    if(this.vendorsListEnabled) {
      this.vendorsList.forEach(i=>{
          i.selected = this.todasFuentesCheck;
      });
    }


  }

  setLabelsFilters(){

    let fuentesList = [];

    if(this.shortFormCheck )
      fuentesList.push("Short Form");

    if(this.longFormCheck )
      fuentesList.push("Long Form");

    if(this.facebookCheck )
      fuentesList.push("Facebook");

    if(this.mgmCheck)
      fuentesList.push("MGM");



    this.fuentesProcesadasLabel =  fuentesList.join(" + ");
  
    let vendorsAll = (this.vendorsList.length == this.vendorsList.filter(v=>v.selected).length);

    if(vendorsAll)
      this.vendorsLabel = "TODOS";
    else if(this.vendorsList.filter(v=>v.selected).length == 0)
      this.vendorsLabel = "NINGUNO" 
    else
      this.vendorsLabel  = this.vendorsList.filter((i) => i.selected).map((i)=> i.name).join(" + ");  
   


  }

  loadReport(){

this.rolesAutorization();

    if(!Utils.dateIsValid(this.precheckFilter.fecha_procesamiento_inicio)) {
      this.alertMessage = new AlertModel("Fecha de Procesamiento Desde es requerida.", this.title, true, ALERT_TYPE.INFO);
      return;
    }

    if(Utils.dateIsValid(this.precheckFilter.fecha_procesamiento_inicio) &&
       Utils.dateIsValid(this.precheckFilter.fecha_procesamiento_fin)){

        let cap_days =  Utils.dateDiffBetween(this.precheckFilter.fecha_procesamiento_inicio, this.precheckFilter.fecha_procesamiento_fin, "days");

        if(cap_days < 0){
          this.alertMessage = new AlertModel("Fecha de Procesamiento: Desde debe ser mayor que Hasta.",this.title,true);
          return;
        }  


    }

    if(!this.shortFormCheck && !this.longFormCheck 
      && !this.facebookCheck && !this.mgmCheck
      && !this.vendorsList.some(v=> v.selected)){
      this.alertMessage = new AlertModel("Fuentes de procesamiento es requerida.", this.title, true, ALERT_TYPE.INFO);
      return;
    }

    this.setLabelsFilters();
    this.loading = true;
    this.showResult = false;



    
    this.precheckFilter.fuentes = new Array<FuentesFilterModel>(); 

    if(this.shortFormCheck)
       this.precheckFilter.fuentes.push(new FuentesFilterModel("SHORT_FORM"));

    if(this.longFormCheck)
       this.precheckFilter.fuentes.push(new FuentesFilterModel("LONG_FORM"));

    if(this.facebookCheck)
       this.precheckFilter.fuentes.push(new FuentesFilterModel("FACEBOOK"));

    if(this.mgmCheck)
       this.precheckFilter.fuentes.push(new FuentesFilterModel("MGM"));

    



    let vendorsSelected = this.vendorsList.filter(v=> v.selected);

    if(vendorsSelected.length != 0) {
      let vendorsIds = [];

      vendorsSelected.forEach(i=>{
        vendorsIds.push(i.id);
      });


      
      this.precheckFilter.fuentes.push(new FuentesFilterModel("TELEMARKETING", vendorsIds.join()));
    }
    

    

    this.loadReportRequest();

  }

  loadReportRequest(){
    
    let request = Object.assign({}, this.precheckFilter);  

    if(!Utils.dateIsValid(request.fecha_procesamiento_fin))
      request.fecha_procesamiento_fin = request.fecha_procesamiento_inicio;  

    this.precheckService.getPrecheckData(request).subscribe(
      data => {
        if (data['code'] == "0") {
          this.precheckDataList = data['data'];

          this.precheckDataList.forEach(i=>{
             i.estado = (i.estado == null ? "" : i.estado.toLocaleUpperCase().replace("APROBAR", "APROBADO"));
             
          });

          this.setupTable();
   
        }
        else {

          let message  = data['message'] ? data['message'] :"Hubo un inconveniente al obtener la información, inténtalo nuevamente.";
          this.alertMessage = new AlertModel(message, this.title, true, ALERT_TYPE.WARNING);
          this.loading = false;
                    
        }
      },
      error => {
        
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true, ALERT_TYPE.WARNING);
        this.loading = false;

        this.setupTable();

      }
    );

  }

  tieneFechaProcesamientoFin():boolean{
   return Utils.dateIsValid(this.precheckFilter.fecha_procesamiento_fin);
  }

  loadVendors(){

    this.precheckService.getVendorsList().subscribe(
      data => {
        if (data['data']) {
          
          this.vendorsList = Object.assign(this.vendorsList, data['data']);

          let user = this.sessionStorageService.getCurrentUser();

          if(!this.sessionStorageService.hasRolesUser(this.vendorRoles)){
            let vendor = this.vendorsList.find(r=> String(r.id) == user.promotor);

            if(vendor){
              vendor.selected = true;
              this.vendorsList = [vendor]
            }

          }       
   
        }
        else {
          console.error(data);
        }

        this.loading = false;
      },
      error => {
        console.error(error);
      }
    );

    
    

  }

  loadProductos(){

    this.commonDataService.getProductos().subscribe(
      data => {
        if (data['code'] == "0" && data['data']) {          
          this.productosList.data = Object.assign([], data['data']);
        }
        else {
          console.error(data);
        }

        this.loading = false;
      },
      error => {
        console.error(error);
      }
    );

    
    

  }
 
  setupTable(){

    this.showResult = true;
    this.loading = false;

    if (this.tablereports != null) {
      this.tablereports.clear().destroy();
    } 
    
    $(document).ready( () => {
       this.tablereports = $("#precheck-result-table").DataTable({

        searching: false, 
        paging: true, 
        
        lengthChange: false,
        pageLength: 10,
        pagingType: 'full_numbers',  
        language: Environment.DataTableLanguageSetting
       });


    });

  }

  public rolConsulta:boolean=true;
  public rolesAutorization(){
    let rolesSession =this.sessionStorageService.getCurrentUser().roles
    console.log(rolesSession)
    if(rolesSession.includes("AMX_CONSULTA")){
      this.rolConsulta=false
    }
  }

  alertaPermisosExportar(){
    try{
      if(!this.rolConsulta){
        this.alertMessage=new AlertModel("No tiene permisos para exportar",this.title,true,"WARNING");
        return;
      }
    }catch{}
  }

  exportar(){

    this.alertaPermisosExportar();

    let reportExport = new Array<any>();

    this.precheckDataList.forEach(item => {

      if(item.variables_salida == null)
         item.variables_salida = []
      
      reportExport.push({
        "pcn":item.pcn,
        "cuil": item.cuil,
        "dni": item.dni,
        "genero":item.genero,
        "promotor":item.promotor,
        "promotor_original":item.promotor_original,
        "producto":item.producto,
        "estado":item.estado,
        "motivo":item.motivo,
        "fuente": item.fuente,
        "limiteFinal": item.limiteFinal,
        "Score NOSIS":item.variables_salida["score_nosis"] ? item.variables_salida.score_nosis : "",
        "Score Veraz":item.variables_salida["score_veraz"] ? item.variables_salida.score_veraz : "",
        "Income Nosis":item.variables_salida["income_nosis"] ? item.variables_salida.income_nosis : "",
        "Tenure":item.variables_salida["cb_derog"] ? item.variables_salida.cb_derog : "",
        "bera_peor_sit_u12m":item.variables_salida["bcra_peor_sit_u12m"] ? item.variables_salida.bcra_peor_sit_u12m : "",
        "peor_sit_bureau_u12m":item.variables_salida["peor_sit_bureau_u12m"] ? item.variables_salida.peor_sit_bureau_u12m : "",
        "axp_risk_score":item.variables_salida["axp_risk_score"] ? item.variables_salida.axp_risk_score : "",
        "Lim_TC_Preferida":item.variables_salida["lim_TC_Preferida"] ? item.variables_salida.lim_TC_Preferida : "",
        "income_avg":item.variables_salida["income_avg"] ? item.variables_salida.income_avg : ""
      });

    });   

    this.excelExportService.exportAsExcelFile(reportExport, this.title);
  }

  


  setStringToDateFormat(value:Date){

    let day = value.getDate();
    let month = (value.getMonth() + 1)
    let year = value.getFullYear()

    let daytr = day < 9 ? '0' + day : day;
    let monthstr = month < 9 ? '0' + month : month;


     return daytr + "/" + monthstr + "/" + year;

  }

  setCalendarLocale(){

    this.es = Environment.CalendarLanguageSetting;
  }

}

import { Component, OnInit } from '@angular/core';
import { BusquedaFiltrosModel, AplicantDetailModel } from './models/models';//src/app/models/aplicante.model
import { CustomerModel, IntPersonaSuples, IntObservaciones ,InternalSourceCode
} from 'src/app/models/aplicante.model';
import { Router } from '@angular/router';

import { AlertComponent, AlertModel, ALERT_TYPE } from '../../component/shared/alert/alert.component';
import { Environment } from 'src/app/config/environment';
import { CommonDataService } from 'src/app/services/commons/commondata.service';
import { Utils } from 'src/app/commons/utils';
import { SessionStorageService } from 'src/app/auth/services/session.service';
import { InternalReprocessApplicantService } from 'src/app/services_internal/internalReprocessApplicant/internalReprocessApplicant.service';
import { ProductosList } from 'src/app/models/lists.model';
import { GenerosList } from 'src/app/models/lists.model';


@Component({
  selector: 'app-internalReprocessApplicant',
  templateUrl: './internalReprocessApplicant.component.html',
  styleUrls: ['./internalReprocessApplicant.component.css']
})


export class InternalReprocessApplicantComponent {
  public es: any;
  public title: string = "REPROCESAR APLICANTE INTERNO";

  public loading: boolean;

  public alertMessage: AlertModel = new AlertModel;
  public alertConfirmEvent: Function;

  public dataResult: AplicantDetailModel = new AplicantDetailModel;
  public showResult: boolean;
  public tablereports = null;

  public busquedafiltros: BusquedaFiltrosModel = new BusquedaFiltrosModel;
  public internalReprocessApplicant: CustomerModel = new CustomerModel;

  public isCuil: boolean = true;
  public isExcepcion: boolean = false;
  public showSuples: boolean;
  public cssShow: string;
  public selector: string = "Reproceso";
  public productosList: ProductosList = new ProductosList();
  public productoObj:any = [];

  constructor(private internalReprocessApplicantService: InternalReprocessApplicantService,
    private sessionStorageService: SessionStorageService,
    private router: Router,
    private commonDataService: CommonDataService) {

    if (!(this.sessionStorageService.hasRolesUser(["AMX_INT_ADMIN"]) || 
          this.sessionStorageService.hasRolesUser(["AMX_INT_RIESGO_ADM"])))
            this.sessionStorageService.redirectToLogin();

      if (this.router.url.includes('internal-reprocessApplicantException')) {
        this.isExcepcion = true;
        this.title = "REPROCESAR APLICANTE INTERNO POR EXCEPCION DE RIESGO"
      }
  }


  loadResults() {

    if (Utils.isNullOrEmpty(this.busquedafiltros.pcn) &&
      Utils.isNullOrEmpty(this.busquedafiltros.dni) &&
      Utils.isNullOrEmpty(this.busquedafiltros.nombre)
      && Utils.isNullOrEmpty(this.busquedafiltros.cuil)) {

      this.alertMessage = new AlertModel("Se requiere completar al menos un filtro.", this.title, true);
      return;
    }


    this.showResult = false;
    this.loading = true;
    this.loadProductos();
    this.loadResultsRequest();

  }

  loadResultsRequest() {


    let request = { 
      "pcn":this.busquedafiltros.pcn == "" ? null : this.busquedafiltros.pcn, 
      "dni":this.busquedafiltros.dni == "" ? null : this.busquedafiltros.dni,
      "cuil":this.busquedafiltros.cuil == "" ? null : this.busquedafiltros.cuil,
      "nombreapellido":this.busquedafiltros.nombre == "" ? null : this.busquedafiltros.nombre, 
      "motivo" : "PRECHECK RECHAZADO"
    };
    
    let arrayTipoVentas = {"SUP":"Venta Isolada","RCO":"Reverse Companion","CON":"Conversion (Upgrade)","COM":"Companion"}
    this.internalReprocessApplicantService.getInternalDetailRejected(request).subscribe(
      response => {

        if( response.status == 200 && response.body != null){
          var data = response.body;

          this.internalReprocessApplicant = Object.assign(this.internalReprocessApplicant, data);
  
          if(this.internalReprocessApplicant.internalSourceCode == null){
            this.internalReprocessApplicant.internalSourceCode = new InternalSourceCode
          }
          
          let prod = this.productosList.data.find(p=> (p.key??"") == parseInt( this.internalReprocessApplicant.informedProduct ) );
          this.internalReprocessApplicant.productoObj = prod;

          this.internalReprocessApplicant.tipoVenta = ( typeof arrayTipoVentas[ this.internalReprocessApplicant.tipoVenta ] != "undefined" ) ? arrayTipoVentas[ this.internalReprocessApplicant.tipoVenta ] : this.internalReprocessApplicant.tipoVenta;
          if( this.internalReprocessApplicant.nombreCompleto != null ){
            this.internalReprocessApplicant.nombreCompleto = this.internalReprocessApplicant.nombreCompleto.toLowerCase();
          }
          this.showResult = true;
          if( this.internalReprocessApplicant.intPersonaSuples.length ){
            for( var i = 0; i < this.internalReprocessApplicant.intPersonaSuples.length; i++ ){
              let objSuple = this.internalReprocessApplicant.intPersonaSuples[ i ]
              let prodSuple = null;
              objSuple.index = i+1;

              if( objSuple.nombreCompleto == "" || objSuple.nombreCompleto == null ){
                objSuple.nombreCompleto = objSuple.nombre1 + " " +objSuple.apellido;
              }

              if( objSuple.denegado != null && objSuple.denegado ){
                objSuple.denegadoString = "Denegado";
              }else{
                objSuple.denegadoString = "Aprobado";
              }
              if( objSuple.tipoDoc == "" || objSuple.tipoDoc == null ){
                objSuple.tipoDoc = "DNI";
              }
              
              if( !Utils.isNullOrEmpty( objSuple.idProd ) ){
                prodSuple = this.productosList.data.filter(data => data.key == objSuple.idProd);
                objSuple.productName = prodSuple[0].value;
              }else{
                objSuple.productName = this.internalReprocessApplicant.internalSourceCode.nombreProducto;
              }

            }
            if( this.internalReprocessApplicant.intObservaciones.length ){
              this.internalReprocessApplicant.intObservaciones.sort(function(a, b) {
                var textA = a.id;
                var textB = b.id;
                return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
              });
              //this.internalReprocessApplicant.lastObservation = this.internalReprocessApplicant.intObservaciones[0].observacion;
            }
            
            console.log( this.internalReprocessApplicant.intPersonaSuples );

            this.showSuples = true;
            this.cssShow = "show";
          }else{
            this.showSuples = false;
            this.cssShow = "show";
          }
        }else if( response.status == 204 ){
          this.alertMessage = new AlertModel( "No se encontraron registros con los datos ingresados.",this.title, true);
        }else{
          this.alertMessage = new AlertModel( "Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title, true,"WARNING");
        }

        this.loading = false;
        
      }, err => {
        this.alertMessage = new AlertModel( "Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title, true,"WARNING");
        this.loading = false;
        throw err;
      }
    
    );

  }

  loadProductos(){

    this.commonDataService.getProductsAndId().subscribe(
      data => {
        if (data['code'] == "0" && data['data']) {          
          this.productosList.data = Object.assign([], data['data']);
        }
        else {
          console.error(data);
        }

        this.loading = false;
      },
      error => {
        console.error(error);
      }
    );

  }

  cancel() {

    this.showResult = false;

  }

  // reprocess(){
  //   this.showResult = false;
  // }


  public reprocess() {
    this.loading = true;
    let request = {
      "pcn": this.internalReprocessApplicant.pcn,
      "excepcion": this.isExcepcion,
      "cuil": this.isCuil,
      "observacion": this.internalReprocessApplicant.observacion || null,
      "selector": this.selector

      //AGREGAR BOOLEANO PARA SABER SI VA CON CUIL
    };


    this.internalReprocessApplicantService.getApplicantReevaluation(request).subscribe(
      response => {

        if( response.status == 200 && response.body != null){
          let data:any = response.body;

          if (data.reevaluation.validation == "OK") {//aca va OK (Estaba el nook)

            // Alert Event:
            this.alertConfirmEvent = (confirm: any) => {
              this.loading = false;
              this.showResult = false
              this.busquedafiltros.pcn = null
              this.busquedafiltros.dni = null
              this.busquedafiltros.nombre = null


            };

            this.alertMessage = new AlertModel("APROBADO: " + data.reevaluation.message, this.title, true, ALERT_TYPE.CONFIRM, null, false);

          } else if (data.reevaluation.validation == "NOOK") {//ACA VA NOOK (estaba el NOOK666)


            // Alert Event:
            this.alertConfirmEvent = (confirm: any) => {

              this.cancel();;
            };

            this.alertMessage = new AlertModel("RECHAZADO: " + data.reevaluation.message, this.title, true, ALERT_TYPE.CONFIRM, null, false);
          } else {
            this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true);
          }

        } else {
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true);
        }

        this.loading = false;

      },
      error => {
        this.loading = false;
        console.error(error);
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true);
      });


  }

  refactorObservationPaste(event:ClipboardEvent){ 
    this.internalReprocessApplicant.observacion=this.sanitizedText(this.internalReprocessApplicant.observacion); 
   }
  sanitizedText(text:string):string{
    return text.replace(/[^a-zA-Z0-9.$, ]/g,'')
  }

  refactorObservation(evento:any){
  const entrada= String.fromCharCode(evento.keyCode);
  const expresion=/[^a-zA-Z0-9.$, ]/g;

  if(expresion.test(entrada)){
    evento.preventDefault();
  }
 }
}


import { Injectable } from '@angular/core';
import * as $ from 'jquery';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Environment } from '../../config/environment';
import { Config } from '../../config/config';
import { config } from 'process';
import { Observable, of } from 'rxjs';
import { MockStatic } from 'src/app/config/mock.static';


@Injectable({
  providedIn: 'root'
})
export class DocRecoveryService {

    
    constructor(private http: HttpClient, private Conf: Config) { }

    public getApplicantsReportByArea(data){


        let httpOptions = {
          withCredentials: false,
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
          })
        };

        if(Environment.CREDENTIALS){
          httpOptions.withCredentials = true;
        }

        let url = this.Conf.ENDPOINT_API + 'getApplicantsReportByArea';
        let request = Object.assign({}, data);

        request.area = "DOCRECOVERY";

        return this.http.post(url, request, httpOptions);    

    }

    public getApplicantDataByArea(data:any): Observable<any>{



      let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }

      let url = this.Conf.ENDPOINT_API + 'getApplicantDataByArea';
      let request = Object.assign({}, data);

      return this.http.post(url, request, httpOptions);         

    }

    public saveApplicantDataByArea(data:any){   

      // if(MockStatic.MOCKS.includes("CPIDS"))  
      //     return of({
      //       "code": "0",
      //       "message": "Ok"
      //     });
  

      let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }



      let url = this.Conf.ENDPOINT_API + 'saveApplicantDataByArea';
      let request = Object.assign({}, data);
      request.area = "DOCRECOVERY";

      return this.http.post(url, request, httpOptions);        

    }


    public getValidationAccion(){

      let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }



      let url = this.Conf.ENDPOINT_API + 'getValidationAccion';
      let request = {"area": "DOCRECOVERY"};


      return this.http.post(url, request, httpOptions);         

    }

    public getValidationMotivo(data:any){
      
      let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }

      let url = this.Conf.ENDPOINT_API + 'getValidationMotivo';
      let request = Object.assign({}, data);

      request.area = "DOCRECOVERY";

      return this.http.post(url, request, httpOptions);        

    }


    aprobacionMasivaFileUpload(file: File, usuario:string) {

      let formData:FormData = new FormData();
      let filename = file.name;
      //let filename = file.name;
      formData.append('file', file, filename);
      formData.append('usuario', usuario);      

      let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          //'Content-Type': 'multipart/form-data',
          'Accept': 'application/json'
        })
      };

      
      let url = this.Conf.ENDPOINT_API + 'procesamientoMasivo';

      return this.http.post(url, formData, httpOptions);

    }

    aprobacionMasivaInternalDocRecoveryFileUpload(file: File, usuario:string) {

      let formData:FormData = new FormData();
      let filename = file.name;
      //let filename = file.name;
      formData.append('file', file, filename);
      formData.append('usuario', usuario);      

      let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          //'Content-Type': 'multipart/form-data',
          'Accept': 'application/json'
        })
      };

      
      let url = this.Conf.ENDPOINT_API + 'intprocesamientoMasivo';

      return this.http.post(url, formData, httpOptions);

    }


    aprobacionMasivaFileDownload(data) {

      let httpOptions = {
        withCredentials: false,
        responseType:'text',
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      };
      
      let url = this.Conf.ENDPOINT_API + 'downloadProMasivo';
      let request = Object.assign({}, data);

      return this.http.post(url, request, httpOptions as any);     

    }

    
    public getListIngresoArchivo(filters) {
      let url = this.Conf.ENDPOINT_API + 'listIngresoArchivo';
      return this.http.post(url, filters);   
    }

    public getIntListIngresoArchivo(filters) {
      let url = this.Conf.ENDPOINT_API + 'internal/listIngresoArchivo';
      return this.http.post(url, filters);   
    }

    public getSourceCodeRecovery(data:any): Observable<any>{

      let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }

      let url = this.Conf.ENDPOINT_API + 'getSourceCodeRecovery';
      let request = Object.assign({}, data);

      return this.http.post(url, request, httpOptions);         

    }
    
}
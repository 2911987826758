import { Component, OnInit } from '@angular/core';
import { CreditLimitMatrixService } from 'src/app/services/creditLimitMatrix/credit-limit-matrix.service';
import { BaseComponent } from '../shared/base.component';
import { Environment } from 'src/app/config/environment';
import { LimitCreditHeadModel, MatrixListModel,LimitCreditMatrixModel,LimitCreditMatrixCompleteModel } from './model/models';
import { BusquedaFiltrosModel,ScoreType } from './model/models';
import {  AlertModel } from '../shared/alert/alert.component';
import { SessionStorageService } from 'src/app/auth/services/session.service';
import { Roles } from 'src/app/auth/models/roles.static';
import { Utils } from 'src/app/commons/utils';

declare var $ : any;


@Component({
  selector: 'app-credit-limit-matrix',
  templateUrl: './credit-limit-matrix.html',
  styleUrls: ['./credit-limit-matrix.css']
})

export class CreditLimitMatrixComponent extends BaseComponent implements OnInit {
  public loading: boolean;
  public alertMessage: AlertModel = new AlertModel();
  public title: string = "Matriz de límite de crédito";
  public showResult : boolean;
  //public headerMatrixList: MatrixListModel = new MatrixListModel();
  public headerMatrixList: LimitCreditHeadModel[] = new Array<LimitCreditHeadModel>();
  public busquedafiltros: BusquedaFiltrosModel = new BusquedaFiltrosModel();
  public limitCreditHeadCurrent: LimitCreditHeadModel = new LimitCreditHeadModel();
  public limitCrediMatrixModel: LimitCreditMatrixModel = new LimitCreditMatrixModel();
  public limitCreditMatrixCompleteModel: LimitCreditMatrixCompleteModel = new LimitCreditMatrixCompleteModel();
  public resultData = [];
  public resultDataV = [];
  public sendData = [];
  public showMatrixTable: boolean;
  public showSearchMatrixTable: boolean;
  public showNewMatrixOptions: boolean;
  public showBtnSaveChanges: boolean;
  public showBtnCancel: boolean;
  public showBtnDelete: boolean;
  public showBtnCreate: boolean;
  public validateShow: boolean =  false;
  public validateMessage: string;
  public es: any;
  public vendorRoles = [ Roles.AMX_RIESGO_ADM];
  public scoreTypeList: ScoreType[] = new Array<ScoreType>();
  public matrixType: string[] = ['PROSPECT','CUSTOMER'];
  // public matrixType: string[] = ['STD','ING'];//SUBIDA 05/10/2023
  public initDate: string;
  minDate: Date | undefined;
  

  constructor(private sessionStorageService: SessionStorageService
    ,private creditLimitMatrixService: CreditLimitMatrixService) { 
  
      super();
      // this.sessionStorageService.rolValidate("creditLimitMatrix");

      this.busquedafiltros.cmId = "";
      this.showResult = true;
      
      
      }

  ngOnInit(): void {

      
      this.setCalendarLocale(); 
      this.loadHeaderMatrixList();
      this.getScoreTypes();

      // if(!this.sessionStorageService.hasRolesUser(this.vendorRoles)){
      // this.sessionStorageService.redirectToLogin()
      // }    


    }

  setCalendarLocale(){
    this.es = Environment.CalendarLanguageSetting;
  }

  setArrayResults( obj,isForTable:boolean ):Array<any>{
    var arrayFinal = [];
    var columnsQuantity = 1;
    var i = 0;
    for( i = 0; i < obj.length; i++ ){
      if( obj[ i ].tipoDato == "S" ){
        columnsQuantity++;
      }
    }
    
    if( !isForTable ){
      columnsQuantity = 3;
    }

    var row = 0;
    var arrayRow = [];
    for( i = 0; i < obj.length; i++ ){

      if( obj[ i ].tipoDato != "V" && isForTable ){

        arrayRow.push( obj[ i ] );
        if( ( i + 1 ) % columnsQuantity == 0 ){
          arrayFinal[row] = arrayRow;
          row++;
          arrayRow = [];
        }
        
      }else if(  obj[ i ].tipoDato == "V" && !isForTable  ){

        arrayRow.push( obj[ i ] );
        
        arrayFinal = arrayRow;

      }
    
    }
    return arrayFinal;
  }

  loadHeaderMatrixList(){
    this.creditLimitMatrixService.getHeaderMatrixList().subscribe(
      data => {
        if (data['code'] == "0" && data['data']) {    
          this.headerMatrixList = data['data'];

          this.showSearchMatrixTable = true;
        }
        else {
          console.error(data);
        }
        
      },
      error => {
        console.error("error en la carga de id de headers");
      }
    );
  }
  getScoreTypes(){
    this.creditLimitMatrixService.getScoreTypes().subscribe(
      response => {

        if( response.status == 200 && response.body != null){

          this.scoreTypeList = Object.assign([], response.body);

        }else if( response.status == 204 ){
          console.log( "no se encontraron scoreTypes" );
        }else{
          console.log( "Error 1 obteniendo los scoreTypes" );
        }

      }, err => {
        console.log( "Error 2 obteniendo los scoreTypes" );
        this.loading = false;
        throw err;
      }
    );
  }

  loadCreditLimitMatrixResult( isNew:boolean ){
  
    if( isNew && !this.creditLimitMatrixValidate())
    return;

    this.limitCreditHeadCurrent = this.headerMatrixList.find( a => a.clId == this.busquedafiltros.cmId )
    this.loading = true;
    this.showBtnCreate = false;
    
    if( isNew ){
      $( "#min-new-cab-text" ).val( this.initDate );
      this.limitCreditHeadCurrent.vigenciaHasta = $( "#min-new-cab-text" ).val();
    }

    if (this.limitCreditHeadCurrent){
      this.creditLimitMatrixService.getLimitCreditMatrizByCab( this.limitCreditHeadCurrent ).subscribe(
        data => {
            if (data && data["code"] == "0" && data['data']) {
              this.loading = false;
  
              this.showMatrixTable = true;
              if( !isNew ){
                this.showNewMatrixOptions = false;   
                this.showBtnSaveChanges = true;
                this.showBtnCancel = true;
                this.showBtnDelete = false;
                
                let date =  Utils.dateToString(new Date())
                let compare = Utils.compareTwoDates(date , this.limitCreditHeadCurrent.vigenciaDesde)
                if(compare == 1 || compare == 0){
                  this.showBtnSaveChanges = false
                  this.showBtnCancel = false
                }else if(compare == -1 && this.limitCreditHeadCurrent.vigenciaHasta == null){
                  this.showBtnDelete = true;
                }else{
                  this.showBtnSaveChanges = true;
                  this.showBtnCancel = true;
                  this.showBtnDelete = false;
                }
              }else{
                
                if( this.limitCreditHeadCurrent.tipoScoreCol != $( "#score-val" ).val() ){
                  let arrayScores = data['data'].filter( a => a.tipoDato == "S" );
                  for( var j = 0; j< arrayScores.length; j++ ){
                    arrayScores[ j ].valor = "";
                  }
                }
                
                if( this.limitCreditHeadCurrent.tipoScoreRow != $( "#score-val-row" ).val() ){
                  let arrayScores = data['data'].filter( a => a.tipoDato == "I" );
                  for( var j = 0; j< arrayScores.length; j++ ){
                    arrayScores[ j ].valor = "";
                  }
                }//SUBIDA 05/10/2023
  
                this.showBtnSaveChanges = false;   
                this.showBtnCreate = true; 
                this.showBtnCancel = true;
                this.showBtnDelete = false;
              }
              this.resultData = data['data'];
              this.resultData.unshift({"valor":""});
  
              this.resultData = this.setArrayResults( this.resultData, true );
              this.resultDataV = this.setArrayResults( data['data'], false );
          }
          else {
            this.loading = false;
            this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true);
          }
        },
        error => {
          this.loading = false;
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true);
        }
      );

      return true;
    }else{
      this.loading = false;
      this.showMatrixTable = false;
    }
  
  }
  
  showNewCreditLimitMatrix(){
    this.limitCreditMatrixCompleteModel = new LimitCreditMatrixCompleteModel;
    this.limitCreditMatrixCompleteModel.creditLimitCab = new LimitCreditHeadModel;
    this.showMatrixTable = false;
    this.showSearchMatrixTable = false;
    this.showNewMatrixOptions = true;
  }

  newCreditLimitMatrix(){
    this.showSearchMatrixTable = false;
    this.showMatrixTable = false;
    this.showMatrixTable = true;
  }

  cancelNewCreditLimitMatrix(){
    this.showSearchMatrixTable = true;
  
    this.showMatrixTable = false;
    if(this.showNewMatrixOptions){
      this.limitCreditHeadCurrent.vigenciaHasta = null;
    }
    this.showNewMatrixOptions = false;
    this.busquedafiltros.cmId = "";
    this.busquedafiltros.tipo_matriz = "";
 
    this.limitCreditMatrixCompleteModel.tipoScoreCol = "";
    this.limitCreditMatrixCompleteModel.tipoScoreRow = "";
  }

  //update de matriz
  getArrayMatrix():Array<any>{
    var locArray = [];
    var idHead: string = this.limitCreditHeadCurrent.clId;
    $( ".td-obj-matrix" ).each(function(){

      var values: string[] = $( this ).find('.obj-value').val().split("__");
      var val = $( this ).find('.matrix-value').val();

      this.limitCrediMatrixModel = new LimitCreditMatrixModel();
      if( values[0] != "" ){
        this.limitCrediMatrixModel.id = values[0];
        this.limitCrediMatrixModel.clId = idHead;
        this.limitCrediMatrixModel.nombreVariable = values[1];
        this.limitCrediMatrixModel.tipoDato = values[2];
        this.limitCrediMatrixModel.ordenAbm = values[3];
        this.limitCrediMatrixModel.ordenParam = values[4];
        this.limitCrediMatrixModel.valor = val;

        if( $( this ).find('.matrix-label').length ){
          this.limitCrediMatrixModel.label = $( this ).find('.matrix-label').html();
        }

        locArray.push( this.limitCrediMatrixModel );
        
      }
      
    });

    return locArray;
  }

  succesUpdate(){
    this.alertMessage = new AlertModel("Los datos se guardaron con exito",this.title,true,"SUCCESS");
    this.cancelNewCreditLimitMatrix( );
  }

  updateCreditLimitMatrix(): void {
    
    this.sendData = this.getArrayMatrix();
    ////////////

    this.loading = true;

    if( this.validateInputs() ){

      this.creditLimitMatrixService.updateMatrix(this.sendData).subscribe(
        data => {
          if (data && data["code"] == "0") {
            this.loading = false;
            this.succesUpdate();
          }
          else {
            this.loading = false;
            this.alertMessage = new AlertModel("Hubo un inconveniente al guardar la información, inténtalo nuevamente.",this.title,true);
          }
        },
        error => {
          console.error(error);
          this.loading = false;
          this.alertMessage = new AlertModel("Hubo un inconveniente al guardar la información.",this.title,true);
        }
      );

    }else{
      this.loading = false;
    }//fin validacion
  }

  setDateMinNewCab( date:Date ){
    let dateSplit = date.toString().split( "/" );
    let dateFromat = dateSplit[2]+"-"+dateSplit[1]+"-"+dateSplit[0];
    
    let dateNew = new Date( dateFromat+"T00:00:00" );
    //sumo un dia al calendario para el desde
    dateNew.setDate( dateNew.getDate() + 1 );
    // let dateNewSplit = dateNew.toISOString().split( "T" )[0];
  
    this.initDate = "";
    this.minDate = dateNew;
  }

  getMaxCabByMatrixType(){
    this.limitCreditMatrixCompleteModel.listCreditLimitMatriz = null;
    this.showMatrixTable = false;
    this.loading = true;
    this.creditLimitMatrixService.getMaxCabByMatrixType( this.busquedafiltros.tipo_matriz ).subscribe(
      data => {
        if (data && data["code"] == "0") {
          this.loading = false;
          this.limitCreditHeadCurrent = data["data"][0];
          if(this.limitCreditHeadCurrent.tipoScoreCol != null){ $( "#score-val" ).val(this.limitCreditHeadCurrent.tipoScoreCol)}
          if(this.limitCreditHeadCurrent.tipoScoreCol != null){$( "#score-val-row" ).val(this.limitCreditHeadCurrent.tipoScoreRow)}
          
          this.setDateMinNewCab( this.limitCreditHeadCurrent.vigenciaDesde);

          this.busquedafiltros.cmId = this.limitCreditHeadCurrent.clId;

        }
        else {
          this.loading = false;
        }
      },
      error => {
        console.error(error);
        this.loading = false;
      }
    );

  }
  succesCreate(){
    this.alertMessage = new AlertModel("Los datos se guardaron con exito",this.title,true);
    this.loadHeaderMatrixList();
    this.cancelNewCreditLimitMatrix();
    this.limitCreditMatrixCompleteModel.tipoScoreCol = "";
    this.limitCreditMatrixCompleteModel.tipoScoreRow = "";
  }
  createNewLimitMatrix(){

    this.limitCreditMatrixCompleteModel = new LimitCreditMatrixCompleteModel();
    this.limitCreditMatrixCompleteModel.creditLimitCab = this.limitCreditHeadCurrent;
    this.limitCreditMatrixCompleteModel.listCreditLimitMatriz = this.getArrayMatrix();
    this.limitCreditMatrixCompleteModel.tipoScoreCol = $( "#score-val" ).val();
    this.limitCreditMatrixCompleteModel.tipoScoreRow = $( "#score-val-row" ).val();

    if(!this.creditLimitMatrixValidate())
        return;

    this.loading = true;
    this.creditLimitMatrixService.createNewLimitMatrix( this.limitCreditMatrixCompleteModel ).subscribe(
      data => {
        if (data && data["code"] == "0") {
          this.loading = false;
          this.succesCreate();

        }
        else {
          this.loading = false;
          this.alertMessage = new AlertModel("Hubo un inconveniente al guardar la información, inténtalo nuevamente.",this.title,true);
        }
      },
      error => {
        console.error(error);
        this.loading = false;
      }
    );


  }
  inOrder( arr, asc ): boolean{
    return arr.every(function(_, i) {
      if( asc == "asc" ){
        return i == 0 || arr[i] > arr[i - 1];
        }else{
        return i == 0 || arr[i] < arr[i - 1];
        }
      });
  }
  creditLimitMatrixValidate():boolean{

    let validations = "";
    let arrayErrors = [];

    if( this.limitCreditMatrixCompleteModel.listCreditLimitMatriz != null ){
      let arrayCol = [];
      let arrayRow = [];

      for( var i = 0; i < this.limitCreditMatrixCompleteModel.listCreditLimitMatriz.length; i++ ){
    		var objCel = this.limitCreditMatrixCompleteModel.listCreditLimitMatriz[ i ];
    		if( objCel.tipoDato == "S" ){
    			arrayCol.push( parseFloat(objCel.valor.toString() ) );
    		}
    		if( objCel.tipoDato == "I" ){
    			arrayRow.push( parseFloat( objCel.valor.toString() ) );
    		}
        
    	}

      if( Utils.isNullOrEmpty( objCel.valor ) || isNaN( objCel.valor ) ){
        arrayErrors.push( "Todos los campos deben tener un valor numérico separando decimales con punto." );
      }
      
      let objScoreTypeCol = this.scoreTypeList.filter( a => a.codigo == this.limitCreditMatrixCompleteModel.tipoScoreCol )
      let objScoreTypeRow = this.scoreTypeList.filter( a => a.codigo == this.limitCreditMatrixCompleteModel.tipoScoreRow )
      
      if( !this.inOrder( arrayCol,objScoreTypeCol[0].tipo ) ){
        var dir = ( objScoreTypeCol[0].tipo == "asc" ) ? "ascendente" : "descendente";
        arrayErrors.push( "Los valores de la cabecera tienen que ir en orden "+dir+"." );
      }
      if( !this.inOrder( arrayRow,objScoreTypeRow[0].tipo ) ){
        var dir = ( objScoreTypeRow[0].tipo == "asc" ) ? "ascendente" : "descendente";
        arrayErrors.push( "Los valores de la primero columna tienen que ir en orden "+dir+"." );
      }//SUBIDA 05/10/2023

    }

    /////////////

    if(this.busquedafiltros.tipo_matriz == null || this.busquedafiltros.tipo_matriz == ""){
      arrayErrors.push( "Debe seleccionar tipo de matriz." );
     }

    if( Utils.isNullOrEmpty( this.initDate ) ){
      arrayErrors.push( "Debe seleccionar vigencia desde." );
     }
    
    $( ".score-val-select" ).each(function(){
      if( Utils.isNullOrEmpty( $(this).val() ) ){
        arrayErrors.push( "Debe seleccionar tipo de score." );
      }
    });

    if( $( "#score-val-row" ).length && $( "#score-val" ).val() == $( "#score-val-row" ).val() ){
      arrayErrors.push( "El tipo de score col debe ser distinto del tipo de score fila" );
    }

    var booleanInputP = true;
     $( "input.matrix-value.data-P:visible" ).each(function(){
         var valToCheck = $(this).val();
         var valNum = parseInt( valToCheck );
         if( valNum < 0
           || valNum > 100 ){
            booleanInputP = false;
             return false;
         }
         
     });
     if( !booleanInputP ){
      arrayErrors.push( "Por favor, revise que todos los porcentajes tengan valor entre 0 y 100" );
    }

    var booleanInput = true;
    $( "input.matrix-value:visible" ).each(function(){
        var valToCheck = $(this).val();
        var valNum = parseInt( valToCheck );
        if( valToCheck == null || valToCheck == "" 
          || isNaN( valToCheck ) 
          ){
            booleanInput = false;
            return false;
        }
        
    });
   
   if( !booleanInput ){
     arrayErrors.push( "Por favor, revise que todos los campos tengan valor numérico." );
   }
    
    if( arrayErrors.length ) {

      for( var i = 0; i < arrayErrors.length; i++ ){
        var conc = ( i == 0 ) ? "- ":"<br>- ";
        validations += conc + arrayErrors[ i ];
      }

        this.validateShow  =  true;
        this.validateMessage = validations;
        this.alertMessage = new AlertModel(this.validateMessage,this.title,true);
        return false;
    }

    this.validateShow  =  false;
    this.validateMessage = "";
    return true;
  }

  validateInputs():boolean{

    let validations = "";
    let arrayErrors = [];

    if( this.sendData != null ){
      let arrayCol = [];
      let arrayRow = [];

      for( var i = 0; i < this.sendData.length; i++ ){
    		var objCel = this.sendData[ i ];
    		if( objCel.tipoDato == "S" ){
    			arrayCol.push( parseFloat(objCel.valor.toString() ) );
    		}
    		if( objCel.tipoDato == "I" ){
    			arrayRow.push( parseFloat( objCel.valor.toString() ) );
    		}
        
    	}

      if( Utils.isNullOrEmpty( objCel.valor ) || isNaN( objCel.valor ) ){
        arrayErrors.push( "Todos los campos deben tener un valor numérico separando decimales con punto." );
      }
      
      let objScoreTypeCol = this.scoreTypeList.find( a => a.codigo == this.limitCreditHeadCurrent.tipoScoreCol )
      let objScoreTypeRow = this.scoreTypeList.filter( a => a.codigo == this.limitCreditHeadCurrent.tipoScoreRow )

      if( !this.inOrder( arrayCol,objScoreTypeCol.tipo ) ){
        var dir = ( objScoreTypeCol.tipo == "asc" ) ? "ascendente" : "descendente";
        arrayErrors.push( "Los valores de la cabecera tienen que ir en orden "+dir+"." );
      }
      if( !this.inOrder( arrayRow,objScoreTypeRow[0].tipo ) ){
        var dir = ( objScoreTypeRow[0].tipo == "asc" ) ? "ascendente" : "descendente";
        arrayErrors.push( "Los valores de la primero columna tienen que ir en orden "+dir+"." );
      }//SUBIDA 05/10/2023

    }

     var booleanInput = true;
     $( "input.matrix-value:visible" ).each(function(){
         var valToCheck = $(this).val();
         var valNum = parseInt( valToCheck );
         if( valToCheck == null || valToCheck == "" 
           || isNaN( valToCheck ) 
           ){
             booleanInput = false;
             return false;
         }
         
     });
    
    if( !booleanInput ){
      arrayErrors.push( "Por favor, revise que todos los campos tengan valor numérico." );
    }

    var booleanInputP = true;
     $( "input.matrix-value.data-P:visible" ).each(function(){
         var valToCheck = $(this).val();
         var valNum = parseInt( valToCheck );
         if( valNum < 0
           || valNum > 100 ){
            booleanInputP = false;
             return false;
         }
         
     });
     if( !booleanInputP ){
      arrayErrors.push( "Por favor, revise que todos los porcentajes tengan valor entre 0 y 100" );
    }
    
    if( arrayErrors.length ) {

      for( var i = 0; i < arrayErrors.length; i++ ){
        var conc = "- ";
        validations += conc + arrayErrors[ i ];
      }

        this.validateShow  =  true;
        this.validateMessage = validations;
        this.alertMessage = new AlertModel(this.validateMessage,this.title,true);
        return false;
    }

    this.validateShow  =  false;
    this.validateMessage = "";
    return true;
  }

  hideTable(){
    this.showMatrixTable = false
  }
  //FUNCIONES PARA DELETE
  matrizDeleteConfirm() {
    this.alertMessage = new AlertModel("Está seguro de eliminar esta matriz",this.title,true, "CONFIRM");

  }

  onConfirmEvent(confirm:any){
    if(confirm.confirm){
      this.matrizDelete();
    }
  }
  matrizDelete(){

    this.loading = true;
    let request = { "clId":this.limitCreditHeadCurrent.clId };
    this.creditLimitMatrixService.deleteLimitCreditMatriz( request ).subscribe(
      response => {
        if( response.status == 200 ){
          this.succesDelete();
        }else if( response.status == 204 ){
          this.alertMessage = new AlertModel("No se encontró la matriz que desea borrar.",this.title,true,"ERROR");
        }else {
          this.alertMessage = new AlertModel("Hubo un inconveniente al intentar eliminar la matriz.",this.title,true,"ERROR");
        }

        this.loading = false;
      },
      err => {
        console.error(err);
        this.alertMessage = new AlertModel("Hubo un inconveniente al intentar eliminar la matriz.",this.title,true,"ERROR");
        this.loading = false;
      }
    );
  }
  succesDelete(){
    this.alertMessage = new AlertModel("La matriz se borró correctamente.",this.title,true,"SUCCESS");
    this.loadHeaderMatrixList();
    this.cancelNewCreditLimitMatrix();
    this.limitCreditMatrixCompleteModel.tipoScoreCol = "";
    this.limitCreditMatrixCompleteModel.tipoScoreRow = "";
  }

}

import { Component, Injector, OnInit, Optional } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { LoadingComponent } from '../shared/loading/loading.component';
import { AlertComponent, AlertModel } from '../shared/alert/alert.component';

import { ɵAnimationGroupPlayer } from '@angular/animations';
import { Environment } from 'src/app/config/environment';
import { ReturnStatement } from '@angular/compiler';
import { Utils } from 'src/app/commons/utils';
import { SessionStorageService } from 'src/app/auth/services/session.service';
import { ExcelExportService } from 'src/app/services/excelExport/excel.export.service';
import { AppModule } from 'src/app/app.module';



@Component({
  selector: 'base',
  template: ""
})
export class BaseComponent  {

  public setDateFormat: Function = Utils.setDateFormat  
  
   
  
  dateStringToMillisenconds(date:string){

    return new Date(Utils.dateParse(date)).getTime();
  }

  datetimeStringToMillisenconds(date:string){

    return new Date(Utils.dateParse(date, "DD/MM/YYYY HH:mm")).getTime();
  }

  public  setupTootip(){
    $(function () {
        (<any>$('[data-toggle="tooltip"]')).tooltip()
    })

    return true;
  }

}
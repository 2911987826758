export class AplicantDetailModel {
    area: string;  
    canal: string;  
    fecha_aplicacion: Date;
    pcn: string;  
    dni: string;  
    nombre: string;  
    domicilio: string;  
    localidad: string;  
    celular: string;  
    telefono: string;  
    estado: string; 
    subestado: string; 
    motivo: string; 
    promotor: string;
    promotor_original: string; 
    prod: string; 
    observacion: string; 
    genero: string; 
    fechaModificacion: string;
    cuil: string;

}


export class BusquedaFiltrosModel {

    pcn: string;  
    dni: string;  
    nombre: string;  
    cuil: string;
}

export class InternalReprocessApplicant{
    apellido: string;
    area: string;
    cuil: string;
    denegado: boolean;
    docNum: string;
    nacionalidad: string;
    nombre1: string;
    nombre2: string;
    nombreCompleto: string;
    pcn: string;
    estado: string;
    intPersonaSuples: IntPersonaSuples[];
    internalSourceCode: InternalSourceCode;
    fechaModificacion: string;
    accion: string;
    fechaArchivo: string;
    canalArchivo: string;
    intSocioData: intSocioData;
    tipoVenta: string;
    motivo: string;
    lastObservation: string;
    intObservaciones: IntObservaciones[];
    estadoRegistro: string;
}

export class IntPersonaSuples{
    genero: string;
    vinculo: string;
    nombre1: string;
    nombre2: string;
    apellido: string;
    activo: string;
    fnac: string;
    nombreCompleto: string;
    plasticColorType: string;
    tipoDoc: string;
    bridgerStatus: string;
    caseNumber: string;
    docNumSuple: string;
    lastUpdate: string;
    usuarioIc: string;
    caseStatus: string;
    pcnSuple: string;
    insertDate: string;
    index: number;
    estado: string;
    accion: string;
    motivo: string;
    estadoRegistro: string;
    denegado: string;
    area: string;
    prod: string;
    idProd: string;
}

export class InternalSourceCode{
    idPromotor: string;
    comercializadora: string;
    nombreProducto: string;
    prod: string;
}

export class intSocioData{
    
    cuil: string;
    firstName: string;
    secondName: string;
    lastName: string;
    cardName: string;
    birthDate: string;
    dniType: string;
    dni: string;
    gender: string;
    tel1: string;
    tel2: string;
    email: string;
    postalCode: string;
    province: string;
    city: string;
    street: string;
    civilState: string;
    ivaType: string;
    profession: string;
    company: string;
    ocupation: string;
    area: string;
    monthlySalary: string;
    mr: string;
    memberSinceTitular: string;
    loc: string;
    income: string;
    nationality: string;
    keyDigits: string;

}
export class IntObservaciones{
    area: string;
    fecha: string;
    id: string;
    observacion: string;
    persona:string;
  }
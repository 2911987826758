import {  Component, OnInit } from '@angular/core';
import { LoadingComponent } from '../shared/loading/loading.component';
import { AlertComponent, AlertModel, ALERT_TYPE } from '../shared/alert/alert.component';

import { ɵAnimationGroupPlayer } from '@angular/animations';
import { Environment } from 'src/app/config/environment';
import { ReturnStatement } from '@angular/compiler';
import { Utils } from 'src/app/commons/utils';
import { SessionStorageService } from 'src/app/auth/services/session.service';
import { ExcelExportService } from 'src/app/services/excelExport/excel.export.service';
import { CommonDataService } from 'src/app/services/commons/commondata.service';
import { ProductosList } from 'src/app/models/lists.model';


import { ProductsModel } from './model/models';
import { ProductsService} from '../../services/products/products.service';

declare var $ : any;

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {

  public title: string = "Productos";
  public es: any;
  public showResult : boolean;
  public tablereports = null;
  public showNewProduct : boolean; 
  public showUpdateProductModal: boolean;
  public productCurrent: ProductsModel = new ProductsModel();
  
  public loading: boolean;
  public alertMessage: AlertModel = new AlertModel();

  public validateShow: boolean =  false;
  public validateMessage: string;

  public resultData = [];


  constructor(public productsService: ProductsService,
              public sessionStorageService: SessionStorageService) { 
          this.sessionStorageService.rolValidate("products");
  }

  ngOnInit(): void {
    this.setCalendarLocale();
    this.loadResultData();   
  }


  newProduct(){
    this.validateShow  =  false;
    this.validateMessage = "";
    
    this.productCurrent = new ProductsModel();
    this.showNewProduct = true;

  }
 
  newProductCancel(){
    this.showUpdateProductModal = false;
    this.showNewProduct = false;
  }

  newProductSave(){   

    if(!this.newProductValidate(true))
        return;

    this.loading = true;

    this.productsService.createProduct(this.productCurrent).subscribe(
      data => {
        if (data && data["code"] == "0") {
          this.showNewProduct = false;      
          this.loading = false;

          $('#producto-nuevo').modal('hide');

          this.productCurrent = new ProductsModel();
          this.alertMessage = new AlertModel("Los datos se guardaron con exito","Producto",true);
          this.loadResultData();

        }
        else {
          this.loading = false;
          this.alertMessage = new AlertModel("Hubo un inconveniente al guardar la información, inténtalo nuevamente.","Producto",true);
        }
      },
      error => {        
        this.loading = false;
        this.alertMessage = new AlertModel("Hubo un inconveniente al guardar la información.","Producto",true);
        console.error(error);
      }
    );
    
    
  }


  newProductValidate(isNew?:boolean):boolean{

    let validations = "";

    if(isNew == true && !Utils.isNullOrEmpty(this.productCurrent.prod) && this.resultData.length != 0){
      if(this.resultData.find(i=>i.prod == this.productCurrent.prod) != null){
        validations+="El id de producto 'PROD' ya existe. "
      }
    }
    
    if(this.productCurrent.prod == null || this.productCurrent.prod == "")
        validations+="Debe completar el campo Prod. "

    if(this.productCurrent.prodName == null || this.productCurrent.prodName == "")
      validations+="Debe completar el campo Product Name."

    if(this.productCurrent.prodLgf == null || this.productCurrent.prodLgf == "")
        validations+="Debe completar el campo Product LGF."

    if(this.productCurrent.income == null || this.productCurrent.income == "")
        validations+="Debe completar el campo Income."

    if(this.productCurrent.prodMns == null || this.productCurrent.prodMns == ""){
        validations+="Debe completar el campo Product MNS."
	  }


    
    if(validations != "") {
        this.validateShow  =  true;
        this.validateMessage = validations;

        return false;
    }

    this.validateShow  =  false;
    this.validateMessage = "";
    return true;
  }

  updateProduct(item: ProductsModel) : void {
    this.validateShow  =  false;
    this.validateMessage = "";

    this.productCurrent = item;
    this.showUpdateProductModal = true;
  }

  updateProductSave(): void {
    if(!this.newProductValidate())
        return;

    this.loading = true;

    this.productsService.updateProduct(this.productCurrent).subscribe(
      data => {
        if (data && data["code"] == "0") {
          this.showUpdateProductModal = false;      
          this.loading = false;

          $('#producto-modificado').modal('hide');
          this.productCurrent = new ProductsModel(); 
          
          this.alertMessage = new AlertModel("Los datos se guardaron con exito","Producto",true);

          this.loadResultData();
        }
        else {
          this.loading = false;
          this.alertMessage = new AlertModel("Hubo un inconveniente al guardar  la información, inténtalo nuevamente.","Producto",true);
        }
      },
      error => {
        console.error(error);
        this.loading = false;
        this.alertMessage = new AlertModel("Hubo un inconveniente al guardar la información.","Producto",true);
      }
    );
  }

  productDeleteConfirm(item) {
    this.productCurrent = item;
    this.alertMessage = new AlertModel("Está seguro de eliminar este producto?","Producto",true, "CONFIRM");

  }

  productDelete(){
    this.loading = true;

    this.productsService.deleteProduct(this.productCurrent).subscribe(
        data => {
          if (data && data["code"] == "0") {
            this.showUpdateProductModal = false;      
            this.loading = false;
            this.productCurrent = new ProductsModel();
            this.alertMessage = new AlertModel("Se ha eliminado el producto con exito","Producto",true);
            this.loadResultData();
          }
          else {
            this.loading = false;
            this.alertMessage = new AlertModel("Hubo un inconveniente al borrar la información, inténtalo nuevamente.","Producto",true);
          }
        },
        error => {
          console.error(error);
          this.loading = false;
          this.alertMessage = new AlertModel("Hubo un inconveniente al borrar la información.","Producto",true);
        }
      );    
  }

  onConfirmEvent(confirm:any){

    if(confirm.confirm){
      this.productDelete();
    }else{
      this.showUpdateProductModal = false;
      this.productCurrent = null;
    }
  }

  loadResultData(){
    
    this.loading = true;

    this.productsService.getProducts().subscribe(
      data => {
        if (data && data["code"] == "0" && data['data']) {
          this.resultData = data['data'];
          this.setupTable();             

        }
        else {
          console.error(data);
          this.loading = false;
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.","Producto",true);
        }
      },
      error => {
        console.error(error);
        this.loading = false;
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.","Producto",true);
      }
    );

  }

  

  setupTable(){

    this.loading = false;
    this.showResult = true;



    $(document).ready( () => {

      this.tablereports =   $("#result-table").DataTable({
          retrieve: true,
          searching: false, 
          paging: true, 
          
          lengthChange: false,
          pageLength: 10,
          pagingType: 'full_numbers',
          columnDefs: [
            { targets: 'no-sort', orderable: false }
          ],        
          language: Environment.DataTableLanguageSetting
          });
          

    });

  }  

  setCalendarLocale(){

    this.es = Environment.CalendarLanguageSetting;
  }


}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ayuda',
  templateUrl: './ayuda.component.html',
  styleUrls: ['./ayuda.component.css']
})

export class AyudaComponent implements OnInit {
  public title: string = "INFORMACION";
  public mail: string = "help.desk@veraz.com.ar";
  
  constructor(){}
    ngOnInit(): void {

    }
}
 


import { Component, OnInit } from '@angular/core';
import { BusquedaFiltrosModel, AplicantDetailModel } from './models/models';

import { AlertModel } from '../shared/alert/alert.component';
import { ApplicantDetailService } from '../../services/applicantDetail/applicantDetail.service'
import { CommonDataService } from 'src/app/services/commons/commondata.service';
import { Utils } from 'src/app/commons/utils';
import { SessionStorageService } from 'src/app/auth/services/session.service';


@Component({
  selector: 'app-applicantdetail',
  templateUrl: './applicantDetail.component.html',
  styleUrls: ['./applicantDetail.component.css']
})


export class ApplicantDetailComponent {
  public es: any;
  public titulo: string = "ESTADO DE APLICANTE";
  public loading: boolean;
  public alertMessage: AlertModel = new AlertModel;


  public dataResult: AplicantDetailModel = new AplicantDetailModel;
  public showResult : boolean;
  public tablereports = null;

  public busquedafiltros: BusquedaFiltrosModel = new BusquedaFiltrosModel; 

  constructor(private applicantDetailService: ApplicantDetailService,
              private sessionStorageService: SessionStorageService,
              private commonDataService: CommonDataService) { 

                this.sessionStorageService.rolValidate("applicantDetail");
   }

 

  loadResults(){   

    if(Utils.isNullOrEmpty(this.busquedafiltros.pcn) && 
       Utils.isNullOrEmpty(this.busquedafiltros.dni) &&
       Utils.isNullOrEmpty(this.busquedafiltros.nombre)) {
      
      this.alertMessage = new AlertModel( "Se requiere completar al menos un filtro.",this.titulo, true);
      return;
    }

    this.showResult = false;
    this.loading = true;

    let request = { 
        "pcn":this.busquedafiltros.pcn == "" ? null : this.busquedafiltros.pcn, 
        "dni":this.busquedafiltros.dni == "" ? null : this.busquedafiltros.dni, 
        "nombreapellido":this.busquedafiltros.nombre == "" ? null : this.busquedafiltros.nombre, 
    };         

    this.applicantDetailService.getData(request).subscribe(
      data => {
    
          if(data['data'] && data['code'] == "0" && data['data'].length != 0){
            
         
              this.dataResult = data['data'][0];
              this.dataResult.domicilio  = this.dataResult.domicilio.replace(/null/gi, "").trim();
              this.showResult = true;
            

          } else if(data['code'] == "1" ){
            this.alertMessage = new AlertModel(data['message'],this.titulo, true);
          
          }else{
            console.error(data);
            this.alertMessage = new AlertModel( "Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.titulo, true);
         
          }          

          this.loading = false;
   
      },
      error => {
        console.error(error);
        this.alertMessage = new AlertModel( "Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.titulo, true);
      }
    );

  }

  refactorObservationPaste(event:ClipboardEvent){ 
    this.dataResult.observacion=this.sanitizedText(this.dataResult.observacion); 
   }
   sanitizedText(text:string):string{
     return text.replace(/[^a-zA-Z0-9.$, ]/g,'')
   }

   refactorObservation(evento:any){
    const entrada= String.fromCharCode(evento.keyCode);
    const expresion=/[^a-zA-Z0-9.$, ]/g;
  
    if(expresion.test(entrada)){
      evento.preventDefault();
    }
   
  }

  }

import { Injectable } from '@angular/core';
import * as $ from 'jquery';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Environment } from '../../config/environment';
import { Config } from '../../config/config';
import { config } from 'process';
import { of } from 'rxjs';
import { MockStatic } from 'src/app/config/mock.static';
//import { areAllEquivalent } from '@angular/compiler/src/output/output_ast';
import { windowWhen } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class NormalizePatternsService {


    constructor(private http: HttpClient, private Conf: Config) { }
    

    getNormalizePatterns(){
      
      let httpOptions = {
        withCredentials: false
      };
  
      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }
  
      let url = this.Conf.ENDPOINT_API + 'getNormalizacionPatrones';  
  
      return this.http.get(url, httpOptions);
    }

    getNormalizePatternsByPatternId( data ){
      
      let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      };
  
      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }
  
      let url = this.Conf.ENDPOINT_API + 'getNormalizacionPatronesByPatronId';  
      let request = Object.assign({}, data);
  
      return this.http.post(url,request, httpOptions);
    }

    public getDate(){
      return new Date();
    }


    public getData(){



      let httpOptions = {
        withCredentials: false
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }

      let url = this.Conf.ENDPOINT_API + 'getCpids';

      return this.http.get(url, httpOptions);  

    }

    createNormalizePaterns(data) {

      let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      };
  
      if (Environment.CREDENTIALS) {
        httpOptions.withCredentials = true;
      }
  
      let url = this.Conf.ENDPOINT_API + "createNormalizacionPatrones";
      let request = Object.assign({}, data);
  
      return this.http.post(url, request, httpOptions);
    }

    public update(data){

 
      let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }

      let url = this.Conf.ENDPOINT_API + 'updateNormalizacionPatrones';
      let request = Object.assign({}, data);

      return this.http.post(url, request, httpOptions);  

    }    

    deleteNormalizePatterns(data) {


      let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      };
  
      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }
  
      let url = this.Conf.ENDPOINT_API + 'deleteNormalizacionPatrones';
      let request = Object.assign({}, data);
  
      return this.http.post(url, request, httpOptions);  
  
    }
  
    getPatterns(){
      
      let httpOptions = {
        withCredentials: false
      };
  
      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }
  
      let url = this.Conf.ENDPOINT_API + 'getPatrones';  
  
      return this.http.get(url, httpOptions);
    }


    uploadMassiveNormalizePatterns(file: File, action:string, patronId:string, usuario:string) {
      console.log( "action "+action );
      let formData:FormData = new FormData();
      let filename = file.name;
      formData.append('file', file, filename);
      formData.append('action', action);
      formData.append('patronId', patronId);

      let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          //'Content-Type': 'multipart/form-data',
          'Accept': 'application/json'
        })
      };

      
      let url = this.Conf.ENDPOINT_API + 'uploadMassiveNormalizePatterns';

      return this.http.post(url, formData, httpOptions);

    }

    /*import(file: File) {

        let formData:FormData = new FormData();
        let filename = file.name;
        formData.append('file', file, filename);
 
  
        let httpOptions = {
          withCredentials: false,
          headers: new HttpHeaders({
            //'Content-Type': 'multipart/form-data',
            'Accept': 'application/json'
          })
        };
  
        
        let url = this.Conf.ENDPOINT_API + 'importCpids';
  
        return this.http.post(url, formData, httpOptions);
  
      }*/

}

import { Injectable } from '@angular/core';
import * as $ from 'jquery';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Environment } from '../../config/environment';
import { Config } from '../../config/config';
import { config } from 'process';
import { Observable, of } from 'rxjs';
import { MockStatic } from 'src/app/config/mock.static';


@Injectable({
  providedIn: 'root'
})
export class InternalTelemarketingService {
//Componente de plantilla Telemarketing

    constructor(private http: HttpClient, private Conf: Config) { }

    public getApplicantsReportByArea(data){
      
      let url = this.Conf.ENDPOINT_API + 'internal/getIntPersonaByArea';
      let request = Object.assign({}, data);
      let headers = new HttpHeaders({
        'Content-Type': 'application/json',
      })
      let wc = (Environment.CREDENTIALS) ? true : false;
      
      return this.http.post(url, request, {headers: headers, observe: "response",withCredentials: wc})

    }

    public getApplicantDataByArea(data:any):Observable<any>{
      
      let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }



      let url = this.Conf.ENDPOINT_API + 'getApplicantDataByArea';
      let request = Object.assign({}, data);

      return this.http.post(url, request, httpOptions);     

    }

    // public saveApplicantDataByArea(data:any){ 
      
    //   let httpOptions = {
    //     withCredentials: false,
    //     headers: new HttpHeaders({
    //       'Content-Type': 'application/json',
    //     })
    //   };

    //   if(Environment.CREDENTIALS){
    //     httpOptions.withCredentials = true;
    //   }


      
    //   let url = this.Conf.ENDPOINT_API + 'saveApplicantDataByArea';
    //   let request = Object.assign({}, data);
    //   request.area = "TELEMARKETING";

    //   return this.http.post(url, request, httpOptions);        

    // }

    public editIntPersona(data){

      let url = this.Conf.ENDPOINT_API + 'internal/saveApplicantDataByArea';
      let request = Object.assign({}, data);
      let headers = new HttpHeaders({
        'Content-Type': 'application/json',
      })
      let wc = (Environment.CREDENTIALS) ? true : false;
      
      return this.http.post(url, request, {headers: headers, observe: "response",withCredentials: wc})
        
    }


    public getValidationAccion(){

      let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }



      let url = this.Conf.ENDPOINT_API + 'getValidationAccion';
      let request = {"area": "TELEMARKETING"};


      return this.http.post(url, request, httpOptions);         

    }

    public getValidationMotivo(data:any){
      
      let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }

      let url = this.Conf.ENDPOINT_API + 'getValidationMotivo';
      let request = Object.assign({}, data);

      request.area = "TELEMARKETING";

      return this.http.post(url, request, httpOptions);        

    }



    aprobacionMasivaFileUpload(file: File, usuario:string) {

      let formData:FormData = new FormData();
      let filename = file.name;
      formData.append('file', file, filename);
      //formData.append('usuario', usuario);

      let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          //'Content-Type': 'multipart/form-data',
          'Accept': 'application/json'
        })
      };

      
      let url = this.Conf.ENDPOINT_API + 'procesamientoPrecheckMasivo';

      return this.http.post(url, formData, httpOptions);

    }


    aprobacionMasivaFileDownload(data) {

      let httpOptions = {
        withCredentials: false,
        responseType:'text',
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      };
      
      let url = this.Conf.ENDPOINT_API + 'downloadProMasivo';
      let request = Object.assign({}, data);

      return this.http.post(url, request, httpOptions as any);     

    }


    public getListIngresoArchivo(filters) {
      let url = this.Conf.ENDPOINT_API + 'listIngresoArchivo';
      return this.http.post(url, filters);   
    }



    public getProductsApplicationSource(data){

      let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }



      let url = this.Conf.ENDPOINT_API + 'getProductsApplicationSource';
      let request = Object.assign({}, data);

      return this.http.post(url, request, httpOptions);         

    }

    public getVendorsList(){

    

      let httpBody;

      let httpOptions = {
        withCredentials: false
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }



      let url = this.Conf.ENDPOINT_API + 'getPromotores';

    
      return this.http.get(url, httpOptions);   

    }   
     
    public getAllPromotores(){

      let url = this.Conf.ENDPOINT_API + 'getPromotores';
      let headers = new HttpHeaders()
      let wc = (Environment.CREDENTIALS) ? true : false;
      
      return this.http.get(url,  {observe: "response",withCredentials: wc})
        
    }


    //for: masiveTelemarketingChangeStatus
    cambioEstadoMasivaFileUpload(file: File, usuario:string) {

      let formData:FormData = new FormData();
      let filename = file.name;
      formData.append('file', file, filename);
      formData.append('usuario', usuario);

      let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          //'Content-Type': 'multipart/form-data',
          'Accept': 'application/json'
        })
      };

      
      let url = this.Conf.ENDPOINT_API + 'procesamientoMasivo';

      return this.http.post(url, formData, httpOptions);

    }

    public getListProvince(){

      let url = this.Conf.ENDPOINT_API + 'getProvincias';
      let headers = new HttpHeaders()
      let wc = (Environment.CREDENTIALS) ? true : false;
      
      return this.http.get(url, {observe: "response",withCredentials: wc})

    }


}
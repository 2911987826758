import { Injectable } from '@angular/core';
import * as $ from 'jquery';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Environment } from '../../config/environment';
import { Config } from '../../config/config';
import { config } from 'process';
import { Observable, of } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ExcelFilesService {

    
    constructor(private http: HttpClient, private Conf: Config) { }


    aprobacionMasivaFileDownload(data) {

      let httpOptions = {
        withCredentials: false,
        responseType:'text',
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      };
      
      let url = this.Conf.ENDPOINT_API + 'downloadProMasivo';
      let request = Object.assign({}, data);

      return this.http.post(url, request, httpOptions as any);     

    }

    
    public getListIngresoArchivo() {
      let request = {nombre:""};

      let url = this.Conf.ENDPOINT_API + 'listIngresoArchivo';
      return this.http.post(url, request);   
    }
}
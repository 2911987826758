import { Injectable } from '@angular/core';
import * as $ from 'jquery';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Environment } from '../../config/environment';
import { Config } from '../../config/config';

import { map, catchError, retry } from 'rxjs/operators';
import { Observable, Subscription, throwError, timer } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InternalSourceCodesService {


    constructor(private http: HttpClient, private Conf: Config) { }

    public getData(){


      let httpOptions = {
        withCredentials: false
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }

      let url = this.Conf.ENDPOINT_API + 'internal/getSourcecodes';

      return this.http.get(url, httpOptions);  

    }

    public create(data){

        let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }

      let url = this.Conf.ENDPOINT_API + 'internal/createSourcecode';
      let request = Object.assign({}, data);

      return this.http.post(url, request, httpOptions);  

    }

    public update(data){

      let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }

      let url = this.Conf.ENDPOINT_API + 'internal/updateSourcecode';
      let request = Object.assign({}, data);

      return this.http.post(url, request, httpOptions);  

    }    

    public delete(data){

  

      let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }

      let url = this.Conf.ENDPOINT_API + 'internal/deleteSourcecode';
      let request = Object.assign({}, data);

      return this.http.post(url, request, httpOptions);  

    }    

    import(file: File) {

      let formData:FormData = new FormData();
      let filename = file.name;
      formData.append('file', file, filename);


      let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          //'Content-Type': 'multipart/form-data',
          'Accept': 'application/json'
        })
      };

      
      let url = this.Conf.ENDPOINT_API + 'internal/importSourcecodes';

      return this.http.post(url, formData, httpOptions);

    }

    getProductos():Observable<any>{


      
      let httpOptions = {
        withCredentials: false
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }

      let url = this.Conf.ENDPOINT_API + 'getProductos';  

      return this.http.get(url, httpOptions) .pipe(
        map(data => {
          
            if(data["code"] == "0" && data["data"]){
              if(data["data"].length != 0) {      

                let datamap = {code:0, data:[]};
                
                datamap.data = data["data"].map(d=> {
                  //return {key: d.prod, value: d.prodName, data: d}
                  return {key: d.id, value: d.prodName, data: d}
                });
                
                
                return datamap;
              }
            }
            
            return data; // kind of useless
        }),
        catchError(e => throwError(e))
     ); // end of pipe; 
    }
    

    getVendors(){
      
      let httpOptions = {
        withCredentials: false
      };
  
      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }
  
      let url = this.Conf.ENDPOINT_API + 'internal/getVendorsList';  
  
      return this.http.get(url, httpOptions);
    }

    public getAllPromotores(){

      let url = this.Conf.ENDPOINT_API + 'getPromotores';
      let headers = new HttpHeaders()
      let wc = (Environment.CREDENTIALS) ? true : false;
      
      return this.http.get(url,  {observe: "response",withCredentials: wc})
        
    }

    public getIntSubchannel(){

      
      let httpOptions = {
        withCredentials: false
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }

      let url = this.Conf.ENDPOINT_API + 'internal/getSubchannels';
      let wc = (Environment.CREDENTIALS) ? true : false;

      return this.http.get(url,  {observe: "response",withCredentials: wc})
    }
}

import { Injectable } from '@angular/core';
import * as $ from 'jquery';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Environment } from '../../config/environment';
import { Config } from '../../config/config';


@Injectable({
  providedIn: 'root'
})
export class ReportApplicantsService {
  
    constructor(private http: HttpClient, private Conf: Config) { }

    public getVendorsList(){

        let httpBody;
  
        let httpOptions = {
          withCredentials: false
        };
  
        if(Environment.CREDENTIALS){
          httpOptions.withCredentials = true;
        }
  
  
        let url = this.Conf.ENDPOINT_API + 'getVendorsList';
  
        return this.http.get(url, httpOptions);   
  
    }    

    public getProductsList(){

      let httpBody;

      let httpOptions = {
        withCredentials: false
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }


      let url = this.Conf.ENDPOINT_API + 'getProductos';

      return this.http.get(url, httpOptions);   

  }    

    public getData(data:any){

      let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }

      let url = this.Conf.ENDPOINT_API + 'getNewAccountsApproved';//ENDPOINT A MODIFICAR
      let request = Object.assign({}, data);

      return this.http.post(url,request, httpOptions);  

    }

}

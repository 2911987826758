import { AbstractType, Component, OnInit } from '@angular/core';
import { LoadingComponent } from '../shared/loading/loading.component';
import { AlertComponent, AlertModel } from '../shared/alert/alert.component';
import { ReportTotalService } from 'src/app/services/reportTotal/reportTotal.service';
import { v4 as uuidv4 } from 'uuid';

import { ɵAnimationGroupPlayer } from '@angular/animations';
import { Environment } from 'src/app/config/environment';
import { ReturnStatement } from '@angular/compiler';
import { ReporteTotalDetails, BusquedaFiltrosModel } from './models/models';
import { disableDebugTools } from '@angular/platform-browser';
import { SessionStorageService } from 'src/app/auth/services/session.service';
import { Roles } from 'src/app/auth/models/roles.static';
import { Utils } from 'src/app/commons/utils';


@Component({
  selector: 'app-report-total',
  templateUrl: './reportTotal.component.html',
  styleUrls: ['./reportTotal.component.css']
})

export class ReportTotalComponent implements OnInit {

  public titlemap: string = "Reporte Total - <fecha>";
  public title: string = "Reporte Total";
  public es: any;
  public showResult : boolean;
  public showDetailsResult : boolean;
  public tablereports = null;   
  public busquedafiltros: BusquedaFiltrosModel = new BusquedaFiltrosModel;

  public loading: boolean;
  public alertMessage: AlertModel = new AlertModel;

  public validateShow: boolean =  false;
  public resultData = [];
  public resultDataDetails: ReporteTotalDetails = new ReporteTotalDetails();

  
  constructor(private reportTotalService: ReportTotalService,
              private sessionStorageService: SessionStorageService) {

                this.sessionStorageService.rolValidate("reportTotal");
  }

  ngOnInit() {
    this.showResult = false;
    this.setCalendarLocale();
    
    this.title =  this.title.replace("<fecha>", this.setDateFormat(new Date()));
  }


  showBotonDetalle(value):boolean{
    let estados = ["DATO RECHAZADO POR CRITERIO NOSIS",
                   "DATO RECHAZADO POR CRITERIO VRZ",
                   "NEGATIVE",
                   "REPETIDO",
                   "DATO RECHAZADO"
                  ];  

    if(Utils.isNullOrEmpty(value))
        return false;

    return !estados.includes(value);

  }

  loadResultData(){

    if(Utils.isNullOrEmpty(this.busquedafiltros.fecha_modificacion)){
      this.alertMessage = new AlertModel("El campo fecha es requerido.",this.title,true);
      return;
    }

    let request = {"fecha_modificacion": this.busquedafiltros.fecha_modificacion};
    
    this.title =  this.titlemap.replace("<fecha>", this.busquedafiltros.fecha_modificacion);
    this.loading = true;

    this.reportTotalService.getData(request).subscribe(
      data => {
        if (data['data']) {
          this.resultData = data['data'];

          this.setupTable();             

        }
        else {
          this.loading = false;
          console.error(data);
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title,true);
        }
        
      },
      error => {
        this.loading = false;
        console.error(error);
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title,true);
      }
    );

  }

  loadResultDataDetails(id:string, details: HTMLElement){

    this.showDetailsResult = false;
    this.loading = true;
    let request = { "pcn":id};

    this.reportTotalService.getDataDetails(request).subscribe(
      data => {
        if (data != null) {
          let result = <any>data;

          this.resultDataDetails.adicionales = result.adicionales;
          this.resultDataDetails.variables_equifax = this.getDetalleParse(result.variables_equifax);
          this.resultDataDetails.variables_nosis = this.getDetalleParse(result.variables_nosis);

          if(this.resultDataDetails.adicionales &&
             this.resultDataDetails.adicionales.length != 0)
              this.resultDataDetails.adicionales.forEach((i, idx)=> i.supp_seq_no = idx+1);

          
          this.showDetailsResult = true;
          $(document).ready( () => {
             details.scrollIntoView({ behavior: "smooth", block: "start" });
          });
        }
        else {
          console.error("no data null");
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title,true);
        }

        this.loading = false;
      },
      error => {
        console.error(error);
        this.loading = false;
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title,true);
      }
    );

  }

  getDetalleParse(variables_salida: any){
    let detalleReturn = [];

    for(var key in variables_salida)
    {       
       if(variables_salida[key])
       {
          detalleReturn.push({"key":key, "value":variables_salida[key]});
       }else
         detalleReturn.push({"key":key, "value":""});
    }

    return detalleReturn;
  }


  onConfirmEvent(confirm:any){
    console.log(confirm);
  }


  backToResults(){
    this.showResult = true;
    this.loading = false;

  }

  setDateFormat(value:Date){

    return Utils.setDateFormat(value);

  }



  setupTable(){ 

    this.showResult = true;
    this.loading = false;

    if (this.tablereports != null) {
      this.tablereports.clear().destroy();
    } 
    
    $(document).ready( () => {
        this.tablereports =   $("#result-table").DataTable({

        searching: false, 
        paging: true, 
        
        lengthChange: false,
        pageLength: 10,
        pagingType: 'full_numbers',
        order: [],
        columnDefs: [
          { targets: 'no-sort', orderable: false }
        ],        
        language: Environment.DataTableLanguageSetting
       });


    });

  }  

  setCalendarLocale(){

    this.es = Environment.CalendarLanguageSetting;
  }

  public  showSubRow(id){
   
   
    $(".div-sub-row").not("#tr-" + id).hide();
    $("#tr-" + id).toggle(500);
  }


}



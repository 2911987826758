export class AplicantDetailModel {
    area: string;  
    canal: string;  
    fecha_aplicacion: Date;
    pcn: string;  
    dni: string;  
    nombre: string;  
    domicilio: string;  
    localidad: string;  
    celular: string;  
    telefono: string;  
    estado: string; 
    subestado: string; 
    motivo: string; 
    promotor: string;
    promotor_original: string; 
    prod: string; 
    observacion: string; 
    genero: string; 
    fechaModificacion: string;
    cuil: string;

}


export class BusquedaFiltrosModel {

    pcn: string;  
    dni: string;  
    nombre: string;  
}
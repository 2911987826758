export class Roles {
    
    
        static AMX_ADMIN ='AMX_ADMIN';
        static AMX_MANAG = "AMX_MANAG";  
        
        static AMX_SUPER = "AMX_SUPER";

        static AMX_AG_OPER = "AMX_AG_OPER";  
        static AMX_AG_SUPER = "AMX_AG_SUPER";  
        
        static AMX_RD_OPER = "AMX_RD_OPER";  
        static AMX_RD_SUPER = "AMX_RD_SUPER";  

        static AMX_NA_OPER = "AMX_NA_OPER";  
        static AMX_NA_SUPER = "AMX_NA_SUPER"; 
        static AMX_RIESGO_ADM = "AMX_RIESGO_ADM"

        static AMX_CONSULTA = "AMX_CONSULTA"



}

export const RolesArea = []


import { Component, ElementRef, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import * as _ from 'lodash';
import { Environment } from '../../config/environment';

import { AlertComponent, AlertModel, ALERT_TYPE } from '../shared/alert/alert.component';
import { ActivatedRoute } from '@angular/router';
import {Router} from '@angular/router';
import { Config } from '../../config/config';
import { Subject } from 'rxjs';
import { HttpClient, JsonpClientBackend } from '@angular/common/http';
import { DocRecoveryService } from 'src/app/services/docrecovery/docrecovery.service';

import { SessionStorageService } from 'src/app/auth/services/session.service';
import { extend } from 'jquery';
import { BaseComponent } from '../shared/base.component';
import { ShortLongFormService } from 'src/app/services/shortLongForm/shortLongForm.service';

@Component({
  selector: 'app-masive-shortlongform',
  templateUrl: './masiveShortLongForm.component.html',
  styleUrls: ['./masiveShortLongForm.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class MasiveShortLongFormComponent extends BaseComponent implements OnInit{

  public title: string = "Carga Manual de Archivos SHORT & LONG Form";
  public loading: boolean;
  public displayError: boolean;
  public msgError: string;
  public displayCombo: boolean;
  public showBackButton: any = Environment.SHOW_BACK_BUTTON;

  public alertMessage: AlertModel = new AlertModel;
  public alertConfirmEvent: Function;

  public table = null;
  public files = [];

  


  constructor(private sessionStorageService: SessionStorageService,
              private shortLongFormService: ShortLongFormService) { 

            super();
            this.sessionStorageService.rolValidate("config");

            this.loading = true;
            this.displayError = false;
  }

  ngOnInit(): void {
    this.loading = false;
    this.displayError = false;
    this.msgError = null;

    this.loadTable();
  }

 
  public uploadFileValidate(event):boolean{

    let fileList: FileList = event.target.files;
    

    if(fileList.length == 0) {          
      return false;
    }

    if(fileList.length > 1) {      
      this.alertMessage = new AlertModel("Seleccione solo un archivo.", this.title, true, ALERT_TYPE.WARNING);
      return false;
    }

    let file: File = fileList[0];

    if(!file.name.toUpperCase().includes("EAPPLY_EASY_BASIC_") &&
       !file.name.toUpperCase().includes("AR_LEADCAPTURE_IFORMS")) {
      this.alertMessage = new AlertModel('El nombre de archivo debe comenzar con "EAPPLY_EASY_BASIC_" o "AR_LeadCapture_iForms".', this.title, true, ALERT_TYPE.WARNING);
      return false;
    }

    if(file.name.toUpperCase().includes("AR_LEADCAPTURE_IFORMS")) {
      var extension = file.name.split(".").pop().toLowerCase();
      var extensiones = ["csv"];

      if(extensiones.indexOf(extension) === -1){
        this.alertMessage = new AlertModel("Para Short Form seleccione un archivo con extención .csv", this.title, true, ALERT_TYPE.WARNING);
        return false;
      }
    }

    if(file.name.toUpperCase().includes("EAPPLY_EASY_BASIC")) {
      var extension_e = file.name.split(".").pop().toLowerCase();
      var extensiones_e = ["txt"];

      if(extensiones_e.indexOf(extension_e) === -1){
        this.alertMessage = new AlertModel("Para Long Form seleccione un archivo con extención .txt", this.title, true, ALERT_TYPE.WARNING);
        return false;
      }
    }



    return true;

  }


  public uploadFile(event){
      if(!this.uploadFileValidate(event))
        return;

        
      let fileList: FileList = event.target.files;
      let file: File = fileList[0];

  
      this.loading = true;
      let usuario = this.sessionStorageService.getCurrentUser().name;

      this.shortLongFormService.procLongShortFileUpload(file, usuario).subscribe(
        data => {
          if (data['code']== 0) {
        
            this.alertMessage = new AlertModel("La carga se procesó con exito", this.title, true, ALERT_TYPE.SUCCESS);
            this.loadTable();
          }
          else {
            let message  = data['message'] ? data['message'] :"Hubo un inconveniente al obtener la información, inténtalo nuevamente.";
            this.alertMessage = new AlertModel(message, this.title, true, ALERT_TYPE.WARNING);
            this.loading = false;
          }

         
        },
        error => {
          console.error("Error");
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true, ALERT_TYPE.ERROR);
          this.loading = false;
        }
      );   
    
    $("#importFile").val('');
  }

  public downloadFile(name){

    let request = {"name" : name};

    this.shortLongFormService.procLongShortFileDownload(request).subscribe(
      data => {

        if (data) {
          
          const blob = new Blob([data], { type: 'text/csv' });
          const url = window.URL.createObjectURL(blob);
          
          var filelink = document.createElement("a");
          filelink.download = name;
          filelink.href = url;
          filelink.click();
          
        }
        else {
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true, ALERT_TYPE.ERROR);
        }

        this.loading = false;
      },
      error => {
        console.error(error);
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true, ALERT_TYPE.ERROR);
        this.loading = false;
      }
    );

  }

 
  public loadTable(){
      this.loading = true;
      
      this.shortLongFormService.getListLongShort({}).subscribe(
        data => {
          if (data['code']== 0) {
            this.files = data['response'];
            this.files.forEach(i=> i.estado = i.estado.replace("PROCESANDO", "PROCESADO"))
            this.setupTable();
          
          }
          else {
            console.error("Error");
            this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true, ALERT_TYPE.WARNING);
            this.loading = false;
          }

         
        },
        error => {
          console.error("Error");
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true, ALERT_TYPE.WARNING);
          this.loading = false;
        }
      );   
  }

  setupTable(){

    if (this.table != null) {
      this.table.clear().destroy();
    } 
  

    $(document).ready( () => {
      this.table =   $("#main-table").DataTable({
                  paging: true, 
                  lengthChange: false,
                  pageLength: 10,
                  pagingType: 'full_numbers',
                  columnDefs: [
                    { targets: 'no-sort', orderable: false }
                  ],        
                  language: Environment.DataTableLanguageSetting
                });
     });

     this.loading = false;
}

}

import { Component, OnInit } from '@angular/core';
import { AbmUsersService } from 'src/app/services/abmUsers/abmUsers.service';
import { BaseComponent } from '../shared/base.component';
import { AlertModel } from '../shared/alert/alert.component';
import { SessionStorageService } from 'src/app/auth/services/session.service';
import { UserModel,UserRolesModel,Matrix,BusquedaFiltrosModel } from './models/models';
import { Environment } from '../../config/environment';
import { Utils } from 'src/app/commons/utils';
import { CommonDataService } from 'src/app/services/commons/commondata.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { User } from 'src/app/auth/models/user.model';

declare var $ : any;


@Component({
  selector: 'app-abm-users',
  templateUrl: './abmUsers.component.html',
  styleUrls: ['./abmUsers.component.css'],
})
export class AbmUsersComponent extends BaseComponent implements OnInit {

  public fecha;

  //public resultData = [];
  public showResult : boolean;
  public showForm : boolean;
  public showSearchForm : boolean;
  public loading: boolean;
  public title: string = "Abm de Usuarios";
  public validateShow: boolean =  false;
  public validateMessage: string;
  public alertMessage: AlertModel = new AlertModel();
  public ngSelect: string = "";

  public userList: UserModel[] = new Array<UserModel>();
  //public userList2 : UserModel = new UserModel();
  public alertConfirmEvent: Function;
  public tablereports = null;
  public matrixList: Matrix[] = new Array<Matrix>();
  public rolesList: UserRolesModel[] = new Array<UserRolesModel>();
  public rolesListAll: UserRolesModel[] = new Array<UserRolesModel>();
  public rolesListCancel: UserRolesModel[] = new Array<UserRolesModel>();
  public currentUser : UserModel;
  public isEdit: boolean;
  public rowForDelete: string;
  public busquedaFiltros : BusquedaFiltrosModel = new BusquedaFiltrosModel();
  public mfaTypes:any = [];


  constructor(private sessionStorageService: SessionStorageService,
              private abmUsersService: AbmUsersService,
              private commonDataService: CommonDataService) { 
    super();
    this.sessionStorageService.rolValidate("userManager");
    this.loading = true;
  }

  ngOnInit(): void {
    this.mfaTypes = [{"name":"Google Authenticator", "value":1},{"name":"Mail", "value":2}];
    this.loading = false;
    this.showResult = false;
    this.showSearchForm = true;
    this.loadRolesList();
    this.loadMatrixList();
    
    this.busquedaFiltros.active = "ACTIVO";
    this.busquedaFiltros.matriz = "";
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
  }

  public loadRolesList(){
    
    this.abmUsersService.getUserRoles().subscribe(
      response => {
        if( response.status == 200 && response.body != null){
          var data = response.body['data'];
          this.rolesList = Object.assign([], data);
          this.rolesListAll = Object.assign([], data);
        }else if( response.status == 204 ){
          this.alertMessage = new AlertModel( "No se encontraron registros con los datos ingresados.",this.title, true);
        }else{
          this.alertMessage = new AlertModel( "Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title, true,"WARNING");
        }
      
        this.loading = false;
        },
    );

  }

  public loadMatrixList(){
   
    this.abmUsersService.getMatrices().subscribe(
      response => {

        if( response.status == 200 && response.body != null){
          var data = response.body['data'];
          this.matrixList = Object.assign([], data);
        }else if( response.status == 204 ){
          this.alertMessage = new AlertModel( "No se encontraron registros con los datos ingresados.",this.title, true);
        }else{
          this.alertMessage = new AlertModel( "Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title, true,"WARNING");
        }
      
        this.loading = false;
        },
    );
  }

  loadUsersData(){

    this.loading = true;

    let request = { 
      "userName": Utils.isNullOrEmpty(this.busquedaFiltros.userName) ? null : this.busquedaFiltros.userName,
      "fullName": Utils.isNullOrEmpty(this.busquedaFiltros.fullName) ? null : this.busquedaFiltros.fullName,
      "email": Utils.isNullOrEmpty(this.busquedaFiltros.email) ? null : this.busquedaFiltros.email,
      "matriz": Utils.isNullOrEmpty(this.busquedaFiltros.matriz) ? null : this.busquedaFiltros.matriz,
      "active": this.mapEstadoToBoolean(this.busquedaFiltros.active)
    };

    this.abmUsersService.getUserListByFilters( request ).subscribe(
      response => {
        if( response.status == 200 && response.body != null){
          var data = response.body['data'];
          this.userList = Object.assign([], data);
          this.showResult = true;
          this.setupTable();
        }else if( response.status == 204 ){
          this.alertMessage = new AlertModel( "No se encontraron registros con los datos ingresados.",this.title, true);
        }else{
          this.alertMessage = new AlertModel( "Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title, true,"WARNING");
        }
      
        this.loading = false;
        },
    );

  }

  mapEstadoToBoolean(estado: string): boolean | null {
    if (estado === 'ACTIVO') {
      return true;
    } else if (estado === 'INACTIVO') {
      return false;
    } else {
      return null; // Para "AMBAS" u otros casos
    }
  }

  setupTable(){

    if (this.tablereports != null) {
      this.tablereports.clear().destroy();
    } 
    
    $(document).ready( () => {

      this.tablereports =   $("#result-table").DataTable({
        searching: true, 
        paging: true, 
        lengthChange: false,
        pageLength: 10,
        pagingType: 'full_numbers',
        columnDefs: [
          { targets: 'no-sort', orderable: false }
        ],        
        language: Environment.DataTableLanguageSetting
      });

    });

  }

  public showUserEdit( username, matriz ){
    this.rolesList = Object.assign([], this.rolesListAll);
    let userObject = this.userList.filter(data => data.userName == username && data.customerId == matriz);


    if( userObject.length ){
      this.currentUser = Object.assign([], userObject[0]);
      this.rolesListCancel = Object.assign([], this.currentUser.roles);
      this.currentUser.newUserName = this.currentUser.userName
      this.currentUser.newCustomerId = this.currentUser.customerId

      this.currentUser.mfaTipo = ( this.currentUser.mfaTipo != null ) ? this.currentUser.mfaTipo : 0;
      // //quito de la lista de roles los que ya tiene el usuario
      for( var i = 0; i < this.rolesList.length; i++ ){
        for( var j = 0; j < this.currentUser.roles.length; j++ ){
          if( this.rolesList[i].idRol == this.currentUser.roles[j].idRol ){
            this.rolesList.splice(i,1);
          }
        }
      }
    }else{
      this.currentUser = new UserModel();
      console.log( "No se encontro el usuario" );
    }
    this.isEdit = true;
    this.showResult = false;
    this.showSearchForm = false;
    this.showForm = true;
  }

  public changeNewUser(value:string , fieldType: string) {
    if(fieldType == "userName"){
      this.currentUser.newUserName = value
    }else{
      this.currentUser.newCustomerId = value
    }

  }

  public newFormUser(){
    this.rolesList = Object.assign([], this.rolesListAll);
    this.currentUser = new UserModel();
    this.currentUser.customerId = "";
    this.currentUser.newCustomerId = "";
    this.currentUser.active = true;
    this.currentUser.roles = new Array<UserRolesModel>();
    this.isEdit = false;
    this.showResult = false;
    this.showSearchForm = false;
    this.showForm = true;
  }

  public cancelNewUser(){
    let userObject = this.userList.filter(data => data.userName == this.currentUser.userName);
    if( userObject.length ){
      userObject[0].roles = Object.assign([],this.rolesListCancel);
    }
    this.rolesList = Object.assign([], this.rolesListAll);
    this.showResult = true;
    this.showSearchForm = true;
    this.showForm = false;
  }

  public saveDataUser(){
    if( !this.validFormUser() )
      return;

      this.loading = true;
  
      if( this.isEdit ){
        this.editUser()
      }else{
        this.createNewUser();
      }

  }

  editUser(){

    let userToSend = this.userToSend();

    this.abmUsersService.editUser( userToSend ).subscribe(
      response => {
        if( response.status == 200 && response.body != null){
          this.loading = false;  
          this.showForm = false;
          this.showSearchForm = true;
          this.alertMessage = new AlertModel("El usuario se edito correctamente", this.title, true, "SUCCESS");
          this.clearAllDates()
        }else if( response.status == 204 ){
          this.alertMessage = new AlertModel( "No se encontraron registros con los datos ingresados.",this.title, true);
        }else{
          this.alertMessage = new AlertModel( "Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title, true,"WARNING");
        }
      
        this.loading = false;
      },err => {
        if( !Utils.isNullOrEmpty( err.error.message ) ){
          this.alertMessage = new AlertModel( err.error.message+"<br>Revise los datos enviados",this.title, true,"WARNING");
        }else{
          this.alertMessage = new AlertModel( "Se produjo un error, revise los datos enviados",this.title, true,"WARNING");
        }
        this.loading = false;
        throw err;
      },
    );
  }

  createNewUser(){
      
    let userToSend = this.userToSend();
    this.currentUser.userName = this.currentUser.newUserName
    this.currentUser.customerId = this.currentUser.newCustomerId

    this.abmUsersService.createNewUser( userToSend ).subscribe(
      response => {
        if( response.status == 200 && response.body != null){
          this.loading = false;  
          this.showForm = false;
          this.showSearchForm = true;
          this.alertMessage = new AlertModel("El usuario se creo correctamente", this.title, true, "SUCCESS");
          this.clearAllDates()
        }else if( response.status == 204 ){
          this.alertMessage = new AlertModel( "No se encontraron registros con los datos ingresados.",this.title, true);
        }else{
          this.alertMessage = new AlertModel( "Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title, true,"WARNING");
        }
      
        this.loading = false;
        },err => {
          // console.log( err )
          if( err.error.code != null && err.error.code == 542 ){
            this.alertMessage = new AlertModel( "Usuario creado correctamente<br>Error al enviar mail de notificación",this.title, true );
          }else{
            var mge = ( err.error != null && err.error.message != null )? err.error.message : err.message;
            this.alertMessage = new AlertModel( mge,this.title, true,"WARNING");
          }
          this.loading = false;
          throw err;
        }
    );
  }

  public userToSend():UserModel{
    this.currentUser.email = ( !Utils.isNullOrEmpty(this.currentUser.email) ) ? this.currentUser.email.toLowerCase().trim() : null;
    this.currentUser.newUserName = this.currentUser.newUserName.toUpperCase().trim();
    if(!this.isEdit){
      this.currentUser.newUserName = this.currentUser.newUserName.toUpperCase().trim();
      this.currentUser.newCustomerId = this.currentUser.newCustomerId;
    }
    this.currentUser.mfaTipo = Number(this.currentUser.mfaTipo);
    this.currentUser.btb = (this.currentUser.btb === undefined) ? false : this.currentUser.btb
    return this.currentUser;
  }

  public confirmDeleteUser( userName, matriz ){
    let userObject = this.userList.filter(data => data.userName == userName && data.customerId == matriz);
    if( userObject.length ){
      this.currentUser = Object.assign([], userObject[0]);
      this.rowForDelete = "#"+userName+"-"+matriz;
    }
    this.alertMessage = new AlertModel("Está seguro de eliminar este usuario",this.title,true, "CONFIRM");
    
  }

  onConfirmEvent(confirm:any){
    if(confirm.confirm){
      this.userDelete();
    }
  }

  public userDelete(){
    this.loading = true;
    this.abmUsersService.deleteUser(this.currentUser ).subscribe(
      response => {
        if( response.status == 200 && response.body != null){
          this.loading = false;  
          //si sale todo bien con el endpoint para borrar elimino la fila de la tabla
          $('#result-table').DataTable().row( $( this.rowForDelete ).parents('tr') ).remove().draw();
          this.alertMessage = new AlertModel("El usuario se borro correctamente", this.title, true, "SUCCESS");
          this.clearAllDates()
        }else if( response.status == 204 ){
          this.alertMessage = new AlertModel( "No se encontraron registros con los datos ingresados.",this.title, true);
        }else{
          this.alertMessage = new AlertModel( "Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title, true,"WARNING");
        }
        },
    );

  }

  public validFormUser():boolean{
    let validations = "";
    let arrayErrors = [];

    if( Utils.isNullOrEmpty(this.currentUser.fullName) )
      arrayErrors.push( "Debe completar el campo Nombre Completo.");

    if( !Utils.validTextField( this.currentUser.fullName ) )
      arrayErrors.push( "El campo Nombre Completo solo puede contener letras.");

    if( Utils.isNullOrEmpty(this.currentUser.newUserName) || this.currentUser.newUserName.trim().indexOf(" ") != -1 )
      arrayErrors.push( "Debe completar el campo Usuario sin espacios en blanco.");

    let rxEmail = new RegExp(Environment.RegExEmail);
    if( !rxEmail.test( this.currentUser.email ) ){
      arrayErrors.push( "Debe completar el campo Email con una dirección válida.");
    }

    if(Utils.isNullOrEmpty(this.currentUser.newCustomerId))
      arrayErrors.push( "Debe seleccionar la Matriz.");

   if( !this.isEdit ){
      let userByMail = this.userList.filter(data => data.email == this.currentUser.email);
      if( userByMail.length ){
        //arrayErrors.push( "Ya existe un usuario con el Email ingresado.");
      }

      let userByUser = this.userList.filter(data => data.newUserName == this.currentUser.newUserName.toUpperCase().trim() 
        && data.newCustomerId == this.currentUser.newCustomerId);
      if( userByUser.length ){
        arrayErrors.push( "Ya existe este Usuario con esta Matriz");
      }
    }

    if( arrayErrors.length ) {

      for( var i = 0; i < arrayErrors.length; i++ ){
        var conc = ( i == 0 )? "- " : "<br> -";
        validations += conc + arrayErrors[ i ];
      }

        this.validateShow  =  true;
        this.validateMessage = validations;
        this.alertMessage = new AlertModel(this.validateMessage,this.title,true,"WARNING");
        return false;
    }
    
    this.validateShow  =  false;
    this.validateMessage = "";
    return true;

  }
    
  public clearAllDates(){
    this.busquedaFiltros.email = ""
    this.busquedaFiltros.matriz = ""
    this.busquedaFiltros.fullName = "";
    this.busquedaFiltros.userName = "";
    
  }

  exportUsersData(){

    let reportDataExport = new Array<any>();
    
    this.userList.forEach(item => {   
      //string con roles del usuario
      let rolesString = "";
      for( var i = 0; i < item.roles.length; i++ ){
        let conct = (i == 0) ? "" : ", ";
        rolesString += conct + item.roles[i]["name"];
      }
      //objeto con el tipo de mfa
      let nameMfa = this.mfaTypes.filter(data => data["value"] == item.mfaTipo)[0];
      //objeto que agrego al array
      let objRow: any = new Object;

      objRow["Usuario"] = item.userName;
      objRow["Nombre completo"] = item.fullName;
      objRow["Mail"] = item.email;
      objRow["Rol"] = rolesString;
      objRow["Matriz"] = item.customerId;
      objRow["Estado"] = (item.active) ? "ACTIVO" : "INACTIVO";
      objRow["Tipo de MFA"] = (nameMfa != null ) ? nameMfa["name"] : "";      

      reportDataExport.push(objRow);
    }); 

    this.commonDataService.exportAsExcelFile(reportDataExport, this.title);

  }

}




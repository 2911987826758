export class SourceCodesModel {
    constructor(){
        this.prod = "";
    }
    
    id: string;
    canal: string;
    descripcion: string;
    sid: string;
    estado: string;
    comercializadora: string;
    promotor: string;
    prod: string;
    ciclo: string;
    source_code: string;
    source_code_completo: string;
    tipo_aplicacion: string;
    delivery_1: string;
    delivery_2: string;
    prod_name: string;
    prod_mns: string;
    ingreso: string;


    created_timestamp: string;
    created_user: string;
    last_updated: string;
    initial_effective_date: string;
    final_effective_date: string;
    
}




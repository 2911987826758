import { Injectable } from '@angular/core';
import * as $ from 'jquery';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Environment } from '../../config/environment';
import { Config } from '../../config/config';
import { config } from 'process';
import { of } from 'rxjs';
import { MockStatic } from 'src/app/config/mock.static';
//import { areAllEquivalent } from '@angular/compiler/src/output/output_ast';
import { windowWhen } from 'rxjs/operators';
import { stringify } from 'querystring';
import { ParametersService } from '../parameters/parameters.service';


@Injectable({
  providedIn: 'root'
})

export class CreditLimitMatrixService {


    constructor(private http: HttpClient, private Conf: Config) { }

    getHeaderMatrixList(){
      
      let httpOptions = {
        withCredentials: false
      };
  
      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }
  
      let url = this.Conf.ENDPOINT_API + 'getCreditLimitCab';  
  
      return this.http.get(url, httpOptions);
    }

    public getScoreTypes(){

      let url = this.Conf.ENDPOINT_API + 'getCreditLimitScoreType';
      let headers = new HttpHeaders()
      let wc = (Environment.CREDENTIALS) ? true : false;
      
      return this.http.get(url,  {observe: "response",withCredentials: wc})
        
    }

    getLimitCreditMatrizByCab( data ){
      
      let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      };
  
      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }
  
      let url = this.Conf.ENDPOINT_API + 'getLimitCreditMatrizByCab';  
      let request = Object.assign({}, data);
  
      return this.http.post(url,request, httpOptions);
    }

    updateMatrix( data ){
      
      let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      };
  
      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }
  
      let url = this.Conf.ENDPOINT_API + 'updateLimitCreditMatriz';  
      let request = Object.assign([], data);
  
      return this.http.post(url,request, httpOptions);
    }


    getMaxCabByMatrixType( data:string ){

      let formData:FormData = new FormData();
      formData.append('tipoMatriz', data);

      let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          //'Content-Type': 'multipart/form-data',
          'Accept': 'application/json'
        })
      };

      
      let url = this.Conf.ENDPOINT_API + 'getMaxCabByMatrixType';

      return this.http.post(url, formData, httpOptions);
    }

    createNewLimitMatrix(data) {

      let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      };
  
      if (Environment.CREDENTIALS) {
        httpOptions.withCredentials = true;
      }
  
      let url = this.Conf.ENDPOINT_API + "createCreditLimitMatrizComplete";
      let request = Object.assign({}, data);
  
      return this.http.post(url, request, httpOptions);
    }

  public deleteLimitCreditMatriz(data){

    let url = this.Conf.ENDPOINT_API + 'deleteLimitCreditMatriz';
    let request = Object.assign({}, data);
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    })
    let wc = (Environment.CREDENTIALS) ? true : false;

    return this.http.post(url, request, {headers: headers, observe: "response",withCredentials: wc})
      
  }

}
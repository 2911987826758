import { Component, OnInit } from '@angular/core';
import { AlertModel } from '../../component/shared/alert/alert.component';
import { Utils } from 'src/app/commons/utils';
import { ReportAgenciesBusquedaModel,VendorFilterModel } from './models/models';
import { Environment } from 'src/app/config/environment';
import { SessionStorageService } from 'src/app/auth/services/session.service';
import { InternalReportAgenciesService } from 'src/app/services_internal/internalReportAgencies/internalReportAgencies.service';
import { ProductosList,ProfesionesList } from 'src/app/models/lists.model';
import { ExcelExportService } from 'src/app/services/excelExport/excel.export.service';
import { CommonDataService } from 'src/app/services/commons/commondata.service';
import { BaseComponent } from '../../component/shared/base.component';
declare var $ : any;

@Component({
  selector: 'app-internal-reportagencies',
  templateUrl: './internalReportAgencies.component.html',
  styleUrls: ['./internalReportAgencies.component.css']
})
export class InternalReportAgenciesComponent extends BaseComponent implements OnInit {

  public title: string = "Reporte de Status de Agencias - Customers";
  public es: any;
  public busquedafiltros: ReportAgenciesBusquedaModel = new ReportAgenciesBusquedaModel();
  public showResult : boolean;
  public tablereports = null;
  public loading: boolean;
  public alertMessage: AlertModel = new AlertModel;
  public validateShow: boolean =  false;
  public validateMessage: string;
  public estadosList: any = {data:[]};  
  public vendorsList :Array<VendorFilterModel> = new Array<VendorFilterModel>();
  public agenciesList: any = [];
  public agenciesWithVendors: any = {};
  public productosList: ProductosList = new ProductosList();
  public resultData = [];
  public currentCustomer : any;
  public showExtraData: boolean;
  public provinceData:any = [];
  public profesionesList: ProfesionesList =  new ProfesionesList();

  public areasList: Array<any> = [
    {"value":"TELEMARKETING", "text": "TELEMARKETING"},
    {"value":"RECOVERY", "text": "DOC RECOVERY"},
    {"value":"NEWACCOUNT", "text": "NEW ACCOUNT"},
    {"value":"DELIVERY", "text": "DELIVERY"},
    {"value":"FINISH", "text": "FINISH"}
  ];

  public arrayTipoVentas: Array<any> = [{"value":"SUP", "text": "Venta Isolada"},
    {"value":"RCO", "text": "Reverse Companion"},
    {"value":"CON", "text": "Conversion (Upgrade)"}
  ]

  constructor(private excelExportService: ExcelExportService,
      private internalReportAgenciesService: InternalReportAgenciesService,
      private commonDataService: CommonDataService,              
      private sessionStorageService: SessionStorageService) { 
               
      super();
      this.sessionStorageService.rolValidate("internalReportAgencies");

  }

  ngOnInit() {
    this.busquedafiltros.area = "";
    this.busquedafiltros.producto ="";
    this.busquedafiltros.promotor ="";
    this.busquedafiltros.tipo_de_venta ="";
    this.loading = true;

    this.profesionesLoad();
    this.listProvinceLoad();
    this.setCalendarLocale(); 
    this.loadVendorsList();
    this.loadProductosList();
  }

  profesionesLoad(){

    this.commonDataService.getProfesiones().subscribe(
      data => {
        if (data['data']){
          this.profesionesList.data = data['data'];
          this.profesionesList.data.sort((a, b)=> a.value?.localeCompare(b.value));  
        }        
        else 
          console.error(data);          
        
      },
      error => console.error(error)
    );
    return true;

  }

  loadVendorsList(){
  
    this.internalReportAgenciesService.getAllPromotores().subscribe(
      response => {
  
        if( response.status == 200 && response.body != null){
          this.vendorsList = Object.assign(this.vendorsList, response.body);
          this.setAgenciesWithVendors();
        }else if( response.status == 204 ){
          console.log( "no se encontraron promotores" );
        }else{
          console.log( "Error 1 obteniendo los promotores" );
        }
        this.loading = false;
  
      }, err => {
        console.log( "Error 2 obteniendo los promotores" );
        this.loading = false;
        throw err;
      }
    );
    
  }
  
  public setAgenciesWithVendors(){
    var arrayRespuesta = this.vendorsList;
    let nuevoObjeto = {}

    arrayRespuesta.forEach( x => {
      if( !nuevoObjeto.hasOwnProperty(x['grupoAgencia'])){
          nuevoObjeto[x['grupoAgencia']] = {
          promotores: []
        }
      }
  
      nuevoObjeto[x['grupoAgencia']].promotores.push(x.id)
    })
  
    this.agenciesWithVendors = nuevoObjeto;
    this.agenciesList = Object.keys(nuevoObjeto);
  }

  loadResultData(){

    if( !this.validateForm() ){
      return;
    }

    this.resultData = [];
    this.loading = true;

    let request = { 
      "fecha_captura_fin": Utils.isNullOrEmpty(this.busquedafiltros.fecha_captura_fin) ? null : Utils.formatDateToCustomFormat( this.busquedafiltros.fecha_captura_fin ), 
      "fecha_captura_inicio": Utils.isNullOrEmpty(this.busquedafiltros.fecha_captura_inicio) ? null : Utils.formatDateToCustomFormat( this.busquedafiltros.fecha_captura_inicio ), 
      "producto": Utils.isNullOrEmpty(this.busquedafiltros.producto) ? null : this.busquedafiltros.producto, 
      "area": Utils.isNullOrEmpty(this.busquedafiltros.area) ? null : this.busquedafiltros.area, 
      "promotores": Utils.isNullOrEmpty(this.busquedafiltros.promotor) ? null : this.agenciesWithVendors[this.busquedafiltros.promotor].promotores,
      "tipoVenta": Utils.isNullOrEmpty(this.busquedafiltros.tipo_de_venta) ? null : this.busquedafiltros.tipo_de_venta
    }; 

    this.internalReportAgenciesService.getInternalData(request).subscribe(
      response => {

        if( response.status == 200 && response.body != null && response.body['code'] == 0 ){

          this.resultData = Object.assign(this.resultData, response.body['data']);
          this.setDataToShow();
          this.setupTable();

        }else if( response.status == 200 && response.body['code'] == 1 ){
          this.setupTable();
          this.alertMessage = new AlertModel( "No se encontraron registros con los datos ingresados.",this.title, true);
        }else{
          this.alertMessage = new AlertModel( "Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title, true,"WARNING");
        }
        this.loading = false;

      }, err => {
        this.alertMessage = new AlertModel( "Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title, true,"WARNING");
        this.loading = false;
        throw err;
      }
    );

  }

  public setDataToShow(){
    //asigno producto y agencia a cada customer
    this.resultData.forEach(i=>{
      let prod = this.productosList.data.find(p=> (p.key??"") == parseInt( i.producto ) );
      i.productoObj = prod;
      let agency = this.vendorsList.find(q=> (q.id??"") == parseInt( i.informedPromotor ) );
      i.agencyObj = agency;

      //mapeo nombre de la profesion
      let profession = this.profesionesList.data.find(p=> (p.key??"") == parseInt( i.profession ) );
      i.professionName = ( profession ) ? profession.value : "";

      //ordeno y asigno lastObservation
      if( !Utils.isNullOrEmpty( i.observaciones ) ){
        i.lastObservation = i.observaciones.split("###")[0];
      }
      //formato de fechas
      if( !Utils.isNullOrEmpty( i.fechaModificacion ) ){
        i.fechaModificacion = Utils.dateToString( i.fechaModificacion );
      }
      if( !Utils.isNullOrEmpty( i.birthDate ) ){
        i.birthDate = Utils.dateToString( i.birthDate );
      }
      if( !Utils.isNullOrEmpty( i.memberSinceTitular ) ){
        i.memberSinceTitular = Utils.dateToString( i.memberSinceTitular );
      }
      if( !Utils.isNullOrEmpty( i.fechaCapturaPrecheck ) ){
        i.fechaCapturaPrecheck = Utils.dateToString( i.fechaCapturaPrecheck );
      }
      if( !Utils.isNullOrEmpty( i.fechaArchivo ) ){
        i.fechaArchivo = Utils.dateToString( i.fechaArchivo );
      }
      //asigno nombre de provincia
      if( !Utils.isNullOrEmpty( i.province ) ){
        let nameProvince = this.provinceData.filter(data => data.codigo == i.province);
        if( !Utils.isNullOrEmpty (nameProvince)){
          i.provinceName = nameProvince[0].descripcion
        }
      }

      //area
      i.area = (i.area === "TELEMARKETING") ? "TLMK" : i.area;
    })

  }

  validateForm():boolean{
    let validations = "";
    let arrayErrors = [];

    if(Utils.isNullOrEmpty( this.busquedafiltros.fecha_captura_inicio ) || Utils.isNullOrEmpty(this.busquedafiltros.fecha_captura_fin) ){
      arrayErrors.push( "Seleccione <b>Fecha Captura Inicio</b> y <b>Fecha Captura Fin</b>.");
    }

    if( arrayErrors.length ) {
      for( var i = 0; i < arrayErrors.length; i++ ){
        var conc = ( i == 0 )? "" : "<br>";
        validations += conc + arrayErrors[ i ];
      }
      this.alertMessage = new AlertModel(validations,this.title,true,"WARNING");
      return false;
    }
    
    return true;
  }

  setupTable(){

    this.showResult = true;

    if (this.tablereports != null) {
      this.tablereports.clear().destroy();
    } 
    
    $(document).ready( () => {
        this.tablereports =   $("#result-table").DataTable({

        searching: true, 
        paging: true, 
        
        lengthChange: false,
        pageLength: 10,
        pagingType: 'full_numbers',
        columnDefs: [
          { targets: 'no-sort', orderable: false },
          {
            targets: 8,
            render: (data, type, row) => {
              if (type === 'sort') {
                return Utils.dateForm(data);
              }
              return data;
            }
          },
          {
            targets: 9,
            render: (data, type, row) => {
              if (type === 'sort') {
                return Utils.dateForm(data);
              }
              return data;
            }
          },
        ],    
        deferRender: true,
        language: Environment.DataTableLanguageSetting,
        initComplete: (setting, json) => {
          this.loading = false;
        }
      });
    });

  }

  exportar (){

    let reportDataExport = new Array<any>();

    this.resultData.forEach(item => {      
      reportDataExport.push({
        "PCN":item.pcn
        ,"CUIL":item.cuil
        ,"NOMBRE COMPLETO":item.nombreCompleto
        ,"CANAL INGRESO":item.agencyObj.name
        ,"4 DIG.":item.keyDigits
        //,"AGENCIA":item.agencyObj.name
        ,"PROMOTOR":item.informedPromotor
        ,"TIPO VENTA":item.tipoVenta
        ,"PRODUCTO":item.productoObj.value
        ,"FECHA CAPTURA":item.fechaArchivo
        ,"DNI":item.docNum
        ,"FECHA NACIMIENTO":item.birthDate
        ,"GENERO":item.gender
        ,"NACIONALIDAD":item.nationality
        ,"TELÉFONO":item.tel1
        ,"CELULAR":item.tel2
        ,"PROFESIÓN":item.professionName
        ,"CANT. ADICIONALES":item.cantAdicionales
        ,"DIRECCIÓN":item.street
        ,"PROVINCIA":item.provinceName
        ,"COD. POSTAL":item.postalCode
        ,"EMAIL PERSONAL":item.email
        ,"MEMBER SINCE":item.memberSinceTitular
        ,"SOURCECODE":item.sourcecodecompleto
        ,"AGENCIA": item.grupoAgencia
        ,"FECHA APROB LANAS":item.fechaAprobacionLanas
        ,"FECHA MODIF.":item.fechaModificacion
        ,"REFNAPS":item.refnaps
        ,"AREA":item.area
        ,"ESTADO PRECHECK":item.estado
        ,"MOTIVO":item.estadoRegistro
        ,"OBSERVACIONES":item.lastObservation
        ,"ESTADO": item.accion
        ,"SUBESTADO DEL WORKFLOW": item.motivo
      });

    }); 

    this.excelExportService.exportAsExcelFile(reportDataExport, this.title);

  }

  setCalendarLocale(){
    this.es = Environment.CalendarLanguageSetting;
  }

  loadProductosList(){

    this.commonDataService.getProductsAndId().subscribe(
      data => {
        if (data['code'] == "0" && data['data']) {      
          
          this.productosList.data = Object.assign([], data['data']);

        }else {
          console.error(data);
        }
        
      },
      error => {
        console.error(error);
      }
    );

  }

  public showModalExtraData( pcn ){
    this.currentCustomer = this.resultData.find(p=> (p.pcn??"") == pcn );
    this.showExtraData = true;
  }

  listProvinceLoad(){
  
    this.internalReportAgenciesService.getListProvince().subscribe(
    response => {
      if( response.status == 200 && response.body != null){
        var data = response.body;
        this.provinceData = data['data']
      }else if( response.status == 204 ){
        this.alertMessage = new AlertModel( "No se encontraron registros con los datos ingresados.",this.title, true);
      }else{
        this.alertMessage = new AlertModel( "Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title, true,"WARNING");
      }
  
      this.loading = false;
      },
    );

    

  }

}

import { Injectable } from '@angular/core';
import * as $ from 'jquery';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Environment } from '../../config/environment';
import { Config } from '../../config/config';
import { config } from 'process';
import { of } from 'rxjs';
import { MockStatic } from 'src/app/config/mock.static';


@Injectable({
  providedIn: 'root'
})
export class AgenciesService {


    constructor(private http: HttpClient, private Conf: Config) { }

    public getAgencies(){

   
          var mockresponse = {
            "data": [
               {
                 "id":"1",
                 "agencia":"Conecta",
                 "responsable":"Ricardo Martinez"
               },
               
               {
                "id":"2",
                "agencia":"Agencia Sales",
                "responsable":"Mario Rodriguez"
               }, 
               
               {
                "id":"3",
                "agencia":"Market",
                "responsable":"Luciano Fiamengo"
               },
               
               {
                "id":"4",
                "agencia":"Nova",
                "responsable":"Martin Palavecino"
               },                   
          ]
          };

          return of(mockresponse);



    }

 

}
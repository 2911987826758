import { Component, OnInit } from '@angular/core';
import { ModifyUserService } from 'src/app/services/modifyUser/modifyUser.service';
import { BaseComponent } from '../shared/base.component';
import { AlertModel } from '../shared/alert/alert.component';
import { SessionStorageService } from 'src/app/auth/services/session.service';
import { UserModel } from './models/models';
import { Utils } from 'src/app/commons/utils';
import { CommonDataService } from 'src/app/services/commons/commondata.service';

declare var $ : any;


@Component({
  selector: 'app-modify-user',
  templateUrl: './modifyUser.component.html',
  styleUrls: ['./modifyUser.component.css'],
})
export class ModifyUserComponent extends BaseComponent implements OnInit {

  public loading: boolean;
  public title: string = "Modificar contraseña";
  public titleMfa: string = "Modificar tipo de autenticación"
  public alertMessage: AlertModel = new AlertModel();
  public alertConfirmEvent: Function;
  public currentUser : UserModel;
  public validateMessage : string;
  public securityLevel: string;
  private applicationStorageService;
  public showMfa: boolean;
  public mfaTypes:any = [];
  public mfaType: string;
  public activeModifyMfa: boolean = false;
  public parametersList = { data: new Array<any>()}; 

  constructor(private commonDataService: CommonDataService,
              private sessionStorageService: SessionStorageService,
              private modifyUserService: ModifyUserService) { 
    super();
    //this.sessionStorageService.rolValidate("normalizePatterns");
    this.applicationStorageService = localStorage;
    let objUser = this.sessionStorageService.currentSession.user;
    this.currentUser = new UserModel;
    this.currentUser.customerId = objUser.matriz;
    this.currentUser.userName = objUser.name;
    this.loading = true;
  }

  ngOnInit(): void {
    this.mfaTypes = [{"name":"Google Authenticator", "value":1},{"name":"Mail", "value":2}];
    this.loadParametersAbm();
    //this.loading = false;
    this.validatePasswordStrength();
    this.showMfa = (this.sessionStorageService.currentSession.mfa == 1)
    this.mfaType = this.sessionStorageService.currentSession.mfaType +"";
  }

  loadParametersAbm(){

    this.commonDataService.getParametersForAbm().subscribe(
      data => {
        if (data['code'] == "0" && data['data']) {          
          this.parametersList.data = Object.assign([], data['data']);
          let actModMfa = this.parametersList.data.find(p=>p.codigo == "SHOW_MOD_MFA" && p.activo == true)
          this.activeModifyMfa = actModMfa.valor == "S"
          this.loading = false;
        }
        else {
          console.error(data);
          this.loading = false;
        }
        
      },
      error => {
        console.error(error);
        this.loading = false;
      }
    ); 

  }

  public updateUser(){
    if( !this.validFormUser() )
      return;

    let request = {
      "actualPassword":this.currentUser.oldPassword,
      "newPassword":this.currentUser.password
    };

    this.loading = true;

    //////////
    let passRepeat = this.parametersList.data.find(p=>p.codigo == "PASS_REPEAT" && p.activo == true)
    /////////

    this.modifyUserService.changePassword(request).subscribe(
      response => {
        if( response.status == 200 && response.body != null && response.body["code"] == 0 ){
          this.loading = false;
          this.currentUser.oldPassword = "";
          this.currentUser.password = "";
          $( "#new-pass-bis" ).val( "" );
          this.alertMessage = new AlertModel( "Contraseña cambiada correctamente",this.title, true,"SUCCESS");

          this.sessionStorageService.currentSession.renewPassword = false;
          this.applicationStorageService.setItem( "currentUser",JSON.stringify(this.sessionStorageService.currentSession) );

        }else if( response.body["code"] == 445 ){
          this.loading = false;
          this.alertMessage = new AlertModel( "La clave debe contener al menos un caracter especial de la siguiente lista: !@#$%*o?",this.title, true,"WARNING");
        }else if( response.body["code"] == 446 ){
          this.loading = false;
          this.alertMessage = new AlertModel( "La clave no puede estar entre las "+passRepeat.valor+" últimas ingresadas",this.title, true,"WARNING");
        }else{
          this.loading = false;
          if( !Utils.isNullOrEmpty( response.body["message"] ) ){
            this.alertMessage = new AlertModel( response.body["message"],this.title, true,"WARNING");
          }else{
            this.alertMessage = new AlertModel( "Ocurrió un error, verifique que los datos sean correctos",this.title, true,"WARNING");

          }
        }

      }, err => {
        if( err.error.code != null && err.error.code == 542 ){
          this.currentUser.oldPassword = "";
          this.currentUser.password = "";
          $( "#new-pass-bis" ).val( "" );
          this.alertMessage = new AlertModel( "Contraseña modificada con éxito<br>Error al enviar mail de notificación",this.title, true );
        }else{
          this.alertMessage = new AlertModel( err.message,this.title, true,"WARNING");
        }
        this.loading = false;
        throw err;
      }
    
    );
    /////
  }
  public changeMfaType(){
    
    let request = {
      "username":this.currentUser.userName,
      "matriz":this.currentUser.customerId,
      "mfaType":parseInt(this.mfaType)
    };

    this.loading = true;

    this.modifyUserService.changeMfaType(request).subscribe(
      response => {
        if( response.status == 200 ){
          this.loading = false;
          
          this.alertMessage = new AlertModel( "Los cambios se realizaron correctamente",this.title, true,"SUCCESS");

          this.sessionStorageService.currentSession.mfaType = parseInt(this.mfaType);
          this.applicationStorageService.setItem( "currentUser",JSON.stringify(this.sessionStorageService.currentSession) );

        }else if( response.status == 204 ){
          var mjeError = "Su usuario no tiene un mail registrado. Comuniquese con un administrador";
          this.alertMessage = new AlertModel( mjeError,this.title, true,"WARNING");
          this.loading = false;
        }else{
          this.loading = false;
          this.alertMessage = new AlertModel( "Ocurrió un error, verifique que los datos sean correctos",this.title, true,"WARNING");

        }

      }, err => {
        if( err.error.code != null && err.error.code == 542 ){
          this.currentUser.oldPassword = "";
          this.currentUser.password = "";
          $( "#new-pass-bis" ).val( "" );
          this.alertMessage = new AlertModel( "Contraseña modificada con éxito<br>Error al enviar mail de notificación",this.title, true );
        }else{
          this.alertMessage = new AlertModel( err.message,this.title, true,"WARNING");
        }
        this.loading = false;
        throw err;
      }
    
    );
    /////
  }

  
  public validFormUser():boolean{
    let validations = "";
    let arrayErrors = [];

    if(Utils.isNullOrEmpty(this.currentUser.oldPassword))
      arrayErrors.push( "Complete el campo Contraseña Actual.");

    if(Utils.isNullOrEmpty(this.currentUser.password))
      arrayErrors.push( "Complete el campo Nueva Contraseña.");

    if( !Utils.isNullOrEmpty(this.currentUser.password) && this.currentUser.password == this.currentUser.oldPassword )
      arrayErrors.push( "La Nueva Contraseña debe ser distinta a la Contraseña Actual.");

    if( !Utils.isNullOrEmpty(this.currentUser.password) && $( "#secur-pwd" ).val() == "N" )
      arrayErrors.push( "Escriba una contraseña más segura.");

    if( !Utils.isNullOrEmpty(this.currentUser.password) && this.currentUser.password != $("#new-pass-bis").val() )
      arrayErrors.push( "Nueva Contraseña y Repetir Nueva Contraseña deben ser iguales.");

    if( arrayErrors.length ) {

      for( var i = 0; i < arrayErrors.length; i++ ){
        var conc = ( i == 0 )? "- " : "<br> -";
        validations += conc + arrayErrors[ i ];
      }
      this.validateMessage = validations;
      this.alertMessage = new AlertModel(this.validateMessage,this.title,true,"WARNING");
      return false;
    }
    
    this.validateMessage = "";
    return true;

  }

  onConfirmEvent(confirm:any){
    if(confirm.confirm){
      console.log( "confirm" );
    }
  }

  public validatePasswordStrength(){
    
    $('#password').on('input', function() {
      $('[data-toggle="tooltip"]').tooltip('dispose')
      var password = $(this).val();
      var strength = 0;
      var tips = "";
      let arrayErrors = [];

      // Check password length
      if (password.length < 8) {
          arrayErrors.push("Escriba una contraseña más larga");
      } else {
          strength += 1;
      }

      // Check for mixed case
      if (password.match(/[a-z]/) && password.match(/[A-Z]/)) {
          strength += 1;
      } else {
        arrayErrors.push("Incluya minúsculas y mayúsculas");
      }

      // Check for numbers
      if (password.match(/\d/)) {
          strength += 1;
      } else {
        arrayErrors.push("Incluya por lo menos un número");
      }

      // Check for special characters
      if (password.match(/[^a-zA-Z\d]/)) {
          strength += 1;
      } else {
        arrayErrors.push("Incluya por lo menos un caracter especial de la siguiente lista: !@#$%*o?");
      }

      if( arrayErrors.length ){
        for( var i = 0; i < arrayErrors.length; i++ ){
          var conc = ( i == 0 ) ? "- ":"<br>- ";
          tips += conc + arrayErrors[ i ];
        }
      }

      // Update the text and color based on the password strength
      var passwordStrengthElement = $('#password-strength #security');
      if (strength < 2) {
        this.securityLevel = "N";
        passwordStrengthElement.html('Bajo <i class="fa fa-exclamation-circle" style="margin-left: 3px"></i>');
        passwordStrengthElement.attr( "title",tips );
        $('[data-toggle="tooltip"]').tooltip();
        passwordStrengthElement.css('color', '#e60026');
      } else if (strength === 2) {
        this.securityLevel = "N";
        passwordStrengthElement.html('Medio <i class="fa fa-warning" style="margin-left: 3px"></i>');
        passwordStrengthElement.attr( "title",tips );
        $('[data-toggle="tooltip"]').tooltip();
        passwordStrengthElement.css('color', 'orange');
      } else if (strength === 3) {
        this.securityLevel = "N";
        passwordStrengthElement.html('Alto <i class="fa fa-warning" style="margin-left: 3px"></i>');
        passwordStrengthElement.attr( "title",tips );
        $('[data-toggle="tooltip"]').tooltip();
        passwordStrengthElement.css('color', '#2898ee');
      } else {
        this.securityLevel ="S";
        passwordStrengthElement.html('Muy Alto <i class="fa fa-check-circle" style="margin-left: 3px"></i>');
        $('[data-toggle="tooltip"]').tooltip('dispose');
        passwordStrengthElement.css('color', '#6dbf58');
      }
      $( "#secur-pwd" ).val( this.securityLevel );
    });
  }
  
}

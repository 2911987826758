import { Directive, ElementRef, HostListener, Input, OnChanges } from '@angular/core';
import { Console } from 'console';

@Directive({
  selector: '[only-number]'
})

export class HtmlInputOnlyNumber implements OnChanges {
  private onlynumberRegEx = '^[0-9]*$';
  private isValid : Boolean = false;
  private input:ElementRef;
  
  constructor(private el: ElementRef) { 
    this.input = el;
  }

  @Input() onlynumberenabled: boolean;
  @Input() spaces: boolean = false;


  @HostListener('keydown', ['$event']) onKeyDown(event) {
    
    let e = <KeyboardEvent> event;
    this.isValid = false;

    if (this.onlynumberenabled) {

      if(e.shiftKey){
        e.preventDefault();
        return true;
      }

      if ([46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
        // Allow: Ctrl+A
        (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+C
        (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+X
        (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
        //spaces
        (e.keyCode === 32 && this.spaces) ||
        // Allow: home, end, left, right
        (e.keyCode >= 35 && e.keyCode <= 39)) {
          // let it happen, don't do anything
          return true;
        }

        //filenumber
        if(e.keyCode >= 48 && e.keyCode <= 57)
          return true;

        //numpas
        if(e.keyCode >= 96 && e.keyCode <= 105)
          return true;

        // Block: Ctrl+V
        if((e.keyCode === 86 && (e.ctrlKey || e.metaKey))){
          
          return true;
        }

        e.preventDefault();
        console.log("preventDefault");
        return false;
        
      }

      return true;
  }

  @HostListener('keypress', ['$event']) onKeyPress(event) {
    
    let e = <KeyboardEvent> event;
    this.isValid = false;

    if (this.onlynumberenabled) {

      if(e.shiftKey){
        e.preventDefault();
        return true;
      }

      if ([46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
        // Allow: Ctrl+A
        (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+C
        (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+X
        (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
        //spaces
        (e.keyCode === 32 && this.spaces) ||
        // Allow: home, end, left, right
        (e.keyCode >= 35 && e.keyCode <= 39)) {
          // let it happen, don't do anything
          return true;
        }

        //filenumber
        if(e.keyCode >= 48 && e.keyCode <= 57)
          return true;

        //numpas
        if(e.keyCode >= 96 && e.keyCode <= 105)
          return true;

        // Block: Ctrl+V
        if((e.keyCode === 86 && (e.ctrlKey || e.metaKey))){
          //e.preventDefault();
          return true;
        }

        e.preventDefault();
        console.log("preventDefault");
        return false;
      }

      return true;
  } 

  @HostListener('ngModelChange', ['$event']) ngOnChanges(event) {

    var regex = new RegExp(/[^0-9\.].*/);
    
    if(this.spaces)
       regex = new RegExp(/[^0-9\.\s].*/);


    this.input.nativeElement.value = this.input.nativeElement.value.replace(regex, '');
    
    return true;
  }

  @HostListener('contextmenu', ['$event'])
    onRightClick(event) {
      event.preventDefault();
      return true;
    }
}
export class ReportApplicantsSearchModel {
    fecha_procesamiento_inicio: string;
    fecha_procesamiento_fin: string;
    promotor: string;
}

export class VendorFilterModel {
    id: number;
    name: string;
    selected: boolean;
    
    
    constructor(id: number,name: string,  selected: boolean){

        this.id = id;
        this.name = name;
        this.selected = selected;
    }
}
export class ParametersIncomeNseHeadList {

    constructor(){
        this.data = new Array<ParametersIncomeNseHeadModel>();
    }

    data: Array<ParametersIncomeNseHeadModel>;
}

export class ParametersIncomeNseHeadModel{
    instanciaId: string;
    vigenciaDesde: Date;
    vigenciaHasta: Date;
}

export class ParametersIncomeNseModel{
    detId: string;
    instanciaId: string;
    tipoParam: string;
    nombreVariable: string;
    tipoDato: string;
    ordenAbm: number;
    ordenParam: number;
    valor: number;
    label: string;
}

export class ParamNosisCompleteRequest{
    paramNosisCab: ParametersIncomeNseHeadModel;
    listParamNosisDet: ParametersIncomeNseModel[];
}
import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import { Utils } from 'src/app/commons/utils';
import * as XLSX from 'xlsx';
const EXCEL_EXTENSION = '.xlsx';
const CSV_EXTENSION = '.csv';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';



@Injectable({
  providedIn: 'root'
})
export class ExcelExportService {

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'Sheet1': worksheet }, SheetNames: ['Sheet1'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });

    
    FileSaver.saveAs(data, fileName + "_" + Utils.datetimeToStringFlat(new Date()) + EXCEL_EXTENSION);
  }

  public exportAsCsvFile(json: any[], excelFileName: string): void {
    
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'Sheet1': worksheet }, SheetNames: ['Sheet1'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'csv', type: 'array' });
    this.saveAsCsvFile(excelBuffer, excelFileName);
  }

  private saveAsCsvFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });

    
    FileSaver.saveAs(data, fileName + "_" + Utils.datetimeToStringFlat(new Date()) + CSV_EXTENSION);
  }

    public exportCsvWithSeparator(json: any[], excelFileName: string, separator: string): void {
    
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    var csv = XLSX.utils.sheet_to_csv(worksheet,{FS:separator, strip: true});
    
    this.saveAsCsvFile(csv, excelFileName);
    
  }

}

import { Component, ElementRef, OnInit, ViewEncapsulation } from '@angular/core';
import * as _ from 'lodash';
import { Environment } from '../../config/environment';
import { LoadingComponent } from '../shared/loading/loading.component';

import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Config } from '../../config/config';
import { DataService } from 'src/app/services/loginUser/data.service';

import { User } from '../../auth/models/user.model';
import { SessionStorageService} from "../../auth/services/session.service";
import { Session } from 'src/app/auth/models/session.model';
import { AlertModel } from '../shared/alert/alert.component';
import { InterceptorGuard } from 'src/app/auth/guards/interceptor.guard';
import { HttpErrorResponse } from '@angular/common/http';
import { disableDebugTools } from '@angular/platform-browser';
import { MockStatic } from 'src/app/config/mock.static';



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class HomeComponent implements OnInit {

  public isAuthenticated: boolean;
  public loading: boolean = true;
  public displayError: boolean;
  public msgError: string;
  public displayCombo: boolean;
  public showBackButton: any = Environment.SHOW_BACK_BUTTON;
  public alertMessage: AlertModel;
  public showLogin: boolean;

  constructor(private router: Router, 
              private route: ActivatedRoute, 
              private Conf: Config,
              private sessionStorageService: SessionStorageService,
              private interceptorGuard: InterceptorGuard,
              private dataService: DataService) { 
    this.loading = true;
    this.displayError = false;

    this.sessionStorageService.sessionBehavior.subscribe((session: Session)  => {

        this.showLogin = Environment.IDP ? false : (session == null);     
        this.loading = Environment.IDP && !session.login ? true : false;
    });    
    
    
    this.interceptorGuard.httpErroBehavior.subscribe((error: HttpErrorResponse)  => {
              
        if(error && error.error && error.error.status === 403){   
       
          this.alertMessage = new AlertModel( "Se ha intentado acceder a una operación no autorizada","UI Select", true);
        }

    });  
      
  }

  /**
   * On initialization, example catalog data should be retrieved if needed.
   * Default catalog data will apply otherwise.
   */
  ngOnInit(): void {



    if(Environment.IDP){

      if( this.sessionStorageService.isAuthenticated()){
        this.loading = false;
        return;
      }

      this.loading = true;
      this.showLogin = false;
      return;
    }

    this.loading = false;
    this.displayError = false;
    this.msgError = null;  
    
  }


  public sendFormLogin(){
    this.loading = true;



      let userSession = new Session();
      userSession.token = "f3338a94-76c2-422b-b648-1306bb2607c5";
      userSession.user = new User();
      userSession.user.username = "11";
      userSession.user.name = "11";
      userSession.user.roles = ["AMEX_ADMIN"];
      userSession.user.matriz = "VN0001";
      userSession.login = true;
  
      this.sessionStorageService.setCurrentSession(userSession);
      this.sessionStorageService.redirectToLogin();
      this.showLogin = false; 

  
  }



 
}

export class MatrixListModel {

    // constructor(){
    //     this.data = new Array<any>();
        

    // }

    data: Array<any>;
}

export class BusquedaFiltrosModel {

    cmId: string;
    fecha_inicio: string;  
    tipo_matriz: string;
    
}

export class LimitCreditHeadModel{
    clId: string;
    tipoMatriz: string;
    vigenciaDesde: Date;
    vigenciaHasta: Date;
    tipoScore: string;
    tipoScoreCol: string;
    tipoScoreRow: string;
}


export class LimitCreditMatrixModel{
    id: string;
    clId: string;
    nombreVariable: string;
    tipoDato: string;
    ordenAbm: number;
    ordenParam: number;
    valor: number;
}

export class LimitCreditMatrixCompleteModel{
    creditLimitCab: LimitCreditHeadModel;
    listCreditLimitMatriz: LimitCreditMatrixModel[];
    tipoScoreCol: string;
    tipoScoreRow: string;
}

export class ScoreType{
    codigo: string;
    descripcion: string;
    tipo: string;
}
export class normalizePatternsModel{
    id: string;
    patronId: string;
    patOrigen: string;
    patDestino: string;
}

export class PatternsListModel {

    constructor(){
        this.data = new Array<any>();
        

    }

    data: Array<any>;
}

export class BusquedaFiltrosModel {

    patronId: string;  
    
}
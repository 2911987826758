import { Injectable } from '@angular/core';
import * as $ from 'jquery';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Environment } from '../../config/environment';
import { Config } from '../../config/config';
import { config } from 'process';
import { of } from 'rxjs';
import { MockStatic } from 'src/app/config/mock.static';
//import { areAllEquivalent } from '@angular/compiler/src/output/output_ast';
import { windowWhen } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class SourceCodesService {


    constructor(private http: HttpClient, private Conf: Config) { }

    public getData(){


      let httpOptions = {
        withCredentials: false
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }

      let url = this.Conf.ENDPOINT_API + 'getSourcecodes';

      return this.http.get(url, httpOptions);  

    }

    public create(data){

        let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }

      let url = this.Conf.ENDPOINT_API + 'createSourcecode';
      let request = Object.assign({}, data);

      return this.http.post(url, request, httpOptions);  

    }

    public update(data){

      let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }

      let url = this.Conf.ENDPOINT_API + 'updateSourcecode';
      let request = Object.assign({}, data);

      return this.http.post(url, request, httpOptions);  

    }    

    public delete(data){

  

      let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }

      let url = this.Conf.ENDPOINT_API + 'deleteSourcecode';
      let request = Object.assign({}, data);

      return this.http.post(url, request, httpOptions);  

    }    

    import(file: File) {

      let formData:FormData = new FormData();
      let filename = file.name;
      formData.append('file', file, filename);


      let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          //'Content-Type': 'multipart/form-data',
          'Accept': 'application/json'
        })
      };

      
      let url = this.Conf.ENDPOINT_API + 'importSourcecodes';

      return this.http.post(url, formData, httpOptions);

    }

    

}

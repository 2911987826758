import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BusquedaFiltrosModel, InternalCustomerDetailModel,IntPersonaSuples,InternalSourceCode,ProductoObj } from './models/models';

import { AlertModel } from '../../component/shared/alert/alert.component';
import { InternalCustomerHistoryService } from '../../services_internal/internalCustomerHistory/internalCustomerHistory.service'
import { CommonDataService } from 'src/app/services/commons/commondata.service';
import { Utils } from 'src/app/commons/utils';
import { SessionStorageService } from 'src/app/auth/services/session.service';
import { ProductosList } from 'src/app/models/lists.model';


@Component({
  selector: 'app-internal-customerthistory',
  templateUrl: './internalCustomerHistory.component.html',
  styleUrls: ['./internalCustomerHistory.component.css']
})


export class InternalCustomerHistoryComponent {
  public es: any;
  public titulo: string = "CONSULTA HISTORIAL SOCIO";
  public loading: boolean;
  public alertMessage: AlertModel = new AlertModel;

  public tableResult: any = [];
  public dataResult: InternalCustomerDetailModel = new InternalCustomerDetailModel;
  public dataSuple: IntPersonaSuples = new IntPersonaSuples;
  public showResult : boolean;
  public showDetails : boolean = false;
  public tablereports = null;
  public showSuples: boolean;
  public cssShow: string;
  public productosList: ProductosList = new ProductosList();

  public busquedafiltros: BusquedaFiltrosModel = new BusquedaFiltrosModel; 

  @ViewChild('applicantdata') applicantDataRef: ElementRef;

  constructor(private internalCustomerHistoryService: InternalCustomerHistoryService,
              private sessionStorageService: SessionStorageService,
              private commonDataService: CommonDataService) { 

                this.sessionStorageService.rolValidate("internalConsultas");
   }

   ngOnInit() {
      this.rolesAutorization();
      this.loadProductos();
    }

//reptidos 17659866 20218673 20281805 21003353 21688197 25546617 26500480 27170449
 
  loadProductos(){

    this.commonDataService.getProductsAndId().subscribe(
      data => {
        if (data['code'] == "0" && data['data']) {      
          
          this.productosList.data = Object.assign([], data['data']);

        }
        else {
          console.error(data);
        }
  
        
      },
      error => {
        console.error(error);
      }
    );
  
  }

  loadResults(){   

    if(Utils.isNullOrEmpty(this.busquedafiltros.dni)
      && Utils.isNullOrEmpty(this.busquedafiltros.cuil)) {
      
        this.alertMessage = new AlertModel( "Se requiere completar al menos un filtro.",this.titulo, true,'WARNING');
      return;
    }
    
    this.showDetails = false;
    this.showResult = false;
    this.loading = true;

    let request = { 
      "pcn":this.busquedafiltros.pcn == "" ? null : this.busquedafiltros.pcn, 
      "dni":this.busquedafiltros.dni == "" ? null : this.busquedafiltros.dni,
      "cuil":this.busquedafiltros.cuil == "" ? null : this.busquedafiltros.cuil,
      "matriz": this.sessionStorageService.currentSession.user.matriz
    };


    this.internalCustomerHistoryService.getCustomerDetailHistory(request).subscribe(
      response => {

        if( response.status == 200 && response.body != null){

          var data = response.body;
          this.tableResult = data;
          let arrayTipoVentas = {"SUP":"Venta Isolada","RCO":"Reverse Companion","CON":"Conversion (Upgrade)","COM":"Companion"}
          for( var i = 0; i < this.tableResult.length; i++ ){
            this.dataResult = this.tableResult[ i ];
            this.dataResult.tipoVenta = ( typeof arrayTipoVentas[ this.dataResult.tipoVenta ] != "undefined" ) ? arrayTipoVentas[ this.dataResult.tipoVenta ] : this.dataResult.tipoVenta;
            
            let prod = this.productosList.data.find(p=> (p.key??"") == parseInt( this.dataResult.informedProduct ) );
            
            if( this.dataResult.tipoVenta == "Venta Isolada" && this.dataResult.intSocioData != null ){
              prod = this.productosList.data.find(p=> (p.key??"") == parseInt( this.dataResult.intSocioData.producto ) );
            }

            if(prod){
              this.dataResult.productoObj = prod;
            }else{
              this.dataResult.productoObj = new ProductoObj();
            }

            if( this.dataResult.intPersonaSuples.length ){
               for( var j = 0; j < this.dataResult.intPersonaSuples.length; j++ ){
                var objSuple = this.dataResult.intPersonaSuples[ j ];
                objSuple.index = j+1;
  
                if( objSuple.nombreCompleto == "" || objSuple.nombreCompleto == null ){
                  objSuple.nombreCompleto = objSuple.nombre1 + " " +objSuple.apellido;
                }
  
                if( objSuple.denegado != null && objSuple.denegado ){
                  objSuple.denegado = "Denegado";
                }else{
                  objSuple.denegado = "Aprobado";
                }
                if( objSuple.tipoDoc == "" || objSuple.tipoDoc == null ){
                  objSuple.tipoDoc = "DNI";
                }
                var value = objSuple.idProd;
                let prod = this.productosList.data.find(p=> (p.key??"") == parseInt( value ) );
                if( this.dataResult.tipoVenta == "Reverse Companion" 
                || this.dataResult.tipoVenta == "Conversion (Upgrade)"
                && this.dataResult.canalArchivo == "Telemarketing" 
                && this.dataResult.productoObj.data.prod == "50"
                && objSuple.plasticColorType != "00" ){
                  prod = this.productosList.data.find(p=> (p.data.plasticColorType??"") == objSuple.plasticColorType );
                }
                if( prod ){
                  objSuple.prod = prod.data.prod; 
                }
  
               }
            }

            if( this.dataResult.intObservaciones.length ){
              this.dataResult.intObservaciones.sort(function(a, b) {
                var textA = a.id;
                var textB = b.id;
                return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
              });
              this.dataResult.lastObservation = this.dataResult.intObservaciones[0].observacion;
            }

          }

          this.showResult = true;
          
        }else if( response.status == 204 ){
          this.alertMessage = new AlertModel( "No se encontraron registros con los datos ingresados.",this.titulo, true);
        }else{
          this.alertMessage = new AlertModel( "Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.titulo, true,"WARNING");
        }
        this.loading = false;

      }, err => {
        this.alertMessage = new AlertModel( "Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.titulo, true,"WARNING");
        this.loading = false;
        throw err;
      }
    );
    
  }


  loadDetails(pcn){   

    this.dataResult =this.tableResult.find(i=>i.pcn == pcn);
    if( this.dataResult.intSocioData.street != null ){
      this.dataResult.intSocioData.street  = this.dataResult.intSocioData.street.replace(/null/gi, "").trim();
    }
    if( this.dataResult.internalSourceCode == null ){
      this.dataResult.internalSourceCode = new InternalSourceCode();
    }
    if( this.dataResult.intPersonaSuples.length ){
      for( var j = 0; j < this.dataResult.intPersonaSuples.length; j++ ){
        this.dataResult.intPersonaSuples[ j ].index = j+1;
      }
      this.showSuples = true;
      this.cssShow = "";
    }else{
      this.showSuples = false;
      this.cssShow = "show";
    }

    this.showDetails = true;

    setTimeout(()=>  $("#applicantdata").get(0).scrollIntoView(), 250);
    

  }

  export(){

    let reportDataExport = new Array<any>();

    this.tableResult.forEach(item => {      
          reportDataExport.push({
            
            "PCN":item.pcn,
            "CUIT":item.cuil,
            "DNI":item.intSocioData.dni,
            "NOMBRE Y APELLIDO":item.nombreCompleto,
            "GENERO":item.intSocioData.gender,
            "FECHA APLICACION":item.fechaArchivo,
            "PROMOTOR":item.internalSourceCode.idPromotor,        
            "PROMOTOR ORIGINAL":item.internalSourceCode.idPromotor, 

            "PRODUCTO":item.internalSourceCode.prod,        
            "LOCALIDAD":item.intSocioData.city,        
            "DOMICILIO":item.intSocioData.street,   

            "TELEFONO":item.intSocioData.tel1,        
            "CELULAR":item.intSocioData.tel2, 

            "CANAL":item.canalArchivo,
            "AREA":item.area,       
            "ESTADO PRECHECK":item.estado,
            "MOTIVO PRECHECK":item.estadoRegistro,
            "ESTADO WORKFLOW":item.accion,
            "SUB-ESTADO WORKFLOW":item.motivo,
            "OBSERVACIONES":item.lastObservation,
        }); 
      });

    this.commonDataService.exportAsExcelFile(reportDataExport, this.titulo);

  }

  refactorObservationPaste(event:ClipboardEvent){ 
    //this.dataResult.observacion=this.sanitizedText(this.dataResult.observacion); //ESTO ES LO DE OBSERVACIONES QUE ESTA HACIENDO GABY
   }
   sanitizedText(text:string):string{
     return text.replace(/[^a-zA-Z0-9.$, ]/g,'')
   }

   refactorObservation(evento:any){
    const entrada= String.fromCharCode(evento.keyCode);
    const expresion=/[^a-zA-Z0-9.$, ]/g;
  
    if(expresion.test(entrada)){
      evento.preventDefault();
    }
   
  }

  public rolConsulta : boolean=true;

  public rolesAutorization(){
    let rolesSession = this.sessionStorageService.getCurrentUser().roles
      console.log(rolesSession)
    if (rolesSession.includes("AMX_CONSULTA")){
      this.rolConsulta=false
    }
  
  }
}

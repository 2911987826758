import { Injectable } from '@angular/core';
import * as $ from 'jquery';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Environment } from '../../config/environment';
import { Config } from '../../config/config';
import { config } from 'process';
import { Observable, of } from 'rxjs';
import { MockStatic } from 'src/app/config/mock.static';


@Injectable({
  providedIn: 'root'
})
export class ShortLongFormService {


    constructor(private http: HttpClient, private Conf: Config) { }



    procLongShortFileUpload(file: File, usuario:string) {

      let formData:FormData = new FormData();
      let filename = file.name;
      formData.append('file', file, filename);


      let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          //'Content-Type': 'multipart/form-data',
          'Accept': 'application/json'
        })
      };

      
      let url = this.Conf.ENDPOINT_API + 'procLongShortFileUpload';

      return this.http.post(url, formData, httpOptions);

    }


    procLongShortFileDownload(data) {

      let httpOptions = {
        withCredentials: false,
        responseType:'text',
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      };
      
      let url = this.Conf.ENDPOINT_API + 'procLongShortFileDownload';
      let request = Object.assign({}, data);

      return this.http.post(url, request, httpOptions as any);     

    }


    getListLongShort(filters) {
      let url = this.Conf.ENDPOINT_API + 'getListLongShort';
      return this.http.post(url, filters);   
    }



   

}
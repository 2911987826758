import { Injectable } from '@angular/core';
import * as $ from 'jquery';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Environment } from '../../config/environment';
import { Config } from '../../config/config';
import { config } from 'process';
import { of } from 'rxjs';
import { MockStatic } from 'src/app/config/mock.static';


@Injectable({
  providedIn: 'root'
})
export class UsersService {


    constructor(private http: HttpClient, private Conf: Config) { }

    public getUsers(){


          var mockresponse = {
            "data": [
               {
                "id":"4",
                "agencia":"Nova",
                "agenciaId":"4",
                "nombrecompleto":"Ricardo Martinez",
                "rol":"OPERATIVO", 
                "rolId": "4"
               },
               
               {
                "id":"1",
                "agencia":"Conecta",
                "agenciaId":"1",
                "nombrecompleto":"Mario Rodriguez",
                "rol":"SUPERVISOR AGENCIA",    
                "rolId": "3"                 
               }, 
               
               {
                "id":"2",
                "agencia":"Agencia Sales",
                "agenciaId":"2",
                "nombrecompleto":"Luciano Fiamengo",
                "rol":"ADMINISTRADOR",       
                "rolId": "1"         
               },
               
               {
                "id":"3",
                "agencia":"Market",
                "agenciaId":"3",
                "nombrecompleto":"Martin Palavecino",
                "rol":"MANAGER",
                "rolId": "2"
               },                   
          ]
          };

          return of(mockresponse);


    }

    public createUser(data){


          var mockresponse = {
            "code":0,
            "message":""
          };

          return of(mockresponse);
      


    }

    public updateUser(data){

  
          var mockresponse = {
            "code":0,
            "message":""
          };

          return of(mockresponse);
      


    }    

    public disableUser(data){


          var mockresponse = {
            "code":0,
            "message":""
          };

          return of(mockresponse);
      


    }    

    public getUsersRoles(){

     
          var mockresponse = {
            "data": [
               {
                "id":"4",
                "rol":"OPERATIVO", 
               },
               
               {
                "id":"3",
                "rol":"SUPERVISOR AGENCIA",                     
               }, 
               
               {
                "id":"1",
                "rol":"ADMINISTRADOR",                
               },
               
               {
                "id":"2",
                "rol":"MANAGER",
               },                   
          ]
          };

          return of(mockresponse);
      


    }

}
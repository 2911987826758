import { AbstractType, Component, OnInit } from '@angular/core';
import { LoadingComponent } from '../shared/loading/loading.component';
import { AlertComponent, AlertModel } from '../shared/alert/alert.component';

import { ReportAgenciesBusquedaModel } from './models/models';

import { ɵAnimationGroupPlayer } from '@angular/animations';
import { Environment } from 'src/app/config/environment';
import { ReturnStatement } from '@angular/compiler';
import { SessionStorageService } from 'src/app/auth/services/session.service';
import { Roles } from 'src/app/auth/models/roles.static';
import { ReportAgenciesService } from 'src/app/services/reportAgencies/reportagencies.service';
import { Utils } from 'src/app/commons/utils';
import { ProductosList } from 'src/app/models/lists.model';
import { ExcelExportService } from 'src/app/services/excelExport/excel.export.service';
import { VendorFilterModel } from '../precheck/models/models';
import { BehaviorSubject } from 'rxjs';
import { CommonDataService } from 'src/app/services/commons/commondata.service';
import { BaseComponent } from '../shared/base.component';
//import { asLiteral } from '@angular/compiler/src/render3/view/util';
declare var $ : any;


@Component({
  selector: 'app-reportagencies',
  templateUrl: './reportagencies.component.html',
  styleUrls: ['./reportagencies.component.css']
})
export class ReportAgenciesComponent extends BaseComponent implements OnInit {

  public title: string = "Reporte de Status de Agencias";
  public es: any;
  public busquedafiltros: ReportAgenciesBusquedaModel = new ReportAgenciesBusquedaModel();
  public showResult : boolean;
  public tablereports = null;
  public showNewAgencie : boolean; 
  
  public loading: boolean;
  public alertMessage: AlertModel = new AlertModel;

  public validateShow: boolean =  false;
  public validateMessage: string;

  public estadosList: any = {data:[]};  

  public vendorsList :Array<VendorFilterModel> = new Array<VendorFilterModel>(); 
  public productosList: ProductosList = new ProductosList();
  public resultData = [];

  protected loadingListsBehavior: BehaviorSubject<any>= new BehaviorSubject<any>(null);
  public loadingLists: Array<any> = [
    {"load":"VENDORS", "finish": false},
    {"load":"ESTADOS", "finish": false},
    {"load":"PRODUCTOS", "finish": false}
    
  ];

  constructor(private excelExportService: ExcelExportService,
              private reportAgenciesService: ReportAgenciesService,
              private commonDataService: CommonDataService,              
              private sessionStorageService: SessionStorageService) { 
                
               
              super();
              this.loadingListsBehavior = new BehaviorSubject<any>(null);
              this.loadingListsBehavior.subscribe(data  => { 
                
                if(this.loadingLists.filter(l=> !l.finish).length == 0){
                  this.loading = false;
                }
                 
              });

  }

  ngOnInit() {
    this.busquedafiltros.area = "";
    this.busquedafiltros.producto ="";
    this.busquedafiltros.promotor ="";
    this.busquedafiltros.estado_aplicante ="";
    this.loading = true;

    this.setCalendarLocale(); 
    this.estadosFilterLoad();
    this.loadVendors();
    this.loadProductos();
  }

  setLoadingListsFinish(loadname){

    this.loadingLists.forEach(i=>{
      if(i.load == loadname){
        i.finish = true;
        this.loadingListsBehavior.next(null);
      }

    })
  }

  loadResultData(){

    this.rolesAutorization();
    this.loading = true;

    let request = { 
      "matriz" : this.sessionStorageService.getCurrentUser()?.matriz,
      "fecha_captura_fin": Utils.isNullOrEmpty(this.busquedafiltros.fecha_captura_fin) ? null : this.busquedafiltros.fecha_captura_fin, 
      "fecha_captura_inicio": Utils.isNullOrEmpty(this.busquedafiltros.fecha_captura_inicio) ? null : this.busquedafiltros.fecha_captura_inicio, 
      "producto": Utils.isNullOrEmpty(this.busquedafiltros.producto) ? null : this.busquedafiltros.producto, 
      "area": Utils.isNullOrEmpty(this.busquedafiltros.area) ? null : this.busquedafiltros.area, 
      "promotor": Utils.isNullOrEmpty(this.busquedafiltros.promotor) ? null : this.busquedafiltros.promotor,
      "estado_aplicante": Utils.isNullOrEmpty(this.busquedafiltros.estado_aplicante) ? null : this.busquedafiltros.estado_aplicante
    };  

    this.reportAgenciesService.getData(request).subscribe(
      data => {
        if (data['data']) {
          this.resultData = data['data'];

          this.setupTable();             

        }
        else {
          this.loading = false;
          console.error(data);
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title,true);
        }
      },
      error => {
        this.loading = false;
        console.error(error);
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title,true);
      }
    );

  }

 


  backToResults(){
    this.showResult = true;
    this.loading = false;

  }


  setupTable(){

    this.showResult = true;

    if (this.tablereports != null) {
      this.tablereports.clear().destroy();
    } 
    
    $(document).ready( () => {
        this.tablereports =   $("#result-table").DataTable({

        searching: false, 
        paging: true, 
        
        lengthChange: false,
        pageLength: 10,
        pagingType: 'full_numbers',
        columnDefs: [
          { targets: 'no-sort', orderable: false }
        ],    
        deferRender: true,
        language: Environment.DataTableLanguageSetting,
        initComplete: (setting, json) => {
          this.loading = false;
        }
      });
    });

  }
  
  public rolConsulta:boolean=true;
  public rolesAutorization(){
    let rolesSession =this.sessionStorageService.getCurrentUser().roles
    console.log(rolesSession)
    if(rolesSession.includes("AMX_CONSULTA")){
      this.rolConsulta=false
    }
  }

  exportar (){

    try{
      if(!this.rolConsulta){
        this.alertMessage=new AlertModel("No tiene permisos para exportar",this.title,true,"WARNING");
        return;
      }
    }catch{}

    let reportDataExport = new Array<any>();

    this.resultData.forEach(item => {      
      reportDataExport.push({
        "PCN": item.pcn,
        "RefNaps": item.refnaps,
        "Tipo Doc": item.tipo_doc,
        "Documento": item.documento,
        "Nombre": item.nombre,
        "Apellido": item.apellido,
        "Fecha nacimiento": item.fecha_nacimiento,
        "Nacionalidad": item.nacionalidad,
        "CUIL": item.cuil,
        "Profesion": item.profesion,
        "Telefono": item.telefono,
        "Celular": item.celular,
        "Fecha Aprob Lanas": item.fecha_aprob_lanas,
        "Cant Adicionales": item.cant_adicionales,
        "Email personal": item.email_personal,
        "Area": item.area,
        "Fecha Ult Est": item.fecha_ult_est,
        "Fecha de rellamado": item.fecha_de_rellamado,
        "Fecha de pactacion": item.fecha_de_pactacion,
        "Estado": item.estado,
        "Motivo": item.motivo,
        "Producto": item.producto,
        "Cod Postal": item.cod_postal,
        "Provincia": item.provincia,
        "Direccion": item.direccion,
        "Fecha Captura": item.fecha_captura,
        "Income": item.income,
        "Promotor": item.promotor,
        "Observaciones": item.observaciones,
        "Sourcecode": item.sourcescode_complete,
        "Promotor original": item.promotor_original        
      });
    }); 

    this.excelExportService.exportAsExcelFile(reportDataExport, this.title);

  }

  setCalendarLocale(){
    this.es = Environment.CalendarLanguageSetting;
  }

  estadosFilterLoad(){

    this.commonDataService.getValidationAccionAll().subscribe(
      data => {
        if(data) {
          let estados = data as Array<any>;
            
          estados = estados.filter(e=>{ 

            return  e.area == "TELEMARKETING" 
                || (e.area == "PRECHECK" && e.subestado.includes("CON NSE"))
                || (e.area == "RECOVERY" && e.subestado.includes("DOWNGRADE"))
                || (e.area == "NEWACCOUNT" && e.subestado.includes("DOC ILEGIBLE")) 
                || (e.area == "NEWACCOUNT" && e.subestado.includes("DOC FALTANTE")) ;
        
          })
          .concat([ {estado: "A TRABAJAR", subestado:"A TRABAJAR", area: "TELEMARKETING"}])
          .map(m => { 
            return {"key":m.subestado, "value":m.subestado, "parent":m.estado};
          }).reduce((acc,e)=>{ 
            if(!acc.some(i=>i.value == e.value))
               acc.push(e);
            
            return acc;
          },[]);

          estados.sort((a, b)=> a.key?.localeCompare(b.key));  
         
          this.estadosList.data = estados;
   
        }

        this.setLoadingListsFinish("ESTADOS");

      },
      error =>{
          this.setLoadingListsFinish("ESTADOS");
      }
    )

  }


  loadVendors(){

    if(Utils.storageLoad("vendors-rpta")){
      this.vendorsList = Utils.storageLoad("vendors-rpta");
      this.setLoadingListsFinish("VENDORS");
      return;
    }

    this.reportAgenciesService.getVendorsList().subscribe(
      data => {
        if (data['data']) {
          
          this.vendorsList = Object.assign(this.vendorsList, data['data']);
      
          this.vendorsList.sort((a, b)=> a.name.localeCompare(b.name));  
          
          Utils.storageSave("vendors-rpta",  this.vendorsList);  

        }
        else {
          console.error(data);
        }

        this.setLoadingListsFinish("VENDORS")
      },
      error => {
        this.setLoadingListsFinish("VENDORS")
        console.error(error);
      }
    );

    
    

  }

  loadProductos(){

    this.commonDataService.getProductos().subscribe(
      data => {
        if (data['code'] == "0" && data['data']) {          
          this.productosList.data = Object.assign([], data['data']);
        }
        else {
          console.error(data);
        }

        this.setLoadingListsFinish("PRODUCTOS");
      },
      error => {
        this.setLoadingListsFinish("PRODUCTOS");
      }
    );

    
    

  }

  public  showSubRow(id){
    $(".div-sub-row").not("#tr-" + id).hide();
    $("#tr-" + id).toggle(500);
  }


}

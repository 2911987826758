//DATA LIST
export class AccionAplicanteList {

    constructor(){
        this.data = new  Array<any>();

    }

    data: Array<any>;
}

export class MotivosAplicanteList {

    constructor(){
        this.data = new  Array<any>();      
        
    }
    data: Array<any>;
}

export class NacionalidadesList {

    constructor(){
        this.data = new Array<any>();
        
        this.data.push({"key":"1","value":"AFGANISTAN"});
        this.data.push({"key":"10","value":"ARABIA SAUDI"});
        this.data.push({"key":"100","value":"HONDURAS"});
        this.data.push({"key":"101","value":"HONG KONG"});
        this.data.push({"key":"102","value":"HOWLAND ISLAND"});
        this.data.push({"key":"103","value":"HUNGRIA"});
        this.data.push({"key":"104","value":"INDIA"});
        this.data.push({"key":"105","value":"INDIAN OCEAN"});
        this.data.push({"key":"106","value":"INDONESIA"});
        this.data.push({"key":"107","value":"IRAN"});
        this.data.push({"key":"108","value":"IRAQ"});
        this.data.push({"key":"109","value":"IRLANDA"});
        this.data.push({"key":"11","value":"ARCTIC OCEAN"});
        this.data.push({"key":"110","value":"ISLA BOUVET"});
        this.data.push({"key":"111","value":"ISLA CHRISTMAS"});
        this.data.push({"key":"112","value":"ISLA NORFOLK"});
        this.data.push({"key":"113","value":"ISLANDIA"});
        this.data.push({"key":"114","value":"ISLAS CAIMAN"});
        this.data.push({"key":"115","value":"ISLAS COCOS"});
        this.data.push({"key":"116","value":"ISLAS COOK"});
        this.data.push({"key":"117","value":"ISLAS FEROE"});
        this.data.push({"key":"118","value":"ISLAS GEORGIA DEL SUR Y SANDWICH DEL SUR"});
        this.data.push({"key":"119","value":"ISLAS HEARD Y MCDONALD"});
        this.data.push({"key":"12","value":"ARGELIA"});
        this.data.push({"key":"120","value":"ISLAS MALVINAS"});
        this.data.push({"key":"121","value":"ISLAS MARIANAS DEL NORTE"});
        this.data.push({"key":"122","value":"ISLAS MARSHALL"});
        this.data.push({"key":"123","value":"ISLAS PITCAIRN"});
        this.data.push({"key":"124","value":"ISLAS SALOMON"});
        this.data.push({"key":"125","value":"ISLAS TURCAS Y CAICOS"});
        this.data.push({"key":"126","value":"ISLAS VIRGENES AMERICANAS"});
        this.data.push({"key":"127","value":"ISLAS VIRGENES BRITANICAS"});
        this.data.push({"key":"128","value":"ISRAEL"});
        this.data.push({"key":"129","value":"ITALIA"});
        this.data.push({"key":"13","value":"ARGENTINA"});
        this.data.push({"key":"130","value":"JAMAICA"});
        this.data.push({"key":"131","value":"JAN MAYEN"});
        this.data.push({"key":"132","value":"JAPON"});
        this.data.push({"key":"133","value":"JARVIS ISLAND"});
        this.data.push({"key":"134","value":"JERSEY"});
        this.data.push({"key":"135","value":"JOHNSTON ATOLL"});
        this.data.push({"key":"136","value":"JORDANIA"});
        this.data.push({"key":"137","value":"JUAN DE NOVA ISLAND"});
        this.data.push({"key":"138","value":"KAZAJISTAN"});
        this.data.push({"key":"139","value":"KENIA"});
        this.data.push({"key":"14","value":"ARMENIA"});
        this.data.push({"key":"140","value":"KINGMAN REEF"});
        this.data.push({"key":"141","value":"KIRGUIZISTAN"});
        this.data.push({"key":"142","value":"KIRIBATI"});
        this.data.push({"key":"143","value":"KUWAIT"});
        this.data.push({"key":"144","value":"LAOS"});
        this.data.push({"key":"145","value":"LESOTO"});
        this.data.push({"key":"146","value":"LETONIA"});
        this.data.push({"key":"147","value":"LIBANO"});
        this.data.push({"key":"148","value":"LIBERIA"});
        this.data.push({"key":"149","value":"LIBIA"});
        this.data.push({"key":"15","value":"ARUBA"});
        this.data.push({"key":"150","value":"LIECHTENSTEIN"});
        this.data.push({"key":"151","value":"LITUANIA"});
        this.data.push({"key":"152","value":"LUXEMBURGO"});
        this.data.push({"key":"153","value":"MACAO"});
        this.data.push({"key":"154","value":"MACEDONIA"});
        this.data.push({"key":"155","value":"MADAGASCAR"});
        this.data.push({"key":"156","value":"MALASIA"});
        this.data.push({"key":"157","value":"MALAUI"});
        this.data.push({"key":"158","value":"MALDIVAS"});
        this.data.push({"key":"159","value":"MALI"});
        this.data.push({"key":"16","value":"ASHMORE AND CARTIER ISLANDS"});
        this.data.push({"key":"160","value":"MALTA"});
        this.data.push({"key":"161","value":"MAN"});
        this.data.push({"key":"162","value":"MARRUECOS"});
        this.data.push({"key":"163","value":"MARTINICA"});
        this.data.push({"key":"164","value":"MAURICIO"});
        this.data.push({"key":"165","value":"MAURITANIA"});
        this.data.push({"key":"166","value":"MAYOTTE"});
        this.data.push({"key":"167","value":"MEXICO"});
        this.data.push({"key":"168","value":"MICRONESIA"});
        this.data.push({"key":"169","value":"MIDWAY ISLANDS"});
        this.data.push({"key":"17","value":"ATLANTIC OCEAN"});
        this.data.push({"key":"170","value":"MOLDAVIA"});
        this.data.push({"key":"171","value":"MONACO"});
        this.data.push({"key":"172","value":"MONGOLIA"});
        this.data.push({"key":"173","value":"MONTSERRAT"});
        this.data.push({"key":"174","value":"MOZAMBIQUE"});
        this.data.push({"key":"175","value":"NAMIBIA"});
        this.data.push({"key":"176","value":"NAURU"});
        this.data.push({"key":"177","value":"NAVASSA ISLAND"});
        this.data.push({"key":"178","value":"NEPAL"});
        this.data.push({"key":"179","value":"NICARAGUA"});
        this.data.push({"key":"18","value":"AUSTRALIA"});
        this.data.push({"key":"180","value":"NIGER"});
        this.data.push({"key":"181","value":"NIGERIA"});
        this.data.push({"key":"182","value":"NIUE"});
        this.data.push({"key":"183","value":"NORUEGA"});
        this.data.push({"key":"184","value":"NUEVA CALEDONIA"});
        this.data.push({"key":"185","value":"NUEVA ZELANDA"});
        this.data.push({"key":"186","value":"OMAN"});
        this.data.push({"key":"187","value":"PACIFIC OCEAN"});
        this.data.push({"key":"188","value":"PAISES BAJOS"});
        this.data.push({"key":"189","value":"PAKISTAN"});
        this.data.push({"key":"19","value":"AUSTRIA"});
        this.data.push({"key":"190","value":"PALAOS"});
        this.data.push({"key":"191","value":"PALMYRA ATOLL"});
        this.data.push({"key":"192","value":"PANAMA"});
        this.data.push({"key":"193","value":"PAPUA-NUEVA GUINEA"});
        this.data.push({"key":"194","value":"PARACEL ISLANDS"});
        this.data.push({"key":"195","value":"PARAGUAY"});
        this.data.push({"key":"196","value":"PERU"});
        this.data.push({"key":"197","value":"POLINESIA FRANCESA"});
        this.data.push({"key":"198","value":"POLONIA"});
        this.data.push({"key":"199","value":"PORTUGAL"});
        this.data.push({"key":"2","value":"ALBANIA"});
        this.data.push({"key":"20","value":"AZERBAIYAN"});
        this.data.push({"key":"200","value":"PUERTO RICO"});
        this.data.push({"key":"201","value":"QATAR"});
        this.data.push({"key":"202","value":"REINO UNIDO"});
        this.data.push({"key":"203","value":"REPUBLICA CENTROAFRICANA"});
        this.data.push({"key":"204","value":"REPUBLICA CHECA"});
        this.data.push({"key":"205","value":"REPUBLICA DEMOCRATICA DEL CONGO"});
        this.data.push({"key":"206","value":"REPUBLICA DOMINICANA"});
        this.data.push({"key":"207","value":"REUNION"});
        this.data.push({"key":"208","value":"RUANDA"});
        this.data.push({"key":"209","value":"RUMANIA"});
        this.data.push({"key":"21","value":"BAHAMAS"});
        this.data.push({"key":"210","value":"RUSIA"});
        this.data.push({"key":"211","value":"SAHARA OCCIDENTAL"});
        this.data.push({"key":"212","value":"SAMOA"});
        this.data.push({"key":"213","value":"SAMOA AMERICANA"});
        this.data.push({"key":"214","value":"SAN CRISTOBAL Y NIEVES"});
        this.data.push({"key":"215","value":"SAN MARINO"});
        this.data.push({"key":"216","value":"SAN PEDRO Y MIQUELON"});
        this.data.push({"key":"217","value":"SAN VICENTE Y LAS GRANADINAS"});
        this.data.push({"key":"218","value":"SANTA HELENA"});
        this.data.push({"key":"219","value":"SANTA LUCIA"});
        this.data.push({"key":"22","value":"BAHRAIN"});
        this.data.push({"key":"220","value":"SANTO TOME Y PRINCIPE"});
        this.data.push({"key":"221","value":"SENEGAL"});
        this.data.push({"key":"222","value":"SERBIA AND MONTENEGRO"});
        this.data.push({"key":"223","value":"SEYCHELLES"});
        this.data.push({"key":"224","value":"SIERRA LEONA"});
        this.data.push({"key":"225","value":"SINGAPUR"});
        this.data.push({"key":"226","value":"SIRIA"});
        this.data.push({"key":"227","value":"SOMALIA"});
        this.data.push({"key":"228","value":"SOUTHERN OCEAN"});
        this.data.push({"key":"229","value":"SPRATLY ISLANDS"});
        this.data.push({"key":"23","value":"BAKER ISLAND"});
        this.data.push({"key":"230","value":"SRI LANKA"});
        this.data.push({"key":"231","value":"SUAZILANDIA"});
        this.data.push({"key":"232","value":"SUDAFRICA"});
        this.data.push({"key":"233","value":"SUDAN"});
        this.data.push({"key":"234","value":"SUECIA"});
        this.data.push({"key":"235","value":"SUIZA"});
        this.data.push({"key":"236","value":"SURINAM"});
        this.data.push({"key":"237","value":"SVALBARD Y JAN MAYEN"});
        this.data.push({"key":"238","value":"TAILANDIA"});
        this.data.push({"key":"239","value":"TAIWAN"});
        this.data.push({"key":"24","value":"BANGLADESH"});
        this.data.push({"key":"240","value":"TANZANIA"});
        this.data.push({"key":"241","value":"TAYIKISTAN"});
        this.data.push({"key":"242","value":"TERRITORIO BRITANICO DEL OCEANO INDICO"});
        this.data.push({"key":"243","value":"TERRITORIOS AUSTRALES FRANCESES"});
        this.data.push({"key":"244","value":"TIMOR ORIENTAL"});
        this.data.push({"key":"245","value":"TOGO"});
        this.data.push({"key":"246","value":"TOKELAU"});
        this.data.push({"key":"247","value":"TONGA"});
        this.data.push({"key":"248","value":"TRINIDAD Y TOBAGO"});
        this.data.push({"key":"249","value":"TROMELIN ISLAND"});
        this.data.push({"key":"25","value":"BARBADOS"});
        this.data.push({"key":"250","value":"TUNEZ"});
        this.data.push({"key":"251","value":"TURKMENISTAN"});
        this.data.push({"key":"252","value":"TURQUIA"});
        this.data.push({"key":"253","value":"TUVALU"});
        this.data.push({"key":"254","value":"UCRANIA"});
        this.data.push({"key":"255","value":"UGANDA"});
        this.data.push({"key":"256","value":"URUGUAY"});
        this.data.push({"key":"257","value":"UZBEKISTAN"});
        this.data.push({"key":"258","value":"VANUATU"});
        this.data.push({"key":"259","value":"VENEZUELA"});
        this.data.push({"key":"26","value":"BASSAS DA INDIA"});
        this.data.push({"key":"260","value":"VIETNAM"});
        this.data.push({"key":"261","value":"WAKE ISLAND"});
        this.data.push({"key":"262","value":"WALLIS Y FUTUNA"});
        this.data.push({"key":"263","value":"WEST BANK"});
        this.data.push({"key":"264","value":"WORLD"});
        this.data.push({"key":"265","value":"YEMEN"});
        this.data.push({"key":"266","value":"YIBUTI"});
        this.data.push({"key":"267","value":"ZAMBIA"});
        this.data.push({"key":"268","value":"ZIMBABUE"});
        this.data.push({"key":"27","value":"BELGICA"});
        this.data.push({"key":"28","value":"BELICE"});
        this.data.push({"key":"29","value":"BENIN"});
        this.data.push({"key":"3","value":"ALEMANIA"});
        this.data.push({"key":"30","value":"BERMUDAS"});
        this.data.push({"key":"31","value":"BIELORRUSIA"});
        this.data.push({"key":"32","value":"BIRMANIA; MYANMAR"});
        this.data.push({"key":"33","value":"BOLIVIA"});
        this.data.push({"key":"34","value":"BOSNIA Y HERCEGOVINA"});
        this.data.push({"key":"35","value":"BOTSUANA"});
        this.data.push({"key":"36","value":"BRASIL"});
        this.data.push({"key":"37","value":"BRUNEI"});
        this.data.push({"key":"38","value":"BULGARIA"});
        this.data.push({"key":"39","value":"BURKINA FASO"});
        this.data.push({"key":"4","value":"ANDORRA"});
        this.data.push({"key":"40","value":"BURUNDI"});
        this.data.push({"key":"41","value":"BUTAN"});
        this.data.push({"key":"42","value":"CABO VERDE"});
        this.data.push({"key":"43","value":"CAMBOYA"});
        this.data.push({"key":"44","value":"CAMERUN"});
        this.data.push({"key":"45","value":"CANADA"});
        this.data.push({"key":"46","value":"CHAD"});
        this.data.push({"key":"47","value":"CHILE"});
        this.data.push({"key":"48","value":"CHINA"});
        this.data.push({"key":"49","value":"CHIPRE"});
        this.data.push({"key":"5","value":"ANGOLA"});
        this.data.push({"key":"50","value":"CLIPPERTON ISLAND"});
        this.data.push({"key":"51","value":"COLOMBIA"});
        this.data.push({"key":"52","value":"COMORAS"});
        this.data.push({"key":"53","value":"CONGO"});
        this.data.push({"key":"54","value":"CORAL SEA ISLANDS"});
        this.data.push({"key":"55","value":"COREA DEL NORTE"});
        this.data.push({"key":"56","value":"COREA DEL SUR"});
        this.data.push({"key":"57","value":"COSTA DE MARFIL"});
        this.data.push({"key":"58","value":"COSTA RICA"});
        this.data.push({"key":"59","value":"CROACIA"});
        this.data.push({"key":"6","value":"ANGUILA"});
        this.data.push({"key":"60","value":"CUBA"});
        this.data.push({"key":"61","value":"DINAMARCA"});
        this.data.push({"key":"62","value":"DOMINICA"});
        this.data.push({"key":"63","value":"ECUADOR"});
        this.data.push({"key":"64","value":"EGIPTO"});
        this.data.push({"key":"65","value":"EL SALVADOR"});
        this.data.push({"key":"66","value":"EL VATICANO"});
        this.data.push({"key":"67","value":"EMIRATOS ARABES UNIDOS"});
        this.data.push({"key":"68","value":"ERITREA"});
        this.data.push({"key":"69","value":"ESLOVAQUIA"});
        this.data.push({"key":"7","value":"ANTARTIDA"});
        this.data.push({"key":"70","value":"ESLOVENIA"});
        this.data.push({"key":"71","value":"ESPAÑA"});
        this.data.push({"key":"72","value":"ESTADOS UNIDOS"});
        this.data.push({"key":"73","value":"ESTONIA"});
        this.data.push({"key":"74","value":"ETIOPIA"});
        this.data.push({"key":"75","value":"EUROPA ISLAND"});
        this.data.push({"key":"76","value":"FILIPINAS"});
        this.data.push({"key":"77","value":"FINLANDIA"});
        this.data.push({"key":"78","value":"FIYI"});
        this.data.push({"key":"79","value":"FRANCIA"});
        this.data.push({"key":"8","value":"ANTIGUA Y BARBUDA"});
        this.data.push({"key":"80","value":"GABON"});
        this.data.push({"key":"81","value":"GAMBIA"});
        this.data.push({"key":"82","value":"GAZA STRIP"});
        this.data.push({"key":"83","value":"GEORGIA"});
        this.data.push({"key":"84","value":"GHANA"});
        this.data.push({"key":"85","value":"GIBRALTAR"});
        this.data.push({"key":"86","value":"GLORIOSO ISLANDS"});
        this.data.push({"key":"87","value":"GRANADA"});
        this.data.push({"key":"88","value":"GRECIA"});
        this.data.push({"key":"89","value":"GROENLANDIA"});
        this.data.push({"key":"9","value":"ANTILLAS NEERLANDESAS"});
        this.data.push({"key":"90","value":"GUADALUPE"});
        this.data.push({"key":"91","value":"GUAM"});
        this.data.push({"key":"92","value":"GUATEMALA"});
        this.data.push({"key":"93","value":"GUAYANA FRANCESA"});
        this.data.push({"key":"94","value":"GUERNSEY"});
        this.data.push({"key":"95","value":"GUINEA"});
        this.data.push({"key":"96","value":"GUINEA ECUATORIAL"});
        this.data.push({"key":"97","value":"GUINEA-BISSAU"});
        this.data.push({"key":"98","value":"GUYANA"});
        this.data.push({"key":"99","value":"HAITI"});       
         
        this.data.sort((a, b)=> a.value.localeCompare(b.value));
        
    }

    data: Array<any>;
}

export class GenerosList {

    constructor(){
        this.data = new Array<any>();
        
        this.data.push({ "key" : "M", "value" : "MASCULINO"});
        this.data.push({ "key" : "F", "value" : "FEMENINO"});
        this.data.push({ "key" : "O", "value" : "OTRO"});
        
    }

    data: Array<any>;
}

export class TiposDocumentosList {

    constructor(){
        this.data = new Array<any>();
        
        this.data.push({ "key" : "DNI", "value" : "DNI"});
        this.data.push({ "key" : "PAS", "value" : "PAS"});
        this.data.push({ "key" : "LC", "value" : "LC"});
        this.data.push({ "key" : "LE", "value" : "LE"});
        
    }

    data: Array<any>;
}

export class ProfesionesList {

    constructor(){
        this.data = new Array<any>();

    }

    data: Array<any>;
}

export class ProvinciasList {

    constructor(){
        this.data = new Array<any>();
        
        this.data.push({ "key":"CAPITAL FEDERAL", "value":"CAPITAL FEDERAL"});
        this.data.push({ "key":"BUENOS AIRES", "value":"BUENOS AIRES"});
        this.data.push({ "key":"CATAMARCA", "value":"CATAMARCA"});
        this.data.push({ "key":"CORDOBA", "value":"CORDOBA"});
        this.data.push({ "key":"CORRIENTES", "value":"CORRIENTES"});
        this.data.push({ "key":"CHACO", "value":"CHACO"});
        this.data.push({ "key":"CHUBUT", "value":"CHUBUT"});
        this.data.push({ "key":"ENTRE RIOS", "value":"ENTRE RIOS"});
        this.data.push({ "key":"FORMOSA", "value":"FORMOSA"});
        this.data.push({ "key":"JUJUY", "value":"JUJUY"});
        this.data.push({ "key":"LA PAMPA", "value":"LA PAMPA"});
        this.data.push({ "key":"LA RIOJA", "value":"LA RIOJA"});
        this.data.push({ "key":"MENDOZA", "value":"MENDOZA"});
        this.data.push({ "key":"MISIONES", "value":"MISIONES"});
        this.data.push({ "key":"NEUQUEN", "value":"NEUQUEN"});
        this.data.push({ "key":"RIO NEGRO", "value":"RIO NEGRO"});
        this.data.push({ "key":"SALTA", "value":"SALTA"});
        this.data.push({ "key":"SAN JUAN", "value":"SAN JUAN"});
        this.data.push({ "key":"SAN LUIS", "value":"SAN LUIS"});
        this.data.push({ "key":"SANTA CRUZ", "value":"SANTA CRUZ"});
        this.data.push({ "key":"SANTA FE", "value":"SANTA FE"});
        this.data.push({ "key":"SANTIAGO DEL ESTERO", "value":"SANTIAGO DEL ESTERO"});
        this.data.push({ "key":"TIERRA DEL FUEGO", "value":"TIERRA DEL FUEGO"});
        this.data.push({ "key":"TUCUMAN", "value":"TUCUMAN"}); 
        
            
        
        this.data.sort((a, b)=> a.value.localeCompare(b.value));
    }

    data: Array<any>;
}

export class TiposViviendasList {

    constructor(){
        this.data = new Array<any>();
        
        this.data.push({ "key" : "Propia", "value" : "Propia"});
        this.data.push({ "key" : "Alquilada", "value" : "Alquilada"});
        this.data.push({ "key" : "De La Familia", "value" : "De La Familia"});
        this.data.push({ "key" : "De la Empresa", "value" : "De la Empresa"});
        this.data.push({ "key" : "Otros", "value" : "Otros"});
        this.data.push({ "key" : "Hipoteca", "value" : "Hipoteca"});
        
        this.data.sort((a, b)=> a.value.localeCompare(b.value));  
        
    }

    data: Array<any>;
}

export class VinculosList {

    constructor(){
        this.data = new Array<any>();
        
        this.data.push({ "key" : "ESPOSA", "value" : "ESPOSA"});
        this.data.push({ "key" : "HIJO", "value" : "HIJO"});
        this.data.push({ "key" : "PADRE", "value" : "PADRE"});
        this.data.push({ "key" : "HIJA", "value" : "HIJA"});
        this.data.push({ "key" : "PRIMO", "value" : "PRIMO"});
        this.data.push({ "key" : "MARIDO", "value" : "MARIDO"});
        this.data.push({ "key" : "HERMANO/HERMANA", "value" : "HERMANO/HERMANA"});
        this.data.push({ "key" : "MADRE", "value" : "MADRE"});
        this.data.push({ "key" : "AMIGO", "value" : "AMIGO"});
        this.data.push({ "key" : "SOBRINO/A", "value" : "SOBRINO/A"});
        this.data.push({ "key" : "COMPANERO", "value" : "COMPANERO"});
        this.data.push({ "key" : "YERNO", "value" : "YERNO"});
        this.data.push({ "key" : "CUNADO", "value" : "CUNADO"});
        this.data.push({ "key" : "CUNADA", "value" : "CUNADA"});
        this.data.push({ "key" : "NUERA", "value" : "NUERA"});
        this.data.push({ "key" : "TIO", "value" : "TIO"});
        this.data.push({ "key" : "NIETO", "value" : "NIETO"});
        this.data.push({ "key" : "ABUELO/ABUELA", "value" : "ABUELO/ABUELA"});
        this.data.push({ "key" : "SUEGRA", "value" : "SUEGRA"});
        this.data.push({ "key" : "SUEGRO", "value" : "SUEGRO"});
        
                
        this.data.sort((a, b)=> a.value.localeCompare(b.value));
        
    }

    data: Array<any>;
}

export class ProductosList {

    constructor(){
        this.data = new Array<any>();
        

    }

    data: Array<any>;
}

export class SourceCodesList {

    constructor(){
        this.data = new Array<any>();       

        
    }

    data: Array<any>;
}

export class EstadosCivilList {

    constructor(){
        this.data = new Array<any>();
        
        this.data.push({ "key" : "NA", "value" : "N/A"});
        this.data.push({ "key" : "N", "value" : "SOLTERO/A"});
        this.data.push({ "key" : "C", "value" : "CASADO/A"});
        this.data.push({ "key" : "U", "value" : "UNIÓN DE HECHO"});
        this.data.push({ "key" : "S", "value" : "SEPARADO/A"});
        this.data.push({ "key" : "D", "value" : "DIVORCIADO/A"});
        this.data.push({ "key" : "V", "value" : "VIUDO/A"});
        
    }

    data: Array<any>;
}

export class EducacionNivleslList {

    constructor(){
        this.data = new Array<any>();
        
        this.data.push({ "key" : "P", "value" : "Primario"});
        this.data.push({ "key" : "S", "value" : "Secundario"});
        this.data.push({ "key" : "T", "value" : "Terciario"});
        this.data.push({ "key" : "U", "value" : "Universitario"});
        this.data.push({ "key" : "M", "value" : "Master"});
        
        
    }

    data: Array<any>;
}


export class TrabajosTipolList {

    constructor(){
        this.data = new Array<any>();
        
        this.data.push({ "key" : "Independiente", "value" : "Independiente"});
        this.data.push({ "key" : "Dependiente", "value" : "Dependiente"});
        this.data.push({ "key" : "Desempleado", "value" : "Desempleado"});

    }

    data: Array<any>;
}


export class CargoslList {

    constructor(){
        this.data = new Array<any>();
        
        this.data.push({ "key" : "GERENTE", "value" : "GERENTE"});
        this.data.push({ "key" : "EMPLEADO", "value" : "EMPLEADO"});
        this.data.push({ "key" : "SUPERVISOR", "value" : "SUPERVISOR"});

        
    }

    data: Array<any>;
}


export class HorasList {

    constructor(){
        this.data = new Array<any>();
        
       
        this.data.push({"key" : "09:00", "value" : "09:00"});
        this.data.push({"key" : "09:30", "value" : "09:30"});
        this.data.push({"key" : "10:00", "value" : "10:00"});
        this.data.push({"key" : "10:30", "value" : "10:30"});
        this.data.push({"key" : "11:00", "value" : "11:00"});
        this.data.push({"key" : "11:30", "value" : "11:30"});
        this.data.push({"key" : "12:00", "value" : "12:00"});
        this.data.push({"key" : "12:30", "value" : "12:30"});
        this.data.push({"key" : "13:00", "value" : "13:00"});
        this.data.push({"key" : "13:30", "value" : "13:30"});
        this.data.push({"key" : "14:00", "value" : "14:00"});
        this.data.push({"key" : "14:30", "value" : "14:30"});
        this.data.push({"key" : "15:00", "value" : "15:00"});
        this.data.push({"key" : "15:30", "value" : "15:30"});
        this.data.push({"key" : "16:00", "value" : "16:00"});
        this.data.push({"key" : "16:30", "value" : "16:30"});
        this.data.push({"key" : "17:00", "value" : "17:00"});
        this.data.push({"key" : "17:30", "value" : "17:30"});
        this.data.push({"key" : "18:00", "value" : "18:00"});
        this.data.push({"key" : "18:30", "value" : "18:30"});
        this.data.push({"key" : "19:00", "value" : "19:00"});
        this.data.push({"key" : "19:30", "value" : "19:30"});
        this.data.push({"key" : "20:00", "value" : "20:00"});
        this.data.push({"key" : "20:30", "value" : "20:30"});
        this.data.push({"key" : "21:00", "value" : "21:00"});

 
        
    }

    data: Array<any>;
}




import { Component, OnInit } from '@angular/core';
import { BusquedaFiltrosModel, AplicantDetailModel } from './models/models';

import { AplicantModel, DatosPersonalesModel, 
  DatosContactoModel, DatosLaboralesModel,
  DireccionModel, TelefonoModel, ObservacionesModel,
  EntregaDocumentacionModel, ObservacionesTackingModel, 
  ValidacionModel, AplicanteAdicionalModel,
 } from 'src/app/models/aplicante.model';

import { LoadingComponent } from '../shared/loading/loading.component';
import { AlertComponent, AlertModel, ALERT_TYPE } from '../shared/alert/alert.component';
import { Environment } from 'src/app/config/environment';
import { CommonDataService } from 'src/app/services/commons/commondata.service';
import { Utils } from 'src/app/commons/utils';
import { SessionStorageService } from 'src/app/auth/services/session.service';
import { ChangePromotorService } from 'src/app/services/changePromotor/changePromotor.service';
import { GenerosList } from 'src/app/models/lists.model';


@Component({
  selector: 'app-changepromotor',
  templateUrl: './changePromotor.component.html',
  styleUrls: ['./changePromotor.component.css']
})


export class ChangePromotorComponent  {
  public es: any;
  public title: string = "Cambio de Promotor";
  public loading: boolean;

  public alertMessage: AlertModel = new AlertModel;
  public alertConfirmEvent: Function;

  public dataResult: AplicantDetailModel = new AplicantDetailModel;
  public showResult : boolean;
  public tablereports = null;

  public busquedafiltros: BusquedaFiltrosModel = new BusquedaFiltrosModel; 

  public validations: Array<any> = new Array<any>();
  public validationsShow: boolean = true; 

  public saveButtonShow: boolean; 

  constructor(private changePromotorService: ChangePromotorService,
              private sessionStorageService: SessionStorageService,
              private commonDataService: CommonDataService) { 

             
              if (!(this.sessionStorageService.hasRolesUser(["AMX_ADMIN"]) || 
              this.sessionStorageService.hasRolesUser(["AMX_RIESGO_ADM"])))
                 this.sessionStorageService.redirectToLogin();
   }

 
  loadResults(){   

    if(Utils.isNullOrEmpty(this.busquedafiltros.pcn) && 
       Utils.isNullOrEmpty(this.busquedafiltros.dni) &&
       Utils.isNullOrEmpty(this.busquedafiltros.nombre)) {
      this.alertMessage = new AlertModel( "Se requiere completar al menos un filtro.",this.title, true);
      return;
    }


    this.showResult = false;
    this.loading = true;
    this.loadResultsRequest();
    
  }

  loadResultsRequest(){

    
    let request = { 
      "pcn": Utils.parseEmptyNull(this.busquedafiltros.pcn),
      "dni": Utils.parseEmptyNull(this.busquedafiltros.dni),
      "nombreapellido": Utils.parseEmptyNull(this.busquedafiltros.nombre),
    };         

  this.changePromotorService.getData(request).subscribe(
    data => {
      if (data['data'] && data['code'] == "0") {
 
        this.dataResult = data['data'][0];

        this.showResult = true;
        this.loading = false;          

      }else if(data['code'] == "1" ){
        this.loading = false;   
        this.alertMessage = new AlertModel(data['message'],this.title, true);
      }
      else {
        this.loading = false;   
        console.error(data);
        this.alertMessage = new AlertModel( "Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title, true);
      }

    },
    error => {
      console.error(error);
      this.alertMessage = new AlertModel( "Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title, true);
    }
  );


  }


cancel(){

  this.showResult = false;

}



public save(){
  this.loading = true;

  let request = {
    "pcn" : this.dataResult.pcn,
    "promotor" : this.dataResult.promotor,
    "promotor_original" : this.dataResult.promotor_original
  };
  
  
  this.changePromotorService.changePromotor(request).subscribe(
    data => { 
      
      if(data["data"] && data["code"] == "0") {

              this.loading = true; 
              this.loadResultsRequest();
  
            this.alertMessage = new  AlertModel("Aplicante actualizado" , this.title, true, ALERT_TYPE.CONFIRM, null, false);

        }else{
          this.loading = true; 
          this.loadResultsRequest();
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true);
        }
},
    error => {
      this.loading = false;
      console.error(error);
      this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true);
    });  
        

}


public validationsMessage(name):string{
  if(!this.validationsShow){
    this.saveButtonShow = true;
    return null;
  }
    
  this.validationsForm();

  let validation = this.validations.find(v => v[name] != null);

  if(validation){
    return validation[name];
  }
    
  return  null;
}

public validationsForm(){
  this.validations = new Array<any>();


  if(Utils.isNullOrEmpty(this.dataResult.promotor)){
    this.validations.push({"dataResult.promotor" : "este campos es requerido."});
   
  }

  if(Utils.isNullOrEmpty(this.dataResult.promotor_original)){
    this.validations.push({"dataResult.promotor_original" : "este campos es requerido."});
 
  }

  if(this.validations.length > 0){
    this.saveButtonShow = false
  }else{
    this.saveButtonShow = true
  }

  return this.validations.length == 0;
}

refactorNonNumeric(evento:any){
  const entrada= String.fromCharCode(evento.keyCode);
  const expresion=/\D/g
  
  if(expresion.test(entrada)){
    evento.preventDefault();
  }
 
}




}


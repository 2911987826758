import { Injectable } from '@angular/core';
import { SessionStorageService} from "../../auth/services/session.service";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Environment } from '../../config/environment';
import { Config } from '../../config/config';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private http: HttpClient, 
              private Conf: Config, 
              public sessionStorageService: SessionStorageService,) { }


  public getIdentity() 
  {
    


      let httpOptions = {
        withCredentials: false
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }


      let url = this.Conf.ENDPOINT_API + 'getIdentity';

      return this.http.get(url, httpOptions);       
        
  
 

  }

  public userLogout(){
    this.sessionStorageService.logout()
  }
}


import { Component, OnInit } from '@angular/core';
import { BusquedaFiltrosModel, AplicantDetailModel } from './models/models';

import { LoadingComponent } from '../shared/loading/loading.component';
import { AlertComponent, AlertModel, ALERT_TYPE } from '../shared/alert/alert.component';
import { CommonDataService } from 'src/app/services/commons/commondata.service';
import { Utils } from 'src/app/commons/utils';
import { SessionStorageService } from 'src/app/auth/services/session.service';
import { BridgerStatusService } from 'src/app/services/bridgerStatus/bridgerStatus.service';
import { Environment } from 'src/app/config/environment';


@Component({
  selector: 'app-bridgerstatus',
  templateUrl: './bridgerStatus.component.html',
  styleUrls: ['./bridgerStatus.component.css']
})


export class BridgerStatusComponent  {
  public es: any;
  public title: string = "Bridger Prospect - A espera de Type II";
  public loading: boolean;
  public caseNumSup: boolean = false;

  public alertMessage: AlertModel = new AlertModel;
  public alertConfirmEvent: Function;

  public dataResult = [];
  public showResult : boolean;
  public tablereports = null;
  public adicionalesList = [];

  public busquedafiltros: BusquedaFiltrosModel = new BusquedaFiltrosModel; 

  constructor(private bridgerStatusService: BridgerStatusService,
              private sessionStorageService: SessionStorageService,
              private commonDataService: CommonDataService) { 

            if (!(this.sessionStorageService.hasRolesUser(["AMX_ADMIN"]) || 
              this.sessionStorageService.hasRolesUser(["AMX_RIESGO_ADM"])))
                 this.sessionStorageService.redirectToLogin();
            else 
                 this.loadResults();
   }

 
  loadResults(){   

    this.showResult = false;
    this.loading = true;
    
    this.bridgerStatusService.getData().subscribe(
      data => {
        if (data['data'] && data['code'] == "0") {
   
          this.dataResult = data['data'];
          
          this.setupTable();             
  
        }else if(data['code'] == "1" ){
          this.loading = false;   
          this.alertMessage = new AlertModel(data['message'],this.title, true);
        }
        else {
          this.loading = false;   
          console.error(data);
          this.alertMessage = new AlertModel( "Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title, true);
        }
  
      },
      error => {
        console.error(error);
        this.loading = false;   
        this.alertMessage = new AlertModel( "Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title, true);
      }
    );
  
    
  }

  hasCaseNumber(item: any): boolean {
    for (let i = 1; i <= 5; i++) {
      const caseNumber = item[`caseNumberSuple${i}`];
      if (!Utils.isNullOrEmpty(caseNumber)) {
        return true;
      }
    }
    return false;
  }
  
  denegarConfirm(id){    
    
    this.alertConfirmEvent = this.onConfirmEvent;

    this.alertMessage = new AlertModel("Está seguro de denegar el PCN" + id + "?",this.title,true, "CONFIRM", id);
  }
  
  onConfirmEvent(confirm:any){

    if(confirm.confirm){
      this.denegar(confirm.data);
    }
  }

  cancel(){
    this.showResult = false;
  }



  public denegar(pcn){
    this.loading = true;

    let request = {
      "pcn" : pcn
    };
    
    
    this.bridgerStatusService.denegadoBridger(request).subscribe(
      data => { 
        
        if(data["code"] == "0") {
          
       
          this.alertMessage = new AlertModel("Los datos se guardaron con exito.", this.title, true, "SUCCESS");
          this.loadResults();
            
        }else{
          this.alertMessage = new AlertModel("Hubo un inconveniente al procesar la información, inténtalo nuevamente.", this.title, true);
          this.loading = false;
        }        

      },
      error => {
        this.loading = false;
        console.error(error);
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true);
      });  
          

  }

  export(){

    let reportDataExport = new Array<any>();

    this.dataResult.forEach(item => {      
          reportDataExport.push({
            
            "PCN":item.pcn,
            "CUIT":item.cuit,
            "NOMBRE Y APELLIDO":item.nombreapellido,
            "GENERO":item.genero,
            "DNI":item.dni,
            "FECHA":item.fecha,
            "ULTIMA MODIF":item.fecha_modificacion,
            "PROMOTOR":item.promotor,        
            "PROMOTOR ORIGINAL":item.promotor_original,        
            "ESTADO":item.estado,
            "MOTIVO":item.motivo,
        }); 
      });

    this.commonDataService.exportAsExcelFile(reportDataExport, this.title);

  }

  
    

  setupTable(){

    this.showResult = true;
    this.loading = false;

    if (this.tablereports != null) {
      this.tablereports.clear().destroy();
    } 
    
    $(document).ready( () => {
        this.tablereports =   $("#result-table").DataTable({

        searching: true, 
        paging: true, 
        //info: false,
        lengthChange: false,
        pageLength: 10,
        pagingType: 'full_numbers',
        columnDefs: [
          { targets: 'no-sort', orderable: false }
        ],        
        language: Environment.DataTableLanguageSetting
      });


    });

  }
  
  public  showSubRow(id){
   
   
    $(".div-sub-row").not("#tr-" + id).hide();
    $("#tr-" + id).toggle(10);
  }

  openPopupSuples(pcn: string) {

    const selectedData = this.dataResult.find(data => data.pcn === pcn);
    if (selectedData) {

      let results = [];
      let maxSuples = Object.keys(selectedData)
      .filter(key => key.startsWith('nombre1Suple'))
      .length;
    
      for (let i = 1; i <= maxSuples; i++) {
        const name = selectedData[`nombre1Suple${i}`];
        const secondName = selectedData[`nombre2Suple${i}`];
        const lastname = selectedData[`apellidoSuple${i}`];
        const caseNumber = selectedData[`caseNumberSuple${i}`];
         if ( caseNumber &&( name || secondName || lastname) ) {
          results.push({
            nombreCompleto: `${name} ${secondName} ${lastname}`.trim(),
            caseNumber: caseNumber
          });
        }
      }
      this.adicionalesList = results;

    } else {
      this.adicionalesList = [];
    }
  }

  adicionalesCancelar(){
    this.adicionalesList = [];
  }

}


import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BusquedaFiltrosModel, AplicantDetailModel } from './models/models';

import { AlertModel } from '../shared/alert/alert.component';
import { ApplicantDetailService } from '../../services/applicantDetail/applicantDetail.service'
import { CommonDataService } from 'src/app/services/commons/commondata.service';
import { Utils } from 'src/app/commons/utils';
import { SessionStorageService } from 'src/app/auth/services/session.service';


@Component({
  selector: 'app-applicanthistory',
  templateUrl: './applicantHistory.component.html',
  styleUrls: ['./applicantHistory.component.css']
})


export class ApplicantHistoryComponent {
  public es: any;
  public titulo: string = "HISTORIAL DEL APLICANTE";
  public loading: boolean;
  public alertMessage: AlertModel = new AlertModel;

  public tableResult: any = [];
  public dataResult: AplicantDetailModel = new AplicantDetailModel;
  public showResult : boolean;
  public showDetails : boolean = false;
  public tablereports = null;

  public busquedafiltros: BusquedaFiltrosModel = new BusquedaFiltrosModel; 

  @ViewChild('applicantdata') applicantDataRef: ElementRef;

  constructor(private applicantDetailService: ApplicantDetailService,
              private sessionStorageService: SessionStorageService,
              private commonDataService: CommonDataService) { 

                this.sessionStorageService.rolValidate("applicantDetail");
   }

   ngOnInit() {
    this.rolesAutorization();
    }


 

  loadResults(){   

    if(Utils.isNullOrEmpty(this.busquedafiltros.dni)) {
      
      this.alertMessage = new AlertModel( "Se requiere completar DNI.",this.titulo, true);
      return;
    }

    this.showDetails = false;
    this.showResult = false;
    this.loading = true;

    let request = {
        "dni":this.busquedafiltros.dni
    };         

    this.applicantDetailService.getHistory(request).subscribe(
      data => {
    
          if(data['code'] == "0"){
            
         
              this.tableResult = data['data'];              
              this.showResult = true;
            

          } else if(data['code'] == "1" ){
            this.alertMessage = new AlertModel(data['message'],this.titulo, true);
          
          }else{
            console.error(data);
            this.alertMessage = new AlertModel( "Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.titulo, true);
         
          }          

          this.loading = false;
   
      },
      error => {
        console.error(error);
        this.loading = false;
        this.alertMessage = new AlertModel( "Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.titulo, true);
      }
    );

  }


  loadDetails(pcn){   

    this.dataResult =this.tableResult.find(i=>i.pcn == pcn);
    this.dataResult.domicilio  = this.dataResult.domicilio.replace(/null/gi, "").trim();
    this.showDetails = true;

    setTimeout(()=>  $("#applicantdata").get(0).scrollIntoView(), 250);
    

  }

  export(){

    let reportDataExport = new Array<any>();

    this.tableResult.forEach(item => {      
          reportDataExport.push({
            
            "PCN":item.pcn,
            "CUIT":item.cuil,
            "DNI":item.dni,
            "NOMBRE Y APELLIDO":item.nombre,
            "GENERO":item.genero,
            "FECHA APLICACION":item.fecha_aplicacion,
            "PROMOTOR":item.promotor,        
            "PROMOTOR ORIGINAL":item.promotor_original, 

            "PRODUCTO":item.prod,        
            "LOCALIDAD":item.localidad,        
            "DOMICILIO":item.domicilio,   

            "TELEFONO":item.telefono,        
            "CELULAR":item.celular, 

            "CANAL":item.canal,
            "AREA":item.area,       
            "ESTADO":item.estado,
            "MOTIVO":item.subestado,
            "ESTADO MOTIVO":item.motivo,
            "OBSERVACIONES":item.observacion,
        }); 
      });

    this.commonDataService.exportAsExcelFile(reportDataExport, this.titulo);

  }

  refactorObservationPaste(event:ClipboardEvent){ 
    this.dataResult.observacion=this.sanitizedText(this.dataResult.observacion); 
   }
   sanitizedText(text:string):string{
     return text.replace(/[^a-zA-Z0-9.$, ]/g,'')
   }

   refactorObservation(evento:any){
    const entrada= String.fromCharCode(evento.keyCode);
    const expresion=/[^a-zA-Z0-9.$, ]/g;
  
    if(expresion.test(entrada)){
      evento.preventDefault();
    }
   
  }

  public rolConsulta : boolean=true;

  public rolesAutorization(){
    let rolesSession = this.sessionStorageService.getCurrentUser().roles
      console.log(rolesSession)
    if (rolesSession.includes("AMX_CONSULTA")){
      this.rolConsulta=false
    }
  
  }
}

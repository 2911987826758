export class UserModel {

    userName: string;
    fullName: string;
    active: boolean;
    customerId: string;
    email: string;
    password: string;
    oldPassword: string;
    roles: UserRolesModel[];
}

export class UserRolesModel{
    idRol: string;
    nombre: string;
}

export class Matrix{
    matrixName: string;
}
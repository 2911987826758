import { Injectable } from '@angular/core';
import * as $ from 'jquery';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Environment } from '../../config/environment';
import { Config } from '../../config/config';


@Injectable({
  providedIn: 'root'
})
export class InternalReportApplicantsService {
  
    constructor(private http: HttpClient, private Conf: Config) { }

    public getData(data:any){

      let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }

      let url = this.Conf.ENDPOINT_API + 'getNewAccountsApproved';//ENDPOINT A MODIFICAR
      let request = Object.assign({}, data);

      return this.http.post(url,request, httpOptions);  

    }

    public getApprovedAccountsReport(data){

      let url = this.Conf.ENDPOINT_API + 'internal/getNewAccountsAprroved';
      let request = Object.assign({}, data);
      let headers = new HttpHeaders({
        'Content-Type': 'application/json',
      })
      let wc = (Environment.CREDENTIALS) ? true : false;
      
      return this.http.post(url, request, {headers: headers, observe: "response",withCredentials: wc})
        
    }

    public getAllPromotores(){

      let url = this.Conf.ENDPOINT_API + 'getPromotores';
      let wc = (Environment.CREDENTIALS) ? true : false;
      
      return this.http.get(url,  {observe: "response",withCredentials: wc})
        
    }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InterceptorGuard } from 'src/app/auth/guards/interceptor.guard';
import { Session } from 'src/app/auth/models/session.model';
import { User } from 'src/app/auth/models/user.model';
import { SessionStorageService } from 'src/app/auth/services/session.service';
import { Config } from 'src/app/config/config';
import { DataService } from 'src/app/services/loginUser/data.service';
import { UsersService } from 'src/app/services/users/users.service';
import { Utils } from 'src/app/commons/utils';
import { AlertModel } from '../../component/shared/alert/alert.component';
import { AuthService } from 'src/app/auth/services/auth.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-loggin',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public titulo: string = "Login";
  public isAuthenticated: boolean;
  public loading: boolean;
  public displayError: boolean;
  public msgError: string;
  public displayCombo: boolean;
  public showLogin: boolean;
  public passRecovery: boolean = true;

  public customerId: string;
  public username: string;
  public codigo: string;
  public password: string;
  public alertMessage: AlertModel = new AlertModel;
  public showFormLogin: boolean = true;
  public hideInputCode: boolean = true;
  public hideForm: boolean = false;
  public hideQr: boolean = true;
  public hideMfaType: boolean = true;
  public mfaTypes:any = [];
  public mfaType: number;
  public titleMfaType: string;

  constructor(private router: Router, 
    private route: ActivatedRoute, 
    private Conf: Config,
    private sessionStorageService: SessionStorageService,
    private dataService: DataService,
    private authService: AuthService) {
      if( this.sessionStorageService.isAuthenticated() ){
        this.router.navigate(['/']);
      }
     }

  ngOnInit(): void {
    this.mfaTypes = [{"name":"Google Authenticator", "value":1},{"name":"Mail", "value":2}];
    this.stylesInputform();
  }

  public recoverPassword(){
    let request = { 
      "customerId":this.customerId.toUpperCase(),
      "userName":this.username.toUpperCase(),
    };

    this.loading = true;
    this.dataService.recoverPassword(request).subscribe(
      response => {

        if( response.status == 200 && response.body != null && response.body["code"] == 0 ){
          this.loading = false;
          this.showRecoverPassword(false);
          this.alertMessage = new AlertModel( "Se ha enviado un mensaje a su email registrado",this.titulo, true,"SUCCESS");
        }else if( response.status == 200 && response.body != null && response.body["code"] == 463 ){
          this.loading = false;
          this.alertMessage = new AlertModel( "Este usuario no puede recuperar la contraseña.<br>Contáctese con un administrador.",this.titulo, true,"WARNING");
        }else{
          this.loading = false;
          this.alertMessage = new AlertModel( "Ocurrió un error, verifique que los datos sean válidos",this.titulo, true,"WARNING");
        }

        
      }, err => {
        console.log( err );
        // this.alertMessage = new AlertModel( err.error.message,this.titulo, true,"WARNING");
        this.alertMessage = new AlertModel( "Se produjo un error en la aplicación",this.titulo, true,"WARNING");
        this.loading = false;
        throw err;
      }
    
    );

  }

  public sendFormLogin(){

    let request = { 
      "customerId":this.customerId.toUpperCase(),
      "userName":this.username.toUpperCase(),
      "password":this.password, 
    };

    this.loading = true;
    this.dataService.login(request).subscribe(
      response => {
        if( response.status == 200 && response.body != null && response.body["code"] == 0 ){
          //si showInputCode muestro el input para los 6 digitos
          if( (response.body["mfaType"] == 1 || response.body["mfaType"] == 2) && Utils.isNullOrEmpty( response.body["imgBase64"] ) ){
            this.mfaType = response.body["mfaType"];
            this.titleMfaType = ( this.mfaType == 1 ) ? "Ingrese el código de seis dígitos de su app de autenticación" : "Ingrese el código que se ha enviado a su casilla de email"
            this.loading = false;
            this.hideInputCode = false;
            this.hideForm = true;
            this.hideQr = true;
            this.hideMfaType = true;
          }else if( !Utils.isNullOrEmpty( response.body["imgBase64"] ) ){
            //si esto viene con valor es porque tiene que escanear el qr
            $("#img-qr-2fga").attr("src","data:image/png;base64," + response.body['imgBase64'] )
            this.hideForm = true;
            this.hideQr = false;
            this.loading = false;
            this.hideMfaType = true;
          }else if( response.body["message"] == "SELECT" ){
            //si dice select el usuario debe seleccionar una forma de verificacion
            this.hideForm = true;
            this.hideMfaType = false;
            this.loading = false;
          }else{
            // si lo anterior es false o nulo quiere decir que no necesita doble factor y pasa normalmente
            this.successLogin();
          }
          
        }else if( response.body != null && response.body["code"] == 2 ){
          this.loading = false;
          this.alertMessage = new AlertModel( "Su usuario no tiene un mail registrado. Comuniquese con un administrador",this.titulo, true,"WARNING");
        }else if(response.status == 200 && response.body != null && response.body["code"] == 406){
          this.loading = false;
          this.alertMessage = new AlertModel( response.body["message"],this.titulo, true,"WARNING");
        }else{
          this.loading = false;
          this.alertMessage = new AlertModel( "Ocurrió un error, verifique que las credenciales sean válidas",this.titulo, true,"WARNING");
        }

       
        
      }, err => {
        var mjeError = "Se produjo un error en la aplicación";

        if( err.error.message != null ){
        	mjeError = err.error.message
        }else if( err.error.message == null && err.error != null ){
        	mjeError = err.error
        }

        this.alertMessage = new AlertModel( mjeError,this.titulo, true,"WARNING");
        this.loading = false;
        throw err;
      }
    
    );
  
  }
  
  selectMfaType(){
    console.log( this.mfaType );
    this.loading = true;
    let request = { 
      "username":this.username.toUpperCase(),
      "matriz":this.customerId.toUpperCase(),
      "mfaType":this.mfaType,
      "password":this.password
    };
    //envio el codigo de 6 digitos
    this.dataService.selectMfaType(request).subscribe(
      response => {
        if( response.status == 200 ){
          this.sendFormLogin();
        }else if( response.status == 204 ){
          var mjeError = "Su usuario no tiene un mail registrado. Comuniquese con un administrador";
          this.alertMessage = new AlertModel( mjeError,this.titulo, true,"WARNING");
          this.loading = false;
        }else{
          var mjeError = "Se produjo un error en la aplicación";
          this.alertMessage = new AlertModel( mjeError,this.titulo, true,"WARNING");
          this.loading = false;
        }

      }, err => {
        var mjeError = "Se produjo un error en la aplicación";
        this.alertMessage = new AlertModel( mjeError,this.titulo, true,"WARNING");
        this.loading = false;
        throw err;
      }
    
    );
  }
  
  validCode():boolean{
    const regex = /^[0-9]*$/;
    const onlyNumbers = regex.test(this.codigo);
    if( !onlyNumbers )
      this.alertMessage = new AlertModel( "El código solo puede contener números",this.titulo, true,"WARNING");

    return onlyNumbers;
  }

  send2FGA(){
    if( !this.validCode() ){
      return;
    }
    this.loading = true;
    let request = { 
      "username":this.username.toUpperCase(),
      "matriz":this.customerId.toUpperCase(),
      "code":this.codigo,
      "password":this.password,
      "mfaType":this.mfaType
    };
    //envio el codigo de 6 digitos
    this.dataService.send2FGA(request).subscribe(
      response => {
        if( response.body['authorizeUser'] ){
          this.successLogin();
        }else{
          this.loading = false;
          this.alertMessage = new AlertModel( "El codigo ingresado es incorrecto",this.titulo, true,"WARNING");
        }
        
      }, err => {
        var mjeError = "Se produjo un error en la aplicación";

        this.alertMessage = new AlertModel( mjeError,this.titulo, true,"WARNING");
        this.loading = false;
        throw err;
      }
    
    );
  }


  successLogin(){
    this.authService.getIdentity().subscribe(data => {
            
      if (data) {   
          if(!this.sessionStorageService.isAuthenticated()){
            data = data as any;

             this.createSession(data);     
             if( data['renewPassword'] ){
              this.router.navigate(['/modifyUser']);
             }else{
              this.router.navigate(['/']);
            } 
            return true;
          }

      }
      this.loading = false;
    },
    error => { 
      this.loading = false;
      console.error(error);
      this.sessionStorageService.logout();
      return false;
    }); 
  }

  createSession(data:any){

    let userSession = new Session();
    userSession.token = uuidv4();
    userSession.user = new User();
    userSession.user.username = data.code;
    userSession.user.name = data.code;
    userSession.user.roles = data.roles as Array<string>;
    userSession.user.acciones = data.acciones as Array<string>;
    userSession.user.matriz = data.matriz;
    userSession.user.promotor = data.promotor;
    userSession.user.agencia  = data.agencia;
    userSession.login = true;
    userSession.renewPassword = data.renewPassword;
    userSession.mfa = data.mfa;
    userSession.mfaType = data.mfaType;

    this.sessionStorageService.setCurrentSession(userSession);
    
  }

  public stylesInputform(){
    for( var i = 0; i < $(".form-control").length; i++ ){

      let objInput = $(".form-control")[ i ];
      $( objInput ).on( "focus",function(){
        $( objInput ).parent().addClass("cont-input-login-focus")
      });

      $( objInput ).on( "blur",function(){
        
        if( Utils.isNullOrEmpty( $(objInput).val() ) ){
          $( objInput ).parent().removeClass("cont-input-login-focus")
          $( objInput ).parent().addClass("login-error");
        }else{
          $( objInput ).parent().removeClass("login-error");
        }
        
      });
      $( objInput ).on( "keyup",function(){
        valid(objInput);
      });
    }

    function valid(objInput){
      let error = false;
      let objInp = $(".form-control[data-btn='"+$(objInput).attr('data-btn') +"']");
      for( var i = 0; i < objInp.length; i++ ){
        let objInputValid = objInp[ i ];
        if( Utils.isNullOrEmpty( $(objInputValid).val() ) ){
          error = true;
          break;
        }else{
          $( objInputValid ).parent().removeClass("login-error");
        }
      }
      if( error ){
        $( "#"+$(objInput).attr('data-btn') ).addClass( "mat-button-disabled" );
      }else{
        $( "#"+$(objInput).attr('data-btn') ).removeClass( "mat-button-disabled" );
      }
    }
    
  }

  public showRecoverPassword( cancel:boolean ){
    this.customerId = "";
    this.username = "";
    this.password = "";
    $(".cont-input-login").removeClass( "login-error" );
    $(".cont-input-login").removeClass( "cont-input-login-focus" );
    this.showFormLogin = ( cancel ) ? false : true;
    this.hideForm = false
    this.hideQr = true
    $( "#submit-login,#submit-login-recover,#submit-code" ).addClass( "mat-button-disabled" );
  }

  public newFormUser(){
    console.log("test")
  }
 
}

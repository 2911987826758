export class ChangeStatusApplicantModel {
   
    nombreapellido:string;
    dni:string;
    pcn:string;
    fecha:string;
    fecha_modificacion:string;
    producto:string;
    area:string;
    accion:string;
    motivo:string;
    promotor:string;
    promotor_original:string;
     
}

export class ChangeStatusModel {
      
    pcn:string;
    area:string;
    accion:string;
    motivo:string;
    observaciones:string;
     
}




export class BusquedaFiltrosModel {

    estado: string;  
    fecha: string;  
    producto: string;  
    dni: string;  
    pcn: string;  
    nombre: string;
    promotor: string;
    refnaps: string;
}

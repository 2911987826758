export class InternalCpidsModel {
  id: string;  
  parentcpid : string;
  creative : string;
  referringsite : string;
  querystring : string;
  groupsite : string;
  keywordphrase : string;
  subchannel : string;
  lastUpdatedUser : string;
  lastUpdated : string;
  createdTimestamp : string;
  createdUser : string;
}




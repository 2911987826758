import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { delay } from 'rxjs/internal/operators';

import { Environment } from "../../config/environment";
import { Config } from "../../config/config";
import { ProductsModel } from "src/app/component/products/model/models";
import { MockStatic } from "src/app/config/mock.static";

@Injectable({
  providedIn: "root",
})
export class ProductsService {
 


  private mockDataArray = {
    data: [
      { 
        prodId: "1",
        prodCode: "11",
        prodName: "RCP Green",
        prodMns: "000",
        income: "52.600",
        prodLgf: "green-card",
      },

      {
        prodId: "2",
        prodCode: "21",
        prodName: "RCP Gold",
        prodMns: "001",
        income: "69.000",
        prodLgf: "gold-card",
      },

      {
        prodId: "3",
        prodCode: "39",
        prodName: "RCP Platinum",
        prodMns: "003",
        income: "196.000",
        prodLgf: "platinum-card",
      }
    ]
  };

  constructor(private http: HttpClient, private Conf: Config) {}

  getProducts(){


      
    let httpOptions = {
      withCredentials: false
    };

    if(Environment.CREDENTIALS){
      httpOptions.withCredentials = true;
    }

    let url = this.Conf.ENDPOINT_API + 'getProductos';  

    return this.http.get(url, httpOptions);
  }

  createProduct(data: ProductsModel) {

    let httpOptions = {
      withCredentials: false,
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    if (Environment.CREDENTIALS) {
      httpOptions.withCredentials = true;
    }

    let url = this.Conf.ENDPOINT_API + "createProductos";
    let request = Object.assign({}, data);

    return this.http.post(url, request, httpOptions);
  }

  updateProduct(data: ProductsModel) {

       let httpOptions = {
      withCredentials: false,
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    if (Environment.CREDENTIALS) {
      httpOptions.withCredentials = true;
    }

    let url = this.Conf.ENDPOINT_API + "updateProducto";
    let request = Object.assign({}, data);

    return this.http.post(url, request, httpOptions);

  }
  
  deleteProduct(data) {


    let httpOptions = {
      withCredentials: false,
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    if(Environment.CREDENTIALS){
      httpOptions.withCredentials = true;
    }

    let url = this.Conf.ENDPOINT_API + 'deleteProducto';
    let request = Object.assign({}, data);

    return this.http.post(url, request, httpOptions);  

  }



  
}

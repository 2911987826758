import {  Component, OnInit } from '@angular/core';
import { AlertModel, ALERT_TYPE } from '../../component/shared/alert/alert.component';

import { InternalCpidsService } from '../../services_internal/internalCpid/internalCpids.service';
import { InternalCpidsModel } from './models/models';

import { ɵAnimationGroupPlayer } from '@angular/animations';
import { Environment } from 'src/app/config/environment';
import { ReturnStatement } from '@angular/compiler';
import { Utils } from 'src/app/commons/utils';
import { SessionStorageService } from 'src/app/auth/services/session.service';
import { ExcelExportService } from 'src/app/services/excelExport/excel.export.service';

@Component({
  selector: 'app-internal-cpids',
  templateUrl: './internalCpids.component.html',
  styleUrls: ['./internalCpids.component.css']
})
export class InternalCpidsComponent implements OnInit {

  public title: string = "Administrador de CPIDS";
  public titleUser: string;
  public es: any;
  public showResult : boolean;
  public tablereports = null;
  public showNew : boolean; 
  public entityCurrent: InternalCpidsModel = new InternalCpidsModel;
  
  public loading: boolean;
  public alertMessage: AlertModel = new AlertModel;
  public alertConfirmEvent: Function;

  public validateShow: boolean =  false;
  public validateMessage: string;
  public isUpdate: boolean = false;


  public resultData : InternalCpidsModel[]

  


  constructor(private internalCpidsService: InternalCpidsService, 
              private excelExportService: ExcelExportService, 
              private sessionStorageService: SessionStorageService) { 
      
                this.sessionStorageService.rolValidate("intCpid");
  }



  ngOnInit() {
    this.setCalendarLocale();
    this.loadResultData();   

  }

  loadResultData(){
    
    this.loading = true;

    this.internalCpidsService.getData().subscribe(
      data => {
        if (data['data']) {
          this.resultData = data['data'];

          this.setupTable();             

        }
        else {
          this.loading = false;
          this.showResult = true;
          console.error(data);
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title,true);
        }
      },
      error => {
        this.loading = false;
        this.showResult = true;
        console.error(error);
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title,true);
      }
    );

  }

  onConfirmEvent(confirm:any){

    if(confirm.confirm){
      this.delete();
    }else{
      this.entityCurrent = null;
    }
  }

  delete(){
      this.loading = true;

      let request = {
        "parentcpid" : this.entityCurrent.parentcpid
      };


      this.internalCpidsService.delete(request).subscribe(
        data => {
          if (data && data["code"] && data["code"] == "0") {
           
  
            this.entityCurrent = new InternalCpidsModel();
            this.alertMessage = new AlertModel("Los datos se guardaron con exito",this.title,true);
            this.loadResultData();
          }
          else {
            this.loading = false;
            console.error(data);
            this.alertMessage = new AlertModel("Hubo un inconveniente en el borrado, inténtalo nuevamente.",this.title,true);
          }
          
        },
        error => {
          this.loading = false;
          console.error(error);
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title,true);
        }
      );    




  }

  deleteConfirm(itemId){    
    this.entityCurrent =  new InternalCpidsModel();
    this.entityCurrent.parentcpid = itemId;
    this.alertConfirmEvent = this.onConfirmEvent;

    this.alertMessage = new AlertModel("Está seguro de eliminar este CPID?",this.title,true, "CONFIRM");
  }

  new(id){
    
    this.entityCurrent = new InternalCpidsModel();
    this.showNew = true;
    this.showResult = false;

    if(id == null){
      this.titleUser = "Nuevo CPID";
      this.entityCurrent.createdUser = this.sessionStorageService.getCurrentUser().name;
      this.entityCurrent.createdTimestamp = new Date().getTime().toString();
      this.entityCurrent.lastUpdated = new Date().getTime().toString();

    } else{
      this.isUpdate = true;
      this.titleUser = "Modificar CPID";
      this.entityCurrent = Object.assign({}, this.resultData.find(m => { return m.id == id}));
      this.entityCurrent.lastUpdated = new Date().getTime().toString();
      this.entityCurrent.lastUpdatedUser = this.sessionStorageService.getCurrentUser().name;
    }

    this.validateShow  =  false;
    this.validateMessage = "";

  }
 
  newCancel(){

    this.isUpdate = false;
    this.showResult = true;
    this.showNew = false;
  }

  newSave(){   

    if(this.entityCurrent.id == null){
      this.newCreate();
    } else{
      this.newUpdate();
    }
    
  }

  newCreate(){
    
    if(!this.newValidate())
        return;

    if(this.resultData.some( c=>c.parentcpid ==this.entityCurrent.parentcpid )){
      this.alertMessage = new AlertModel("El CPID que se intenta crear ya existe.",this.title,true);
      this.loading = false;    
      return
    }

    this.loading = true;

    this.internalCpidsService.create(this.entityCurrent).subscribe(
      data => {
        if (data && data["code"] && data["code"] == "0") {
          this.showNew = false;      
          this.isUpdate = false;

          this.entityCurrent = new  InternalCpidsModel();
          this.alertMessage = new AlertModel("Los datos se guardaron con exito",this.title,true);
          this.loadResultData();
        }
        else if (data && data["code"] && data["code"] == "100") {
          
          this.alertMessage = new AlertModel("Hubo un inconveniente: " + data["message"],this.title,true);
          this.loading = false;    
          return;      
        } else{
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title,true);
          this.loading = false;   
        }

        
      },
      error => {
        console.error(error);
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title,true);
        this.showResult = true;
        this.loading = false;   
      }
    );
    
  }

  newUpdate(){

    if(!this.newValidate())
        return; 

    this.loading = true;

    this.internalCpidsService.update(this.entityCurrent).subscribe(
      data => {
        if (data && data["code"] && data["code"] == "0") {
          this.showNew = false;      
                

          this.entityCurrent = new  InternalCpidsModel();
          this.alertMessage = new AlertModel("Los datos se guardaron con exito",this.title,true);
          this.loadResultData();
        }
        else if (data && data["code"] && data["code"] == "100") {
          
          this.alertMessage = new AlertModel("Hubo un inconveniente: " + data["message"],this.title,true);
          this.loading = false;    
          return;      
        } else{
          this.alertMessage = new AlertModel("Hubo un inconveniente al actualizar la información, inténtalo nuevamente.",this.title,true);
          this.loading = false;   
        } 
      },
      error => {        
        this.alertMessage = new AlertModel("Hubo un inconveniente al actualizar la información, inténtalo nuevamente.",this.title,true);
        this.showResult = true;
        this.loading = false;   
        console.error(error);
      }
    );
    
  }

  newValidate():boolean{

    let validations = "";
    
    
    if(Utils.isNullOrEmpty(this.entityCurrent.parentcpid))
        validations+="Debe completar el campo CPID. "

    if(Utils.isNullOrEmpty(this.entityCurrent.subchannel))
        validations+="Debe completar el campo Sub Channel. "

    if(Utils.isNullOrEmpty(this.entityCurrent.groupsite))
        validations+="Debe completar el campo Group Site."        


    if(Utils.isNullOrEmpty(this.entityCurrent.referringsite))
      validations+="Debe completar el campo Referring Site."           


    if(Utils.isNullOrEmpty(this.entityCurrent.creative))
      validations+="Debe completar el campo Creative."    

    if(validations != "") {
        this.validateShow  =  true;
        this.validateMessage = validations;

        return false;
      }

    this.validateShow  =  false;
    this.validateMessage = "";
    return true;
  }

  backToResults(){
    this.showResult = true;
    this.loading = false;

  }

  setDateFormat(value:Date){

     return Utils.setDateFormat(value);
  }

  upload(event){
        
    let fileList: FileList = event.target.files;

    if(fileList.length == 0) {      
      this.alertMessage = new AlertModel("Seleccione solo un archivo.", this.title, true, ALERT_TYPE.WARNING);
      return;
    }

    let file: File = fileList[0];

    if(!file.name.toUpperCase().includes("CPID_CUS_")) {
      this.alertMessage = new AlertModel('El archivo no tiene el nombre indicado.', this.title, true, ALERT_TYPE.WARNING);
      return;
    }


    var extension = file.name.split(".").pop().toLowerCase();
    var extensiones = ["txt"];
   
    if(extensiones.indexOf(extension) === -1){
      this.alertMessage = new AlertModel("Seleccione un archivo con extención .txt ", this.title, true, ALERT_TYPE.WARNING);
      return;
   }else{
      this.loading = true;
      

      this.internalCpidsService.import(file).subscribe(
        data => {
          if (data['code']== 0) {
            
            this.loadResultData();
            this.alertMessage = new AlertModel("La carga se procesó con exito: " + data['message'], this.title, true, ALERT_TYPE.SUCCESS);
          }
          else {
            let message  = !Utils.isNullOrEmpty(data['message']) ? "Hubo un inconveniente: " +data['message'] :"Hubo un inconveniente al obtener la información, inténtalo nuevamente.";
            this.alertMessage = new AlertModel(message, this.title, true, ALERT_TYPE.WARNING);
            this.loading = false;
          }
          
        },
        error => {
          console.error(error);
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true, ALERT_TYPE.ERROR);
          this.loading = false;
        }
      );   


    }

    $("#importFile").val('');
  }

  export(){

    let reportDataExport = new Array<any>();

    this.resultData.forEach(item => {      
      reportDataExport.push({
        "CPID":item.parentcpid ,
        "Creative":item.creative,
        "Group Site":item.groupsite,
        "Keyword":item.keywordphrase,
        "Query String":item.querystring,
        "Referring Site":item.referringsite,
        "Sub Channel":item.subchannel,     
        "Created Timestamp": Utils.dateTimeStamp(item.createdTimestamp),     
        "Created User":item.createdUser,     
        "Last Updated User":item.lastUpdatedUser,  
        "Last Updated": Utils.dateTimeStamp(item.lastUpdated), 
      });
    }); 

   
    this.excelExportService.exportAsExcelFile(reportDataExport, this.title);

  }
  
  private setupTable(){

    this.showResult = true;
    this.loading = false;

    if (this.tablereports != null) {
      this.tablereports.clear().destroy();
    } 
    
    $(document).ready( () => {
        this.tablereports =   $("#result-table").DataTable({

        searching: true, 
        paging: true, 
        
        lengthChange: false,
        pageLength: 10,
        pagingType: 'full_numbers',
        columnDefs: [
          { targets: 'no-sort', orderable: false },
          { width: "70px", targets: "ACCIÓN" }
        ],        
        language: Environment.DataTableLanguageSetting
       });


    });

  }  

  setCalendarLocale(){

    this.es = Environment.CalendarLanguageSetting;
  }

  castDateShort(date){
        return Utils.isNullOrEmpty(Utils.dateTimeStamp(date)) ? date : Utils.dateTimeStamp(date);
  }

}

export class PreCheckFilterModel {
    fecha_procesamiento_inicio: string;
    fecha_procesamiento_fin: string;
    fuentes: Array<FuentesFilterModel> ;
    

}

export class FuentesFilterModel {
    fuente: string;
    valor: any;   

    constructor(fuente: string,valor: any = null) {
        this.fuente = fuente;
        this.valor = valor;
    }
}


export class VendorFilterModel {
    id: number;
    name: string;
    selected: boolean;
    
    
    constructor(id: number,name: string,  selected: boolean){

        this.id = id;
        this.name = name;
        this.selected = selected;
    }
}

export class ProductoFilterModel {
    id: number;
    value: string;
    selected: boolean;
    
    
    constructor(id: number,value: string,  selected: boolean){

        this.id = id;
        this.value = value;
        this.selected = selected;
    }
}


export class Vendor{
    id: string;
    name: string;
}



import { Directive, ElementRef, HostListener, Input, OnChanges } from '@angular/core';
import { Console } from 'console';

@Directive({
  selector: '[only-words]'
})

export class HtmlInputOnlyWords implements OnChanges {

  private isValid : Boolean = false;
  private input:ElementRef;
  
  constructor(private el: ElementRef) { 
    this.input = el;
  }

  @Input() spaces: boolean = true;


  @HostListener('keydown', ['$event']) onKeyDown(event) {
    
      let e = <KeyboardEvent> event;
      let aeiou = [225, 233, 237, 243, 250, 192, 241, 27, 219, 209];
      let arrow = [37,38,39,49];

      if(e.shiftKey){
        return true;
      }

      if ([46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
        // Allow: Ctrl+A
        (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+C
        (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+X
        (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
        //spaces
        (e.keyCode === 32 && this.spaces) ||
        // Allow: home, end, left, right
        (arrow.includes(e.keyCode)) ||
        //letter:
        (aeiou.includes(e.keyCode)) ||
        (e.keyCode >= 65 && e.keyCode <= 90) ||
        (e.keyCode >= 97 && e.keyCode <= 122)) {
          // let it happen, don't do anything
          return true;
        }  

        //Ctrl+V
        if((e.keyCode === 86 && (e.ctrlKey || e.metaKey))){
          return true;
        }

        e.preventDefault();
        return false;
  }

  @HostListener('keypress', ['$event']) onKeyPress(event) {
  
      let e = <KeyboardEvent> event;
      let aeiou = [225, 233, 237, 243, 250, 192, 241, 27, 219, 209];
      let arrow = [37,38,39,49];
      

      if(e.shiftKey){
        return true;
      } 
      

      if ([46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+C
      (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+X
      (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
      //spaces
      (e.keyCode === 32 && this.spaces) ||
      // Allow: home, end, left, right
      (arrow.includes(e.keyCode)) ||
      //letter:
      (aeiou.includes(e.keyCode)) ||
      (e.keyCode >= 65 && e.keyCode <= 90) ||
      (e.keyCode >= 97 && e.keyCode <= 122)) {
        // let it happen, don't do anything
        return true;
      }  

      //Ctrl+V
      if((e.keyCode === 86 && (e.ctrlKey || e.metaKey))){
        return true;
      }

      e.preventDefault();
      return false;
  } 

  @HostListener('ngModelChange', ['$event']) ngOnChanges(event) {

    var regex = new RegExp(/[^a-z|^A-Z|^\s|áéíóúnñÑ\'].*/);
    
    this.input.nativeElement.value = this.input.nativeElement.value.replace(regex, '');
    
    return true;
  }

  @HostListener('contextmenu', ['$event'])
    onRightClick(event) {
      event.preventDefault();
      return true;
    }
}
import { Injectable } from '@angular/core';
import { User } from 'src/app/auth/models/user.model';
import { Session } from '../../auth/models/session.model';
import { SessionStorageService} from "../../auth/services/session.service";


import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Environment } from '../../config/environment';
import { MockStatic } from '../../config/mock.static';
import { Config } from '../../config/config';
import { config } from 'process';
import { of } from 'rxjs';
import { Mock } from 'protractor/built/driverProviders';


@Injectable({
  providedIn: 'root'
})
export class DataService {
  constructor(private http: HttpClient, 
              private Conf: Config, 
              public sessionStorageService: SessionStorageService,) { }

  
  public userLogin(data : any) 
  {

          let mockresponse = null;

          var dataUser = [{
            usuario: "Dev User",
            name: "Dev User",
            roles: ["WOL_MODIF", "AMX_ADMIN"],
            matriz: "VN0000"
          }];
          
          if(dataUser.length > 0){
            
            let user = new User();
            user.username = dataUser[0].usuario;
            user.name = dataUser[0].name;
            user.roles = dataUser[0].roles;
            user.matriz = dataUser[0].matriz;
      
            mockresponse = user;
      
          }

          return of(mockresponse);
          
          
    
  }

  public userLogout(){
    this.sessionStorageService.logout()
  }

  public login(data){

    let url = this.Conf.ENDPOINT_API + 'accessToken/login';
    let request = Object.assign({}, data);
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    })
    let wc = (Environment.CREDENTIALS) ? true : false;
    
    return this.http.post(url, request, {headers: headers, observe: "response",withCredentials: wc})
      
  }

  public getUserAuth(data){

    let url = this.Conf.ENDPOINT_API + 'user_auth/find_user_auth_key';
    let request = Object.assign({}, data);
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    })
    let wc = (Environment.CREDENTIALS) ? true : false;
    
    return this.http.post(url, request, {headers: headers, observe: "response",withCredentials: wc})
      
  }

  public send2FGA(data){

    //let url = this.Conf.ENDPOINT_API + 'code/validate/key';
    let url = this.Conf.ENDPOINT_API + 'accessToken/validate/key';
    let request = Object.assign({}, data);
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    })
    let wc = (Environment.CREDENTIALS) ? true : false;
    
    return this.http.post(url, request, {headers: headers, observe: "response",withCredentials: wc})
      
  }

  public selectMfaType(data){

    //let url = this.Conf.ENDPOINT_API + 'code/validate/key';
    let url = this.Conf.ENDPOINT_API + 'accessToken/select-mfa-type';
    let request = Object.assign({}, data);
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    })
    let wc = (Environment.CREDENTIALS) ? true : false;
    
    return this.http.post(url, request, {headers: headers, observe: "response",withCredentials: wc})
      
  }

  getQr2FGA(data){

    let url = this.Conf.ENDPOINT_API + 'code/generate/'+data;
    let request = Object.assign({}, data);
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    })
    let wc = (Environment.CREDENTIALS) ? true : false;
    
    return this.http.post(url, request, {headers: headers, observe: "response",withCredentials: wc})

  }


  public recoverPassword(data){

    let url = this.Conf.ENDPOINT_API + 'user/recoverPassword';
    let request = Object.assign({}, data);
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    })
    let wc = (Environment.CREDENTIALS) ? true : false;
    
    return this.http.post(url, request, {headers: headers, observe: "response",withCredentials: wc})
      
  }

}

import { Injectable } from '@angular/core';
import * as $ from 'jquery';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Environment } from '../../config/environment';
import { Config } from '../../config/config';
import { Observable, of } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AddressService {
//Componente de plantilla Telemarketing

    constructor(private http: HttpClient, private Conf: Config) { }

    public getApplicantsReportByArea(data){


      let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }

      let url = this.Conf.ENDPOINT_API + 'getApplicantsReportByArea';
      let request = Object.assign({}, data);

      request.area = "DOCRECOVERY";

      return this.http.post(url, request, httpOptions);    

  }

  public getApplicantDataByArea(data:any): Observable<any>{



    let httpOptions = {
      withCredentials: false,
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    if(Environment.CREDENTIALS){
      httpOptions.withCredentials = true;
    }

    let url = this.Conf.ENDPOINT_API + 'getApplicantDataByArea';
    let request = Object.assign({}, data);

    return this.http.post(url, request, httpOptions);         

  }

  public editAddress(data){

    let url = this.Conf.ENDPOINT_API + 'editAddress';
    let request = Object.assign({}, data);
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    })
    let wc = (Environment.CREDENTIALS) ? true : false;

   return this.http.post(url, request, {headers: headers, observe: "response",withCredentials: wc}) 

  }

}
import { Component, OnInit } from '@angular/core';
import { NormalizePatternsService } from 'src/app/services/normalizePatterns/normalizePatterns.service';
import { BaseComponent } from '../shared/base.component';
import { Environment } from 'src/app/config/environment';
import { normalizePatternsModel } from './model/models';
import { PatternsListModel } from './model/models';
import { BusquedaFiltrosModel } from './model/models';
import { AlertComponent, AlertModel, ALERT_TYPE } from '../shared/alert/alert.component';
import { SessionStorageService } from 'src/app/auth/services/session.service';
import { ExcelExportService } from 'src/app/services/excelExport/excel.export.service';
import { Console } from 'console';

declare var $ : any;





@Component({
  selector: 'app-normalize-patterns',
  templateUrl: './normalize-patterns.component.html',
  styleUrls: ['./normalize-patterns.component.css']
})
export class NormalizePatternsComponent extends BaseComponent implements OnInit {

  public fecha;

  public resultData = [];
  public showResult : boolean;
  public loading: boolean;
  public title: string = "Normalización de Patrones";
  public validateShow: boolean =  false;
  public validateMessage: string;
  public normalizePatternsCurrent: normalizePatternsModel = new normalizePatternsModel();
  public showUpdateNormalizePatternsModal: boolean;
  public alertMessage: AlertModel = new AlertModel();
  public showNewNormalizePatterns: boolean;
  public patternsList: PatternsListModel = new PatternsListModel();
  public tablereports = null;
  public ngSelect: string = "";
  public displayError: boolean;

  public actionFile: string = "";
  public showModalUploadType: boolean;
  public inputFileVal : string = "";

  public fileListGlob: FileList;

  public busquedafiltros: BusquedaFiltrosModel = new BusquedaFiltrosModel();

   constructor(private sessionStorageService: SessionStorageService,
    private excelExportService: ExcelExportService,
    private normalizePatternsService: NormalizePatternsService) { 

    super();
    this.sessionStorageService.rolValidate("normalizePatterns");
  
    this.loading = true;
    this.displayError = false;
    }

  ngOnInit(): void {
    this.busquedafiltros.patronId = "";
    this.loadPatterns();
    //this.loadResultData();
    //this.loadNormalizePatternsRequest();

  }


  loadResultData(){
    
    this.loading = true;

    this.normalizePatternsService.getNormalizePatterns().subscribe(
      data => {
        if (data && data["code"] == "0" && data['data']) {
          this.resultData = data['data'];
          this.setupTable();             

        }
        else {
          console.error(data);
          this.loading = false;
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.","Producto",true);
        }
      },
      error => {
        console.error(error);
        this.loading = false;
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.","Producto",true);
      }
    );

  }

  
  setupTable(){

    this.loading = false;
    this.showResult = true;
    $('#result-table').DataTable().destroy();
    $(document).ready( () => {
      
      this.tablereports =   $("#result-table").DataTable({
          retrieve: true,
          searching: true, 
          paging: true, 
          
          lengthChange: false,
          pageLength: 10,
          pagingType: 'full_numbers',
          columnDefs: [
            { targets: 'no-sort', orderable: false }
          ],        
          language: Environment.DataTableLanguageSetting
          });
          

    });

  } 
  
  

  newNormalizePatternsCancel(){
    this.showUpdateNormalizePatternsModal = false;
    this.showNewNormalizePatterns = false;
  }

  updateNormalizePatterns(item: normalizePatternsModel) : void {
    this.validateShow  =  false;
    this.validateMessage = "";

    this.normalizePatternsCurrent = item;
    this.showUpdateNormalizePatternsModal = true;
  }

  updateNormalizePatternsSave(): void {
    if(!this.newNormalizePatternsValidate( false ))
        return;

    this.loading = true;

    this.normalizePatternsService.update(this.normalizePatternsCurrent).subscribe(
      data => {
        if (data && data["code"] == "0") {
          this.showUpdateNormalizePatternsModal = false;      
          this.loading = false;

          $('#producto-modificado').modal('hide');
          this.normalizePatternsCurrent = new normalizePatternsModel(); 
          
          this.alertMessage = new AlertModel("Los datos se guardaron con exito","Producto",true);

          //this.loadResultData();
          this.loadNormalizePatternsResults();
        }
        else {
          this.loading = false;
          this.alertMessage = new AlertModel("Hubo un inconveniente al guardar  la información, inténtalo nuevamente.","Producto",true);
        }
      },
      error => {
        console.error(error);
        this.loading = false;
        this.alertMessage = new AlertModel("Hubo un inconveniente al guardar la información.","Producto",true);
      }
    );
  }

  normalizePatternsDeleteConfirm(item) {
    this.normalizePatternsCurrent = item;
    this.alertMessage = new AlertModel("Está seguro de eliminar este registro?","Normalización",true, "CONFIRM");

  }

  onConfirmEvent(confirm:any){

    if(confirm.confirm){
      this.normalizePatternsDelete();
    }else{
      this.showUpdateNormalizePatternsModal = false;
      this.normalizePatternsCurrent = null;
    }
  }

  normalizePatternsDelete(){
    this.loading = true;

    this.normalizePatternsService.deleteNormalizePatterns(this.normalizePatternsCurrent).subscribe(
        data => {
          if (data && data["code"] == "0") {
            this.showUpdateNormalizePatternsModal = false;      
            this.loading = false;
            this.normalizePatternsCurrent = new normalizePatternsModel();
            this.alertMessage = new AlertModel("Se ha eliminado el producto con exito","Producto",true);
            //this.loadResultData();
            this.loadNormalizePatternsResults();
          }
          else {
            this.loading = false;
            this.alertMessage = new AlertModel("Hubo un inconveniente al borrar la información, inténtalo nuevamente.","Producto",true);
          }
        },
        error => {
          console.error(error);
          this.loading = false;
          this.alertMessage = new AlertModel("Hubo un inconveniente al borrar la información.","Producto",true);
        }
      );    
  }

  newNormalizePatterns(){
    this.ngSelect = this.busquedafiltros.patronId;
    this.validateShow  =  false;
    this.validateMessage = "";
    
    this.normalizePatternsCurrent = new normalizePatternsModel();
    this.showNewNormalizePatterns = true;

  }
  newNormalizePatternsValidate(isNew?:boolean):boolean{

    let validations = "";

    if(isNew == true){
      if(this.ngSelect == null || this.ngSelect == "")
      validations+="Debe completar el campo Id de Patron. "
    }else{
      if(this.normalizePatternsCurrent.patronId == null || this.normalizePatternsCurrent.patronId == "")
      validations+="Debe completar el campo Id de Patron. "
    }

    if(this.normalizePatternsCurrent.patOrigen == null || this.normalizePatternsCurrent.patOrigen == "")
      validations+="Debe completar el campo Origen. "

    if(this.normalizePatternsCurrent.patDestino == null || this.normalizePatternsCurrent.patDestino == "")
      validations+="Debe completar el campo Destino. "

    
    
    if(validations != "") {
        this.validateShow  =  true;
        this.validateMessage = validations;

        return false;
    }

    this.validateShow  =  false;
    this.validateMessage = "";
    return true;
  }

  newProductSave(){   

    if(!this.newNormalizePatternsValidate( true ))
        return;

    this.loading = true;
    this.normalizePatternsCurrent.patronId = this.ngSelect
    this.normalizePatternsService.createNormalizePaterns(this.normalizePatternsCurrent).subscribe(
      data => {
        if (data && data["code"] == "0") {
          this.showNewNormalizePatterns = false;      
          this.loading = false;

          $('#producto-nuevo').modal('hide');

          this.normalizePatternsCurrent = new normalizePatternsModel();
          this.alertMessage = new AlertModel("Los datos se guardaron con exito","Normalización",true);
          //this.loadResultData();
          this.loadNormalizePatternsResults();

        }else if( data["code"] == "1" ){//si ya existe un registro
          this.loading = false;
          this.alertMessage = new AlertModel( data["message"] ,"Normalización",true);
        }
        else {
          this.loading = false;
          this.alertMessage = new AlertModel("Hubo un inconveniente al guardar la información, inténtalo nuevamente.","Normalización",true);
        }
      },
      error => {        
        this.loading = false;
        this.alertMessage = new AlertModel("Hubo un inconveniente al guardar la información.","Producto",true);
        console.error(error);
      }
    );
    
    
  }

  loadPatterns(){

    this.normalizePatternsService.getPatterns().subscribe(
      data => {
        if (data['code'] == "0" && data['data']) {    
          this.busquedafiltros.patronId = data['data'][0].patronId; 
          this.patternsList.data = Object.assign([], data['data']);

          this.loadNormalizePatternsRequest();
        }
        else {
          console.error(data);
        }
        
      },
      error => {
        console.error("error en la carga de id de patrones");
      }
    );


  }



  loadNormalizePatternsResults(){

    this.loading = true;
  
    this.loadNormalizePatternsRequest()
    return true;

  }

  loadNormalizePatternsRequest(){  
    this.normalizePatternsCurrent = new normalizePatternsModel();
    this.normalizePatternsCurrent.patronId = this.busquedafiltros.patronId;
   

    this.normalizePatternsService.getNormalizePatternsByPatternId( this.normalizePatternsCurrent ).subscribe(
      data => {
          if (data && data["code"] == "0" && data['data']) {
            this.resultData = data['data'];
            this.setupTable();             
          
        }
        else {
          console.error(data);
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true);
        }
      },
      error => {
        console.error(error);
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true);
      }
    );

    return true;
  }


  ///UPLOAD

  
  public uploadMassiveNormalizePatterns(event){
    
    let fileList: FileList = this.fileListGlob;
    //si no hay un archivo seleccionado
    if(fileList.length == 0) {      
      this.alertMessage = new AlertModel("Seleccione solo un archivo.", this.title, true, ALERT_TYPE.WARNING);
      return;
    }
    //si no se selecciono una accion
    if( this.actionFile == "" ) {      
      this.alertMessage = new AlertModel("Seleccione una acción.", this.title, true, ALERT_TYPE.WARNING);
      return;
    }

    let file: File = fileList[0];

    var extension = file.name.split(".").pop().toLowerCase();
    var extensiones = ["csv"];
    //si no es un archivo csv
    if(extensiones.indexOf(extension) === -1){
      this.alertMessage = new AlertModel("Seleccione un archivo con extención .csv ", this.title, true, ALERT_TYPE.WARNING);
      return;
   }else{
      this.loading = true;
      let usuario = this.sessionStorageService.getCurrentUser().name;

      this.uploadNormalizePatternsCancel();
      this.normalizePatternsService.uploadMassiveNormalizePatterns(file, this.actionFile,this.busquedafiltros.patronId, usuario).subscribe(
        data => {
          if (data['code']== 0) {
        
            this.alertMessage = new AlertModel("La carga se procesó con exito", this.title, true, ALERT_TYPE.SUCCESS);
            //this.loadResultData();
            this.loadNormalizePatternsResults();
          }
          else {
            let message  = data['message'] ? data['message'] :"Ocurrió un error en la carga, inténtalo nuevamente.";
            this.alertMessage = new AlertModel(message, this.title, true, ALERT_TYPE.WARNING);
            this.loading = false;
          }

         
        },
        error => {
          console.error("Error");
          this.alertMessage = new AlertModel("Ocurrió un error en la carga, inténtalo nuevamente.", this.title, true, ALERT_TYPE.ERROR);
          this.loading = false;
        }
      );


    }

  }


  selectUploadType(item) {

    this.actionFile = "";
    this.fileListGlob = item.target.files;
    
    $('#upload-nuevo').modal();

  }

  uploadNormalizePatternsCancel(){

    this.inputFileVal = "";
    $('#upload-nuevo').modal('hide');

  }


  exportNormalizePatterns(){

    let reportDataExport = new Array<any>();

    this.resultData.forEach(item => {      
      reportDataExport.push({
        "Id de Patron":item.patronId,
        "Origen":item.patOrigen,
        "Destino":item.patDestino
      });
    }); 

    this.excelExportService.exportAsCsvFile(reportDataExport, "listado_normalizacion_patrones");


  }

}

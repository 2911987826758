export class ReportAgenciesBusquedaModel {
    fecha_captura_inicio: string;
    fecha_captura_fin: string;
    tipo_de_venta: string;
    producto: string;
    promotor: string;
    area: string;    
}
export class VendorFilterModel {
    id: number;
    name: string;
    selected: boolean;
    
    
    constructor(id: number,name: string,  selected: boolean){

        this.id = id;
        this.name = name;
        this.selected = selected;
    }
}


import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { SessionStorageService } from "../services/session.service";
import { AuthService } from "../services/auth.service";
import { Environment } from 'src/app/config/environment';
import { Session } from '../models/session.model';
import { User } from '../models/user.model';
import { v4 as uuidv4 } from 'uuid';
import { CommonDataService } from 'src/app/services/commons/commondata.service';


@Injectable({
  providedIn: 'root'
})
export class AuthorizatedGuard implements CanActivate {

  constructor(private router: Router,
              private authService: AuthService,
              private sessionStorageService: SessionStorageService,
              private commonDataService: CommonDataService) {
  
        }

  private isLogin: boolean = false;
  private checkLogin: boolean = false;

  canActivate() {  
  
    this.checkLogin = true;

    this.authService.getIdentity().subscribe(
      data => {
        if (data) {  
          if(!this.sessionStorageService.isAuthenticated()){
            data = data as any;
            
            this.createSession(data);                  
            this.isLogin = true;
            
            this.commonDataLoad();
            return true;
          }
          
          this.commonDataLoad();
        }

      },
      error => { 
      
        console.error(error);
        this.isLogin = false;
        this.sessionStorageService.logout();
        return false;
      }); 
   
    return true;
  }

  createSession(data:any){

    let userSession = new Session();
    userSession.token = uuidv4();
    userSession.user = new User();
    userSession.user.username = data.code;
    userSession.user.name = data.code;
    userSession.user.roles = data.roles as Array<string>;
    userSession.user.acciones = data.acciones as Array<string>;
    userSession.user.matriz = data.matriz;
    userSession.user.promotor = data.promotor;
    userSession.user.agencia  = data.agencia;
    userSession.login = true;
    
    this.sessionStorageService.setCurrentSession(userSession);
    this.sessionStorageService.redirectToLogin();
    
  }

  commonDataLoad(){
      this.commonDataService.getProductos().subscribe();
      this.commonDataService.getNacionalidad().subscribe();
      this.commonDataService.getValidationAccionAll().subscribe();
      this.commonDataService.getProfesiones().subscribe();
  }
}

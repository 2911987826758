import { Component, ElementRef, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import * as _ from 'lodash';
import { Environment } from '../../config/environment';

import { AlertModel, ALERT_TYPE } from '../shared/alert/alert.component';
import { DeliveryService } from 'src/app/services/delivery/delivery.service';
import { SessionStorageService } from 'src/app/auth/services/session.service';
import { Roles } from 'src/app/auth/models/roles.static';
import { BaseComponent } from '../shared/base.component';

@Component({
  selector: 'app-masive-delivery',
  templateUrl: './masiveDelivery.component.html',
  styleUrls: ['./masiveDelivery.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class MasiveDeliveryComponent extends BaseComponent implements OnInit{

  public title: string = "Cambio Estados Masivo - Delivery";
  public loading: boolean;
  public displayError: boolean;
  public msgError: string;
  public displayCombo: boolean;
  public showBackButton: any = Environment.SHOW_BACK_BUTTON;

  public alertMessage: AlertModel = new AlertModel;
  public alertConfirmEvent: Function;

  public table = null;
  public files = [];



  constructor(private deliveryService: DeliveryService,
              private sessionStorageService: SessionStorageService) { 

              super();
              this.sessionStorageService.rolValidate("masiveDelivery");

              this.loading = true;
              this.displayError = false;
  }

  ngOnInit(): void {

    this.loading = false;
    this.displayError = false;
    this.msgError = null;

    this.loadTable();
  }

 
  public uploadFile(event){
    
    let fileList: FileList = event.target.files;

    if(fileList.length == 0) {      
      this.alertMessage = new AlertModel("Seleccione solo un archivo.", this.title, true, ALERT_TYPE.WARNING);
      return;
    }

    let file: File = fileList[0];

    if(!file.name.toUpperCase().includes("DELIVERY_")) {
      this.alertMessage = new AlertModel('El nombre de archivo debe contener la palaba "DELIVERY_".', this.title, true, ALERT_TYPE.WARNING);
      return;
    }

    var extension = file.name.split(".").pop().toLowerCase();
    var extensiones = ["txt"];
   
    if(extensiones.indexOf(extension) === -1){
      this.alertMessage = new AlertModel("Seleccione un archivo con extención .txt ", this.title, true, ALERT_TYPE.WARNING);
      return;
   }else{
      this.loading = true;
      let usuario = this.sessionStorageService.getCurrentUser().username;

      this.deliveryService.aprobacionMasivaFileUpload(file, usuario).subscribe(
        data => {
          if (data['code']== 0) {
            this.files = data['response'];            
            this.alertMessage = new AlertModel("La carga se procesó con exito", this.title, true, ALERT_TYPE.SUCCESS);
            this.loadTable();
          }
          else {
            let message  = data['message'] ? data['message'] :"Hubo un inconveniente al obtener la información, inténtalo nuevamente.";
            this.alertMessage = new AlertModel(message, this.title, true, ALERT_TYPE.WARNING);
            this.loading = false;
          }

          
        },
        error => {
          console.error("Error");
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true, ALERT_TYPE.ERROR);
          this.loading = false;
        }
      );   


    }
    $("#importFile").val('');
  }

  public downloadFile(name){

    this.loading = true;
    let request = {"name" : name};

    this.deliveryService.aprobacionMasivaFileDownload(request).subscribe(
      data => {

        if (data) {
          
          const blob = new Blob([data], { type: 'text/csv' });
          const url = window.URL.createObjectURL(blob);
          
          var filelink = document.createElement("a");
          filelink.download = name;
          filelink.href = url;
          filelink.click();
          
        }
        else {
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true, ALERT_TYPE.ERROR);
        }

        this.loading = false;
      },
      error => {
        console.error(error);
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true, ALERT_TYPE.ERROR);
        this.loading = false;
      }
    );

  }

  

  public loadTable(){
      this.loading = true;
      
      this.deliveryService.getListIngresoArchivo({nombre:"DELIVERY"}).subscribe(
        data => {
          if (data['code']== 0) {
            this.files = data['response'];
            this.files.forEach(i=> i.estado = i.estado.replace("PROCESANDO", "PROCESADO"))
            this.setupTable();
          
          }
          else {
            console.error(data);
            this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true, ALERT_TYPE.WARNING);
          }
          this.loading = false;
        },
        error => {
          console.error(error);
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true, ALERT_TYPE.WARNING);
          this.loading = false;
        }
      );   
  }

  
  setupTable(){

    if (this.table != null) {
      this.table.clear().destroy();
    } 
  

    $(document).ready( () => {
      this.table =   $("#main-table").DataTable({
                  paging: true, 
                  lengthChange: false,
                  pageLength: 10,
                  pagingType: 'full_numbers',
                  columnDefs: [
                    { targets: 'no-sort', orderable: false }
                  ],        
                  language: Environment.DataTableLanguageSetting
                });
     });

     this.loading = false;
}

}

import { Injectable } from '@angular/core';
import * as $ from 'jquery';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Environment } from '../../config/environment';
import { Config } from '../../config/config';
import { config } from 'process';
import { of } from 'rxjs';
import { MockStatic } from 'src/app/config/mock.static';


@Injectable({
  providedIn: 'root'
})
export class RangosIngresoService {


    constructor(private http: HttpClient, private Conf: Config) { }

    public getData(){


          var mockresponse = {
            "data": [
              {
                "id":"1",
                "nse":"A",
                "avg":"295.962",
            },
            {
                "id":"2",
                "nse":"B",
                "avg":"146.641",
            },
            {
                "id":"2",
                "nse":"C1",
                "avg":"82.661",
            },
            {
                "id":"3",
                "nse":"C2",
                "avg":"51.182",
            },
            
            {
                "id":"4",
                "nse":"C3",
                "avg":"36.871",
            },
            {
                "id":"5",
                "nse":"D1",
                "avg":"24.760",
            },
            {
                "id":"6",
                "nse":"D2",
                "avg":"14.879",
            },                                
          ]
          };

          return of(mockresponse);
      

    }

    public create(data){


          var mockresponse = {
            "code":0,
            "message":""
          };

          return of(mockresponse);
      


    }

    public update(data){

    
          var mockresponse = {
            "code":0,
            "message":""
          };

          return of(mockresponse);
      

    }    

    public disable(data){

     
          var mockresponse = {
            "code":0,
            "message":""
          };

          return of(mockresponse);
      


    }    

    public getUsersRoles(){

    
          var mockresponse = {
            "data": [
               {
                "id":"4",
                "rol":"OPERATIVO", 
               },
               
               {
                "id":"3",
                "rol":"SUPERVISOR AGENCIA",                     
               }, 
               
               {
                "id":"1",
                "rol":"ADMINISTRADOR",                
               },
               
               {
                "id":"2",
                "rol":"MANAGER",
               },                   
          ]
          };

          return of(mockresponse);
      

    }

}


import {  Component, OnInit } from '@angular/core';
import { LoadingComponent } from '../../component/shared/loading/loading.component';
import { AlertComponent, AlertModel, ALERT_TYPE } from '../../component/shared/alert/alert.component';

import { InternalSourceCodesService } from '../../services_internal/internalSourcecodes/internalSourcecodes.service';
import { InternalSourceCodesModel,VendorList,Status, Vendor } from './models/models';

import { Environment } from 'src/app/config/environment';
import { Utils } from 'src/app/commons/utils';
import { SessionStorageService } from 'src/app/auth/services/session.service';
import { ExcelExportService } from 'src/app/services/excelExport/excel.export.service';
import { CommonDataService } from 'src/app/services/commons/commondata.service';
import { ProductosList } from 'src/app/models/lists.model';
declare var $ : any;
@Component({
  selector: 'app-internalSourcecodes',
  templateUrl: './internalSourcecodes.component.html',
  styleUrls: ['./internalSourcecodes.component.css']
})

export class InternalSourceCodesComponent implements OnInit {
    public title: string = "SOURCE CODES CUSTOMER";
    public loading: boolean;
    public alertMessage: AlertModel = new AlertModel;
    public alertConfirmEvent: Function;
    public es: any;
    public productosList :ProductosList = new ProductosList();
    public showResult : boolean;
    public resultData = [];
    public tablereports = null;
    public entityCurrent: InternalSourceCodesModel = new InternalSourceCodesModel;
    public showNew : boolean; 
    public titleUser: string;
    public isUpdate:boolean = false;
    public validateShow: boolean =  false;
    public validateMessage: string;
    public vendorList: VendorList = new VendorList();
    public promotoresList: Vendor[] = new Array<Vendor>();
    public statusList: Status[] = new Array<Status>();
    public status: Status = new Status();
    public valorOriginalProdAnterior: any;
    public valorProdAnterior: any; 
    public fileType: string;
    public typesOfSale: any[] = [{"type":"SUP","description":"Venta Isolada"},{"type":"RCO","description":"Reverse Companion"}
                                  ,{"type":"CON","description":"Conversion (Upgrade)"},{"type":"COM","description":"Companion"}];

    constructor(private internalSourcecodesService: InternalSourceCodesService,
        private excelExportService: ExcelExportService,
        private commonDataService: CommonDataService,
        private sessionStorageService: SessionStorageService) { 

          this.sessionStorageService.rolValidate("internalConfiguracion");
        }



  ngOnInit() {
    this.loadStatus();

    this.setCalendarLocale();
    this.loadPromotores();
    this.loadProductos();
    this.loadResultData();   
  
  }

  setCalendarLocale(){

    this.es = Environment.CalendarLanguageSetting;
  }

  loadStatus(){
    var arraySatus = [{"code":"ACTIVO","name":"ACTIVO"},{"code":"INACTIVO","name":"INACTIVO"}];
    for( var i = 0; i < arraySatus.length; i++ ){
      this.status = new Status();
      this.status.code = arraySatus[ i ].code;
      this.status.name = arraySatus[ i ].name;
      this.statusList.push( this.status )
    }
  }

  loadProductos(){

    Utils.storageSave("productos-data", null);

    this.internalSourcecodesService.getProductos().subscribe(
      data => {
        if (data['code'] == "0" && data['data']) {       
          this.productosList.data = Object.assign([], data['data']);
        }
        else {
          console.error(data);
        }

        //this.loading = false;
      },
      error => {
        console.error(error);
      }
    );

  }


  loadPromotores(){
    this.internalSourcecodesService.getAllPromotores().subscribe(
      response => {

        if( response.status == 200 && response.body != null){

          this.promotoresList = Object.assign([], response.body);

        }else if( response.status == 204 ){
          console.log( "no se encontraron promotores" );
        }else{
          console.log( "Error 1 obteniendo los promotores" );
        }

      }, err => {
        console.log( "Error 2 obteniendo los promotores" );
        this.loading = false;
        throw err;
      }
    );
  }

  setDataWithProduct( sc:any ):Array<any>{
    let locArray = [];
    
    for( var i = 0; i < sc.length; i++ ){
      
      let prod = this.productosList.data.find(p=> (p.key??"") == parseInt( sc[ i ].producto.id ) );
      sc[ i ].prodDetail = prod;
      sc[ i ].idProd = sc[ i ].producto.id;
      locArray.push( sc[ i ] );
    }

    return locArray;
  }

  loadResultData(){
    
    this.loading = true;

    this.internalSourcecodesService.getData().subscribe(
      data => {
       
        if (data['data']) {

          this.resultData = this.setDataWithProduct( data['data'] );
          //this.resultData = data['data'];

          for( var i = 0; i < this.resultData.length; i++ ){
            this.resultData[i].initial_effective_date = this.setDateFormat( new Date( this.resultData[i].initial_effective_date ) );
            this.resultData[i].final_effective_date = this.setDateFormat( new Date( this.resultData[i].final_effective_date ) );
            
            var value = this.resultData[i].idOriginalProd;
            let prod = this.productosList.data.find(p=> (p.key??"") == parseInt( value ) );
            if( prod ){
              this.resultData[i].originalProdName = prod.value;
              this.resultData[i].originalProdCod = prod.data.prod;
            }
            
          }
          
          this.setupTable();

        }
        else {
          this.loading = false;
          this.showResult = true;
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title,true);
        }

      },
      error => {
        this.loading = false;
        this.showResult = true;
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title,true);
      }
    );

  }

  private setupTable(){

    this.showResult = true;
    this.loading = false;

    if (this.tablereports != null) {
      this.tablereports.clear().destroy();
    } 
    
    $(document).ready( () => {
        this.tablereports =   $("#result-table").DataTable({

        searching: true, 
        paging: true, 
        
        lengthChange: false,
        pageLength: 10,
        pagingType: 'full_numbers',
        columnDefs: [
          { targets: 'no-sort', orderable: false },
          { width: "70px", targets: 6 }
        ],        
        language: Environment.DataTableLanguageSetting
       });


    });

  }  

  upload(event){
        
    let fileList: FileList = event.target.files;

    if(fileList.length == 0) {      
      this.alertMessage = new AlertModel("Seleccione solo un archivo.", this.title, true, ALERT_TYPE.WARNING);
      return;
    }

    let file: File = fileList[0];


    var extension = file.name.split(".").pop().toLowerCase();
    var extensiones = ["csv","xlsx"];
   
    if( !extensiones.includes(extension) ){
      var extList = "";
      for( var i = 0; i < extensiones.length; i++ ){
        var conc = ( i != 0 ) ? ", " : "";
        extList += conc + "<b>"+extensiones[i]+"</b>";
      }
      this.alertMessage = new AlertModel("Seleccione un archivo con alguna de las siguientes extensiones:<br>"+extList, this.title, true, ALERT_TYPE.WARNING);
      return;
    }else{
      this.loading = true;
 
      this.internalSourcecodesService.import(file).subscribe(
        data => {
          if (data['code']== 0) {
            
            this.loadResultData();
            this.alertMessage = new AlertModel("La carga se procesó con exito: " + data['message'], this.title, true, ALERT_TYPE.SUCCESS);
          }
          else {
            let message  = !Utils.isNullOrEmpty(data['message']) ? "Hubo un inconveniente: " + data['message'] :"Hubo un inconveniente al obtener la información, inténtalo nuevamente.";
            this.alertMessage = new AlertModel(message, this.title, true, ALERT_TYPE.WARNING);
            this.loading = false;
          }
          
        },
        error => {
          console.error(error);
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true, ALERT_TYPE.ERROR);
          this.loading = false;
        }
      );   


    }

    $("#importFile").val('');
  }


  export(){
    
    let reportDataExport = new Array<any>();
    this.resultData.forEach(item => {      
      reportDataExport.push({
        "Canal":item.canal,
        "Ciclo":item.ciclo ,
        "Agencia":item.comercializadora,
        "Delivery 1 ":item.delivery_1,
        "Delivery 2":item.delivery_2,
        "Descripcion":item.descripcion,
        "Estado":item.estado,
        "Ingreso":item.prodDetail.data.income,
        "Producto Final":item.prodDetail.data.prod,//tiene que ir elcampo prod, NO el id
        "Prod MNS Final":item.prodDetail.data.prodMns,
        "Prod Name Final":item.prodDetail.data.prodName,
        "Nro. Promotor":item.idPromotor,
        "SID":item.sid,
        "Source Code":item.source_code,
        "Source Code Completo":item.source_code_completo,
        "Tipo Aplicacion":item.tipo_aplicacion,  
        "Created User":item.created_user,     
        "Created Timestamp": Utils.dateTimeStamp(item.created_timestamp),     
        "Last Updated": Utils.dateTimeStamp(item.last_updated),
        "Fecha vigencia inicial": item.initial_effective_date,
        "Fecha vigencia final": item.final_effective_date   
        ,"Prod Original":item.originalProdCod
        ,"Prod Orig Name": item.originalProdName
        ,"Tipo de Venta": item.tipo_de_venta
      });
    }); 
    if( this.fileType == "C" ){
      this.excelExportService.exportCsvWithSeparator(reportDataExport, this.title,";");
    }else{
      this.excelExportService.exportAsExcelFile(reportDataExport, this.title);
    }
  }
  
  selectUploadType() {
     this.fileType = "E";
    $('#upload-nuevo').modal("show");
  }

  downloadTypeFile(){
    $('#upload-nuevo').modal("hide");
    console.log( this.fileType );
    this.export();
  }

  new(id){
    
    this.entityCurrent = new InternalSourceCodesModel();
    this.showNew = true;
    this.showResult = false;

    if(id == null){
      this.entityCurrent.estado = ""; 
      this.entityCurrent.tipo_de_venta = ""; 
      this.entityCurrent.tipo_aplicacion = "CUSTOMERS"; 
      this.entityCurrent.idProd = 0; 
      this.entityCurrent.idOriginalProd = 0;
      this.entityCurrent.idPromotor = ""; 
      this.titleUser = "Nuevo Sourcecode";
      this.entityCurrent.created_user = this.sessionStorageService.getCurrentUser().name;
      this.entityCurrent.created_timestamp = new Date().getTime().toString();
      this.entityCurrent.last_updated = new Date().getTime().toString();
    } else{
      this.isUpdate = true;
      this.titleUser = "Modificar Sourcecode";
      this.entityCurrent = Object.assign({}, this.resultData.find(m => { return m.id == id}));
      //this.entityCurrent.last_updated = new Date().getTime().toString();
      this.prodChange(this.entityCurrent.idProd+"");
      this.prodOriginalChange( this.entityCurrent.idOriginalProd+"" );
    }

    this.validateShow  =  false;
    this.validateMessage = "";

  }
 
  newCancel(){
    this.isUpdate = false;
    this.showResult = true;
    this.showNew = false;
  }

  prodChange(value:string){
    this.valorProdAnterior = this.entityCurrent.idProd;
    let prod = this.productosList.data.find(p=> (p.key??"") == parseInt( value ) );
  
    if(prod && prod.data){
      this.entityCurrent.idProd = prod.data.id;
      this.entityCurrent.prod = prod.data.prod;
      this.entityCurrent.prod_name = prod.data.prodName;
      this.entityCurrent.prod_mns = prod.data.prodMns;
      this.entityCurrent.ingreso = prod.data.income;
    }else{
      this.entityCurrent.idProd = 0;
    }

  }
  prodOriginalChange(value:string){
    
    this.valorOriginalProdAnterior = this.entityCurrent.idOriginalProd;
    let prod = this.productosList.data.find(p=> (p.key??"") == parseInt( value ) );

    if(prod && prod.data){
      this.entityCurrent.idOriginalProd = prod.data.id;
    }else{
      this.entityCurrent.idOriginalProd = 0;
    }


  }

  promotorChange(value:string){

    let objVendor = this.promotoresList.find(p=> (p.id??"") ==  value );
    this.entityCurrent.comercializadora = ( objVendor ) ? objVendor.name : "";

  }

  agenciaChange(value:string){

    let objVendor = this.promotoresList.find(p=> (p.name??"") ==  value );
    this.entityCurrent.idPromotor = ( objVendor ) ? objVendor.id : "";

  }

  deleteConfirm(source_code_completo){    
    this.entityCurrent =  new InternalSourceCodesModel();
    this.entityCurrent.source_code_completo = source_code_completo;
    this.alertConfirmEvent = this.onConfirmEvent;

    this.alertMessage = new AlertModel("Está seguro de eliminar este Sourcecode?",this.title,true, "CONFIRM");
  }

  onConfirmEvent(confirm:any){

    if(confirm.confirm){
      this.delete();
    }else{
      this.entityCurrent = null;
    }
  }

  delete(){
    this.loading = true;

    let request = {
      "source_code_completo" : this.entityCurrent.source_code_completo
    };


    this.internalSourcecodesService.delete(request).subscribe(
      data => {
        if (data && data["code"] && data["code"] == "0") {
          
          this.loading = false;
          this.entityCurrent = new InternalSourceCodesModel();
          this.alertMessage = new AlertModel("Los datos se guardaron con exito",this.title,true);
          this.loadResultData();
        }
        else {
          this.loading = false;
          console.error(data);
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title,true);
        }
        
      },
      error => {
        this.loading = false;
        console.error(error);
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title,true);
      }
    );    


  }

  castDateShort(date){
    return Utils.isNullOrEmpty(Utils.dateTimeStamp(date)) ? date : Utils.dateTimeStamp(date);
  }
  castDateShortInitial(date){
    const fecha=new Date();
    fecha.setMonth(fecha.getMonth()-4);
    const fechaString=fecha.toLocaleDateString('es-ES')
    if(date==null){
      this.entityCurrent.initial_effective_date=fechaString
    }
    return Utils.isNullOrEmpty(date) ? fechaString : date;
  }

  castDateShortFinal(date){
    const fecha=new Date();
    fecha.setMonth(fecha.getMonth()+4);
    const fechaString=fecha.toLocaleDateString('es-ES')
    if(date==null){
      this.entityCurrent.final_effective_date=fechaString
    }
    return Utils.isNullOrEmpty(date) ? fechaString : date;
  }

  setDateFormat(value:Date){

    return Utils.setDateFormat(value);
 }
 newSave(){   

  if(this.entityCurrent.id == null){
    this.newCreate();
  } else{
    this.newUpdate();
  }
}

getTimesTamp(dateString:string):string{ //le paso una fecha en formato DD/MM/YYYY
  var finalString = "";
  if( dateString != null ){
    var arrayString = dateString.split( "/" );
    var dateToParse = arrayString[2] + "-" + arrayString[1] + "-" + arrayString[0] + " 00:00:00";
  
    finalString = new Date( dateToParse ).getTime().toString();
  }
  return finalString;
}

  newCreate(){    
    
    if(this.verificationDates(this.entityCurrent.initial_effective_date)>this.verificationDates(this.entityCurrent.final_effective_date)){
      this.alertMessage = new AlertModel("La fecha final no puede ser menor que la inicial.",this.title,true,"WARNING");
      this.loading = false;    
      return;
    }
  
    if(this.resultData.some( c=>c.source_code_completo ==this.entityCurrent.source_code_completo )){
      this.alertMessage = new AlertModel("El Source Code que se intenta crear ya existe.",this.title,true,"WARNING");
      this.loading = false;    
      return;
    }
  
    if(!this.newValidate())
        return;    
  
    this.entityCurrent.initial_effective_date = this.getTimesTamp( this.entityCurrent.initial_effective_date );
    this.entityCurrent.final_effective_date = this.getTimesTamp( this.entityCurrent.final_effective_date );
  
    this.loading = true;
    this.internalSourcecodesService.create(this.entityCurrent).subscribe(
      data => {
  
        if (data && data["code"] && data["code"] == "0") {
          this.showNew = false; 
  
          this.entityCurrent=  new InternalSourceCodesModel();
          this.alertMessage = new AlertModel("Los datos se guardaron con exito",this.title,true,"SUCCESS");
          this.loadResultData();
        }
        else if (data && data["code"] && data["code"] == "100") {
          
          this.alertMessage = new AlertModel("Hubo un inconveniente: " + data["message"],this.title,true);
          this.loading = false;    
          return;      
        } else{
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title,true);
          this.loading = false;   
        }
  
      },
      error => {
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title,true);
        this.loading = false; 
      }
    );
    
  }

  verificationDates(dato){
    const[day,month,year]=dato.split("/")
    const date= new Date(year,month-1,day);
    return date
  }
  newUpdate(){      
        
    if(this.verificationDates(this.entityCurrent.initial_effective_date)>this.verificationDates(this.entityCurrent.final_effective_date)){
      this.alertMessage = new AlertModel("La fecha final no puede ser menor que la inicial.",this.title,true);
      this.loading = false;    
      return;
    }

    if(!this.newValidate())
        return;        

    this.entityCurrent.initial_effective_date = this.getTimesTamp( this.entityCurrent.initial_effective_date );
    this.entityCurrent.final_effective_date = this.getTimesTamp( this.entityCurrent.final_effective_date );

    this.loading = true;
    console.log( "this.entityCurrent",this.entityCurrent );
    this.internalSourcecodesService.update(this.entityCurrent).subscribe(
      data => {

        if (data && data["code"] && data["code"] == "0") {
          this.showNew = false;      
          this.isUpdate = false;

          this.entityCurrent=  new InternalSourceCodesModel();
          this.alertMessage = new AlertModel("Los datos se guardaron con exito",this.title,true,"SUCCESS");
          this.loadResultData();
        }
        else if (data && data["code"] && data["code"] == "100") {
          
          this.alertMessage = new AlertModel("Hubo un inconveniente: " + data["message"],this.title,true);
          this.loading = false;    
          return;      
        } else{
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title,true);
          this.loading = false;   
        }

        

      },
      error => {
        console.error(error);
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title,true);
        this.loading = false; 
        this.isUpdate = false;
      }
    );
    
    
  }  


  newValidate():boolean{

    let validations = "";
    let arrayErrors = [];

    if(Utils.isNullOrEmpty(this.entityCurrent.tipo_aplicacion))
      arrayErrors.push( "Debe completar el campo Tipo Aplicación.");

    if(Utils.isNullOrEmpty(this.entityCurrent.idOriginalProd))
      arrayErrors.push( "Seleccione un Producto Inicial.");

    if(Utils.isNullOrEmpty(this.entityCurrent.idProd))
      arrayErrors.push( "Seleccione un Producto Final.");

    if( this.entityCurrent.tipo_de_venta != "SUP" && this.entityCurrent.idProd == this.entityCurrent.idOriginalProd 
        && !Utils.isNullOrEmpty(this.entityCurrent.idProd) )
      arrayErrors.push( "En Producto Incial debe ser distinto al Producto Final.");

    if(Utils.isNullOrEmpty(this.entityCurrent.canal))
      arrayErrors.push( "Debe completar el campo Canal." );

    if(Utils.isNullOrEmpty(this.entityCurrent.descripcion))
      arrayErrors.push( "Debe completar el campo Descripcion." );

    if(Utils.isNullOrEmpty(this.entityCurrent.sid))
      arrayErrors.push( "Debe completar el campo Sid." );   


    if(Utils.isNullOrEmpty(this.entityCurrent.estado))
      arrayErrors.push( "Debe completar el campo Estado." );


    if(Utils.isNullOrEmpty(this.entityCurrent.comercializadora))
      arrayErrors.push( "Debe completar el campo Agencia." );


    if(Utils.isNullOrEmpty(this.entityCurrent.idPromotor))
      arrayErrors.push( "Debe completar el campo Promotor." );

    if(Utils.isNullOrEmpty(this.entityCurrent.ciclo))
      arrayErrors.push( "Debe completar el campo Ciclo." );

      
    if(Utils.isNullOrEmpty(this.entityCurrent.source_code))
      arrayErrors.push( "Debe completar el campo Source Code." );


    if(Utils.isNullOrEmpty(this.entityCurrent.delivery_2))
      arrayErrors.push( "Debe completar el campo Delivery 2." );

    if(Utils.isNullOrEmpty(this.entityCurrent.delivery_1))
      arrayErrors.push( "Debe completar el campo Delivery 1." );



    if( arrayErrors.length ) {

        for( var i = 0; i < arrayErrors.length; i++ ){
          var conc = ( i == 0 )? "" : "<br>";
          validations += conc + arrayErrors[ i ];
        }
  
          this.validateShow  =  true;
          this.validateMessage = validations;
          this.alertMessage = new AlertModel(this.validateMessage,this.title,true,"WARNING");
          return false;
    }
    
    this.validateShow  =  false;
    this.validateMessage = "";
    return true;
  }

  completeSourceCode(){

    if( typeof this.entityCurrent.id == "undefined" ){
    
      var valueChannel = $( "#sc-channel" ).val();
      var valueSc = $( "#sc" ).val();
      $( "#sc-completo" ).val( valueChannel + "" + valueSc );
      this.entityCurrent.source_code_completo = valueChannel + "" + valueSc;

    }

  }

}
import { Injectable } from '@angular/core';
import * as moment from 'moment';
const { version: appVersion } = require('../../../package.json')

@Injectable()
export class Utils {

    public static storageSave(name:string, data:any){
        let key = name + "#" + appVersion;
        sessionStorage.setItem(key, JSON.stringify(data));
        
    }

    public static storageLoad(name:string):any{
        let key = name + "#" + appVersion;
        var storagedata = sessionStorage.getItem(key);
        return storagedata ?  JSON.parse(storagedata) : null;
    }

    public static storageClear(name:string):any{
        sessionStorage.clear();
    }


    public static isNullOrEmpty(value:any):boolean{
  
        return value == null || value == "";
    }

    public static parseEmptyNull(value:any):string{
  
        return  Utils.isNullOrEmpty(value) ? null : value;
    }

    public static dateStringToMillisenconds(date:string){

        return new Date(Utils.dateParse(date)).getTime();
    }
    
    public static  datetimeStringToMillisenconds(date:string){

        return new Date(Utils.dateParse(date, "DD/MM/YYYY HH:mm")).getTime();
    }
       
    public static datetimeToString(value: Date):string{

        let result = null as string;

        try{

            result = moment(value).format("DD/MM/YYYY HH:mm");
            

        }catch{}

        return result;            
        
    }

    public static dateToString(value: Date){

        let result = null;

        try{

            result = moment(value).format("DD/MM/YYYY");
            

        }catch{}

        return result;            
      
    }

    public static dateIsValid(value: string){

        let result = false;

        if(Utils.isNullOrEmpty(value))
           return result;
        
        try{

            let f = ["DD/MM/YYYY", "D/M/YYYY"]
            result = moment(value, f, true).isValid();
            

        }catch{}

        return result;            
      
    }

    public static dateIsValidFormat(value: string, format: string){

        let result = null;
        
        if(Utils.isNullOrEmpty(value))
            return result;

        try{

            result = moment(value, format, true).isValid();
            

        }catch{}

        return result;            
      
    }

    public static dateParse(value: string, format: string = null){

        let result = null;

        try{

            if(Utils.isNullOrEmpty(format))
                result = moment(value, "DD/MM/YYYY");
            else
                result = moment(value, format);
            

        }catch{}

        return result;            
      
    }

    public static dateDiffYears(value: string){

            let result = 0;

            try{
    
                let b = moment(value, "DD/MM/YYYY");
                result = moment().diff(b, 'years');
    
            }catch{}
    
            return result;            
          
    }

    public static dateDiffBetween(from: string, to: string, unitOfTime: any) {

        let result = 0;

        try{
            let a = moment(from, "DD/MM/YYYY");
            let b = moment(to, "DD/MM/YYYY");

            result = moment(b).diff(a, unitOfTime);

        }catch{}

        return result;
      
    }

    public static dateDiff  (value: string, unitOfTime: any) {

        let result = 0;

        try{

            let b = moment(value, "DD/MM/YYYY");
            result = moment().diff(b, unitOfTime);

        }catch{}

        return result;
      
    }

    public static dateDiffFormat  (value: string, unitOfTime: any, format: string) {

        let result = 0;

        try{

            let b = moment(value, format);
            result = moment().diff(b, unitOfTime);

        }catch{}

        return result;
      
    }


    public static dateTimeStamp (value: string) {

        let result = "";

        try{

            let d = new Date(parseInt(value));
            
            result =  moment(d).format("DD/MM/YYYY");


        }catch{}

        return result;
      
    }

   
    public static datetimeToStringFlat(value: Date):string{

        let result = null as string;

        try{

            result = moment(value).format("DD_MM_YYYY_HH_mm");
            

        }catch{}

        return result;            
        
    }

    public static setDateFormat(value:Date){
        let result = null;
    
        try{

          let day = value.getDate();
          let month = (value.getMonth() + 1)
          let year = value.getFullYear()
      
          let daytr = day <= 9 ? '0' + day : day;
          let monthstr = month <= 9 ? '0' + month : month;
          result = daytr + "/" + monthstr + "/" + year;
    
        }catch{}

        return result;
    
    }

      public static formatDateToCustomFormat(value:string){

        let result = "";
    
        try{
            let splitDate = value.split("/")
            result = splitDate[2] + "-" + splitDate[1] + "-" + splitDate[0] + " 00:00:00.000";
    
        }catch{}

        return result;
    
      }

      public static setDateNewFormat (value: string) {

        let result = "";


        try{
        
            let d = new Date(value);
            
          let day = d.getDate();
          let month = (d.getMonth() + 1)
          let year = d.getFullYear()
      
          let daytr = day <= 9 ? '0' + day : day;
          let monthstr = month <= 9 ? '0' + month : month;
          result = daytr + "/" + monthstr + "/" + year;


        }catch{}
        
        return result;
      
    }



    public static urlCSS(url:any){

        let result = null;
    
        try{
    
            result = String(url).replace(/[^\w. \:\/\-]/gi, function(c){
                return "";
             });
        }catch{}
    
    
    
        return result;
    
      }

    public static setAccionListSuples( data ):Array<any>{

        let arrAccionListSuple = [];
        // let arrayDenied = [ "DENEGADO","CANCELADO","NO VENTA","NO CONTACTADO" ];
        let arrayDenied = [ "DENEGADO","NO VENTA","NO CONTACTADO" ];
        for( var i = 0; i < arrayDenied.length; i++ ){
          for( var j = 0; j < data.length; j++ ){
            if( data[ j ].value == arrayDenied[ i ] ){
              arrAccionListSuple.push( data[ j ] );
            }
          }
        }
        return arrAccionListSuple;
      }

    public static validTextField(text:any):boolean{
        //return value == null || value == "";
        var regex = /^([a-zA-Z\sÁÉÍÓÚáéíóúÑñ]+)$/;
    	if( text == null || text == "" || !regex.test(text) || text.indexOf( "  " ) != -1 ){
    		return false;
    	}else{
    		return true;
    	}

    }

    public static validTextFieldNombreTarjeta(text:any):boolean{
        //return value == null || value == "";
        var regex = /^([a-zA-Z\sÁÉÍÓÚáéíóúÑñ']+)$/;
    	if( text == null || text == "" || !regex.test(text) || text.indexOf( "  " ) != -1 ){
    		return false;
    	}else{
    		return true;
    	}

    }

    public static validSpecialCharactersWithoutNumbers(text:any):boolean{
        var regex = /^([^\\;"]+)$/;
        if( text == null || text == "" || /\d/.test(text) || !regex.test(text) || text.indexOf( "  " ) != -1 ){
            return false;
        } else {
            return true;
        }
    }

    public static validSpecialCharactersWithNumbers(text:any):boolean{
        var regex = /^([^\\;"]+)$/;
        if( text == null || text == "" || !regex.test(text)){
            return false;
        } else {
            return true;
        }
    }

    public static getTimesTamp(dateString:string):string{ //le paso una fecha en formato DD/MM/YYYY
        var finalString = "";
        if( dateString != null ){
          var arrayString = dateString.split( "/" );
          var dateToParse = arrayString[2] + "-" + arrayString[1] + "-" + arrayString[0] + " 00:00:00";
        
          finalString = new Date( dateToParse ).getTime().toString();
        }
        return finalString;
      }

    public static setCookie(cname, cvalue, exdays) {
        var d = new Date();
        if( exdays != null ){
            d.setTime(d.getTime() + (exdays*24*60*60*1000));
            var expires = "expires="+ d.toUTCString();
            document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        }else{
            document.cookie = cname + "=" + cvalue +  ";path=/";
        }
    }

    public static getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for(var i = 0; i <ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    public static compareTwoDates(dateTimeA, dateTimeB) {
        var momentA = moment(dateTimeA,"DD/MM/YYYY");
        var momentB = moment(dateTimeB,"DD/MM/YYYY");
        if (momentA > momentB) return 1;
        else if (momentA < momentB) return -1;
        else return 0;
    }

    public static dateForm(date: string): number {
        const [day, month, year] = date.split('/').map(Number);
        return new Date(year, month - 1, day).getTime();
    }

}
import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError, of, BehaviorSubject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { Environment } from 'src/app/config/environment';
import { SessionStorageService } from '../services/session.service';
import { Session } from '../models/session.model';
import { Config } from 'src/app/config/config';
import { Utils } from 'src/app/commons/utils';


@Injectable({
    providedIn: 'root'
  })
export class InterceptorGuard implements HttpInterceptor {

    public isTestUnit = false;

    public httpErroBehavior: BehaviorSubject<HttpErrorResponse>;
    
    constructor(private router: Router,
                private Conf: Config,
                private sessionStorageService: SessionStorageService) { 
                
                    this.httpErroBehavior = new BehaviorSubject<HttpErrorResponse>(null);
                    this.sessionStorageService.sessionLogoutBehavior.subscribe((session: Session)  => {
        
                        if(!Environment.IDP)
                           return;
                        
                        if(session && session.logout){
                          
                           // this.redirectToIDP();
                            this.sessionStorageService.redirectToLogin();
        
                        } 
                         
                      });
                }

     handleAuthError(err: HttpErrorResponse): Observable<any> {
        if(!Environment.IDP)
            return throwError(err);

        if(err.error.status === 403){            
            this.httpErroBehavior.next(err);
        }

        //handle your auth error or rethrow
        if (err.error.status === 401) {            
            // Page to redirect after a successfully log in
            // Use whatever strategy to set this value
            let idpData = {
                "endpointIdp" : err.error.redirectUrl,
                "clientId" : err.error.applicationId
            };

            this.sessionStorageService.clearStorageSession();
            this.sessionStorageService.setIdp(idpData);
                     

            //this.redirectToIDP();
            this.sessionStorageService.redirectToLogin();


            // if you've caught / handled the error, you don't want to rethrow it unless you also want downstream consumers to have to handle it as well.
            return of(err.message); // or EMPTY may be appropriate here
        }

        return throwError(err);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // The withCredentials: true flag is needed to send the cookies in the request to the backend
        const authReq = req.clone({ headers: req.headers, withCredentials: true });

        return next.handle(authReq).pipe(catchError(x => this.handleAuthError(x)));
    }

   public redirectToIDP(){

            // Page to redirect after a successfully log in
            // Use whatever strategy to set this value

            let idpData =  this.sessionStorageService.getIdp();
            let redirectUrl = Utils.urlCSS(window.location.origin);

            
            const uuid = uuidv4();

            // Send to idp values of your page that you need to show after a log in is successfully
            const currentPageStateValues = uuid;

            // A random value is needed for the backend of idp for your log in session
            const nonce = uuid;

            // Id of your application in the IdP platform
            // The applicationId is sent as a clientId for the Oauth ceremony            
            // ***THIS IS NOT THE MATRIX OF THE CLIENT***

            const clientId = Utils.urlCSS(idpData.clientId);
            const endpointIdp = Utils.urlCSS(idpData.endpointIdp);
            
            // Redirect to the IdP log in service.
            // (you can show some sort of alert that the session expired / not are log in, and redirect with a timer/submit)
            // `${err.error.redirectUrl}?

            if(this.isTestUnit)
                return;

            window.location.href = `${endpointIdp}/?client_id=${clientId}&redirect_uri=${redirectUrl}` +
                `&state=${currentPageStateValues}&nonce=${nonce}&response_type=id_token`;

    }
}
import { Injectable } from '@angular/core';
import { Environment } from './environment';
import { ConfigLocal } from './config-local';
import { ConfigTest } from './config-test';
import { ConfigProd } from './config-prod';

@Injectable()
export class Config {
     
    public ENDPOINT_API: any; 
    

    constructor(
                private _ConfigLocal: ConfigLocal,
                private _ConfigTest: ConfigTest,
                private _ConfigProd: ConfigProd) {

        if(window.location.origin.includes("myapp."))
           Environment.ENV = "local";

        console.log(Environment.ENV);

        switch(Environment.ENV){
            case 'local':
                
                    this.ENDPOINT_API = _ConfigLocal.ENDPOINT_API;               
                    
            break;
            case 'test': 
                this.ENDPOINT_API = _ConfigTest.ENDPOINT_API;              
            break;
            case 'prod':
                    this.ENDPOINT_API = _ConfigProd.ENDPOINT_API;  
            break;
        }

        this.setupDataTableLanguageSetting();
        this.setupCalendarLanguageSetting();
     }


     setupDataTableLanguageSetting(){

        Environment.DataTableLanguageSetting =  {
                processing: "Procesando...",
                search: "Busqueda global:",
                lengthMenu: "Mostrar _MENU_ &eacute;l&eacute;ments",
                info: "Página _PAGE_ de _PAGES_",
                infoEmpty: "Mostrando ningún elemento.",
                loadingRecords: "Cargando registros...",
                zeroRecords: "No se encontraron registros.",
                emptyTable: "No hay datos disponibles en la tabla.",
                paginate: {
                  first: '<i class="fa fa-fast-backward" aria-hidden="true"></i>',
                  previous: '<i class="fa fa-step-backward"></i>',
                  next: '<i class="fa fa-step-forward"></i>',
                  last: '<i class="fa fa-fast-forward" aria-hidden="true"></i>'
                }
        };
     }

     setupCalendarLanguageSetting(){

        Environment.CalendarLanguageSetting =  {
                firstDayOfWeek: 0,
                dayNames: ["Domingo", "Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sábado"],
                dayNamesShort: ["Dom", "Lun", "Mar", "Mir", "Juv", "Vir", "Sab"],
                dayNamesMin: ["Do","Lu","Ma","Mi","Ju","Vi","Sa"],
                monthNames: [ "Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre" ],
                monthNamesShort: [ "Ene", "Feb", "Mar", "Abr", "May", "Jun","Jul", "Ago", "Sep", "Oct", "Nov", "Dic" ],
                today: 'Hoy',
                clear: 'Limpiar',
                dateFormat: 'dd/mm/yy',
                weekHeader: 'Wk'
            };
            
       }     

}
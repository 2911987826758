export class UserModel {

    userName: string;
    fullName: string;
    active: boolean;
    customerId: string;
    email: string;
    password: string;
    newUserName: string;
    newCustomerId: string;
    mfa: number;
    mfaActive: boolean;
    mfaTipo: number;
    btb: boolean;
    roles: UserRolesModel[];
}

export class UserRolesModel{
    idRol: string;
    nombre: string;
}

export class Matrix{
    nombre: string;
}

export class BusquedaFiltrosModel {
    userName: string;
    fullName: string;
    email: string;
    matriz: string;
    active: string;
}

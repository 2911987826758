export class User {
    constructor(){
        this.roles = new Array<string>();
        this.acciones = new Array<string>();
    }
    
    public acciones: Array<string>;
    public agencia: string;
    public promotor: string;
    public matriz: string;
    public name: string;
    public roles: Array<string>;
    public email: string;
    public username: string;   
    public customerId: string; 
}


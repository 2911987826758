import { Injectable } from '@angular/core';
import * as $ from 'jquery';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Environment } from '../../config/environment';
import { Config } from '../../config/config';
import { config } from 'process';
import { of } from 'rxjs';
import { MockStatic } from 'src/app/config/mock.static';


@Injectable({
  providedIn: 'root'
})
export class InternalNewAccountsService {


    constructor(private http: HttpClient, private Conf: Config) { }

    public getApplicantsReportByArea(data){

      let url = this.Conf.ENDPOINT_API + 'internal/getIntPersonaByArea';
      let request = Object.assign({}, data);
      let headers = new HttpHeaders({
        'Content-Type': 'application/json',
      })
      let wc = (Environment.CREDENTIALS) ? true : false;

      request.area = "NEWACCOUNT";

     return this.http.post(url, request, {headers: headers, observe: "response",withCredentials: wc}) 

    }

    public getAplicanteDetalle(data:any){

      let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }



      let url = this.Conf.ENDPOINT_API + 'internal/getApplicantDataByArea';
      let request = Object.assign({}, data);

      return this.http.post(url, request, httpOptions);        

    }

    public getApplicantReevaluation(data:any) {

      let url = this.Conf.ENDPOINT_API + 'internal/getApplicantReevaluation';
      let request = Object.assign({}, data);
      let headers = new HttpHeaders({
        'Content-Type': 'application/json',
      })
      let wc = (Environment.CREDENTIALS) ? true : false;
      
      return this.http.post(url, request, {headers: headers, observe: "response",withCredentials: wc})     
      
    }    

    public saveApplicantDataByArea(data:any){    
      

      
      let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }



      let url = this.Conf.ENDPOINT_API + 'internal/saveApplicantDataByArea';
      let request = Object.assign({}, data);
      request.area = "NEWACCOUNT";

      return this.http.post(url, request, httpOptions);        

    }

    public getValidationAccion(){

      let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }



      let url = this.Conf.ENDPOINT_API + 'internal/getValidationAccion';
      let request = {"area": "NEWACCOUNT"};


      return this.http.post(url, request, httpOptions);         

    }

    public getValidationMotivo(data:any){
      
      let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }

      let url = this.Conf.ENDPOINT_API + 'internal/getValidationMotivo';
      let request = Object.assign({}, data);

      request.area = "NEWACCOUNT";

      return this.http.post(url, request, httpOptions);        

    }

    public getApplicantRecalculation(data:any) {


      
      let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }

      let url = this.Conf.ENDPOINT_API + 'internal/recalculate';
      let request = Object.assign({}, data);
      

      return this.http.post(url, request, httpOptions);        

    }

    public editIntPersona(data){

      let url = this.Conf.ENDPOINT_API + 'internal/saveApplicantDataByArea';
      let request = Object.assign({}, data);
      let headers = new HttpHeaders({
        'Content-Type': 'application/json',
      })
      let wc = (Environment.CREDENTIALS) ? true : false;

      return this.http.post(url, request, {headers: headers, observe: "response",withCredentials: wc})
        
    }

    public getListProvince(){

      let url = this.Conf.ENDPOINT_API + 'getProvincias';
      let headers = new HttpHeaders()
      let wc = (Environment.CREDENTIALS) ? true : false;
      
      return this.http.get(url, {observe: "response",withCredentials: wc})

    }


}
//DATA LIST
export class ValidationAccionListModel {

    constructor(){
        this.data = new  Array<ValidationAccionModel>();
    }

    data: Array<any>;
}

export class ValidationAccionModel {

    area: string;
    estado: string;
    subestado: string;
    nextStep: string;
}
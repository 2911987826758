import * as internal from "stream";

export class ParametersModel {
    
    id: number;
    nombre: string;
    descripcion: string;
    codigo: string;
    valor: string;  

    label: string;  
    maxlenght: string;
    regex: string;  
    regexmessage: string;  
    type: string;  
    activo:boolean;  

}



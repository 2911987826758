export class BusquedaFiltrosModel {
  fechaDesde: string;
  fechaHasta: string;
}

export class SourceCodeModel {
  canal:string;
  ciclo:string;
  comercializadora:string;
  delivery1:string;
  delivery2:string;
  descripcion:string;
  idOriginalProd:string;
  idProd:string;
  nombreProducto:string;
  prod:string;
  promotor:string;
  sourceCode:string;
  sourceCodeCompleto:string;
  subChannel:string;
}
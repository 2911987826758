import { Injectable } from '@angular/core';
import * as $ from 'jquery';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Environment } from '../../config/environment';
import { Config } from '../../config/config';
import { config } from 'process';
import { of , throwError} from 'rxjs';
import { MockStatic } from 'src/app/config/mock.static';
import { map, catchError, retry } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class InternalChangeStatusService {


    constructor(private http: HttpClient, private Conf: Config) { }

    public getApplicantsReportByArea(data){

      let url = this.Conf.ENDPOINT_API + 'internal/getIntPersonaByArea';
      let request = Object.assign({}, data);
      let headers = new HttpHeaders({
        'Content-Type': 'application/json',
      })
      let wc = (Environment.CREDENTIALS) ? true : false;

      request.area = "FINISH";

     return this.http.post(url, request, {headers: headers, observe: "response",withCredentials: wc}) 

    }

    public getAplicanteDetalle(data:any){

      let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }



      let url = this.Conf.ENDPOINT_API + 'internal/getApplicantDataByArea';
      let request = Object.assign({}, data);

      return this.http.post(url, request, httpOptions);        

    }

    public getApplicantReevaluation(data:any) {

      let url = this.Conf.ENDPOINT_API + 'internal/getApplicantReevaluation';
      let request = Object.assign({}, data);
      let headers = new HttpHeaders({
        'Content-Type': 'application/json',
      })
      let wc = (Environment.CREDENTIALS) ? true : false;
      
      return this.http.post(url, request, {headers: headers, observe: "response",withCredentials: wc})     
      
    }    

    public saveApplicantDataByArea(data:any){    
      

      
      let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }



      let url = this.Conf.ENDPOINT_API + 'internal/saveApplicantDataByArea';
      let request = Object.assign({}, data);
      request.area = "NEWACCOUNT";

      return this.http.post(url, request, httpOptions);        

    }

    public getValidationAccion(){

      let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }



      let url = this.Conf.ENDPOINT_API + 'internal/getValidationAccion';
      let request = {"area": "NEWACCOUNT"};


      return this.http.post(url, request, httpOptions);         

    }

    public getValidationMotivo(data:any){
      
      let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }

      let url = this.Conf.ENDPOINT_API + 'internal/getValidationMotivo';
      let request = Object.assign({}, data);

      request.area = "NEWACCOUNT";

      return this.http.post(url, request, httpOptions);        

    }

    public editIntPersona(data){

      let url = this.Conf.ENDPOINT_API + 'internal/changeStatus';
      let request = Object.assign({}, data);
      let headers = new HttpHeaders({
        'Content-Type': 'application/json',
      })
      let wc = (Environment.CREDENTIALS) ? true : false;

      return this.http.post(url, request, {headers: headers, observe: "response",withCredentials: wc})
        
    }


    public getAllPromotores(){

      let url = this.Conf.ENDPOINT_API + 'getPromotores';
      let headers = new HttpHeaders()
      let wc = (Environment.CREDENTIALS) ? true : false;
      
      return this.http.get(url,  {observe: "response",withCredentials: wc})
        
    }

    
    getValidationAccionAll(){

      let httpOptions = {
        withCredentials: false
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }

      let url = this.Conf.ENDPOINT_API + 'internal/getValidationAccionAll';  

      return this.http.get(url, httpOptions) .pipe(
        map( response => {  // NOTE: response is of type SomeType

            return response; // kind of useless
        }),
        catchError(e => throwError(e))
     ); // end of pipe; 
      
      // let url = this.Conf.ENDPOINT_API + 'internal/getValidationAccionAll';
      // let headers = new HttpHeaders()
      // let wc = (Environment.CREDENTIALS) ? true : false;
      
      // return this.http.get(url,  {observe: "response",withCredentials: wc})
    }


}
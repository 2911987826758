import { Component,EventEmitter,Input, OnChanges, OnInit, Optional, Output } from '@angular/core';
import { SimpleChanges } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})


export class AlertComponent implements  OnChanges {
  @Input() message: any;
  @Output() confirmEvent: EventEmitter<ConfirmEvent> = new EventEmitter<ConfirmEvent>();
  
  messageInternal:  AlertModel = new AlertModel();
  messageShow: boolean = false;
  confirmShow: boolean = false;
  alertClass: string = "alert-primary";

  //Para este caso hay que pasar toda la clase como New AlertModel
  ngOnChanges(changes: SimpleChanges) {

   
    if(changes["message"].currentValue)
        Object.assign(this.messageInternal, changes["message"].currentValue);


    
    if(this.messageInternal.type == "CONFIRM") {
      this.confirmShow = true;
      this.messageShow = false;
      if(this.messageInternal.show) {
          $("#confirm-modal").modal("show")
      } else{
          $("#confirm-modal").modal("hide")
      }
    }else{       
      this.confirmShow = false;
      this.messageShow = true;
      
      
      if(this.messageInternal.type == "INFO")
        this.alertClass = "alert-primary";

      if(this.messageInternal.type == "WARNING")
        this.alertClass = "alert-warning";

      if(this.messageInternal.type == "SUCCESS")
        this.alertClass = "alert-success";

      if(this.messageInternal.type == "ERROR")
        this.alertClass = "alert-danger";


      if(this.messageInternal.show) {
          $("#message-modal").modal("show")
      } else{
          $("#message-modal").modal("hide")
      }
    }


    return true;
  }

  onConfirmYes(){
    let confirm = new ConfirmEvent(true, this.messageInternal.data);
    this.confirmEvent.emit(confirm);
    return true;
  }

  onConfirmNo(){
    let confirm = new ConfirmEvent(false, this.messageInternal.data);
    this.confirmEvent.emit(confirm);
    return true;
  }

  close(){
    return true;
  }
}

export class AlertModel {
  type: string;
  message: string;
  title: string;
  show: boolean;
  data: any;
  cancel: boolean = true;
  
  constructor( @Optional() message?, @Optional() title?, @Optional() show?, @Optional() type?, @Optional() data?, @Optional() cancel?) {

    this.message = message;
    this.title = title;
    this.show = show;
    this.type = type;
    this.data = data;
    this.cancel = cancel??true;

  }

}
export class ConfirmEvent {

  constructor( @Optional() confirm?, @Optional() data?){
      this.confirm = confirm;
      this.data = data;
  }  
  confirm: boolean;
  data: any;
}




export enum ALERT_TYPE {
  INFO = "INFO",
  WARNING = "WARNING",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  CONFIRM = "CONFIRM",
}  
import { Component, ElementRef, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import * as _ from 'lodash';
import { Environment } from '../../config/environment';

import { ExcelFilesService } from 'src/app/services/excelFiles/excelFiles.service';
import { AlertModel, ALERT_TYPE } from '../shared/alert/alert.component';
import { BaseComponent } from '../shared/base.component';

@Component({
  selector: 'app-exelFile',
  templateUrl: './excelFile.component.html',
  styleUrls: ['./excelFile.component.css']
})

export class ExcelFileComponent extends BaseComponent implements OnInit{

  public title = "Administrar Archivos";
  public loading: boolean;
  public displayError: boolean;
  public msgError: string;
  public displayCombo: boolean;
  public showBackButton: any = Environment.SHOW_BACK_BUTTON;
  public alertMessage: AlertModel = new AlertModel;

  public table = null;
  public files = [];

  constructor(private excelFilesService: ExcelFilesService) { 
    super();
    this.loading = true;
    this.displayError = false;
  }

  /**
   * On initialization, example catalog data should be retrieved if needed.
   * Default catalog data will apply otherwise.
   */
  ngOnInit(): void {

    this.loading = false;
    this.displayError = false;
    this.msgError = null;

    this.loadTable();
  }



  public loadTable(){
    this.loading = true;
    
    this.excelFilesService.getListIngresoArchivo().subscribe(
      data => {
        if (data['code']== 0) {
          this.files = data['response'];  
          this.loading = false;
          this.setupTable();            
 
        }
        else {
          console.error("Error");
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true, ALERT_TYPE.ERROR);
        }
        this.loading = false;
      },
      error => {
        console.error("Error");
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true, ALERT_TYPE.ERROR);
        this.loading = false;
      }
    );   
}



  public downloadFile(name){

    this.loading = true;
    let request = {"name" : name};

    this.excelFilesService.aprobacionMasivaFileDownload(request).subscribe(
      data => {

        if (data) {
          
          const blob = new Blob([data], { type: 'text/csv' });
          const url = window.URL.createObjectURL(blob);
          
          var filelink = document.createElement("a");
          filelink.download = name;
          filelink.href = url;
          filelink.click();
          
        }
        else {
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true, ALERT_TYPE.ERROR);
        }

        this.loading = false;
      },
      error => {
        console.error(error);
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true, ALERT_TYPE.ERROR);
        this.loading = false;
      }
    );

  }

  setupTable(){

    if (this.table != null) {
      this.table.clear().destroy();
    } 
  

    $(document).ready( () => {
      this.table =   $("#main-table").DataTable({
                  paging: true, 
                  lengthChange: false,
                  pageLength: 10,
                  pagingType: 'full_numbers',
                  columnDefs: [
                    { targets: 'no-sort', orderable: false }
                  ],        
                  language: Environment.DataTableLanguageSetting
                });
     });

     this.loading = false;
}
}

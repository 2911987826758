import { AbstractType, Component, EventEmitter, OnInit } from '@angular/core';
import { LoadingComponent } from '../shared/loading/loading.component';
import { AlertComponent, AlertModel } from '../shared/alert/alert.component';



import { RangosIngresoService } from '../../services/rangosIngreso/rangosIngreso.service';
import { RangosIngresoModel } from './models/models';

import { ɵAnimationGroupPlayer } from '@angular/animations';
import { Environment } from 'src/app/config/environment';
import { ReturnStatement } from '@angular/compiler';
//import { FnParam, importType } from '@angular/compiler/src/output/output_ast';
import { Utils } from 'src/app/commons/utils';
import { utils } from 'protractor';
import { SessionStorageService } from 'src/app/auth/services/session.service';


declare var $ : any;


@Component({
  selector: 'app-rangos-ingreso',
  templateUrl: './rangosIngreso.component.html',
  styleUrls: ['./rangosIngreso.component.css']
})
export class RangosIngresoComponent implements OnInit {

  public title: string = "Administrador de Rangos de Ingreso";
  public titleUser: string;
  public es: any;
  public showResult : boolean;
  public tablereports = null;
  public showNew : boolean; 
  public entityCurrent: RangosIngresoModel = new RangosIngresoModel;
  
  public loading: boolean;
  public alertMessage: AlertModel = new AlertModel;
  public alertConfirmEvent: Function;

  public validateShow: boolean =  false;
  public validateMessage: string;



  public resultData = [];
  


  constructor(private rangosIngresoService: RangosIngresoService,
    private sessionStorageService: SessionStorageService) { 
    this.sessionStorageService.rolValidate("config");
  }

  ngOnInit() {
    this.setCalendarLocale();
    this.loadResultData();   

  }

  loadResultData(){
    
    this.loading = true;

    this.rangosIngresoService.getData().subscribe(
      data => {
        if (data['data']) {
          this.resultData = data['data'];

          this.setupTable();             

        }
        else {
          console.error(data);
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title,true);
        }
      },
      error => {
        console.error(error);
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title,true);
      }
    );

  }

  onConfirmEvent(confirm:any){

    if(confirm.confirm){
      this.delete();
    }else{
      this.entityCurrent = null;
    }
  }

  delete(){
      this.loading = true;

      let request = {
        "id" : this.entityCurrent.id
      };


      this.rangosIngresoService.disable(request).subscribe(
        data => {
          if (data) {
            this.loading = false;
  
            this.entityCurrent = new RangosIngresoModel();
            this.alertMessage = new AlertModel("Los datos se guardaron con exito",this.title,true);
            this.loadResultData();
          }
          else {
            console.error(data);
            this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title,true);
          }
        },
        error => {
          console.error(error);
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title,true);
        }
      );    




  }

  deleteConfirm(itemId){    
    this.entityCurrent =  new RangosIngresoModel();
    this.entityCurrent.id = itemId;
    this.alertConfirmEvent = this.onConfirmEvent;

    this.alertMessage = new AlertModel("Está seguro de eliminar este Rangos de Ingreso?",this.title,true, "CONFIRM");
  }

  new(id){
    
    this.entityCurrent = new RangosIngresoModel();
    this.showNew = true;

    if(id == null){
      this.titleUser = "Nuevo Rangos de Ingreso";
    } else{
      this.titleUser = "Modificar Rangos de Ingreso";
      this.entityCurrent = this.resultData.find(m => { return m.id == id});
    }

    this.validateShow  =  false;
    this.validateMessage = "";

  }
 
  newCancel(){

    this.showNew = false;
  }

  newSave(){   

    if(!this.newValidate())
        return;

    this.loading = true;

    this.rangosIngresoService.create(this.entityCurrent).subscribe(
      data => {
        if (data) {
          this.showNew = false;      
          this.loading = false;

          $('#new-user').modal('hide');

          this.entityCurrent = new  RangosIngresoModel();
          this.alertMessage = new AlertModel("Los datos se guardaron con exito",this.title,true);
        }
        else {
          console.error(data);
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title,true);
        }
      },
      error => {
        console.error(error);
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title,true);
      }
    );
    
    
  }


  newValidate():boolean{

    let validations = "";
    
    
    if(Utils.isNullOrEmpty(this.entityCurrent.nse))
        validations+="Debe completar el campo NSE. "

    if(Utils.isNullOrEmpty(this.entityCurrent.avg))
        validations+="Debe completar el campo AVG. "
   

    if(validations != "") {
        this.validateShow  =  true;
        this.validateMessage = validations;

        return false;
      }

    this.validateShow  =  false;
    this.validateMessage = "";
    return true;
  }

  backToResults(){
    this.showResult = true;
    this.loading = false;

  }

  setDateFormat(value:Date){

     return Utils.setDateFormat(value);
  }

  setupTable(){

    this.showResult = true;
    this.loading = false;

    if (this.tablereports != null) {
      this.tablereports.destroy();
    } 
    
    $(document).ready( () => {
        this.tablereports =   $("#result-table").DataTable({

        searching: false, 
        paging: true, 
        //info: false,
        lengthChange: false,
        pageLength: 10,
        pagingType: 'full_numbers',
        columnDefs: [
          { targets: 'no-sort', orderable: false }
        ],        
        language: Environment.DataTableLanguageSetting
       });


    });

  }  

  setCalendarLocale(){

    this.es = Environment.CalendarLanguageSetting;
  }


}

import { Component, OnInit } from '@angular/core';
import { BusquedaFiltrosModel, AplicantDetailModel } from './models/models';

import { AplicantModel, DatosPersonalesModel, 
  DatosContactoModel, DatosLaboralesModel,
  DireccionModel, TelefonoModel, ObservacionesModel,
  EntregaDocumentacionModel, ObservacionesTackingModel, 
  ValidacionModel, AplicanteAdicionalModel,
 } from 'src/app/models/aplicante.model';

import { LoadingComponent } from '../shared/loading/loading.component';
import { AlertComponent, AlertModel, ALERT_TYPE } from '../shared/alert/alert.component';
import { Environment } from 'src/app/config/environment';
import { CommonDataService } from 'src/app/services/commons/commondata.service';
import { Utils } from 'src/app/commons/utils';
import { SessionStorageService } from 'src/app/auth/services/session.service';
import { ReprocessApplicantService } from 'src/app/services/reprocessApplicant/reprocessApplicant.service';
import { GenerosList } from 'src/app/models/lists.model';


@Component({
  selector: 'app-reprocessapplicant',
  templateUrl: './reprocessApplicant.component.html',
  styleUrls: ['./reprocessApplicant.component.css']
})


export class ReprocessApplicantComponent  {
  public es: any;
  public title: string = "REPROCESAR APLICANTE";
  public loading: boolean;

  public alertMessage: AlertModel = new AlertModel;
  public alertConfirmEvent: Function;

  public dataResult: AplicantDetailModel = new AplicantDetailModel;
  public showResult : boolean;
  public tablereports = null;

  public busquedafiltros: BusquedaFiltrosModel = new BusquedaFiltrosModel; 

  public isCuil : boolean = false;

  constructor(private reprocessApplicantService: ReprocessApplicantService,
              private sessionStorageService: SessionStorageService,
              private commonDataService: CommonDataService) { 

            if (!(this.sessionStorageService.hasRolesUser(["AMX_ADMIN"]) || 
                this.sessionStorageService.hasRolesUser(["AMX_RIESGO_ADM"])))
                 this.sessionStorageService.redirectToLogin();
   }

 
  loadResults(){   

    if(Utils.isNullOrEmpty(this.busquedafiltros.pcn) && 
       Utils.isNullOrEmpty(this.busquedafiltros.dni) &&
       Utils.isNullOrEmpty(this.busquedafiltros.nombre)) {
      
      this.alertMessage = new AlertModel( "Se requiere completar al menos un filtro.",this.title, true);
      return;
    }


    this.showResult = false;
    this.loading = true;
    this.loadResultsRequest();
    
  }

  loadResultsRequest(){

    
    let request = { 
      "pcn": Utils.parseEmptyNull(this.busquedafiltros.pcn),
      "dni": Utils.parseEmptyNull(this.busquedafiltros.dni),
      "nombreapellido": Utils.parseEmptyNull(this.busquedafiltros.nombre),
    };         

  this.reprocessApplicantService.getData(request).subscribe(
    data => {
      if (data['data'] && data['code'] == "0") {
 
        this.dataResult = data['data'][0];
        this.dataResult.domicilio  = this.dataResult.domicilio.replace(/null/gi, "").trim();

        this.showResult = true;
        this.loading = false;          

      }else if(data['code'] == "1" ){
        this.loading = false;   
        this.alertMessage = new AlertModel(data['message'],this.title, true);
      }
      else {
        this.loading = false;   
        console.error(data);
        this.alertMessage = new AlertModel( "Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title, true);
      }

    },
    error => {
      console.error(error);
      this.alertMessage = new AlertModel( "Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title, true);
    }
  );


  }


cancel(){

  this.showResult = false;

}

// reprocess(){
//   this.showResult = false;
// }


public reprocess(){
  this.loading = true;

  

  let request = {
    "pcn" : this.dataResult.pcn,
    "cuil" : this.isCuil
    //AGREGAR BOOLEANO PARA SABER SI VA CON CUIL
  };
  
  
  this.reprocessApplicantService.getApplicantReevaluation(request).subscribe(
    data => { 
      
      if(data["reevaluation"] && data["code"] == "0") {
        let response : any  = data;

        if(response.reevaluation.validation == "OK") {

            // Alert Event:
            this.alertConfirmEvent = (confirm:any)=>{
              this.loading = true; 
              this.loadResultsRequest();
            };
                
            this.alertMessage = new  AlertModel("APROBADO: " + response.reevaluation.message, this.title, true, ALERT_TYPE.CONFIRM, null, false);

        } else if(response.reevaluation.validation == "NOOK"){

 
            // Alert Event:
            this.alertConfirmEvent = (confirm:any)=>{
              
              this.cancel();;
            };
              
            this.alertMessage = new  AlertModel("RECHAZADO: " + response.reevaluation.message, this.title, true, ALERT_TYPE.CONFIRM, null, false);
        }else{
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true);
        }
          
      }else{
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true);
      }

      this.loading = false;

    },
    error => {
      this.loading = false;
      console.error(error);
      this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true);
    });  
        

}

refactorObservationPaste(event:ClipboardEvent){ 
  this.dataResult.observacion=this.sanitizedText(this.dataResult.observacion); 
 }
 sanitizedText(text:string):string{
   return text.replace(/[^a-zA-Z0-9.$, ]/g,'')
 }

 refactorObservation(evento:any){
  const entrada= String.fromCharCode(evento.keyCode);
  const expresion=/[^a-zA-Z0-9.$, ]/g;

  if(expresion.test(entrada)){
    evento.preventDefault();
  }

}}


import {  Component, OnInit } from '@angular/core';
import { LoadingComponent } from '../shared/loading/loading.component';
import { AlertComponent, AlertModel, ALERT_TYPE } from '../shared/alert/alert.component';

import { SourceCodesService } from '../../services/sourcecodes/sourcecodes.service';
import { SourceCodesModel } from './models/models';

import { ɵAnimationGroupPlayer } from '@angular/animations';
import { Environment } from 'src/app/config/environment';
import { ReturnStatement } from '@angular/compiler';
import { Utils } from 'src/app/commons/utils';
import { SessionStorageService } from 'src/app/auth/services/session.service';
import { ExcelExportService } from 'src/app/services/excelExport/excel.export.service';
import { CommonDataService } from 'src/app/services/commons/commondata.service';
import { ProductosList } from 'src/app/models/lists.model';

@Component({
  selector: 'app-sourcecodes',
  templateUrl: './sourcecodes.component.html',
  styleUrls: ['./sourcecodes.component.css']
})
export class SourceCodesComponent implements OnInit {

  public title: string = "Administrador de Source Codes";
  public titleUser: string;
  public es: any;
  public showResult : boolean;
  public tablereports = null;
  public showNew : boolean; 
  public entityCurrent: SourceCodesModel = new SourceCodesModel;
  
  public loading: boolean;
  public alertMessage: AlertModel = new AlertModel;
  public alertConfirmEvent: Function;

  public validateShow: boolean =  false;
  public validateMessage: string;
  public isUpdate:boolean = false;
  public productosList :ProductosList = new ProductosList();

  public resultData = [];
  public subchannelList = [];
  public ngsubChannel : string = "";
  


  constructor(private sourcecodesService: SourceCodesService,
              private excelExportService: ExcelExportService,
              private commonDataService: CommonDataService,
              private sessionStorageService: SessionStorageService) { 
      
              this.sessionStorageService.rolValidate("config");
  }



  ngOnInit() {
    this.setCalendarLocale();
    this.loadProductos();
    this.loadResultData();   
    this.loadSubchannel();

  }

  loadResultData(){
    
    this.loading = true;

    this.sourcecodesService.getData().subscribe(
      data => {
        if (data['data']) {
          this.resultData = data['data'];

          this.setupTable();             

        }
        else {
          this.loading = false;
          this.showResult = true;
          console.error(data);
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title,true);
        }

      },
      error => {
        this.loading = false;
        this.showResult = true;
        console.error(error);
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title,true);
      }
    );

  }

  onConfirmEvent(confirm:any){

    if(confirm.confirm){
      this.delete();
    }else{
      this.entityCurrent = null;
    }
  }

  delete(){
      this.loading = true;

      let request = {
        "source_code_completo" : this.entityCurrent.source_code_completo
      };


      this.sourcecodesService.delete(request).subscribe(
        data => {
          if (data && data["code"] && data["code"] == "0") {
            
            this.loading = false;
            this.entityCurrent = new SourceCodesModel();
            this.alertMessage = new AlertModel("Los datos se guardaron con exito",this.title,true);
            this.loadResultData();
          }
          else {
            this.loading = false;
            console.error(data);
            this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title,true);
          }
          
        },
        error => {
          this.loading = false;
          console.error(error);
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title,true);
        }
      );    




  }

  deleteConfirm(source_code_completo){    
    this.entityCurrent =  new SourceCodesModel();
    this.entityCurrent.source_code_completo = source_code_completo;
    this.alertConfirmEvent = this.onConfirmEvent;

    this.alertMessage = new AlertModel("Está seguro de eliminar este Sourcecode?",this.title,true, "CONFIRM");
  }

  new(id){
    
    this.entityCurrent = new SourceCodesModel();
    this.showNew = true;
    this.showResult = false;

    if(id == null){
      this.titleUser = "Nuevo Sourcecode";
      this.entityCurrent.created_user = this.sessionStorageService.getCurrentUser().name;
      this.entityCurrent.created_timestamp = new Date().getTime().toString();
      this.entityCurrent.last_updated = new Date().getTime().toString();

    } else{
      this.isUpdate = true;
      this.titleUser = "Modificar Sourcecode";
      this.entityCurrent = Object.assign({}, this.resultData.find(m => { return m.id == id}));
      this.entityCurrent.last_updated = new Date().getTime().toString();
      this.prodChange(this.entityCurrent.prod);
      this.ngsubChannel = this.entityCurrent.subchannel;
    }

    this.validateShow  =  false;
    this.validateMessage = "";

  }
 
  newCancel(){
    this.isUpdate = false;
    this.showResult = true;
    this.showNew = false;
    this.ngsubChannel = "";
  }

  newSave(){   

    if(this.entityCurrent.id == null){
      this.newCreate();
    } else{
      this.newUpdate();
    }
  }


  newCreate(){    
    
    if(this.verificationDates(this.entityCurrent.initial_effective_date)>this.verificationDates(this.entityCurrent.final_effective_date)){
      this.alertMessage = new AlertModel("La fecha final no puede ser menor que la inicial.",this.title,true);
      this.loading = false;    
      return;
    }

    if(this.resultData.some( c=>c.source_code_completo ==this.entityCurrent.source_code_completo )){
      this.alertMessage = new AlertModel("El Source Code que se intenta crear ya existe.",this.title,true);
      this.loading = false;    
      return;
    }

    if(this.resultData.some( c=>c.promotor ==this.entityCurrent.promotor && c.prod ==this.entityCurrent.prod 
      && this.verificationDates(c.initial_effective_date) <=this.verificationDates(this.entityCurrent.final_effective_date)
      && this.verificationDates(c.final_effective_date) >=this.verificationDates(this.entityCurrent.initial_effective_date) 
      && c.subchannel == this.entityCurrent.subchannel)){

      this.alertMessage = new AlertModel("El Promotor, producto, sub canal y fechas vigencias estan contenidos en otro sourcecode",this.title,true);
      this.loading = false;    
      return;
    }
    this.entityCurrent.subchannel = this.ngsubChannel;
    if(!this.newValidate())
        return;       

    
    this.loading = true;

    this.sourcecodesService.create(this.entityCurrent).subscribe(
      data => {

        if (data && data["code"] && data["code"] == "0") {
          this.showNew = false; 

          this.entityCurrent=  new SourceCodesModel();
          this.alertMessage = new AlertModel("Los datos se guardaron con exito",this.title,true);
          this.loadResultData();
        }
        else if (data && data["code"] && data["code"] == "100") {
          
          this.alertMessage = new AlertModel("Hubo un inconveniente: " + data["message"],this.title,true);
          this.loading = false;    
          return;      
        } else{
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title,true);
          this.loading = false;   
        }

      },
      error => {
        console.error(error);
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title,true);
        this.loading = false; 
      }
    );
    
    
  }

  verificationDates(dato){
    const[day,month,year]=dato.split("/")
    const date= new Date(year,month-1,day);
    return date
  }
  newUpdate(){      

        
    if(this.verificationDates(this.entityCurrent.initial_effective_date)>this.verificationDates(this.entityCurrent.final_effective_date)){
      this.alertMessage = new AlertModel("La fecha final no puede ser menor que la inicial.",this.title,true);
      this.loading = false;    
      return;
    }

    if(this.resultData.some( c=>c.id !=this.entityCurrent.id && c.promotor ==this.entityCurrent.promotor && c.prod ==this.entityCurrent.prod 
      && this.verificationDates(c.initial_effective_date) <=this.verificationDates(this.entityCurrent.final_effective_date)
      && this.verificationDates(c.final_effective_date) >=this.verificationDates(this.entityCurrent.initial_effective_date) 
      && c.subchannel == this.entityCurrent.subchannel)){

      this.alertMessage = new AlertModel("El Promotor, producto, Subchannel y fechas vigencias estan contenidos en otro sourcecode",this.title,true);
      this.loading = false;    
      return;
    }

    this.entityCurrent.subchannel = this.ngsubChannel;
    if(!this.newValidate())
        return;        

    
    this.loading = true;

    this.sourcecodesService.update(this.entityCurrent).subscribe(
      data => {

        if (data && data["code"] && data["code"] == "0") {
          this.showNew = false;      
          this.isUpdate = false;

          this.entityCurrent=  new SourceCodesModel();
          this.alertMessage = new AlertModel("Los datos se guardaron con exito",this.title,true);
          this.loadResultData();
        }
        else if (data && data["code"] && data["code"] == "100") {
          
          this.alertMessage = new AlertModel("Hubo un inconveniente: " + data["message"],this.title,true);
          this.loading = false;    
          return;      
        } else{
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title,true);
          this.loading = false;   
        }

        

      },
      error => {
        console.error(error);
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title,true);
        this.loading = false; 
        this.isUpdate = false;
      }
    );
    
    
  }  


  newValidate():boolean{

    let validations = "";
    


    if(Utils.isNullOrEmpty(this.entityCurrent.tipo_aplicacion))
      validations+="Debe completar el campo tipo_aplicacion. "


    if(Utils.isNullOrEmpty(this.entityCurrent.canal))
        validations+="Debe completar el campo canal. "

    if(Utils.isNullOrEmpty(this.entityCurrent.descripcion))
        validations+="Debe completar el campo descripcion. "

    if(Utils.isNullOrEmpty(this.entityCurrent.sid))
        validations+="Debe completar el campo sid."        


    if(Utils.isNullOrEmpty(this.entityCurrent.estado))
      validations+="Debe completar el campo estado."           


    if(Utils.isNullOrEmpty(this.entityCurrent.comercializadora))
      validations+="Debe completar el campo Agencia."    


    if(Utils.isNullOrEmpty(this.entityCurrent.promotor))
      validations+="Debe completar el campo promotor."        

    if(Utils.isNullOrEmpty(this.entityCurrent.ciclo))
      validations+="Debe completar el campo ciclo."     

      
    if(Utils.isNullOrEmpty(this.entityCurrent.source_code))
      validations+="Debe completar el campo Source Code."    

      
    if(Utils.isNullOrEmpty(this.entityCurrent.source_code_completo))
      validations+="Debe completar el campo Source Code Completo."  


    if(Utils.isNullOrEmpty(this.entityCurrent.ingreso))
     validations+="Debe completar el campo ingreso."

    if(Utils.isNullOrEmpty(this.entityCurrent.prod_mns))
      validations+="Debe completar el campo Prod MNS."

    if(Utils.isNullOrEmpty(this.entityCurrent.prod_name))
      validations+="Debe completar el campo Prod Name."

    if(Utils.isNullOrEmpty(this.entityCurrent.delivery_2))
      validations+="Debe completar el campo Delivery 2."

    if(Utils.isNullOrEmpty(this.entityCurrent.delivery_1))
      validations+="Debe completar el campo Delivery 1."      

      if(Utils.isNullOrEmpty(this.entityCurrent.delivery_1))
      validations+="Debe completar el campo Delivery 1."  

      if(Utils.isNullOrEmpty(this.entityCurrent.subchannel))
      validations+="Debe completar el campo Subchannel."  

      

    if(validations != "") {
        this.validateShow  =  true;
        this.validateMessage = validations;

        return false;
      }

    this.validateShow  =  false;
    this.validateMessage = "";
    return true;
  }

  backToResults(){
    this.showResult = true;
    this.loading = false;

  }

  setDateFormat(value:Date){

     return Utils.setDateFormat(value);
  }

  upload(event){
        
    let fileList: FileList = event.target.files;

    if(fileList.length == 0) {      
      this.alertMessage = new AlertModel("Seleccione solo un archivo.", this.title, true, ALERT_TYPE.WARNING);
      return;
    }

    let file: File = fileList[0];


    var extension = file.name.split(".").pop().toLowerCase();
    var extensiones = ["txt"];
   
    if(extensiones.indexOf(extension) === -1){
      this.alertMessage = new AlertModel("Seleccione un archivo con extención .txt ", this.title, true, ALERT_TYPE.WARNING);
      return;
   }else{
      this.loading = true;
 
      this.sourcecodesService.import(file).subscribe(
        data => {
          if (data['code']== 0) {
            
            this.loadResultData();
            this.alertMessage = new AlertModel("La carga se procesó con exito: " + data['message'], this.title, true, ALERT_TYPE.SUCCESS);
          }
          else {
            let message  = !Utils.isNullOrEmpty(data['message']) ? "Hubo un inconveniente: " + data['message'] :"Hubo un inconveniente al obtener la información, inténtalo nuevamente.";
            this.alertMessage = new AlertModel(message, this.title, true, ALERT_TYPE.WARNING);
            this.loading = false;
          }
          
        },
        error => {
          console.error(error);
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true, ALERT_TYPE.ERROR);
          this.loading = false;
        }
      );   


    }

    $("#importFile").val('');
  }


  export(){

    let reportDataExport = new Array<any>();

    this.resultData.forEach(item => {      
      reportDataExport.push({
        "Agencia":item.comercializadora,
        "Canal":item.canal,
        "Ciclo":item.ciclo ,
        "Delivery 1 ":item.delivery_1,
        "Delivery 2":item.delivery_2,
        "Descripción":item.descripcion,
        "Estado":item.estado,
        "Ingreso ":item.ingreso,
        "Nro. Promotor":item.promotor,
        "Prod MNS":item.prod_mns,
        "Prod Name ":item.prod_name ,
        "Producto":item.prod,
        "SID":item.sid,
        "Source Code":item.source_code,
        "Source Code Completo":item.source_code_completo,
        "Tipo Aplicación":item.tipo_aplicacion,    
        "Created Timestamp": Utils.dateTimeStamp(item.created_timestamp),     
        "Created User":item.created_user,     
        "Last Updated": Utils.dateTimeStamp(item.last_updated),
        "Fecha vigencia inicial": item.initial_effective_date,
        "Fecha vigencia final": item.final_effective_date   
              
      });
    }); 

    this.excelExportService.exportAsExcelFile(reportDataExport, this.title);

  }
  
  prodChange(value:string){

    console.log("entra a prodChange");
    let prod = this.productosList.data.find(p=> (p.data?.prod??"") == value);

    console.log("filtra a prodChange");
    if(prod && prod.data){
      this.entityCurrent.prod = prod.data.prod;
      this.entityCurrent.prod_name = prod.data.prodName;
      this.entityCurrent.prod_mns = prod.data.prodMns;
      this.entityCurrent.ingreso = prod.data.income;
    }

  }


  loadProductos(){

    Utils.storageSave("productos-data", null);

    this.commonDataService.getProductos().subscribe(
      data => {
        if (data['code'] == "0" && data['data']) {          
          this.productosList.data = Object.assign([], data['data']);
        }
        else {
          console.error(data);
        }

        this.loading = false;
      },
      error => {
        console.error(error);
      }
    );

    
    

  }

  private setupTable(){

    this.showResult = true;
    this.loading = false;

    if (this.tablereports != null) {
      this.tablereports.clear().destroy();
    } 
    
    $(document).ready( () => {
        this.tablereports =   $("#result-table").DataTable({

        searching: true, 
        paging: true, 
        
        lengthChange: false,
        pageLength: 10,
        pagingType: 'full_numbers',
        columnDefs: [
          { targets: 'no-sort', orderable: false },
          { width: "70px", targets: 6 }
        ],        
        language: Environment.DataTableLanguageSetting
       });


    });

  }  

  setCalendarLocale(){

    this.es = Environment.CalendarLanguageSetting;
  }

  castDateShort(date){
    return Utils.isNullOrEmpty(Utils.dateTimeStamp(date)) ? date : Utils.dateTimeStamp(date);
  }

  castDateShortInitial(date){
    const fecha=new Date();
    fecha.setMonth(fecha.getMonth()-4);
    const fechaString=fecha.toLocaleDateString('es-ES')
    if(date==null){
      this.entityCurrent.initial_effective_date=fechaString
    }
    return Utils.isNullOrEmpty(date) ? fechaString : date;
  }

  castDateShortFinal(date){
    const fecha=new Date();
    fecha.setMonth(fecha.getMonth()+4);
    const fechaString=fecha.toLocaleDateString('es-ES')
    if(date==null){
      this.entityCurrent.final_effective_date=fechaString
    }
    return Utils.isNullOrEmpty(date) ? fechaString : date;
  }

  loadSubchannel(){
    this.sourcecodesService.getSubchannel().subscribe(
      response => {

        if( response.status == 200 && response.body != null){

          this.subchannelList = Object.assign([], response.body);

        }else if( response.status == 204 ){
          console.log( "no se encontraron subChannels" );
        }else{
          console.log( "Error 1 obteniendo los subChannel" );
        }

      }, err => {
        console.log( "Error 2 obteniendo los subChannel" );
        this.loading = false;
        throw err;
      }
    );
  }


 

}


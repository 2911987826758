export class PreCheckFilterModel {
    fecha_format_inicio: string;
    fecha_format_fin: string;
    fecha_procesamiento_inicio: string;
    fecha_procesamiento_fin: string;
    canales: string[];
    promotores: string[];
}

export class VendorFilterModel {
    id: number;
    name: string;
    selected: boolean;
    
    
    constructor(id: number,name: string,  selected: boolean){

        this.id = id;
        this.name = name;
        this.selected = selected;
    }
}

export class AgenciesModel {
    
    constructor(){
        this.data = new Array<any>();
    }

    data: Array<any>;
}

export class Vendor{
    id: string;
    name: string;
}


import {  Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import {  AlertModel, ALERT_TYPE } from '../shared/alert/alert.component';
import { BusquedaFiltrosModel, ChangeStatusApplicantModel, ChangeStatusModel } from './models/models';


import { CRUD, Environment } from 'src/app/config/environment';
import { Utils } from 'src/app/commons/utils';
import { SessionStorageService } from 'src/app/auth/services/session.service';
import { BaseComponent } from '../shared/base.component';
import { ExcelExportService } from 'src/app/services/excelExport/excel.export.service';
import { ChangeStatusService } from 'src/app/services/changeStatus/changeStatus.service';
import { CommonDataService } from 'src/app/services/commons/commondata.service';
import { ValidationAccionListModel } from 'src/app/models/validationaccion.model';
import { AplicantModel, AplicanteAdicionalModel
 } from 'src/app/models/aplicante.model';


 import {  TiposDocumentosList, VinculosList,
  NacionalidadesList, EstadosCivilList, ProvinciasList,
  TiposViviendasList, EducacionNivleslList, ProfesionesList,
  TrabajosTipolList, ProductosList, CargoslList, GenerosList} 
  from 'src/app/models/lists.model';
import { BehaviorSubject } from 'rxjs';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-changestatus',
  templateUrl: './changeStatus.component.html',
  styleUrls: ['./changeStatus.component.css']
})
export class ChangeStatusComponent extends BaseComponent  implements OnInit {

  public title: string = "Casos Cerrados - Denegadas y Entregadas";
  public titleUser: string;
  public es: any;
  public showResult : boolean  = false;
  public tablereports = null;
  public showNew : boolean; 
  public entityCurrent: ChangeStatusApplicantModel = new ChangeStatusApplicantModel;
  public changeStatus: ChangeStatusModel = new ChangeStatusModel;
  

  public messagetrackingshow:  boolean;
  public messagetrackingtext:  string;
  
  public showAplicanteDetalles : boolean = false; 
  public aplicanteCurrent: AplicantModel = new AplicantModel;
  public aplicanteCurrentBk: AplicantModel = new AplicantModel;
  public adicionalCurrent: AplicanteAdicionalModel = new AplicanteAdicionalModel;
  
  public generosList: GenerosList = new GenerosList();
  public tiposDocumentosList: TiposDocumentosList = new TiposDocumentosList();
  public nacionalidadesList: NacionalidadesList = new NacionalidadesList();
  public estadosCivilList: EstadosCivilList = new EstadosCivilList();
  public provinciasList: ProvinciasList = new ProvinciasList();
  public tiposViviendasList: TiposViviendasList = new TiposViviendasList();
  public educacionNivleslList: EducacionNivleslList =new EducacionNivleslList();
  public profesionesList: ProfesionesList =  new ProfesionesList();
  public trabajosTipolList: TrabajosTipolList = new TrabajosTipolList();
  public vinculosList: VinculosList = new VinculosList();
  public productosList: ProductosList = new ProductosList();
  public cargosList: CargoslList = new CargoslList();
  public vendorsList: Array<any> = new Array<any>();

  public loading: boolean;
  public alertMessage: AlertModel = new AlertModel;
  public alertConfirmEvent: Function;

  public validationAccionList: ValidationAccionListModel = new  ValidationAccionListModel();
  public validationAccionFinishList: ValidationAccionListModel = new  ValidationAccionListModel();

  public validations: Array<any> = new Array<any>();
  public validationsShow: boolean; 

  public validateShow: boolean =  false;
  public validateMessage: string;
  

  public showAdicionalesEditar: boolean =  false;


  public busquedafiltros: BusquedaFiltrosModel = new BusquedaFiltrosModel;
  public resultData = [];  


  protected loadingListsBehavior: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public loadingLists: Array<any> = [
    {"load":"VENDORS", "finish": false},
    {"load":"ESTADOS", "finish": true},
    {"load":"PRODUCTOS", "finish": true}    
  ];
  

  constructor(private commonDataService: CommonDataService,
              private excelExportService: ExcelExportService,
              private changeStatusService: ChangeStatusService,
              private sessionStorageService: SessionStorageService) { 
              
              super();      
    
              this.loadingListsBehavior.subscribe(data  => { 
                
                if(this.loadingLists.filter(l=> !l.finish).length == 0){
                  this.loading = false;
                }
            
            }); 
  }

  canChageStatus(){
    return this.sessionStorageService.hasRolesUser(["AMX_ADMIN","AMX_NA_SUPER"]);;
  }



  ngOnInit() {
    this.loading = true;

    this.setCalendarLocale();
    this.loadEstadosFilter();
    this.loadProfesiones();
    this.loadProductos();
    this.loadVendors();
    this.rolesAutorization();
    this.busquedafiltros.estado = "";
    this.busquedafiltros.promotor = "";


  }

  loadResultData(){
        
    this.loading = true;

    let request = {
                      "fecha": Utils.isNullOrEmpty(this.busquedafiltros.fecha) ? null : this.busquedafiltros.fecha,
                      "nombreapellido": Utils.isNullOrEmpty(this.busquedafiltros.nombre) ? null : this.busquedafiltros.nombre,
                      "pcn": Utils.isNullOrEmpty(this.busquedafiltros.pcn) ? null : this.busquedafiltros.pcn,
                      "dni": Utils.isNullOrEmpty(this.busquedafiltros.dni) ? null : this.busquedafiltros.dni,
                      "promotor":Utils.isNullOrEmpty(this.busquedafiltros.promotor) ? null : this.busquedafiltros.promotor,
                      "refnaps": Utils.isNullOrEmpty(this.busquedafiltros.refnaps) ? null : this.busquedafiltros.refnaps
                  };
    
    

    this.changeStatusService.getChangeStatus(request).subscribe(
      data => {
        if (data['data']) {
          this.resultData = data['data'];


          if(!Utils.isNullOrEmpty(this.busquedafiltros.estado))
             this.resultData  =  this.resultData.filter(d=>d.motivo == this.busquedafiltros.estado);

          this.resultData.forEach(i=>{
              i.producto = this.productosList.data.find(p=>p.key == (i.producto??""))?.value ?? "";
          });

          this.setupTable();             

        }
        else {
          this.loading = true;
          console.error(data);
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title,true);
        }
      },
      error => {
        this.loading = true;
        console.error(error);
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title,true);
      }
    );

  }  


  showAplicante(pcn:string){
    this.loading = true;
    
    let request = {
       "pcn": pcn
    };

    this.changeStatusService.getApplicantDataByArea(request).subscribe(
      data => {

        if (data && data['code'] == "100"){
          this.alertMessage = new AlertModel(data['message'] , this.title, true);
          this.loading = false;
          return;
        }

        if (data && data["data"] && data["data"].length != 0) {

   

    
        if(!Utils.dateIsValid(data["data"][0].datos_personales.fecha_nacimiento)){
          
          if(Utils.dateIsValid(data["data"][0].datos_personales.fecha_nacimiento.split(" ")[0])){
            data["data"][0].datos_personales.fecha_nacimiento = data["data"][0].datos_personales.fecha_nacimiento.split(" ")[0];
          }else
            data["data"][0].datos_personales.fecha_nacimiento = "";
          
        }
          

          this.aplicanteCurrent = Object.assign(this.aplicanteCurrent,data["data"][0]);
          this.aplicanteCurrent.observaciones.observacion = "";


          this.aplicanteCurrent.adicionales = this.aplicanteCurrent.adicionales.filter(a=>{

            if(!Utils.dateIsValid(a.fecha_nacimiento)){
              a.fecha_nacimiento = null;
            }

            return !Utils.isNullOrEmpty(a.numero_documento);
          });            

          if(Utils.isNullOrEmpty(this.aplicanteCurrent.datos_precheck.ingresoReal ))
             this.aplicanteCurrent.datos_precheck.ingresoReal =  this.aplicanteCurrent.datos_precheck.incomeNosis;
          
          this.aplicanteCurrent.detalles.cantidad_suples = this.aplicanteCurrent.adicionales.length.toString();
          this.aplicanteCurrent.datos_personales.nombre_completo = this.aplicanteCurrent?.datos_personales?.nombre_completo?.trim();
          this.aplicanteCurrentBk = JSON.parse(JSON.stringify(this.aplicanteCurrent));
          this.refactorCp();
          this.showNew = true;
          this.showAplicanteDetalles = true;
          this.showResult = false
          this.loading = false;
          this.setupTootip();
          window.scroll(0, 0);

        }
        else {
          console.error(data);
          this.cancel()
          this.loading = false;
          this.alertMessage = new AlertModel("No se pudieron obtener los datos.", this.title, true, ALERT_TYPE.INFO);   
        }
      },
      error => {
        console.error(error);
        this.cancel();
        this.loading = false;
        this.alertMessage = new AlertModel("No se pudieron obtener los datos.", this.title, true, ALERT_TYPE.INFO);   
      }
    );      
    
  return true;

}


  viewData(data){
    
    this.entityCurrent = Object.assign({},data ) as ChangeStatusApplicantModel;
    this.changeStatus = new ChangeStatusModel();
 
    this.titleUser = "Cambio de Estado";
    this.changeStatus.pcn = this.entityCurrent.pcn;
    this.changeStatus.area =  "";
    this.changeStatus.accion = "";
    this.changeStatus.motivo = "";

    this.showAplicante(this.entityCurrent.pcn);

    this.validateShow  =  false;
    this.validateMessage = "";

  }
 
  cancel(){
    
    this.showResult = true;
    this.showNew = false;
    this.validationsShow = false;
    this.showAplicanteDetalles = false;

    this.changeStatus = new ChangeStatusModel();
    this.entityCurrent = new  ChangeStatusApplicantModel;


  }

  save(){   

    if(!this.validationsForm()){
      this.validationsShow = true;
      this.alertMessage = new AlertModel("Existen campos no válidos, por favor revisarlos.",this.title, true, "WARNING");
      return;
    } 


    this.alertConfirmEvent = (confirm:any) => confirm.confirm ? this.saveConfirm() : this.saveCancel();
    this.alertMessage = new AlertModel("Confirma el cambio de estado?",this.title,true, "CONFIRM");	
  }

  saveCancel(){
    this.loading = false;
  }

  saveConfirm(){


    this.loading = true;


    let request = this.changeStatus;    

    this.changeStatusService.saveChangeStatus(request).subscribe(
      data => {
        let result = data as any;
        if (result && result.code == "0") {

          this.showResult = true;
          this.showNew = false;
          this.validationsShow = false;
          this.showAplicanteDetalles = false;

    
          this.changeStatus = new ChangeStatusModel();   
          this.entityCurrent =  new ChangeStatusApplicantModel();
          
          this.loading = false;
          this.alertMessage = new AlertModel("Los cambios se guardaron con éxito.",this.title,true, ALERT_TYPE.INFO);

        }
        else {
          this.loading = false;
          console.error(data);
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title,true);
        }

      },
      error => {
        this.loading = false;
        console.error(error);
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title,true);
      }
    );





  }

  areaChange(item){
    this.changeStatus.accion = "";
    this.changeStatus.motivo = "";
  }

  accionChange(item){
    this.changeStatus.motivo = "";
  }

  motivoChange(item){
    console.log(item);
  }
  
  getAreas(){
    return Object.keys(_.groupBy(this.validationAccionList.data, "area"));   
  }

  getAcciones(){

    if(Utils.isNullOrEmpty(this.changeStatus.area))
      return [];

    let acciones = this.validationAccionList.data.filter(a=>a.area == this.changeStatus.area);
    return Object.keys(_.groupBy(acciones, "estado"));   
  }

  getMotivos(){

    
    if(Utils.isNullOrEmpty(this.changeStatus.area) ||
       Utils.isNullOrEmpty(this.changeStatus.accion) )
      return [];

    let motivos = this.validationAccionList.data.filter(a=>a.area == this.changeStatus.area && a.estado == this.changeStatus.accion);
    
    return Object.keys(_.groupBy(motivos, "subestado"));   
  } 

  loadProductos(){

    this.commonDataService.getProductos().subscribe(
      data => {
        if (data['code'] == "0" && data['data']) {          
          this.productosList.data = Object.assign([], data['data']);
        }
        else {
          console.error(data);
        }

        this.setLoadingListsFinish("PRODUCTOS");
      },
      error => {
        this.setLoadingListsFinish("PRODUCTOS");
      }
    );

    
    

  }

  loadProfesiones(){

    this.commonDataService.getProfesiones().subscribe(
      data => {
        if (data['data']){
          this.profesionesList.data = data['data'];
          this.profesionesList.data.sort((a, b)=> a.value?.localeCompare(b.value));      
        }        
        else 
          console.error(data);          
        
      },
      error => console.error(error)
    );

    return true;

  }

  loadEstadosFilter(){


    this.commonDataService.getValidationAccionAll().subscribe(
      data => {
        if(data) {
          let estados = data as Array<any>;          

          let estadosFinish = estados.filter(e=>{ 

            return  (e.area == "TELEMARKETING" 
                || (e.area == "RECOVERY")
                || (e.area == "NEWACCOUNT")
                || (e.area == "DELIVERY")) && e.nextStep == "FINISH" ;
        
          }).reduce((acc,e)=>{ //Repetidos
            if(!acc.some(i=>i.subestado == e.subestado))
               acc.push(e);
            
            return acc;
          },[]);

          let estadosNoFinish = estados.filter(e=>{

            return  (e.area == "TELEMARKETING" 
                || (e.area == "RECOVERY")
                || (e.area == "NEWACCOUNT")
                || (e.area == "DELIVERY")) && e.nextStep != "FINISH" ;
        
          }).concat([ {estado: "A TRABAJAR", subestado:"A TRABAJAR", area: "TELEMARKETING"}]);     

          this.validationAccionList.data =  estadosNoFinish.filter(function(elem, index, self) {
            return index === self.indexOf(elem);
          });
          
          this.validationAccionFinishList.data =  estadosFinish.filter(function(elem, index, self) {
            return index === self.indexOf(elem);
          });

          this.validationAccionList.data.sort((a, b)=> a.subestado?.localeCompare(b.subestado));  
          this.validationAccionFinishList.data.sort((a, b)=> a.subestado?.localeCompare(b.subestado));  

          this.getAreas();
   
        }

        this.setLoadingListsFinish("ESTADOS");  

      },
      error =>{
        this.setLoadingListsFinish("ESTADOS");

      }
    )

  }

  loadVendors(){

    this.commonDataService.getVendorsList().subscribe(
      data => {
        if (data['data']) {
          
          this.vendorsList = Object.assign(this.vendorsList, data['data']);       
          this.vendorsList.sort((a, b)=> a.name.localeCompare(b.name));
          
          
          if(!this.canChageStatus()){
              let user = this.sessionStorageService.getCurrentUser();

              user.promotor = "208000";
              if(!Utils.isNullOrEmpty(user.promotor)){
                this.vendorsList =  this.vendorsList.filter(p=> p.id == user.promotor)
                this.busquedafiltros.promotor = user.promotor;
              }
          }


        }
        else {
          console.error(data);
        }
        this.setLoadingListsFinish("VENDORS");

      },
      error => {
        this.setLoadingListsFinish("VENDORS");
        console.error(error);
      }
    );

    
    

  }


  validationsMessage(name):string{
    if(!this.validationsShow)
      return null;

    this.validationsForm();

    let validation = this.validations.find(v => v[name] != null);

    if(validation){
      return validation[name];
    }
      
    return  null;
  }

  
  validationsForm(){
    this.validations = new Array<any>();

    if(Utils.isNullOrEmpty(this.changeStatus.area)){
      this.changeStatus.accion = "";
      this.validations.push({"validacion.area" : "este campos es requerido."})
    }  


    if(Utils.isNullOrEmpty(this.changeStatus.accion)){
      this.changeStatus.accion = "";
      this.validations.push({"validacion.accion" : "este campos es requerido."})
    }  

    if(Utils.isNullOrEmpty(this.changeStatus.motivo)){
      this.changeStatus.motivo = "";
      this.validations.push({"validacion.motivo" : "este campos es requerido."})
    } 

   
    return this.validations.length == 0;
  }


  adicionalesEditar(dni:any) {

    let adicional = this.aplicanteCurrent.adicionales.find((a)=>{

      return a.numero_documento == dni;
    });

    let adicionalBk = this.aplicanteCurrentBk.adicionales.find((a)=>{
      return a.numero_documento == dni;
    });

    this.adicionalCurrent  = Object.assign({}, adicional);
    this.adicionalCurrent.crud = adicionalBk ? CRUD.UPDATE : CRUD.CREATE;

    this.showAdicionalesEditar = true;
    
    return true;
  }

  adicionalesCancelar(){

    this.showAdicionalesEditar = false;
    this.adicionalCurrent = null;
  }


  messageTrackingShow(){
    this.messagetrackingshow = true;
  }
  
  adicionalesList(){

    return this.aplicanteCurrent.adicionales.filter((a)=>{

      return a.crud != CRUD.DELETE;
    });

  }
  
  listAplicanteDireccinesTelefonos(origen:string){

    let data = Array<any>();
    let direciones = this.aplicanteCurrent.direcciones.filter(m=> m.origen.toLocaleUpperCase().includes(origen));
    let telefonos = this.aplicanteCurrent.telefonos.filter(m=> m.origen.toLocaleUpperCase().includes(origen));
    let length = (direciones.length >= telefonos.length) ? direciones.length : telefonos.length;

    for(let i = 0; i < length; i++){
      data.push({
        "direccion" : direciones[i] != null ? direciones[i].direcciones : "",
        "telefono" : telefonos[i] != null ? telefonos[i].telefono : "",
      });   
    }
   
    return data;
  }



  backToResults(){
    this.showResult = true;
    this.loading = false;

  }  
 

  setupTable(){



    if (this.tablereports != null) {
      this.tablereports.clear().destroy();
    } 
    
    $(document).ready( () => {
        this.tablereports =   $("#result-table").DataTable({

        searching: false, 
        paging: true, 
        //info: false,
        lengthChange: false,
        pageLength: 10,
        pagingType: 'full_numbers',
        columnDefs: [
          { targets: 'no-sort', orderable: false },
          //{ width: "70px", targets: 6 }
        ],        
        initComplete: (setting, json) => {
          this.showResult = true;
          this.loading = false;
        },
        language: Environment.DataTableLanguageSetting
       });


    });

  }  

  setCalendarLocale(){

    this.es = Environment.CalendarLanguageSetting;
  }

  setLoadingListsFinish(loadname){

    this.loadingLists.forEach(i=>{
      if(i.load == loadname){
        i.finish = true;
        this.loadingListsBehavior.next(null);
      }

    })
  }

  mapGenero(key:string){
    return this.generosList.data.find(i=>i.key == key)?.value;
  }

  export() {  


    let reportDataExport = new Array<any>();

    this.resultData.forEach(item => {      
      reportDataExport.push({
        "Aprobado Lanas":item.aprobado_lanas,
        "DNI":item.dni,
        "Estado":item.estado,
        "Fecha":item.fecha,
        "Fecha Modificación":item.fecha_modificacion,
        "Motivo":item.motivo,
        "Nombre y Apellido":item.nombreapellido,
        "PCN":item.pcn,
        "Producto":item.producto,
        "Promotor":item.promotor,
        "Promotor Original":item.promotor_original,
        "RefNaps":item.refNaps,      
              
      });
    }); 

   
    this.excelExportService.exportAsExcelFile(reportDataExport, this.title);

  }
  
  refactorObservationPaste(event:ClipboardEvent){ 
    this.changeStatus.observaciones=this.sanitizedText(this.changeStatus.observaciones); 
   }
   sanitizedText(text:string):string{
     return text.replace(/[^a-zA-Z0-9.$, ]/g,'')
   }
   
   refactorCp(){ 
      this.aplicanteCurrent.datos_personales.codigo_postal=this.sanitizedTextCp(this.aplicanteCurrent.datos_personales.codigo_postal); 
       }
   sanitizedTextCp(text:string):string{
     return text.replace(/[^0-9]/g,'')
   }

   refactorObservation(evento:any){
    const entrada= String.fromCharCode(evento.keyCode);
    const expresion=/[^a-zA-Z0-9.$, ]/g;

    if(expresion.test(entrada)){
      evento.preventDefault();
    }
   }

   public rolConsulta : boolean=true;

   public rolesAutorization(){
    let rolesSession = this.sessionStorageService.getCurrentUser().roles
      console.log(rolesSession)
    if (rolesSession.includes("AMX_CONSULTA")){
      this.rolConsulta=false
    }
  
  }

  }


import { Component, OnInit } from '@angular/core';
import { InternalVersionInfoService } from 'src/app/services_internal/internlVersionInfo/internalVersionInfo.service';
import { AlertComponent, AlertModel, ALERT_TYPE } from '../../component/shared/alert/alert.component';
import { SessionStorageService } from 'src/app/auth/services/session.service';
import * as moment from 'moment';

@Component({
  selector: 'app-internalVersionInfo',
  templateUrl: './internalVersionInfo.component.html',
  styleUrls: ['./internalVersionInfo.component.css']
})

export class InternalVersionInfoComponent implements OnInit {

  public title: string = "LISTAR VERSIONES";
  public alertMessage: AlertModel = new AlertModel;
  public alertConfirmEvent: Function;
  public loading: boolean;
  public versionData:any = [];

  constructor(private versionInfoInternalService: InternalVersionInfoService,
    private sessionStorageService: SessionStorageService ) {
      //this.sessionStorageService.currentSession.user.roles.includes("AMX_INT_ADMIN")
     }
  

  ngOnInit(): void {

    this.loadVersion();
  }

  loadVersion(){
 
    this.loading = true;
    this.versionInfoInternalService.getListDatabase().subscribe(
      response => {
        if (response.status == 200 && response.body != null) {
          let data = response.body;
          this.versionData = Object.assign([], data['data']);
          this.versionData.BUILD_DATE = moment(this.versionData.BUILD_DATE).format("DD/MM/YYYY");
          this.loading = false;
        }
        else if( response.status == 204 ){
          this.alertMessage = new AlertModel( "No se encontraron registros con los datos ingresados.",this.title, true, ALERT_TYPE.INFO);
        }else{
          this.alertMessage = new AlertModel( "Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title, true,"WARNING");
        }
        this.loading = false;
      },
      error => {
        console.error(error);
        this.loading = false;
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true, "ERROR");
        throw error;
      }
    );

  }

}
import { Injectable } from '@angular/core';
import * as $ from 'jquery';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Environment } from '../../config/environment';
import { Config } from '../../config/config';
import { Observable, of } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AbmUsersService {
//Componente de plantilla Telemarketing

    constructor(private http: HttpClient, private Conf: Config) { }

    public getUserList(){

      let url = this.Conf.ENDPOINT_API + 'user';
      let wc = (Environment.CREDENTIALS) ? true : false;
      
      return this.http.get(url, {observe: "response",withCredentials: wc})

    }

    public getUserListByFilters(data){

      let url = this.Conf.ENDPOINT_API + 'user/byfilter';
      let request = Object.assign({}, data);
      let headers = new HttpHeaders({
        'Content-Type': 'application/json',
      })
      let wc = (Environment.CREDENTIALS) ? true : false;

     return this.http.post(url, request, {headers: headers, observe: "response",withCredentials: wc}) 

    }

    public getUserRoles(){

      let url = this.Conf.ENDPOINT_API + 'user/roles';
      let wc = (Environment.CREDENTIALS) ? true : false;
      
      return this.http.get(url, {observe: "response",withCredentials: wc})

    }

    public getMatrices(){

      let url = this.Conf.ENDPOINT_API + 'getmatrices';
      let wc = (Environment.CREDENTIALS) ? true : false;
      
      return this.http.get(url, {observe: "response",withCredentials: wc})

    }

    
    public createNewUser(data){

      let url = this.Conf.ENDPOINT_API + 'user/';
      let request = Object.assign({}, data);
      let headers = new HttpHeaders({
        'Content-Type': 'application/json',
      })
      let wc = (Environment.CREDENTIALS) ? true : false;

     return this.http.post(url, request, {headers: headers, observe: "response",withCredentials: wc}) 

    }

     
    public editUser(data){

      let url = this.Conf.ENDPOINT_API + 'user/update';
      let request = Object.assign({}, data);
      let headers = new HttpHeaders({
        'Content-Type': 'application/json',
      })
      let wc = (Environment.CREDENTIALS) ? true : false;

     return this.http.post(url, request, {headers: headers, observe: "response",withCredentials: wc}) 

    }

       
    public deleteUser(data){

      let url = this.Conf.ENDPOINT_API + 'user/delete';
      let request = Object.assign({}, data);
      let headers = new HttpHeaders({
        'Content-Type': 'application/json',
      })
      let wc = (Environment.CREDENTIALS) ? true : false;

     return this.http.post(url, request, {headers: headers, observe: "response",withCredentials: wc}) 

    }


}
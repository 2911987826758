import { Injectable } from '@angular/core';
import * as $ from 'jquery';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Environment } from '../../config/environment';
import { Config } from '../../config/config';
import { config } from 'process';
import { Observable, of, Subscription, throwError, timer } from 'rxjs';
import { relativeTimeThreshold } from 'moment';
import { map, catchError, retry } from 'rxjs/operators';
import { MockStatic } from 'src/app/config/mock.static';
import { Utils } from 'src/app/commons/utils';
import { ExcelExportService } from '../excelExport/excel.export.service';


@Injectable({
  providedIn: 'root'
})
export class CommonDataService {


    constructor(private http: HttpClient, private Conf: Config,
               private excelExportService:ExcelExportService) { }

    private pingTimer: any;

    pingApplicantHoldTimerStart(pcn:string){     
 

      this.pingTimer = setInterval(()=>{
        this.pingApplicantHold(pcn).subscribe();
      }, 20000)
    }

    
    pingApplicantHoldTimerStop(){
      clearInterval(this.pingTimer);
    }

    pingApplicantHold(pcn:string){
      
      let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }

      let request = {"pcn":pcn}

      let url = this.Conf.ENDPOINT_API + 'pingApplicantHold';  

      return this.http.post(url, request, httpOptions); // end of pipe; 
    }

    releaseApplicantHold(pcn:string){
      
      let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }

      let request = {"pcn":pcn}

      let url = this.Conf.ENDPOINT_API + 'releaseApplicantHold';  

      return this.http.post(url, request, httpOptions); // end of pipe; 
    } 



    getValidationAccionAll(){
      
      let httpOptions = {
        withCredentials: false
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }

      let url = this.Conf.ENDPOINT_API + 'getValidationAccionAll';  

      return this.http.get(url, httpOptions) .pipe(
        map( response => {  // NOTE: response is of type SomeType

            return response; // kind of useless
        }),
        catchError(e => throwError(e))
     ); // end of pipe; 
    }
    
    getNacionalidad():Observable<any>{
      
      let httpOptions = {
        withCredentials: false
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }

      let url = this.Conf.ENDPOINT_API + 'getNacionalidad';  

      return this.http.get(url, httpOptions) .pipe(
        map( response => {  // NOTE: response is of type SomeType

            return response; // kind of useless
        }),
        catchError(e => throwError(e))
     ); // end of pipe; 
    }

    getProductos():Observable<any>{


      
      let httpOptions = {
        withCredentials: false
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }

      let url = this.Conf.ENDPOINT_API + 'getProductos';  

      return this.http.get(url, httpOptions) .pipe(
        map(data => {
          
            if(data["code"] == "0" && data["data"]){
              if(data["data"].length != 0) {      

                let datamap = {code:0, data:[]};
                
                datamap.data = data["data"].map(d=> {
                  return {key: d.prod, value: d.prodName, data: d}
                });
                
                
                return datamap;
              }
            }
            
            return data; // kind of useless
        }),
        catchError(e => throwError(e))
     ); // end of pipe; 
    }

    getProfesiones():Observable<any>{

      
      let httpOptions = {
        withCredentials: false
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }

      let url = this.Conf.ENDPOINT_API + 'getProfesiones';  

      return this.http.get(url, httpOptions).pipe(
        map(data => {
          
            if(data["code"] == "0" && data["data"]){
              if(data["data"].length != 0) {
                let datamap = {code:0, data:[]};

                datamap.data = data["data"].map(d=> {
                  return {key: d.codigo as string, value: d.descripcion}
                });
                
                return datamap;
              }
            }
            
            return data;
        }),
        catchError(e => throwError(e))
     ); 
    }

    getProductosFull():Observable<any>{

      if(Utils.storageLoad("productosfull-data"))
         return of(Utils.storageLoad("productosfull-data"));
      
      let httpOptions = {
        withCredentials: false
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }

      let url = this.Conf.ENDPOINT_API + 'getProductos';  

      return this.http.get(url, httpOptions) .pipe(
        map(data => {
          
            if(data["code"] == "0" && data["data"]){
              if(data["data"].length != 0) {                
                Utils.storageSave("productosfull-data", data); 
              }
            }
            
            return data; // kind of useless
        }),
        catchError(e => throwError(e))
     ); // end of pipe; 
    }

    getSourcecodes():Observable<any>{


      let httpOptions = {
        withCredentials: false
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }

      let url = this.Conf.ENDPOINT_API + 'getSourcecodes';

      return this.http.get(url, httpOptions);  

    }

    getIntSourcecodes():Observable<any>{


      let httpOptions = {
        withCredentials: false
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }

      let url = this.Conf.ENDPOINT_API + 'internal/getSourcecodes';

      return this.http.get(url, httpOptions);  

    }

    getVendorsList(){

    

      let httpBody;

      let httpOptions = {
        withCredentials: false
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }



      let url = this.Conf.ENDPOINT_API + 'getVendorsList';

    
      return this.http.get(url, httpOptions);   

    }

    getParameters(){

    

      let httpBody;

      let httpOptions = {
        withCredentials: false
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }



      let url = this.Conf.ENDPOINT_API + 'getConstants';

    
      return this.http.get(url, httpOptions);   

    }

    getParametersForAbm(){

      let httpBody;
      let httpOptions = {
        withCredentials: false
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }

      let url = this.Conf.ENDPOINT_API + 'getConstantsForAbm';
    
      return this.http.get(url, httpOptions);   

    }

 
    public getVersion(){
   
    
      let httpOptions = {
        withCredentials: false
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }

      let url = this.Conf.ENDPOINT_API + 'getVersion';  

      return this.http.get(url, httpOptions);  
    }


    public exportAsExcelFile(data:any[], name: string){
      this.excelExportService.exportAsExcelFile(data, name);
    }

    getProductsAndId():Observable<any>{


      
      let httpOptions = {
        withCredentials: false
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }

      let url = this.Conf.ENDPOINT_API + 'getProductos';  

      return this.http.get(url, httpOptions) .pipe(
        map(data => {
          
            if(data["code"] == "0" && data["data"]){
              if(data["data"].length != 0) {      

                let datamap = {code:0, data:[]};
                
                datamap.data = data["data"].map(d=> {
                  return {key: d.id, value: d.prodName, data: d}
                });
                
                
                return datamap;
              }
            }
            
            return data; // kind of useless
        }),
        catchError(e => throwError(e))
     ); // end of pipe; 
    }

    pingHoldInternal(data){

      let url = this.Conf.ENDPOINT_API + 'internal/pingHold';
      let request = Object.assign({}, data);
      let headers = new HttpHeaders({
        'Content-Type': 'application/json',
      })
      let wc = (Environment.CREDENTIALS) ? true : false;
      
      return this.http.post(url, request, {headers: headers, observe: "response",withCredentials: wc})
        
    }
    pingHoldInternalTimerStart(pcn:string){     
      let request = {
        "pcn": pcn
      };
      this.pingTimer = setInterval(()=>{
        this.pingHoldInternal(request).subscribe();
      }, 20000)

    }

    releaseHoldInternal(data){
      let url = this.Conf.ENDPOINT_API + 'internal/releaseHold';
      let request = Object.assign({}, data);
      let headers = new HttpHeaders({
        'Content-Type': 'application/json',
      })
      let wc = (Environment.CREDENTIALS) ? true : false;
      
      return this.http.post(url, request, {headers: headers, observe: "response",withCredentials: wc})
        
    }

    public getLogout(){

      let url = this.Conf.ENDPOINT_API + 'removeCookie';
      let wc = (Environment.CREDENTIALS) ? true : false;
      
      return this.http.get(url,  {observe: "response",withCredentials: wc})
        
    }

  getValidationAccionAllInternal(){
    
    let httpOptions = {
      withCredentials: false
    };

    if(Environment.CREDENTIALS){
      httpOptions.withCredentials = true;
    }

    let url = this.Conf.ENDPOINT_API + 'internal/getValidationAccionAll';  

    return this.http.get(url, httpOptions) .pipe(
      map( response => {  // NOTE: response is of type SomeType

          return response; // kind of useless
      }),
      catchError(e => throwError(e))
    ); // end of pipe; 
  }

}
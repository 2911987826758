import { Component,Input, OnChanges, OnInit, Optional } from '@angular/core';
import { SimpleChanges } from '@angular/core';
import { Roles, RolesArea } from 'src/app/auth/models/roles.static';



import { Session } from 'src/app/auth/models/session.model';
import { SessionStorageService } from 'src/app/auth/services/session.service';
import { Utils } from 'src/app/commons/utils';
import { Environment } from 'src/app/config/environment';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})

export class MenuComponent implements OnInit {

  @Input() currentSession: any;
  
  public session: Session;
  public showMenu: boolean;
  
  constructor(private sessionStorageService: SessionStorageService) { 
    
    this.sessionStorageService.sessionBehavior.subscribe((session: Session)  => {
        this.session = session;   
        this.showMenu = session ?  session.login : false;
    });  
     
    this.session = this.sessionStorageService.getCurrentSession();   
  }

  ngOnInit() {        
    return true;
  }

  hasRolesArea(area:string): boolean {
    return this.sessionStorageService.hasRolesArea(area);
  }

  hasRolesUser(rol:string): boolean {
    return this.sessionStorageService.hasRolesUser([rol]);
  }


  

  //Para este caso hay que pasar toda la clase como New para que se dispare el evento.
  ngOnChanges(changes: SimpleChanges) {
   


    return true;

  }  

}

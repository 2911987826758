import { Injectable } from '@angular/core';
import * as $ from 'jquery';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Environment } from '../../config/environment';
import { Config } from '../../config/config';


@Injectable({
  providedIn: 'root'
})
export class VersionInfoService {


    constructor(private http: HttpClient, private Conf: Config) { }

    public getListDatabase(){

      let url = this.Conf.ENDPOINT_API + 'listVersion';
      let headers = new HttpHeaders()
      let wc = (Environment.CREDENTIALS) ? true : false;
      
      return this.http.get(url,  {observe: "response",withCredentials: wc})

    }
}

export class BusquedaFiltrosModel {
  fechaDesde: string;
  fechaHasta: string;
}

export class AddressModel{
  pcn: string;
  cuil: string;
  numeroDocumento: string;
  nombreCompleto: string;
  provincia: string;
  localidad: string;
  codigoPostal: string;
  calle: string;
  numero: string;
  piso: string;
  depto: string;
  direccion: string;

  calleNew: string;
  numeroNew: string;
  pisoNew: string;
  deptoNew: string;
  direccionNew: string;
}
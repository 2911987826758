export class ReporteTotalDetails{
    adicionales: Array<any> = new Array<any>();
    variables_equifax: Array<any> = new Array<any>();
    variables_nosis: Array<any> = new Array<any>();

}

export class AplicanteAdicionalModel {
    crud: string;
    tipo_documento: string;
    numero_documento: string;
    nombre_completo: string;
    genero: string;
    fecha_nacimiento: string;
    vinculo: string;
}

export class BusquedaFiltrosModel {
    fecha_modificacion: string;

}



import { Injectable } from '@angular/core';
import * as $ from 'jquery';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Environment } from '../../config/environment';
import { Config } from '../../config/config';

@Injectable({
  providedIn: "root",
})
export class InternalReprocessApplicantService {


    constructor(private http: HttpClient, private Conf: Config) { }


    public getData(data){

 

      let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }

      let url = this.Conf.ENDPOINT_API + 'getApplicantDetail';
      let request = Object.assign({}, data);

      // request.area = "NEWACCOUNT";

      return this.http.post(url, request, httpOptions);   

    }

    // public getApplicantReevaluation(data:any) {
  
      
    //   let httpOptions = {
    //     withCredentials: false,
    //     headers: new HttpHeaders({
    //       'Content-Type': 'application/json',
    //     })
    //   };

    //   if(Environment.CREDENTIALS){
    //     httpOptions.withCredentials = true;
    //   }

    //   let url = this.Conf.ENDPOINT_API + 'getApplicantReevaluation';
    //   let request = Object.assign({}, data);
      

    //   return this.http.post(url, request, httpOptions);        

    // }   
    
    
    public getApplicantReevaluation(data:any) {
  
      
      let url = this.Conf.ENDPOINT_API + 'internal/getApplicantReevaluation';
      let request = Object.assign({}, data);
      let headers = new HttpHeaders({
        'Content-Type': 'application/json',
      })
      let wc = (Environment.CREDENTIALS) ? true : false;
      
      return this.http.post(url, request, {headers: headers, observe: "response",withCredentials: wc})     

    } 

    public getApplicantDetailRechazados(data){

      let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }

      let url = this.Conf.ENDPOINT_API + 'internal/getIntPersonaFilter';//'getApplicantDetailRechazados';
      let request = Object.assign({}, data);

      // request.area = "NEWACCOUNT";

      return this.http.post(url, request, httpOptions);   

    }

    public getInternalDetailRejected(data){

        let url = this.Conf.ENDPOINT_API + 'internal/getIntPersonaFilter';
        let request = Object.assign({}, data);
        let headers = new HttpHeaders({
          'Content-Type': 'application/json',
        })
        let wc = (Environment.CREDENTIALS) ? true : false;
        
        return this.http.post(url, request, {headers: headers, observe: "response",withCredentials: wc})
          
      }


}
export class CpidsModel {
      id: string;  
    cpid: string;  
    sub_channel: string;        
    group_site: string;      
    referring_site: string;      
    creative: string;
    cpid_type: string;

    //NEW:
    child_cpid: string;
    marketing_lead: string;
    marketing_location: string;
    mobile_device: string;
    follow_up_channel: string;
    activity_type: string;
    buy_type: string;
    campaign_type: string;
    publisher: string;
    source_code: string;
    keyword: string
    query_string: string;


    created_timestamp: string;
    created_user: string;
    last_updated: string;
    
}




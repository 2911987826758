import {Component, OnInit } from '@angular/core';

import { CRUD, Environment } from '../../config/environment';
import { AlertComponent, AlertModel, ALERT_TYPE } from '../../component/shared/alert/alert.component';

import { InternalNewAccountsService } from '../../services_internal/internalNewAccounts/internalNewAccounts.service';
import { BusquedaFiltrosModel, FilteredApplicant, CustomerModel, IntPersonaSuples,equifaxInt,nosisInt
 } from 'src/app/models/aplicante.model';

import {  TiposDocumentosList, VinculosList,
   NacionalidadesList, EstadosCivilList, ProvinciasList,
   TiposViviendasList, EducacionNivleslList, ProfesionesList,
   TrabajosTipolList, ProductosList, CargoslList, GenerosList,
   AccionAplicanteList, MotivosAplicanteList, SourceCodesList} 
   from 'src/app/models/lists.model';

import 'datatables.net';
import { CommonDataService } from 'src/app/services/commons/commondata.service';

import * as moment from 'moment';
import { SessionStorageService } from 'src/app/auth/services/session.service';
import { Utils } from 'src/app/commons/utils';
import { BaseComponent } from '../../component/shared/base.component';
import {CustomerDetailModel } from '../internalCustomerDetail/models/models';

@Component({
  selector: 'app-internalNewAccounts',
  templateUrl: './internalNewAccounts.component.html',
  styleUrls: ['./internalNewAccounts.component.css']
})

export class InternalNewAccountsComponent  extends BaseComponent  implements OnInit {

  public title: string = "ADMINISTRAR CUENTAS NUEVAS";
  public es: any;
  public tablereports = null;
  public showResult : boolean;
  public showAplicanteDetalles : boolean;
  public showAdicionalesEditar : boolean;
  
  public messagetrackingshow:  boolean;
  public messagetrackingtext:  string;
  public loading: boolean;
  public loadingMotivo: boolean;
  
  public alertMessage: AlertModel = new AlertModel;
  public alertConfirmEvent: Function;

  public resultsData:any = [];
  public aplicanteCurrent: CustomerModel = new CustomerModel;
  public aplicanteCurrentBk: CustomerModel = new CustomerModel;
  public adicionalCurrent: IntPersonaSuples = new IntPersonaSuples;
  
  public validations: Array<any> = new Array<any>();
  public validationsShow: boolean; 

  public validationsAdicional: Array<any> = new Array<any>();
  public validationsAdicionalShow: boolean; 

  dateinfo : Date;
  public busquedafiltros: BusquedaFiltrosModel = new BusquedaFiltrosModel;
  public selectedApplicant : FilteredApplicant = new FilteredApplicant;

  public reevaluarEnabled: boolean = false;  
  public recalcularEnabled: boolean = true;  
  public reevaluarDias: number = null;  

  public accionAplicanteList: AccionAplicanteList = new AccionAplicanteList();
  public motivosAplicanteList: MotivosAplicanteList = new MotivosAplicanteList();
  public accionSupleList: AccionAplicanteList = new AccionAplicanteList();
  public accionListStore: AccionAplicanteList = new AccionAplicanteList();
  
  public generosList: GenerosList = new GenerosList();
  public tiposDocumentosList: TiposDocumentosList = new TiposDocumentosList();
  public nacionalidadesList: NacionalidadesList = new NacionalidadesList();
  public estadosCivilList: EstadosCivilList = new EstadosCivilList();
  public provinciasList: ProvinciasList = new ProvinciasList();
  public tiposViviendasList: TiposViviendasList = new TiposViviendasList();
  public educacionNivleslList: EducacionNivleslList =new EducacionNivleslList();
  public profesionesList: ProfesionesList =  new ProfesionesList();
  public trabajosTipolList: TrabajosTipolList = new TrabajosTipolList();
  public vinculosList: VinculosList = new VinculosList();
  public productosList: ProductosList = new ProductosList();
  public cargosList: CargoslList = new CargoslList();
  public buttonSave:boolean = true;

  public sourcecodesList: SourceCodesList = new SourceCodesList();
  public sourcecodesListForApplicant: Array<any> = new Array<any>();
  
  public parametersList = { data: new Array<any>()};  

  public openWindow : boolean = false;
  public dataResult :  CustomerDetailModel;
  public recalculoRequest : any;
  public limiteNuevo: boolean = false;
  public productsApplicationSource: any = {data:[]};
  public rolConsulta : boolean=true;
  public editNameNA : boolean= false;
  public rolAdmin: boolean=true;

  public data : any;
  public intPersonaSuplesCant : string;
  public isExcepcion: boolean = false;
  public selector: string = "Reevaluar";

  public showAplicanteDetallesIso : boolean = false;
  public showFiltros: boolean = true;
  public initSelectAdd: string = "";
  public showTitleSuples: boolean = false;
  public ngSelect: string = "";
  public ngSelectAccion: string = "";
  public resumeString: string = "";
  public provinceData:any = [];
  public validateMessage: string;

  constructor(private commonDataService: CommonDataService,
              private newAccountsInternalsService: InternalNewAccountsService, 
              private sessionStorageService: SessionStorageService) { 
              
                super();
                this.sessionStorageService.rolValidate("internalNewAccounts");
  }

  ngOnInit() {
    this.listProvinceLoad();
    this.setCalendarLocale();
    this.validationAccionLoad();
    this.profesionesLoad();
    this.loadProductos();
    this.loadSourcecodes();
    this.loadParameters();
    this.rolesAutorization();
    
    this.busquedafiltros.producto = "";    
  }

  mapGenero(key:string){
    return this.generosList.data.find(i=>i.key == key)?.value;
  }


  loadReportResults(){

    this.loading = true;
    this.loadReportRequest();
  }

  loadReportRequest(){

    let request = { 
      "matriz": this.sessionStorageService.getCurrentUser()?.matriz,
      "dni": Utils.isNullOrEmpty(this.busquedafiltros.dni) ? null : this.busquedafiltros.dni, 
      "fecha": Utils.isNullOrEmpty(Utils.getTimesTamp(this.busquedafiltros.fecha)) ? null : Utils.getTimesTamp(this.busquedafiltros.fecha), 
      "nombreapellido": Utils.isNullOrEmpty(this.busquedafiltros.nombre) ? null : this.busquedafiltros.nombre, 
      "pcn": Utils.isNullOrEmpty(this.busquedafiltros.pcn) ? null : this.busquedafiltros.pcn, 
      "producto": Utils.isNullOrEmpty(this.busquedafiltros.producto) ? null : this.busquedafiltros.producto,
      "cuil": Utils.isNullOrEmpty(this.busquedafiltros.cuil) ? null : this.busquedafiltros.cuil
    };    

    this.newAccountsInternalsService.getApplicantsReportByArea(request).subscribe(
      response => {
        if (response.status == 200 && response.body != null) {
          this.resultsData = response.body;

          this.resultsData.forEach(i=>{
            let prod = this.productosList.data.find(p=> (p.key??"") == parseInt( i.informedProduct ) );

            if( i.tipoVenta == "SUP" && i.intSocioData != null && i.intSocioData.producto != null ){
              prod = this.productosList.data.find(p=> (p.key??"") == parseInt( i.intSocioData.producto ) );
            }
            i.productoObj = prod;
            i.accion = ( i.accion != null ) ? i.accion : "";
            i.motivo = ( i.motivo != null ) ? i.motivo : "";

            if( i.intPersonaSuples.length ){
              for( var j = 0; j < i.intPersonaSuples.length; j++ ){ 
                i.intPersonaSuples = i.intPersonaSuples.filter(function (sup) {
                  return sup.denegado !== true;
                })
              }
            }
          })  

          this.setupTable();
        }
        else if( response.status == 204 ){
          this.alertMessage = new AlertModel( "No se encontraron registros con los datos ingresados.",this.title, true, ALERT_TYPE.INFO);
        }else{
          this.alertMessage = new AlertModel( "Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title, true,"WARNING");
        }
        this.loading = false;
      },
      error => {
        console.error(error);
        this.loading = false;
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true, "ERROR");
        throw error;
      }
    );

    return true;
  }

  showAplicante(pcn:string){
    this.loading = true;
    let request = {
      "pcn": pcn
    };

    this.commonDataService.pingHoldInternal(request).subscribe(
      response => {
  
        if( response.status == 200 ){

          this.setDataToShow( pcn, response.body );

        }else if( response.status == 226 && response.body != null){

          this.alertMessage = new AlertModel( "Este socio está siendo editado por "+response.body["message"],this.title, true,"WARNING");
          this.loading = false;
        }else{

          this.alertMessage = new AlertModel( "Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title, true,"WARNING");
          this.loading = false;
        }
        
      }, err => {
        this.alertMessage = new AlertModel( "Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title, true,"WARNING");
        this.loading = false;
        throw err;
      }
    
    );
  }

  public setDataToShow( pcn:string, pers:any ){
    this.ngSelect = "";
    this.ngSelectAccion = "";

    this.reevaluarEnabled = false;
    
    let request = {
        "pcn": pcn
    };

    
    //filter resultData reemplazando el get
    this.selectedApplicant.data = this.resultsData.filter(data => data.pcn == request.pcn)
    if(this.selectedApplicant.data.length == 0){
      this.selectedApplicant.code = "100"
    }
    
    this.data = this.selectedApplicant

      if (this.data && this.data['code'] == "100"){
        this.alertMessage = new AlertModel(this.data['message'] , this.title, true);
        this.loading = false;
        return;
      }

      if (this.data && this.data["data"]) {

        if(this.data["data"].length == 0){
          this.loading = false;
          this.alertMessage = new AlertModel("No se pudieron obtener los datos.", this.title, true, "ERROR");   
          return;
        }
        this.aplicanteCurrent = new CustomerModel();
        this.aplicanteCurrent = Object.assign(this.aplicanteCurrent,this.data["data"][0]);
        this.aplicanteCurrent.intObservaciones = pers.intObservaciones;
        this.aplicanteCurrent.intPersonaSuples = pers.intPersonaSuples;
        ////

        if( this.aplicanteCurrent.intPersonaSuples.length ){
  
          for( var j = 0; j < this.aplicanteCurrent.intPersonaSuples.length; j++ ){
  
            this.aplicanteCurrent.intPersonaSuples = this.aplicanteCurrent.intPersonaSuples.filter(function (sup) {
              return sup.denegado !== true;
            })
          }
        }
  
        ////
        this.aplicanteCurrent.generoString = this.generosList.data.find(i=>i.key == this.aplicanteCurrent.intSocioData.gender)?.value;
        //profesionesList.data
        //this.aplicanteCurrent.professionString = this.profesionesList.data.find(i=>i.key == this.aplicanteCurrent.intSocioData.profession)?.value;

        if(  this.aplicanteCurrent.ciclo != null && parseInt( this.aplicanteCurrent.ciclo ) == -1 ){
          this.aplicanteCurrent.ciclo = "";
        }
        if( this.aplicanteCurrent.fechaCapturaPrecheck != null ){
          var fechaSplit = this.aplicanteCurrent.fechaCapturaPrecheck.split( "/" );
          var fechaInicio = fechaSplit[2]+"-"+fechaSplit[1]+"-"+fechaSplit[0];
          var diff = new Date().getTime() - new Date(fechaInicio).getTime();
          this.aplicanteCurrent.vigenciaPrecheck = Math.trunc(diff/(1000*60*60*24) )

          let codeRevaluado = this.parametersList.data.filter(a => a.codigo == "INTREEVALUAR")
          for( var i = 0; i < codeRevaluado.length; i++ ){
            if ( this.aplicanteCurrent.vigenciaPrecheck >= codeRevaluado[i].valor && this.aplicanteCurrent.tipoVenta != "SUP"){
              if(!this.sessionStorageService.hasInternalRolesUser(["AMX_INT_CONSULTA"])){
                this.reevaluarEnabled = true;
              }
              this.buttonSave = false;
            }
          }
        }

        if( this.aplicanteCurrent.equifaxInt == null ){
          this.aplicanteCurrent.equifaxInt = new equifaxInt();
        }
        if( this.aplicanteCurrent.nosisInt == null ){
          this.aplicanteCurrent.nosisInt = new nosisInt();
        }

        ////
        if(this.aplicanteCurrent.internalSourceCode){//CONDICION PARA VENTAS ISOLADAS (CUANDO NO TIENEN SOURCECODES)
          var value = this.aplicanteCurrent.internalSourceCode.idOriginalProd;
        }else{
          var value = this.aplicanteCurrent.informedProduct;
        }
       
        let prod = this.productosList.data.find(p=> (p.key??"") == parseInt( value ) );
        if( prod ){
          if(this.aplicanteCurrent.internalSourceCode){//CONDICION PARA VENTAS ISOLADAS (CUANDO NO TIENEN SOURCECODES)
            this.aplicanteCurrent.internalSourceCode.originalProdName = prod.value; 
          }else{
            this.aplicanteCurrent.informedProduct = prod.value; 
          }
        
        }

        ///

        this.aplicanteCurrentBk = JSON.parse(JSON.stringify(this.aplicanteCurrent));
          
        this.validationsShow = false;
        this.showFiltros = false;

        if( this.data.data[0].tipoVenta == "SUP" ){
          this.showAplicanteDetallesIso = true;
          if(!this.aplicanteCurrent.intPersonaSuples.length){
 
            this.accionAplicanteList.data  = Utils.setAccionListSuples(this.accionAplicanteList.data);

          }
          
        }else{
          this.showAplicanteDetallesIso = false;
          this.accionAplicanteList.data = this.accionListStore.data;
        }

        //PROVINCIA POR NUMERO
        if( !Utils.isNullOrEmpty( this.aplicanteCurrent.intSocioData.province ) ){
          let nameProvince = this.provinceData.filter(data => data.codigo == this.aplicanteCurrent.intSocioData.province);
          if( !Utils.isNullOrEmpty (nameProvince)){
            this.aplicanteCurrent.intSocioData.provinceName = nameProvince[0].descripcion
          }

        }

        //PROFESION POR NUMERO
        if( !Utils.isNullOrEmpty( this.aplicanteCurrent.intSocioData.profession ) ){
          let professionString = this.profesionesList.data.filter(data => data.key == this.aplicanteCurrent.intSocioData.profession);
          if( !Utils.isNullOrEmpty (professionString)){
            this.aplicanteCurrent.professionString = professionString[0].value
          }
    
        }

        let arrayTipoVentas = {"SUP":"Venta Isolada","RCO":"Reverse Companion","CON":"Conversion (Upgrade)","COM":"Companion"}
        this.aplicanteCurrent.tipoVenta = ( typeof arrayTipoVentas[ this.aplicanteCurrent.tipoVenta ] != "undefined" ) ? arrayTipoVentas[ this.aplicanteCurrent.tipoVenta ] : this.aplicanteCurrent.tipoVenta;
        ////////////////////////////////////////////////////////////////////////////////////////
        if( this.aplicanteCurrent.intPersonaSuples.length ){
          this.showTitleSuples = true;
          
          for( var i = 0; i < this.aplicanteCurrent.intPersonaSuples.length; i++ ){
            let obSuple = this.aplicanteCurrent.intPersonaSuples[ i ];
            obSuple.motivo = ""; 
            let prodSuple = null;
            obSuple.index = i+1;

            if( Utils.isNullOrEmpty(obSuple.nombreCompleto) ){

              if( Utils.isNullOrEmpty( obSuple.nombre2 ) ){
                obSuple.nombreCompleto = obSuple.nombre1 + " " +obSuple.apellido;
              }else{
                obSuple.nombreCompleto = obSuple.nombre1 + " " + obSuple.nombre2 + " " +obSuple.apellido;
              }
              
              obSuple.nombreCompleto = obSuple.nombreCompleto.toUpperCase();
            }
            obSuple.vinculo = obSuple.vinculo.toUpperCase();
            if( obSuple.vinculo == "AMIGA" ){
              obSuple.vinculo = "AMIGO";
            }
            if( obSuple.tipoDoc == "" || obSuple.tipoDoc == null ){
              obSuple.tipoDoc = "DNI";
            }
            if( obSuple.denegado != null && obSuple.denegado ){
              obSuple.denegadoString = "Denegado";
            }else{
              obSuple.denegadoString = "Aprobado";
            }

            if((this.aplicanteCurrent.tipoVenta == "Reverse Companion" 
            || this.aplicanteCurrent.tipoVenta == "Conversion (Upgrade)")
            && this.aplicanteCurrent.canalArchivo == "Telemarketing" 
            && this.aplicanteCurrent.productoObj.data.prod == "50"
            && obSuple.plasticColorType != "00" ){
              let prod = this.productosList.data.find(p=> (p.data.plasticColorType??"") == obSuple.plasticColorType );
              obSuple.idProd = prod.key

            }

            if( !Utils.isNullOrEmpty( obSuple.idProd ) ){
              prodSuple = this.productosList.data.filter(data => data.key == obSuple.idProd);
              //console.log(prodSuple)
              obSuple.productName = prodSuple[0].value;
            }else{
              // if(this.aplicanteCurrent.internalSourceCode){
                obSuple.productName = this.aplicanteCurrent.productoObj.value;
              // }else{
              //   obSuple.productName = this.aplicanteCurrent.informedProduct;
              // }
            }

          }
          
        }else{
          this.showTitleSuples = false;
        }
        if( this.aplicanteCurrent.intObservaciones.length ){
          this.aplicanteCurrent.intObservaciones.sort(function(a, b) {
            var textA = a.id;
            var textB = b.id;
            return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
          });
        }

        this.sourcecodesListForApplicant = [];
        //CONDICION PARA VENTAS ISOLADAS (CUANDO NO TIENEN SOURCECODES)
        if(this.aplicanteCurrent.internalSourceCode){ 
          if( !Utils.isNullOrEmpty( this.aplicanteCurrent.internalSourceCode.delivery1 ) && !Utils.isNullOrEmpty( this.aplicanteCurrent.internalSourceCode.delivery2 ) ){
            this.sourcecodesListForApplicant.push( this.aplicanteCurrent.internalSourceCode.delivery1 );
            this.sourcecodesListForApplicant.push( this.aplicanteCurrent.internalSourceCode.delivery2 );
            //this.aplicanteCurrent.deliveryCode = this.aplicanteCurrent.internalSourceCode.delivery1;
          }
        }

        if ( !this.aplicanteCurrent.deliveryCd && this.sourcecodesListForApplicant.length > 0) {
          this.aplicanteCurrent.deliveryCd = this.sourcecodesListForApplicant[0];
        }
        
        ////////////////////////////////////////////////////////////////////////////////////////

        this.showAplicanteDetalles = true;

        this.showResult = false
        this.loading = false;
        this.disableInputRolconsulta();
        this.refactorCp();
        this.setupTootip();            
        window.scroll(0, 0);
        this.commonDataService.pingHoldInternalTimerStart(pcn);
      }
      else {
        console.error(this.data);
      }
          
  }

  public loadDataRolConsulta(pcn:string){
    // let request = {
    //   "pcn" : pcn
    // }
    // this.newAccountsInternalsService.getAplicanteDetalle(request).subscribe(
    //   data => {
    //     this.productsApplicationSource.data = data["data"]     
    //     if(this.productsApplicationSource.data.find(v=>v.key==this.aplicanteCurrent) == null)
    //       this.disableInputRolconsulta()
    //     },
    //   error => console.error(error));   
  }

  public disableInputRolconsulta(){

    if (!this.rolAdmin){
      const inputs  = document.getElementsByClassName('form-control');
      for(let i=0;i<inputs.length;i++){
        inputs[i].setAttribute("disabled","true")
      }
    }
    
  if (!this.rolConsulta){
    const inputs  = document.getElementsByClassName('form-control');
    const guardarBtn  = document.getElementsByClassName('rolConsultaButton');
    for(let i=0;i<inputs.length;i++){
      inputs[i].setAttribute("disabled","true")
    }
    for(let i=0;i<guardarBtn.length;i++){
      guardarBtn[i].setAttribute("disabled","true")      
    }
  }
}

public rolesAutorization(){
  let rolesSession = this.sessionStorageService.getCurrentUser().roles

  if (rolesSession.includes("AMX_INT_ADMIN")){
    this.rolAdmin=false
  }

  if (rolesSession.includes("AMX_INT_CONSULTA")){
    this.rolConsulta=false
  }

  if(this.sessionStorageService.hasRolesArea("editNameNA")){
    this.editNameNA = true;
  }

}

  confirmSave(){

    try{
      if(!this.rolConsulta){
        this.alertMessage = new AlertModel("No tiene permisos para guardar",this.title, true, "WARNING");
        return;
      }
      
      if( !this.validIntPersona() ){
        return;
      }
      
    }catch{}
    
    this.alertConfirmEvent = this.onConfirmEvent;
    let aplicante: any = new Object;
    aplicante = this.getCustomerToSend( false );
    
    this.alertMessage = new AlertModel("<b>Confirma que desea registrar la información?</b>"+this.resumeString,this.title,true, "CONFIRM");	

  }
  
  validIntPersona():boolean{
    let validations = "";
    let arrayErrors = [];
    
    if( Utils.isNullOrEmpty( this.aplicanteCurrent.intSocioData.cardName ) )
      arrayErrors.push( "Debe completar el campo Nombre en Tarjeta.");

    if( !Utils.validSpecialCharactersWithoutNumbers( this.aplicanteCurrent.intSocioData.firstName) )
       arrayErrors.push("El campo Primer Nombre no puede contener números, doble espaciado ni los caracteres especiales \" \\ ;");

    if( !Utils.isNullOrEmpty( this.aplicanteCurrent.intSocioData.secondName ) && !Utils.validSpecialCharactersWithoutNumbers( this.aplicanteCurrent.intSocioData.secondName) )
       arrayErrors.push("El campo Segundo Nombre no puede contener números, doble espaciado ni los caracteres especiales \" \\ ;");

    if( !Utils.validSpecialCharactersWithoutNumbers( this.aplicanteCurrent.intSocioData.lastName) )
       arrayErrors.push("El campo Apellido no puede contener números, doble espaciado ni los caracteres especiales \" \\ ;");

    if( !Utils.validSpecialCharactersWithoutNumbers( this.aplicanteCurrent.intSocioData.cardName) )
       arrayErrors.push("El campo Nombre en Tarjeta no puede contener números, doble espaciado ni los caracteres especiales \" \\ ;");

    if(!Utils.validSpecialCharactersWithNumbers( this.aplicanteCurrent.intSocioData.street))
       arrayErrors.push("El campo direccion no puede contener doble espaciado ni los caracteres especiales \" \\ ;");

    if(!Utils.validSpecialCharactersWithNumbers( this.aplicanteCurrent.intSocioData.postalCode))
       arrayErrors.push("El campo Código Postal no puede contener doble espaciado ni los caracteres especiales \" \\ ;");

    if(!Utils.validSpecialCharactersWithNumbers( this.aplicanteCurrent.intSocioData.city))
       arrayErrors.push("El campo Localidad no puede contener doble espaciado ni los caracteres especiales \" \\ ;");
      
    if( this.aplicanteCurrent.internalSourceCode.delivery1 != null && Utils.isNullOrEmpty( this.aplicanteCurrent.deliveryCd ) )
      arrayErrors.push( "Debe seleccionar un Delivery Code.");

    if( !Utils.isNullOrEmpty( this.ngSelectAccion ) && Utils.isNullOrEmpty( this.ngSelect ) )
      arrayErrors.push( "Seleccione un Motivo.");

    if( this.aplicanteCurrent.intPersonaSuples.length ){
      for( var i = 0; i < this.aplicanteCurrent.intPersonaSuples.length; i++ ){

        var objSuple = this.aplicanteCurrent.intPersonaSuples[ i ];
        if( Utils.isNullOrEmpty( objSuple.nombreTj ) ){
          arrayErrors.push( "Debe completar el campo Nombre en Tarjeta de los adicionales.");
          break;
        }
        if( !Utils.validSpecialCharactersWithoutNumbers( objSuple.nombreTj ) ){
          arrayErrors.push("El campo Nombre en Tarjeta de los adicionales no puede contener números, doble espaciado ni los caracteres especiales \" \\ ;");
          break;
        }
        if( !Utils.validSpecialCharactersWithoutNumbers( objSuple.nombre1 ) ){
          arrayErrors.push("El campo Primer Nombre de los adicionales no puede contener números, doble espaciado ni los caracteres especiales \" \\ ;");
          break;
        }
        if( !Utils.isNullOrEmpty( objSuple.nombre2 ) && !Utils.validSpecialCharactersWithoutNumbers( objSuple.nombre2 ) ){
          arrayErrors.push("El campo Segundo Nombre de los adicionales no puede contener números, doble espaciado ni los caracteres especiales \" \\ ;");
          break;
        }
        if( !Utils.validSpecialCharactersWithoutNumbers( objSuple.apellido ) ){
          arrayErrors.push("El campo Apellido de los adicionales no puede contener números, doble espaciado ni los caracteres especiales \" \\ ;");
          break;
        }

        if( !Utils.isNullOrEmpty( $("#select-accion-"+objSuple.index).val() ) && Utils.isNullOrEmpty( $("#select-motivo-"+objSuple.index).val() ) ){
          arrayErrors.push( "Faltan seleccionar motivos en adicionales.");
          break;
        }

      }

    }

    if( arrayErrors.length ) {

      for( var i = 0; i < arrayErrors.length; i++ ){
        var conc = ( i == 0 )? "- " : "<br>- ";
        validations += conc + arrayErrors[ i ];
      }

        this.validateMessage = validations;
        this.alertMessage = new AlertModel(this.validateMessage,this.title,true,"WARNING");
        return false;
    }
    
    this.validateMessage = "";
    return true;
  }
  	
  onConfirmEvent(confirm:any){

    if(confirm.confirm){
      this.saveAplicanteData();
    }
  }
  getCustomerToSend(final:boolean):any{
    let aplicante: any = new Object;
    let cantSupAproved = 0;
    let cantSupDenied = 0;
    let titAproved: string = "Sin acción seleccionada";

    this.aplicanteCurrent.motivo = this.ngSelect;
    this.aplicanteCurrent.accion = ( !Utils.isNullOrEmpty( this.ngSelectAccion ) ) ? this.ngSelectAccion : null;
    this.aplicanteCurrent.nombreCompleto = (!Utils.isNullOrEmpty( this.aplicanteCurrent.nombreCompleto )) ? this.aplicanteCurrent.nombreCompleto.trim().toUpperCase() : null;
    if(this.aplicanteCurrent.intSocioData != null){
      this.aplicanteCurrent.intSocioData.cardName = this.aplicanteCurrent.intSocioData.cardName.trim().toUpperCase();
    }
    for( var i = 0; i < this.aplicanteCurrent.intPersonaSuples.length; i++ ){
      var objSuple = this.aplicanteCurrent.intPersonaSuples[ i ];
      objSuple.nombreCompleto = objSuple.nombreCompleto.trim().toUpperCase();
      if( !Utils.isNullOrEmpty( $( "#select-accion-"+objSuple.index ).val() ) && objSuple.accion.indexOf( "APROB" ) == -1 ){

        if( final ){
          //objSuple.denegado = true;
        }
        cantSupDenied++;
      }else if( Utils.isNullOrEmpty( $( "#select-accion-"+objSuple.index ).val() ) ){
        objSuple.accion = this.aplicanteCurrent.accion;
        objSuple.motivo = this.aplicanteCurrent.motivo;
        cantSupAproved++;
      }
    }
    
    aplicante = this.aplicanteCurrent;
    aplicante.persona = this.sessionStorageService.getCurrentUser()?.username;
    aplicante.promotor = this.sessionStorageService.getCurrentUser()?.promotor;
    aplicante.observaciones = this.aplicanteCurrent.observacion;

    // if( !Utils.isNullOrEmpty( this.aplicanteCurrent.intSocioData.province ) ){
    //   let codeProvince = this.provinceData.filter(data => data.descripcion == this.aplicanteCurrent.intSocioData.province);
    //   if( !Utils.isNullOrEmpty (codeProvince)){
    //     this.aplicanteCurrent.intSocioData.province = codeProvince[0].codigo.toString()
    //   }
    // }

    if( final ){
      if( !Utils.isNullOrEmpty( this.aplicanteCurrent.fechaEntrega ) ){
        aplicante.fechaEntrega = Utils.getTimesTamp( this.aplicanteCurrent.fechaEntrega );
      }else{
        aplicante.fechaEntrega = null;
      }
    }
    
    if( !Utils.isNullOrEmpty( this.aplicanteCurrent.accion ) && this.aplicanteCurrent.accion.indexOf( "APROB" ) != -1 ){
      titAproved = "APROBADO";
    }else if( !Utils.isNullOrEmpty( this.aplicanteCurrent.accion ) ){
      titAproved = this.aplicanteCurrent.accion;
    }

    this.resumeString = "<br>Titular: "+titAproved ;
    if( this.aplicanteCurrent.intPersonaSuples.length ){
      this.resumeString = this.resumeString +"<br>Supples aprobadas: "+ cantSupAproved +  "<br>Supples denegadas: "+cantSupDenied;
    }
    return aplicante;
  }
  saveAplicanteData(){

    let aplicante: any = new Object;
    aplicante = this.getCustomerToSend( true );
    //console.log( "aplicante",aplicante );
    this.saveAplicante(aplicante);
  }

  saveAplicante(aplicante) {

    this.loading = true;
    this.newAccountsInternalsService.editIntPersona( aplicante ).subscribe(
      response => {

        if( response.status == 200 ){
          //var data = response.body;
          
          this.alertMessage = new AlertModel("Los datos se guardaron con exito.", this.title, true, "SUCCESS");
          this.showAplicanteDetalles = false;
          this.showResult = false;
          this.loading = true;//mando true porque se vuelve a hacer la busqueda
          this.loadReportResults();
          window.scroll(0, 0);
          
        }else{
          this.alertMessage = new AlertModel("Hubo un inconveniente al guardar la información, inténtalo nuevamente.", this.title, true, ALERT_TYPE.WARNING);
          this.loading = false;
        }

        this.commonDataService.pingApplicantHoldTimerStop();
        this.commonDataService.releaseHoldInternal(this.aplicanteCurrent).subscribe();
        
      }, err => {
        this.commonDataService.pingApplicantHoldTimerStop();
        this.commonDataService.releaseHoldInternal(this.aplicanteCurrent).subscribe();
        this.alertMessage = new AlertModel("Hubo un inconveniente al guardar la información, inténtalo nuevamente.", this.title, true, ALERT_TYPE.WARNING);
        this.loading = false;
        throw err;
        
      }
    
    );
    
    return true;
  }


  messageTrackingShow(){
      this.messagetrackingshow = true;
  }



  // adicionalesList(){

  //   let adicionales = this.aplicanteCurrent.intPersonaSuples.filter((a)=>{

  //     return a.crud != CRUD.DELETE;
  //   });

  //   return adicionales;
  // }
  
  // adicionalesEditar(dni:any) {

  //   let adicional = this.aplicanteCurrent.intPersonaSuples.find((a)=>{

  //     return a.docNumSuple == dni;
  //   });

  //   let adicionalBk = this.aplicanteCurrentBk.intPersonaSuples.find((a)=>{
  //     return a.docNumSuple == dni;
  //   });

  //   this.adicionalCurrent  = Object.assign({}, adicional);
  //   this.adicionalCurrent.crud = adicionalBk ? CRUD.UPDATE : CRUD.CREATE;

  //   this.showAdicionalesEditar = true;
    
  //   return true;
  // }

  adicionalesNuevo() {

    let adicionales = this.aplicanteCurrent.intPersonaSuples.filter(a=>a.crud != CRUD.DELETE);
    if(adicionales.length >= 5){
      this.alertMessage = new AlertModel("Solo se permiten hasta 5 adicionales.", this.title,true, ALERT_TYPE.INFO);	
    }

    this.adicionalCurrent  = new  IntPersonaSuples();
    this.adicionalCurrent.crud = CRUD.CREATE;

    this.showAdicionalesEditar = true;
 }


  validationAccionLoad(){

    this.newAccountsInternalsService.getValidationAccion().subscribe(
      data => {
        if (data['data']) {
          this.accionAplicanteList.data = data['data'];
          this.accionSupleList.data = Utils.setAccionListSuples( data['data'] );
          this.accionListStore.data = data['data'];
        }
        else {
          console.error(data);
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true);
        }
      },
      error => {
        console.error(error);
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true);
      }
    );

    return true;
  }

  profesionesLoad(){

    this.commonDataService.getProfesiones().subscribe(
      data => {
        if (data['data']){
          this.profesionesList.data = data['data'];
          this.profesionesList.data.sort((a, b)=> a.value?.localeCompare(b.value));      
        } else{ 
          //console.error(data);
        }     
        
      },
      error => console.error(error)
    );

    return true;

  }

  loadProductos(){

    this.commonDataService.getProductsAndId().subscribe(
      data => {
        if (data['code'] == "0" && data['data']) {      
          
          this.productosList.data = Object.assign([], data['data']);

        }
        else {
          console.error(data);
        }

        
      },
      error => {
        console.error(error);
      }
    );

    
    

  }

  loadParameters(){

    this.commonDataService.getParameters().subscribe(
      data => {
        if (data['code'] == "0" && data['data']) {          
          this.parametersList.data = Object.assign([], data['data']);

          let paramReevaluarDias = this.parametersList.data
            .find(p=>p.nombre == "N DIAS REEVALUAR" 
                  && p.codigo == "REEVALUAR"
                  && p.activo == true);

          if(paramReevaluarDias != null){
            this.reevaluarDias = parseInt(paramReevaluarDias.valor);
          }

        }
        else {
          console.error(data);
          this.reevaluarDias = null;
        }
        
      },
      error => {
        console.error(error);
      }
    ); 

  }

  loadSourcecodes(){

    this.commonDataService.getSourcecodes().subscribe(
      data => {
        if (data['code'] == "0" && data['data']) {          
          this.sourcecodesList.data = Object.assign([], data['data']);
        }
        else {
          console.error(data);
        }

        
      },
      error => {
        console.error(error);
      }
    ); 

  }
  

  aplicanteAccionChange(item){
    
    this.loadingMotivo = true;
    let request = {"parent" : item}
    
    this.newAccountsInternalsService.getValidationMotivo(request).subscribe(
      data => {
        if (data['data']) {
          this.motivosAplicanteList.data = data['data'];

          this.motivosAplicanteList.data.sort((a, b)=> a.key?.localeCompare(b.key));  

        }
        else {
          console.error(data);
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true);
        }
        this.loadingMotivo = false;
      },
      error => {
        this.loadingMotivo = false;
        console.error(error);
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true);
      }
    );    

    return true;
  }

  supleAccionChange(item, index){

    this.loadingMotivo = true;
    let request = {"parent" : item}
    let motivosArray = [];

    this.aplicanteCurrent.intPersonaSuples[ index - 1 ].accion = item;

    this.newAccountsInternalsService.getValidationMotivo(request).subscribe(
      data => {
        if (data['data']) {
          
          this.motivosAplicanteList.data.sort((a, b)=> a.key?.localeCompare(b.key));  
          
          motivosArray = data['data'];
          const $select = document.querySelector("#select-motivo-"+index);
          var options = document.querySelectorAll("#select-motivo-"+index+" option");
          options.forEach(o => o.remove());
          const optionInit = document.createElement('option');
          optionInit.value = '';
          optionInit.text = "Seleccionar";
          $select.appendChild(optionInit);
          for( var i = 0; i < motivosArray.length; i++ ){
            const option = document.createElement('option');
            var obj = motivosArray[ i ];
            option.value = obj.value;
            option.text = obj.key;
            $select.appendChild(option);
          }

        }
        else {
          console.error(data);
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true);
        }
        this.loadingMotivo = false;
      },
      error => {
        this.loadingMotivo = false;
        console.error(error);
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true);
      }
    );    

    return true;
  }

  backToResults(){
    this.showAplicanteDetalles = false;
    this.showResult = true;
    this.loading = false;
    this.buttonSave = true;

    
    if(!Utils.isNullOrEmpty(this.aplicanteCurrent.pcn)){
      this.commonDataService.pingApplicantHoldTimerStop();
      this.commonDataService.releaseHoldInternal(this.aplicanteCurrent).subscribe();
    }

  }

  setupTable(){
    this.showAplicanteDetalles = false;
    this.showResult = true;
    this.loading = false;

    if (this.tablereports != null) {
      this.tablereports.clear().destroy();
    } 
    
    $(document).ready( () => {
        this.tablereports =   $("#result-table").DataTable({

        searching: true, 
        paging: true, 
        
        lengthChange: false,
        pageLength: 10,
        pagingType: 'full_numbers',
        columnDefs: [
          { targets: 'no-sort', orderable: false },
          {
            targets: 4,
            render: (data, type, row) => {
              if (type === 'sort') {
                return Utils.dateForm(data);
              }
              return data;
            }
          },
          {
            targets: 5,
            render: (data, type, row) => {
              if (type === 'sort') {
                return Utils.dateForm(data);
              }
              return data;
            }
          },
        ],        
        language: Environment.DataTableLanguageSetting
       });


    });

  }
    

  setCalendarLocale(){

    this.es = Environment.CalendarLanguageSetting;
  }
  
  public reevaluar(){
    this.loading = true;

    let request = {
      "pcn" : this.aplicanteCurrent.pcn,
      "cuil":true,
      "excepcion": this.isExcepcion,
      //"observacion": this.aplicanteCurrent.observacion || null,
      "selector": this.selector

    };
    
    
    this.newAccountsInternalsService.getApplicantReevaluation(request).subscribe(
      response => {

        if( response.status == 200 && response.body != null){
          let data:any = response.body;

          if(data.reevaluation.validation == "OK") {
            
            this.buttonSave = true;
            this.alertMessage =  new  AlertModel("APROBADO: "+ data.reevaluation.message, "REEVALUAR", true);
          } else if(data.reevaluation.validation == "NOOK"){
            
            this.alertConfirmEvent = ()=>{
              this.buttonSave = true;
              this.loading = false;          
              this.showAplicanteDetalles = false;
              this.showResult = false;

              //this.loadReportResults();
              //window.scroll(0, 0);   
            };

            //this.buttonSave = false;
            this.alertMessage =  new  AlertModel("RECHAZADO: " + data.reevaluation.message, "REEVALUAR", true, ALERT_TYPE.CONFIRM, null, false);
          }else{
            this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true);
          }

          //this.aplicanteCurrent.datos_precheck.vigenciaPrecheck = "0";
          this.reevaluarEnabled = false;
          this.recalcularEnabled = true;

            
        }else{
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true);
        }

        this.loading = false;

      },
      error => {
        this.loading = false;
        console.error(error);
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true);
      });  
          

  }


  validationsForm(){
    this.validations = new Array<any>();

    if(Utils.isNullOrEmpty(this.aplicanteCurrent.nombre1))
       this.validations.push({"datos_personales.nombre_primero" : "este campo es requerido."})

    if(Utils.isNullOrEmpty(this.aplicanteCurrent.apellido))
       this.validations.push({"datos_personales.apellido_primero" : "este campo es requerido."})       

    if(Utils.isNullOrEmpty(this.aplicanteCurrent.nombreCompleto ))
       this.validations.push({"datos_personales.nombre_completo" : "este campo es requerido."})

    if(this.nacionalidadesList .data.find(v=>v.key==this.aplicanteCurrent.nacionalidad) == null){
        this.aplicanteCurrent.nacionalidad = "";
        this.validations.push({"datos_personales.nacionalidad" : "este campo es requerido."})
    }  

    return this.validations.length == 0;
  }

  // public validationsMessage(name):string{
  //   if(!this.validationsShow)
  //     return null;

  //   this.validationsForm();

  //   let validation = this.validations.find(v => v[name] != null);

  //   if(validation){
  //     return validation[name];
  //   }
      
  //   return  null;
  // }

  validationsAdcionalForm(){
    this.validationsAdicional = new Array<any>();


    if(this.tiposDocumentosList.data.find(v=>v.key==this.adicionalCurrent.tipoDoc) == null){
      this.adicionalCurrent.tipoDoc = "";
      this.validationsAdicional.push({"adicionalCurrent.tipoDoc" : "este campo es requerido."})
    }  

    if(Utils.isNullOrEmpty(this.adicionalCurrent.docNumSuple))
             this.validationsAdicional.push({"adicionalCurrent.docNumSuple" : "este campo es requerido."});

    
    if(Utils.isNullOrEmpty(this.adicionalCurrent.fnac))
        this.validationsAdicional.push({"adicionalCurrent.fnac" : "este campo es requerido."});


    if(!Utils.isNullOrEmpty(this.adicionalCurrent.fnac)){

      let b = moment(this.adicionalCurrent.fnac, "DD/MM/YYYY");

      if(moment().diff(b, 'years') < 14)       
        this.validationsAdicional.push({"adicionalCurrent.fnac" : "no se permite menor a 14 años"})
    }         
    
    if(Utils.isNullOrEmpty(this.adicionalCurrent.nombreCompleto))
        this.validationsAdicional.push({"adicionalCurrent.nombreCompleto" : "este campo es requerido."});

    if(Utils.isNullOrEmpty(this.adicionalCurrent.nombreCompleto))
        this.validationsAdicional.push({"adicionalCurrent.nombreCompleto" : "este campo es requerido."});


    if(Utils.isNullOrEmpty(this.adicionalCurrent.apellido))
        this.validationsAdicional.push({"adicionalCurrent.apellido" : "este campo es requerido."});



    if(this.vinculosList.data.find(v=>v.key==this.adicionalCurrent.vinculo) == null){
      this.adicionalCurrent.vinculo = "";
      this.validationsAdicional.push({"adicionalCurrent.vinculo" : "este campo es requerido."})
    }  


    if(this.generosList.data.find(v=>v.key==this.adicionalCurrent.genero) == null){
      this.adicionalCurrent.genero = "";
      this.validationsAdicional.push({"adicionalCurrent.genero" : "este campo es requerido."})
    }      
    
    

    return this.validationsAdicional.length == 0;
  }

  // public validationsAdcionalMessage(name):string{
  //   if(!this.validationsAdicionalShow)
  //     return null;

  //   this.validationsAdcionalForm();

  //   let validation = this.validationsAdicional.find(v => v[name] != null);

  //   if(validation){
  //     return validation[name];
  //   }
      
  //   return  null;
  // }

  export(){

    try{
      if(!this.rolConsulta){
        this.alertMessage=new AlertModel("No tiene permisos para exportar",this.title,true,"WARNING");
        return;
      }
    }catch{}

    let reportDataExport = new Array<any>();

    this.resultsData.forEach(item => {   
      let objRow: any = new Object;
      objRow["NOMBRE Y APELLIDO"] = item.intSocioData.cardName;
      objRow.CUIL = item.intSocioData.cuil;
      objRow.DNI = item.intSocioData.dni;
      objRow.PCN = item.pcn;
      objRow.FECHA = item.fechaArchivo;
      objRow["ULTIMA MODIF"] = item.fechaModificacion;
      objRow.PRODUCTO = item.productoObj.data.prod+ " - " +item.productoObj.value;
      objRow.ESTADO = item.motivo;

      for( var i = 0; i < item.intPersonaSuples.length; i++ ){
        let obSup = item.intPersonaSuples[ i ];
        objRow["dni_sup_"+( i+1 )] = obSup.docNumSuple;
        objRow["nombre_sup_"+( i+1 )] = ( !Utils.isNullOrEmpty( obSup.nombreCompleto ) ) ? obSup.nombreCompleto : obSup.nombre1 + " "+obSup.apellido ;
        objRow["estado_sup_"+( i+1 )] = ( obSup.denegado ) ? "Denegado" : "Aprobado";
        objRow["motivo_sup_"+( i+1 )] = obSup.motivo;
      }

      reportDataExport.push(objRow);
    }); 

    this.commonDataService.exportAsExcelFile(reportDataExport, this.title);

  } 
  
  public recalcular(){
    this.loading = true;

    let request = {
      "pcn" : this.aplicanteCurrent.pcn,
      "ingresoLoc" : this.recalculoRequest,
      "documento" : this.aplicanteCurrent.docNum,
      "cuil":true
    };
    
    
    this.newAccountsInternalsService.getApplicantRecalculation(request).subscribe(
      data => { 
        let dataMessage = data['message'];
        if(data['data'] && data['code'] == "0") {
         let response : any = data;
          this.dataResult = data['data'][0];
        
          if(this.dataResult != undefined && this.dataResult.limiteFinal) {

            

            // this.aplicanteCurrent.datos_precheck.limiteFinal = this.dataResult.limiteFinal;
            // this.aplicanteCurrent.datos_precheck.limiteFinalOld =  this.dataResult.limiteFinalOld;
            // this.aplicanteCurrent.datos_precheck.ingresoReal = this.recalculoRequest
            // this.loading = false;
            // this.buttonSave = true;
            // this.limiteNuevo = true;
           
            // this.alertMessage =  new  AlertModel(dataMessage,null,true,"SUCCESS");
            // this.closeRecalculate()
            // $('.modal-backdrop').remove();
          } else {
            this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true);
          }
  
        }else{
          this.alertMessage = new AlertModel(dataMessage,null,true,"WARNING");
        }

        this.loading = false;

      },
      error => {
        this.loading = false;
        console.error(error);
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true);
      });  
          

  }

  openRecalculate(){
    this.recalculoRequest = "";
    this.openWindow = true;
  }

  closeRecalculate(){
    this.openWindow = false;
  }

  refactorObservationPaste(event:ClipboardEvent){ 
    this.aplicanteCurrent.observacion=this.sanitizedText(this.aplicanteCurrent.observacion); 
   }
   sanitizedText(text:string):string{
     return text.replace(/[^a-zA-Z0-9.$, ]/g,'')
   }

   refactorCp(){ 
    this.aplicanteCurrent.intSocioData.postalCode=this.sanitizedTextCp(this.aplicanteCurrent.intSocioData.postalCode); 
     }
 sanitizedTextCp(text:string):string{
   return text.replace(/[^0-9]/g,'')
 }
 
 refactorObservation(evento:any){
  const entrada= String.fromCharCode(evento.keyCode);
  const expresion=/[^a-zA-Z0-9.$, ]/g;

  if(expresion.test(entrada)){
    evento.preventDefault();
  }
 
}

  listProvinceLoad(){
  
    this.newAccountsInternalsService.getListProvince().subscribe(
    response => {
      if( response.status == 200 && response.body != null){
        var data = response.body;
        this.provinceData = data['data']

      }else if( response.status == 204 ){
        this.alertMessage = new AlertModel( "No se encontraron registros con los datos ingresados.",this.title, true);
      }else{
        this.alertMessage = new AlertModel( "Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title, true,"WARNING");
      }
  
      this.loading = false;
      },
    );

    

  }
 
}

import { Component, ElementRef, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import * as _ from 'lodash';
import { Environment } from '../../config/environment';

import { ActivatedRoute } from '@angular/router';
import {Router} from '@angular/router';
import { Config } from '../../config/config';
import { Subject } from 'rxjs';
import { AlertComponent, AlertModel, ALERT_TYPE } from '../../component/shared/alert/alert.component';
import { HttpClient, JsonpClientBackend } from '@angular/common/http';
import { InternalMassiveDeliveryService } from 'src/app/services_internal/internalMassiveDelivery/internalMassiveDelivery.service';
import { SessionStorageService } from 'src/app/auth/services/session.service';
import { BaseComponent } from '../../component/shared/base.component';

@Component({
  selector: 'app-exelFile',
  templateUrl: './internalMassiveDelivery.component.html',
  styleUrls: ['./internalMassiveDelivery.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class InternalMassiveDeliveryComponent extends BaseComponent implements OnDestroy, OnInit{

  public title: string = "Cambio de Estados Masivos Info. Delivery - Customer";
  public loading: boolean;
  public displayError: boolean;
  public msgError: string;
  public displayCombo: boolean;
  public showBackButton: any = Environment.SHOW_BACK_BUTTON;

  public alertMessage: AlertModel = new AlertModel;
  public alertConfirmEvent: Function;

  public table = null;
  public files = [];


  constructor(private router: Router,
     private route: ActivatedRoute, private Conf: Config, 
     private http: HttpClient, 
     private sessionStorageService: SessionStorageService,
     private internalMassiveDelivery: InternalMassiveDeliveryService) {

        super();
        if (!this.sessionStorageService.hasRolesArea('internalMassiveDelivery'))
        this.sessionStorageService.redirectToLogin();
        this.loading = true;
        this.displayError = false;
  }


  ngOnInit(): void {


    this.loading = false;
    this.displayError = false;
    this.msgError = null;

    this.loadTable();
  }

  ngOnDestroy(): void{
    
  }

  public uploadFile(event){
        
    let fileList: FileList = event.target.files;

    if(fileList.length == 0) {      
      this.alertMessage = new AlertModel("Seleccione solo un archivo.", this.title, true, ALERT_TYPE.WARNING);
      return;
    }

    let file: File = fileList[0];

    if(!file.name.toUpperCase().includes("DELIVERY_MASIVE_CUS")) {
      this.alertMessage = new AlertModel('El archivo no tiene el nombre indicado.', this.title, true, ALERT_TYPE.WARNING);
      return;
    }

    var extension = file.name.split(".").pop().toLowerCase();
    var extensiones = ["txt"];
   
    if(extensiones.indexOf(extension) === -1){
      this.alertMessage = new AlertModel("Seleccione un archivo con extención .txt ", this.title, true, ALERT_TYPE.WARNING);
      return;
   }else{
      this.loading = true;
      let usuario = this.sessionStorageService.getCurrentUser().name;

      this.internalMassiveDelivery.aprobacionMasivaInternalDeliveryFileUpload(file, usuario).subscribe(
        data => {
          if (data['code']== 0) {
            this.files = data['response'];
            this.loadTable();
            if( data['message']){
            this.alertMessage = new AlertModel(`La carga se procesó con exito parcial <br/>` + data['message'], this.title, true, ALERT_TYPE.INFO);
          }else{
            this.alertMessage = new AlertModel("La carga se procesó con exito ", this.title, true, ALERT_TYPE.SUCCESS);
          }
          }
          else {
            let message  = data['message'] ? data['message'] :"Hubo un inconveniente al obtener la información, inténtalo nuevamente.";
            this.alertMessage = new AlertModel(message, this.title, true, ALERT_TYPE.WARNING);
            this.loading = false;
          }
          
        },
        error => {
          console.error("Error");
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true, ALERT_TYPE.ERROR);
          this.loading = false;
        }
      );   


    }
    $("#importFile").val('');
  }

  public downloadFile(name){

    this.loading = true;
    let request = {"name" : name};

    this.internalMassiveDelivery.aprobacionMasivaFileDownload(request).subscribe(
      data => {
        
        if (data) {
          
          const blob = new Blob([data], { type: 'text/csv' });
          const url = window.URL.createObjectURL(blob);
          
          var filelink = document.createElement("a");
          filelink.download = name;
          filelink.href = url;
          filelink.click();
          
        }
        else {
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true, ALERT_TYPE.ERROR);
        }

        this.loading = false;
      },
      error => {
        console.error(error);
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true, ALERT_TYPE.ERROR);
        this.loading = false;
      }
    );

  }

 

  public loadTable(){
      this.loading = true;
      
      this.internalMassiveDelivery.getListIngresoArchivo({nombre:"DELIVERY_MASIVE_CUS"}).subscribe(
        data => {
          if (data['code']== 0) {
            this.files = data['response'];  
            this.files.forEach(i=> i.estado = i.estado.replace("PROCESANDO", "PROCESADO"))
            this.loading = false;
            this.setupTable();            
   
          }
          else {
            console.error("Error");
            this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true, ALERT_TYPE.ERROR);
          }
          this.loading = false;
        },
        error => {          
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información.", this.title, true, ALERT_TYPE.ERROR);
          this.loading = false;
          console.error("Error");
        }
      );   
  }


  setupTable(){

      if (this.table != null) {
        this.table.clear().destroy();
      } 
    

      $(document).ready( () => {
        this.table =   $("#main-table").DataTable({
                    paging: true, 
                    lengthChange: false,
                    pageLength: 10,
                    pagingType: 'full_numbers',
                    columnDefs: [
                      { targets: 'no-sort', orderable: false }
                    ],        
                    language: Environment.DataTableLanguageSetting
                  });
       });

       this.loading = false;
  }

}

import { Injectable,  Injector } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from "rxjs";
import { Utils } from "src/app/commons/utils";
import { Session } from "../models/session.model";
import { User } from "../models/user.model";

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {

  public sessionBehavior: BehaviorSubject<Session>;
  public sessionLogoutBehavior: BehaviorSubject<Session>;

  private localStorageService;
  private applicationStorageService;
  public currentSession : Session = null;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private injector: Injector ) {
    this.localStorageService = sessionStorage;
    this.applicationStorageService = localStorage;

    this.currentSession = this.loadSessionData();
    this.sessionBehavior = new BehaviorSubject<Session>(this.currentSession)
    this.sessionLogoutBehavior = new BehaviorSubject<Session>(this.currentSession)
  }

  setCurrentSession(session: Session): void {
    this.currentSession = session;
    this.sessionBehavior.next(this.currentSession);
    this.applicationStorageService.setItem( "currentUser",JSON.stringify(session) );
  }

  loadSessionData(): Session{
    var sessionStr = this.applicationStorageService.getItem('currentUser');
    return (sessionStr) ? <Session> JSON.parse(sessionStr) : new Session;
  }

  getCurrentSession(): Session {
    return this.currentSession;
  }

 clearStorageSession(): void {
    
    this.localStorageService.removeItem('currentUser');
    this.localStorageService.clear();
    this.currentSession = null;
  }

  removeCurrentSession(): void {
    
    this.applicationStorageService.removeItem('currentUser');//applicationStorageService
    this.applicationStorageService.clear();
    this.currentSession = null;

    this.sessionBehavior.next(null);
    //  this.sessionLogoutBehavior.next({logout : true} as Session);
  }

  getCurrentUser(): User {
    var session: Session = this.getCurrentSession();
    return session.user ? session.user : new User;
  };

  isAuthenticated(): boolean {
    
    var session = this.getCurrentSession();
    if(session && !session.login)
      return false;
    
    return (Utils.isNullOrEmpty(this.getCurrentToken())) ? false : true;
  }

  getCurrentToken(): string {
    var session = this.getCurrentSession();
    return (session && session.token) ? session.token : "";
  }

  public logout(): void{    
    this.removeCurrentSession();
    this.router.navigate(['/login']);
    // this.router.navigate(['/']);
  }

  redirectToLogin(): void{
    this.removeCurrentSession();
    this.router.navigate(['/login']);
    // this.router.navigate(['/']);
  }


  rolValidate(area:string){   
    if(this.hasRolesArea(area))
      return;

    this.router.navigate(['/']);
        
  }

  hasRolesArea(area:string): boolean {

    if(this.currentSession == null)
      return false;

    if(this.currentSession.user.roles == null)
      return false;

    if( this.currentSession.renewPassword )
      return false;
      
    let areaaccess = this.currentSession.user.acciones.find(r=> r == area);

    if(areaaccess == null)
       return false;

      
    return true;
  }

  hasRolesUser(roles:Array<string>): boolean {

    if(this.currentSession == null)
      return false;

    if(this.currentSession.user.roles.length == 0)
      return false;

    if(roles.length == 0)
      return false;
           
    if(roles.some(r => this.currentSession.user.roles.includes(r)))  
       return true;

    return false;
  }

  hasInternalRolesUser(roles:Array<string>): boolean {

    if(this.currentSession == null)
      return false;

    if(this.currentSession.user.roles.length == 0)
      return false;

    if(roles.length == 0)
      return false;
           
    if(roles.some(r => this.currentSession.user.roles.find(e => e.includes(r))))  

       return true;

    return false;
  }
  
  
  setIdp(data:any): void {
    this.applicationStorageService.setItem('idp', JSON.stringify(data));
  }

  getIdp():any {
    
    var idpStr = this.applicationStorageService.getItem('idp');
    return !Utils.isNullOrEmpty(idpStr) ? JSON.parse(idpStr) : {};
  }

}





import { Component, ViewEncapsulation } from '@angular/core';
import { Environment } from './config/environment';
import { ActivatedRoute, Router } from '@angular/router';

import { data } from 'jquery';
import { SessionStorageService } from './auth/services/session.service';
import { Session } from './auth/models/session.model';
import { CommonDataService } from './services/commons/commondata.service';
import { Utils } from './commons/utils';
import { User } from './auth/models/user.model';
import { AlertModel, ALERT_TYPE } from './component/shared/alert/alert.component';
declare var $: any;

const { version: appVersion, versiondev: devVersion } = require('../../package.json')

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class AppComponent  {
  title = 'UI Select';
  loading: boolean = false;
  visibleSidebar: boolean;
  version = appVersion;
  versiondev = devVersion;
  versionClickCount = 0;
  SHOW_HEADER = Environment.SHOW_HEADER;
  showUserInfo: boolean;
  message: string;
  messageShow: boolean = false;
  
  user: User;
  nameUser: string;
  agencie: string;
  roles: Array<string>;
  logedOptions: boolean;
  activeModUser: boolean=true;

  constructor(private router: Router, 
              private route: ActivatedRoute,
              public commonDataService: CommonDataService,
              public sessionStorageService: SessionStorageService) { 


      this.sessionStorageService.sessionBehavior.subscribe((session: Session)  => {
        
        if(session && session.user && session.login){
          this.showUserInfo = true;
          this.user = session.user;
          this.nameUser = session.user.name;
          this.agencie = Utils.isNullOrEmpty(session.user.agencia) ? null : session.user.agencia;
          this.roles = session.user.roles;
          this.loading = false;
          this.logedOptions = true;
        } else {
          this.logedOptions = false;
          this.showUserInfo = false;
          this.nameUser = null;
          this.roles = null;   
          this.agencie =null;       
        }
        
         
      });

      if(!this.sessionStorageService.isAuthenticated())
          this.sessionStorageService.redirectToLogin();
          
     

  }



  
  versionBackend(){

    this.versionClickCount++;

    if(this.versionClickCount == 5) {

        this.commonDataService.getVersion().subscribe(
          result => {
              if(data != null){
                let d = result as any;
                let msg = [];

                msg.push(`profile: ${d.profile}`);
                msg.push(`name: ${d.name}`);
                msg.push(`idpService: ${d.idpService }`);
                msg.push(`buildDate: ${d.buildDate }`);
                msg.push(`versionback: ${d.version }`);
                msg.push(`versionfront: ${this.version }`);
                msg.push(`versiondev: ${this.versiondev }`);
              

                
                this.message = msg.join('\n');
                this.messageShow = true;
                $("#message-home").modal("show")


              }
            
              this.versionClickCount = 0;

          },
          error =>  this.versionClickCount = 0
        );    
    }

  }
  

  public viewSidebar(){
    if($('#sidebar-menu').attr('class')=='sidebar-hidden'){
      $('#sidebar-menu').removeClass('sidebar-hidden');
     
    }else{
      $('#sidebar-menu').addClass('sidebar-hidden');

    }
  }

  public closeSessionUser(){
    this.loading = true;

    this.commonDataService.getLogout().subscribe(
      response => {

        this.loading = false;
        if( response.status == 200 && response.body != null && response.body["code"] == 0){
          this.sessionStorageService.logout();
        }else{
          console.log( response );
        }

      }, err => {
        console.log( err );
        this.loading = false;
        throw err;
      }
    );

  }


  hasRolesUser(rol:string): boolean {
    return this.sessionStorageService.hasRolesUser([rol]);
  }


  hasInternalRolesUser(rol:string): boolean {
    return this.sessionStorageService.hasInternalRolesUser([rol]);
  }


  
}

import { Component, OnInit } from '@angular/core';
import { AddressService } from 'src/app/services/address/address.service';
import { BaseComponent } from '../shared/base.component';
import { AlertModel, ALERT_TYPE } from '../shared/alert/alert.component';
import { SessionStorageService } from 'src/app/auth/services/session.service';
import { BusquedaFiltrosModel, AddressModel } from './models/models';
import { Environment } from '../../config/environment';
import { Utils } from 'src/app/commons/utils';
import { CommonDataService } from 'src/app/services/commons/commondata.service';

declare var $ : any;


@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.css'],
})
export class AddressComponent extends BaseComponent implements OnInit {

  public loading: boolean;
  public showResult : boolean;
  public alertConfirmEvent: Function;
  public alertMessage: AlertModel = new AlertModel;
  public busquedafiltros: BusquedaFiltrosModel = new BusquedaFiltrosModel;
  public es: any;
  public title: string = "EDICIÓN DE DIRECCIONES";
  public addressData = [];
  public tablereports = null;
  public rolConsulta : boolean=true;
  public showAplicanteDetalles : boolean;
  public addressCurrent: AddressModel = new AddressModel;
  public cssStatus: string;
  public errorMessage:string;
  public restCaraters: number;
  public regExAddress: any = /^[A-Za-zñÑáéíóúÁÉÍÓÚ0-9-'\s]+$/g;
  public cantMaxCaract: number = 25;

  constructor(private sessionStorageService: SessionStorageService,
              private addressService: AddressService,
              private commonDataService: CommonDataService) { 
    super();
    this.sessionStorageService.rolValidate("docrecovery");
    this.loading = true;
  }

  ngOnInit(): void {

    this.setCalendarLocale();
    this.loading = false;
    this.rolesAutorization();
  }

  public rolesAutorization(){
    let rolesSession = this.sessionStorageService.getCurrentUser().roles
      //console.log(rolesSession)
    if (rolesSession.includes("AMX_CONSULTA")){
      this.rolConsulta=false
    }
  }

  setCalendarLocale(){
    this.es = Environment.CalendarLanguageSetting;
  }

  loadReportResults(){
    
    if(Utils.dateIsValid(this.busquedafiltros.fechaDesde) &&
      Utils.dateIsValid(this.busquedafiltros.fechaHasta)){
      let cap_days =  Utils.dateDiffBetween(this.busquedafiltros.fechaDesde, this.busquedafiltros.fechaHasta, "days");

      if(cap_days < 0){
        this.alertMessage = new AlertModel("Fecha: Desde debe ser mayor que Hasta.",this.title,true,ALERT_TYPE.WARNING);
        return;
      }  
    }
    this.loadReportRequest();
    return true;
  }

  loadReportRequest(){
    this.loading = true;
    let request = { 
        "fechaDesde": Utils.parseEmptyNull(this.busquedafiltros.fechaDesde),
        "fechaHasta": Utils.parseEmptyNull(this.busquedafiltros.fechaHasta),
        "filterAddress":"S"
    };         

    this.addressService.getApplicantsReportByArea(request).subscribe(
      data => {
        if (data['data']) {

          this.addressData = data['data'];
          
          for(var i= 0; i < this.addressData.length; i++){
            console.log( this.addressData[i].direccionRecovery );
            if( this.addressData[i].direccionRecovery == null ){
              this.addressData[i].direccionRecovery = this.setAddress(this.addressData[i]);
            }
          }

          this.setupTable();

        }
        else {
          console.error(data);
          this.loading = false;
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true,ALERT_TYPE.WARNING);
        }
      },
      error => {
        console.error(error);
        this.loading = false;
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true, "ERROR");
      }
    );

    return true;
  }

  setAddress(obj:any):string{
    var finalAdress = "";
    if( obj.calle != null ){
      finalAdress += obj.calle;
      finalAdress += ( obj.numero != null )? " " + obj.numero : "";
      finalAdress += ( obj.piso != null )? " " + obj.piso : "";
      finalAdress += ( obj.depto != null )? " " + obj.depto : "";
    }
    return finalAdress;
  }

  setupTable(){

    this.showResult = true;
    this.loading = false;

    if (this.tablereports != null) {
      this.tablereports.clear().destroy();
    } 
    
    $(document).ready( () => {
      this.tablereports =   $("#result-table").DataTable({

      searching: true, 
      paging: true, 
      
      lengthChange: false,
      pageLength: 10,
      pagingType: 'full_numbers',
      columnDefs: [
        { targets: 'no-sort', orderable: false }
      ],        
      language: Environment.DataTableLanguageSetting
      
      });

    });

  }  

  export(){

    try{
      if(!this.rolConsulta){
        this.alertMessage=new AlertModel("No tiene permisos para exportar",this.title,true,"WARNING");
        return;
      }
    }catch{}

    let reportDataExport = new Array<any>();

    this.addressData.forEach(item => {      
      reportDataExport.push({
        "NOMBRE Y APELLIDO":item.nombreapellido,
        "PCN":item.pcn,
        "CUIL": item.cuit,
        "FECHA":item.fecha,
        "DIRECCION":item.direccionRecovery,
        "ESTADO":item.estado,
              
      });
    }); 

    this.commonDataService.exportAsExcelFile(reportDataExport, "EDICION_DE_DIRECCIONES");

  }  

  showAplicante(pcn:string){
    this.loading = true;

    let request = {
      "pcn":pcn
    };

    this.addressService.getApplicantDataByArea(request).subscribe(
      data => {

        if (data && data['code'] == "100"){
          this.alertMessage = new AlertModel(data['message'] , this.title, true);
          this.loading = false;
          return;
        }

        if (data && data.data) {

          if(data.data.length == 0){
            this.loading = false;
            this.alertMessage = new AlertModel("No se pudieron obtener los datos.", this.title, true, "ERROR");   
            return;
          }
          this.showAplicanteDetalles = true;
          window.scroll(0,0);
          var resp = data.data[0];
          this.setAddressCurrent( resp );

          this.showResult = false
          this.loading = false;
          this.setupTootip();
          this.commonDataService.pingApplicantHoldTimerStart(pcn);
        }
        else {
          console.error(data);
          this.loading = false;
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true, "ERROR");          
        }
      },
      error => {
        console.error(error);
        this.loading = false;
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true, "ERROR");
      }
    );      
    
    return true;
  
  }

  setAddressCurrent( resp:any ){
    this.addressCurrent.pcn = resp.solicitud_tarjeta.pcn;
    this.addressCurrent.cuil = resp.datos_laborales.cuil;
    this.addressCurrent.numeroDocumento = resp.datos_personales.numero_documento;
    this.addressCurrent.nombreCompleto = resp.datos_personales.nombre_completo;
    
    this.addressCurrent.provincia = resp.datos_personales.provincia;
    this.addressCurrent.localidad = resp.datos_personales.localidad;
    this.addressCurrent.codigoPostal = resp.datos_personales.codigo_postal;
    this.addressCurrent.calle = resp.datos_personales.calle;
    this.addressCurrent.numero = resp.datos_personales.calle_nro;
    this.addressCurrent.piso = resp.datos_personales.calle_piso;
    this.addressCurrent.depto = resp.datos_personales.calle_dpto;
    this.addressCurrent.direccion = (resp.datos_personales.direccion != null)? resp.datos_personales.direccion : this.setAddress(this.addressCurrent);

    this.addressCurrent.calleNew = resp.datos_personales.calle;
    this.addressCurrent.numeroNew = resp.datos_personales.calle_nro;
    this.addressCurrent.pisoNew = resp.datos_personales.calle_piso;
    this.addressCurrent.deptoNew = resp.datos_personales.calle_dpto;
    this.addressCurrent.direccionNew = (resp.datos_personales.direccion != null) ? resp.datos_personales.direccion : "";
    
    this.validAddress(false);
  }

  completeAddress(){
    this.addressCurrent.calleNew = this.addressCurrent.calleNew.toUpperCase();
    var calleEdit = this.noSpace( this.addressCurrent.calleNew );
    var numeroEdit = this.noSpace( this.addressCurrent.numeroNew );
    var pisoEdit = this.noSpace( this.addressCurrent.pisoNew );
    var deptoEdit = this.noSpace( this.addressCurrent.deptoNew );
    this.addressCurrent.direccionNew = ( calleEdit + " " + numeroEdit + " " + pisoEdit + " " + deptoEdit ).trim();

    this.validAddress(false);
  }

  validAddress( isValidForm:boolean ){
    this.errorMessage = "";
    this.cssStatus = "";
    let validations = "";
    let arrayErrors = [];
    let patt = new RegExp(this.regExAddress);
    let patt2 = new RegExp(this.regExAddress);
    let patt3 = new RegExp(this.regExAddress);
    let patt4 = new RegExp(this.regExAddress);
    let patt5 = new RegExp(this.regExAddress);
    let mjeInvalidCharacters = "Alguno de los campos tiene caracteres inválidos.";
    this.restCaraters = this.cantMaxCaract - this.addressCurrent.direccionNew.length;

    if(Utils.isNullOrEmpty(this.addressCurrent.direccionNew)){
      arrayErrors.push( "El campo Dirección es obligatorio" );
    }else if( !patt.test( this.addressCurrent.direccionNew ) ){
      arrayErrors.push( mjeInvalidCharacters );
    }

    if(Utils.isNullOrEmpty(this.addressCurrent.calleNew)){
      arrayErrors.push( "El campo Calle es obligatorio" );
    }else if( !patt2.test( this.addressCurrent.calleNew ) ){
      arrayErrors.push( mjeInvalidCharacters );
    }

    if(Utils.isNullOrEmpty(this.addressCurrent.numeroNew)){
      arrayErrors.push( "El campo Número es obligatorio" );
    }else if( !patt3.test( this.addressCurrent.numeroNew ) ){
      arrayErrors.push( mjeInvalidCharacters );
    }

    if( !Utils.isNullOrEmpty(this.addressCurrent.pisoNew) ){
      if( !patt4.test( this.addressCurrent.pisoNew ) ){
        arrayErrors.push( mjeInvalidCharacters );
      }
    }

    if( !Utils.isNullOrEmpty(this.addressCurrent.deptoNew) ){
      if( !patt5.test( this.addressCurrent.deptoNew ) ){
        arrayErrors.push( mjeInvalidCharacters );
      }
    }

    if( this.addressCurrent.direccionNew.length > this.cantMaxCaract ){
      arrayErrors.push( "La cantidad de caracteres excede los "+this.cantMaxCaract+" permitidos.");
    }

    if( arrayErrors.length ) {
      arrayErrors = Array.from(new Set(arrayErrors))
      this.cssStatus = "error-address";
      
      for( var i = 0; i < arrayErrors.length; i++ ){
        var conc = ( i == 0 )? "- " : "<br>- ";
        validations += conc + arrayErrors[ i ];
      }

      this.errorMessage = validations;

      if(isValidForm){
        this.alertMessage = new AlertModel(validations,this.title,true,"WARNING");
      }

      return false;
    }
    
    return true;
  }

  noSpace( t:string ){
    var arr = [];
	
    for(var i = 0; i < t.length; i++){
      if( t[i] != ' ' ){
        arr.push( t[i] )
      }else if( t[i] == ' ' && t[i - 1] != ' ' ){
        arr.push( ' ' )
      }
    }
    
    return arr.join('').trim();
  }

  saveAddress(){
    if( !this.validAddress(true) )
      return;

    this.loading = true;
    var data = {"pcn":this.addressCurrent.pcn
    , "direccion":this.noSpace( this.addressCurrent.direccionNew )
    ,"calle":this.noSpace( this.addressCurrent.calleNew )
    ,"numero":this.noSpace( this.addressCurrent.numeroNew )
    ,"piso":this.noSpace( this.addressCurrent.pisoNew )
    ,"depto":this.noSpace( this.addressCurrent.deptoNew )
    }
    //console.log( data );
    this.addressService.editAddress( data ).subscribe(
      response => {
        if( response.status == 200 && response.body != null){
          this.backToForm( true );
          
          this.alertMessage = new AlertModel("La dirección se editó correctamente", this.title, true, "SUCCESS");

        }else if( response.status == 204 ){
          this.alertMessage = new AlertModel( "No se encontraron registros con los datos ingresados.",this.title, true);
          this.loading = false;
        }else{
          this.alertMessage = new AlertModel( "Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title, true,"WARNING");
          this.loading = false;
        }
      
      },err => {
        if( !Utils.isNullOrEmpty( err.error.message ) ){
          this.alertMessage = new AlertModel( err.error.message+"<br>Revise los datos enviados",this.title, true,"WARNING");
        }else{
          this.alertMessage = new AlertModel( "Se produjo un error, revise los datos enviados",this.title, true,"WARNING");
        }
        this.loading = false;
        throw err;
      },
    );


  }

  backToForm( isSubmit:boolean ){
    this.commonDataService.pingApplicantHoldTimerStop();
    this.commonDataService.releaseApplicantHold(this.addressCurrent.pcn).subscribe();
    this.showAplicanteDetalles = false;
    if( isSubmit ){
      this.loadReportRequest();
    }else{
      this.showResult = true;
    }

    window.scroll(0,0);
  }
 
}




import { Injectable } from '@angular/core';
import * as $ from 'jquery';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Environment } from '../../config/environment';
import { Config } from '../../config/config';
import { config } from 'process';
import { of } from 'rxjs';
import { MockStatic } from 'src/app/config/mock.static';


@Injectable({
  providedIn: 'root'
})
export class NewAccountsService {


    constructor(private http: HttpClient, private Conf: Config) { }

    public getApplicantsReportByArea(data){

  

      let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }

      let url = this.Conf.ENDPOINT_API + 'getApplicantsReportByArea';
      let request = Object.assign({}, data);

      request.area = "NEWACCOUNT";

      return this.http.post(url, request, httpOptions);   

    }

    public getAplicanteDetalle(data:any){

      let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }



      let url = this.Conf.ENDPOINT_API + 'getApplicantDataByArea';
      let request = Object.assign({}, data);

      return this.http.post(url, request, httpOptions);        

    }

    public getApplicantReevaluation(data:any) {


      
      let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }

      let url = this.Conf.ENDPOINT_API + 'getApplicantReevaluation';
      let request = Object.assign({}, data);
      

      return this.http.post(url, request, httpOptions);        

    }    

    public saveApplicantDataByArea(data:any){    
      

      
      let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }



      let url = this.Conf.ENDPOINT_API + 'saveApplicantDataByArea';
      let request = Object.assign({}, data);
      request.area = "NEWACCOUNT";

      return this.http.post(url, request, httpOptions);        

    }

    public getValidationAccion(){

      let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }



      let url = this.Conf.ENDPOINT_API + 'getValidationAccion';
      let request = {"area": "NEWACCOUNT"};


      return this.http.post(url, request, httpOptions);         

    }

    public getValidationMotivo(data:any){
      
      let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }

      let url = this.Conf.ENDPOINT_API + 'getValidationMotivo';
      let request = Object.assign({}, data);

      request.area = "NEWACCOUNT";

      return this.http.post(url, request, httpOptions);        

    }

    public getApplicantRecalculation(data:any) {


      
      let httpOptions = {
        withCredentials: false,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      };

      if(Environment.CREDENTIALS){
        httpOptions.withCredentials = true;
      }

      let url = this.Conf.ENDPOINT_API + 'recalculate';
      let request = Object.assign({}, data);
      

      return this.http.post(url, request, httpOptions);        

    }


}
import { Component, OnInit } from '@angular/core';
//import { BusquedaFiltrosModel, AplicantDetailModel } from './models/models';
import { AplicantModel, AplicanteAdicionalModel, BusquedaFiltrosModel, CustomerModel, IntPersonaSuples, IntObservaciones 
} from 'src/app/models/aplicante.model';

import { LoadingComponent } from '../../component/shared/loading/loading.component';
import { AlertComponent, AlertModel, ALERT_TYPE } from '../../component/shared/alert/alert.component';
import { CommonDataService } from 'src/app/services/commons/commondata.service';
import { Utils } from 'src/app/commons/utils';
import { SessionStorageService } from 'src/app/auth/services/session.service';
import { InternalBridgerStatusService } from '../../services_internal/internalBridgerStatus/internalBridgerStatus.service';
import { Environment } from 'src/app/config/environment';


@Component({
  selector: 'app-bridgerstatus',
  templateUrl: './internalBridgerStatus.component.html',
  styleUrls: ['./internalBridgerStatus.component.css']
})


export class InternalBridgerStatusComponent  {
  public es: any;
  public title: string = "Bridger Customer - A espera de Type II";
  public loading: boolean;

  public alertMessage: AlertModel = new AlertModel;
  public alertConfirmEvent: Function;
  public adicionalesList = [];
  public dataResult = [];
  public showResult : boolean;
  public tablereports = null;

  public busquedafiltros: BusquedaFiltrosModel = new BusquedaFiltrosModel; 

  constructor(private bridgerStatusInternalService: InternalBridgerStatusService,
              private sessionStorageService: SessionStorageService,
              private commonDataService: CommonDataService) { 

             if(!this.sessionStorageService.hasRolesArea("internalBridger"))
                 this.sessionStorageService.redirectToLogin();
            else 
                 this.loadResults();
   }

 
  loadResults(){   

    this.showResult = false;
    this.loading = true;
    
    this.bridgerStatusInternalService.getData().subscribe(
      data => {
        if (data['data'] && data['code'] == "0") {
   
          this.dataResult = data['data'];
          this.dataResult.forEach(i=>{

            i.fecha_modificacion = ( !Utils.isNullOrEmpty( i.fecha_modificacion ) )? Utils.dateToString( new Date( i.fecha_modificacion ) ) : "";
            i.fecha = ( !Utils.isNullOrEmpty( i.fecha ) )? Utils.dateToString( new Date( i.fecha ) ) : "";
            if( i.suples != null && i.suples.length ){
              i.suples.forEach(j=>{

                i.suples = i.suples.filter(function (sup) {
                  return sup.denegado !== true;
                })

              })
            }

          });
         
          this.setupTable();             
  
        }else if(data['code'] == "1" ){
          this.loading = false;   
          this.alertMessage = new AlertModel(data['message'],this.title, true);
        }
        else {
          this.loading = false;   
          console.error(data);
          this.alertMessage = new AlertModel( "Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title, true);
        }
  
      },
      error => {
        console.error(error);
        this.loading = false;   
        this.alertMessage = new AlertModel( "Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title, true);
      }
    );
  
    
  }
  
  denegarConfirm(id){    
    
    this.alertConfirmEvent = this.onConfirmEvent;

    this.alertMessage = new AlertModel("Está seguro de denegar el PCN" + id + "?",this.title,true, "CONFIRM", id);
  }
  
  onConfirmEvent(confirm:any){

    if(confirm.confirm){
      this.denegar(confirm.data);
    }
  }

  cancel(){
    this.showResult = false;
  }



  public denegar(pcn){
    this.loading = true;

    let request = {
      "pcn" : pcn
    };
    
    
    this.bridgerStatusInternalService.denegadoBridger(request).subscribe(
      data => { 
        
        if(data["code"] == "0") {
          
       
          this.alertMessage = new AlertModel("Los datos se guardaron con exito.", this.title, true, "SUCCESS");
          this.loadResults();
            
        }else{
          this.alertMessage = new AlertModel("Hubo un inconveniente al procesar la información, inténtalo nuevamente.", this.title, true);
          this.loading = false;
        }        

      },
      error => {
        this.loading = false;
        console.error(error);
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true);
      });  
          

  }

  export(){

    let reportDataExport = new Array<any>();

    this.dataResult.forEach(item => {      
          reportDataExport.push({
            
            "PCN":item.pcn,
            "CUIT":item.cuil,
            "NOMBRE Y APELLIDO":item.nombreapellido,
            "GENERO":item.genero,
            "DNI":item.dni,
            "FECHA":item.fecha,
            "ULTIMA MODIF":item.fecha_modificacion,
            "PROMOTOR ORIGINAL":item.promotor,        
            "ESTADO":item.estado,
            "MOTIVO":item.motivo,
        }); 
      });

    this.commonDataService.exportAsExcelFile(reportDataExport, this.title);

  }

  
    

  setupTable(){

    this.showResult = true;
    this.loading = false;

    if (this.tablereports != null) {
      this.tablereports.clear().destroy();
    } 
    
    $(document).ready( () => {
        this.tablereports =   $("#result-table").DataTable({

        searching: true, 
        paging: true, 
        //info: false,
        lengthChange: false,
        pageLength: 10,
        pagingType: 'full_numbers',
        columnDefs: [
          { targets: 'no-sort', orderable: false }
        ],        
        language: Environment.DataTableLanguageSetting
      });


    });

  }
  
  public  showSubRow(id){
   
   
    $(".div-sub-row").not("#tr-" + id).hide();
    $("#tr-" + id).toggle(10);
  }

  openPopupSuples(pcn: string) {

    const selectedData = this.dataResult.find(data => data.pcn === pcn);
    if (selectedData && selectedData.suples.length) {
      this.adicionalesList = selectedData.suples.map(item => ({
        ...item,
        nombreCompleto: item.nombreCompleto || `${item.nombre1} ${item.apellido}`,
        denegado: item.denegado ? "DENEGADO" : "APROBADO"
      }));

      this.adicionalesList.forEach(j=>{
        j.caseNumber = ( parseInt( j.caseNumber ) == -1 )? "PASS" : j.caseNumber;
      })

    } else {
      this.adicionalesList = [];
    }
  }
  adicionalesCancelar(){
    this.adicionalesList = [];
  }

}


import { Component, OnInit } from '@angular/core';
import { ParametersIncomeNseService } from 'src/app/services/parametersIncomeNse/parametersIncomeNse.service';
import { BaseComponent } from '../shared/base.component';
import { Environment } from 'src/app/config/environment';
import { ParametersIncomeNseHeadList,ParametersIncomeNseHeadModel,ParametersIncomeNseModel,ParamNosisCompleteRequest } from './models/models';
import {  AlertModel, ALERT_TYPE } from '../shared/alert/alert.component';
import { SessionStorageService } from 'src/app/auth/services/session.service';
import { ExcelExportService } from 'src/app/services/excelExport/excel.export.service';

declare let $ : any;


@Component({
  selector: 'app-parameters-income-nse',
  templateUrl: './parametersIncomeNse.component.html',
  styleUrls: ['./parametersIncomeNse.component.css']
})

export class ParametersIncomeNseComponent extends BaseComponent implements OnInit {
  public title: string = "Parámetros de Income y NSE";
  public parametersIncomeNseHeadList: ParametersIncomeNseHeadList = new ParametersIncomeNseHeadList();
  public parametersIncomeNseHeadModelCurrent: ParametersIncomeNseHeadModel = new ParametersIncomeNseHeadModel();
  public parametersIncomeNseModel: ParametersIncomeNseModel = new ParametersIncomeNseModel();
  public paramNosisCompleteRequest: ParamNosisCompleteRequest = new ParamNosisCompleteRequest();
  public showResult : boolean;
  public showResultsTabs : boolean;
  public headValue: string = "";
  public resultData = [];
  public resultTabs = [];
  public loading: boolean;
  public alertMessage: AlertModel = new AlertModel();
  public initialTab = 0;
  public sendData = [];
  public inputFileVal : string = "";
  public actionFile: string = "";
  public fileListGlob: FileList;
  public showInitOptions : boolean;
  public showNewSetOptions : boolean;
  public validateMessage: string;
  public showBtnCreate: boolean;
  public showBtnSaveChanges: boolean;
  public minDate: string;
  public showBtnDelete: boolean;
  public showBtnUpload: boolean;

  public focusTab : string; //crear,cancelar,


    constructor(private sessionStorageService: SessionStorageService
        ,private excelExportService: ExcelExportService
        ,private parametersIncomeNseService: ParametersIncomeNseService) { 
      
          super();
          if(!(this.sessionStorageService.hasRolesUser(["AMX_ADMIN","AMX_INT_ADMIN"])
          || this.sessionStorageService.hasRolesUser(["AMX_RIESGO_ADM"]))){
                 this.sessionStorageService.redirectToLogin();
                }
          
          }
    
  ngOnInit(): void {
    this.showInitOptions = true;
    this.loadCabParametersIncomeNse();
  }

  loadCabParametersIncomeNse(){
    this.parametersIncomeNseService.getCabParametersIncomeNseList().subscribe(
      data => {
        if (data['code'] == "0" && data['data']) {    
          this.parametersIncomeNseHeadList = data['dataCab'];
          this.showResult = true;
        }
        else {
          console.error(data);
        }
        
      },
      error => {
        console.error("error en la carga de id de headers");
      }
    );
  }

  loadParamIncNseResult( isNew:boolean ){
   
    if( isNew && !this.newSetParamsValidate() ){
    return;
    }
    ///////////////
    
    ////////////////////

    if( isNew ){
      let dateVal = $( "#min-new-cab" ).val();
      let dateSplit = dateVal.split( "-" );
      $( "#min-new-cab-text" ).val( `${dateSplit[2]}/${dateSplit[1]}/${dateSplit[0]}` );
      this.parametersIncomeNseHeadModelCurrent.vigenciaHasta = $( "#min-new-cab-text" ).val();
      this.parametersIncomeNseHeadModelCurrent.instanciaId = this.parametersIncomeNseHeadList[0].instanciaId;
      this.showBtnCreate = true;
      this.showBtnSaveChanges = false;
      this.showBtnDelete = false;
      this.showBtnUpload = true;
    }else{
      const mapped = Object.keys(this.parametersIncomeNseHeadList).map(key => ({type: key, value: this.parametersIncomeNseHeadList[key]}));
      for( let h = 0; h < mapped.length; h++ ){
        if( mapped[ h ].value.instanciaId == parseInt( this.headValue ) && !isNew){
          this.parametersIncomeNseHeadModelCurrent = mapped[ h ].value;
          break;
        }
      }
      this.showBtnCreate = false;
      this.showBtnSaveChanges = true;
    }
    this.loading = true;
    
    this.parametersIncomeNseService.getParamIncNseDetByCab( this.parametersIncomeNseHeadModelCurrent ).subscribe(
      data => {
          if (data && data["code"] == "0" && data['data']) {
            this.initialTab = 0;
            this.loading = false;
            this.resultData = data['data'];
            this.resultTabs = data['tabs'];
            
            if( !isNew ){

              this.showBtnDelete = data[ 'showButtonDelete' ];
              this.showBtnUpload = data[ 'showButtonDelete' ];
              
            }
            this.setContentTabs();

        }
        else {
          this.loading = false;
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true,"ERROR");
        }
      },
      error => {
        this.loading = false;
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true,"ERROR");
      }
    );

    return true;
  
  }

  setContentTabs(){ //creo un array de arrays para cada solapa segun el tipo de parametro
    let arrayTabs = [];

    for( let h = 0; h < this.resultTabs.length; h++ ){
      let arrTab = [];
      for( let i = 0; i < this.resultData.length; i++ ){
        if( this.resultData[ i ].tipoParam == this.resultTabs[ h ] ){
          arrTab.push( this.resultData[ i ] );
        }

      }

      arrayTabs.push( arrTab );
    }

    this.resultData = arrayTabs;

    ///////////////////////////
    let arrayFinal = [];
    for( let j = 0; j < this.resultData.length; j++ ){
      
      let js = this.resultData[ j ];
      let cant = 15;
      let subArray = '[[';
      for(let i = 0; i < js.length; i++ ){
        let ob = js[ i ];
        let conc = ( ( i+1 ) % cant ) == 0 ? '': ',';
        subArray += JSON.stringify( ob ) + conc;
        if( ( ( i+1 ) % cant ) == 0 ){
          subArray += '],['
        }
      }
      let isOk = subArray.slice( -2 ) == ',['
      subArray = ( isOk ) ? subArray.substring(0, subArray.length - 2) + ']' : subArray.substring(0, subArray.length - 1)+ ']]';

      arrayFinal.push( JSON.parse( subArray ) );

    }

    ///////////////////////////////
    this.resultData = arrayFinal;

    this.showResultsTabs = true;

  }

  functionDetailTab(){ //funcion de solapa

    if( this.initialTab == 0 ){

      let tabClass = ".data-tab-header";
      $( tabClass ).unbind();

      $( tabClass ).on('click',function(){
        $( tabClass ).find( "a.nav-link" ).removeClass("active");
        $( this ).find( "a.nav-link" ).addClass("active");
        let tab = $( this ).attr( "id" );
        
        $( ".data-tab" ).removeClass("tab-active");
        $( ".data-tab."+tab ).addClass("tab-active");
      });

      //set focus al tab correspondiente
      if(this.focusTab != undefined && this.focusTab != ""){
        $( "#data-"+this.focusTab+" a").trigger( "click" );
      }else{
      $( ".card.results-parmeters ul.nav.nav-tabs li:first-child a" ).trigger( "click" );
      }
      this.initialTab = 1;

    }

  }

  getParametersData( complete:boolean ):Array<any>{//obtengo un array de detalles para actualizar
    let locArray = [];
    let idObj = ( complete ) ? ".row-detail-parameter" : ".tab-active .row-detail-parameter";
    $( idObj ).each(function(){

      let values: string[] = $( this ).find('.obj-value').val().split("__");
      let val = $( this ).find('.param-value').val();

      this.parametersIncomeNseModel = new ParametersIncomeNseModel();
      this.parametersIncomeNseModel.valor = val;
      this.parametersIncomeNseModel.detId = values[0];
      this.parametersIncomeNseModel.instanciaId = values[1];
      this.parametersIncomeNseModel.tipoParam = values[2];
      this.parametersIncomeNseModel.nombreVariable = values[3];
      this.parametersIncomeNseModel.tipoDato = values[4];
      this.parametersIncomeNseModel.ordenAbm = values[5];
      this.parametersIncomeNseModel.ordenParam = parseInt( values[6] );
      this.parametersIncomeNseModel.label = values[7];

      locArray.push( this.parametersIncomeNseModel );
    });
    
    return locArray;
  }

  updateParametersData(){

    this.sendData = this.getParametersData( true );

    this.loading = true;

      this.parametersIncomeNseService.updateMatrix(this.sendData).subscribe(
        data => {
          if (data && data["code"] == "0") {
            this.loading = false;
            this.alertMessage = new AlertModel("Los datos se guardaron con exito",this.title,true, "SUCCESS");
          }
          else {
            this.loading = false;
            this.alertMessage = new AlertModel("Hubo un inconveniente al guardar la información, inténtalo nuevamente.",this.title,true,"ERROR");
          }
        },
        error => {
          console.error(error);
          this.loading = false;
          this.alertMessage = new AlertModel("Hubo un inconveniente al guardar la información.",this.title,true,"ERROR");
        }
      );


  }

  sortJSON(data, key, orden) {
      return data.sort(function (a, b) {
          let x = a[key],
          y = b[key];
  
          if (orden === 'asc') {
              return ((x < y) ? -1 : ((x > y) ? 1 : 0));
          }
  
          if (orden === 'desc') {
              return ((x > y) ? -1 : ((x < y) ? 1 : 0));
          }
      });
  }

  exportParametersDetails(){
    this.sendData = this.sortJSON( this.getParametersData( false ), 'ordenParam', 'asc' );
    let reportDataExport = new Array<any>();
    let tipoParamReq = $( ".data-tab.tab-active" ).attr( "id" );

    this.sendData.forEach(item => {      
      reportDataExport.push({
        "nombre_variable":item.nombreVariable,
        "valor":item.valor
      });
    });


    this.excelExportService.exportCsvWithSeparator(reportDataExport, tipoParamReq,';');

    
    
  }

  selectUploadType(item) {
    this.fileListGlob = null;
    this.actionFile = "";
    this.fileListGlob = item.target.files;
    
    $('#upload-nuevo').modal();
  }

  cancelUploadParamsDetail(){
    this.inputFileVal = "";
    $('#upload-nuevo').modal('hide');
  }

  cleanFile(){
    let tipoParamReq = $( ".data-tab.tab-active" ).attr( "id" );
    $( "#importFile-"+tipoParamReq ).val('');
  }


  public mergeData( data ){

    let arrayAlterTab = this.getParametersData(false);
    let arrayMod = [];
    for( let i = 0; i < data.length; i++ ){

      for( let h = 0; h < arrayAlterTab.length; h++ ){
        if( arrayAlterTab[ h ].detId == data[ i ].detId  ){
          arrayAlterTab[ h ].valor = data[ i ].valor;
          arrayMod.push( arrayAlterTab[ h ] );
        }
      }

    }

    let objArray = JSON.parse( localStorage.getItem( "arrayDetailCreate" ) );
    let newArrayUpload = [];

    for( let j = 0; j < objArray.length; j++ ){

      for( let i = 0; i < arrayMod.length; i++ ){

        if( objArray[ j ].detId == arrayMod[ i ].detId ){
          objArray[ j ].valor = arrayMod[ i ].valor;
          newArrayUpload.push( objArray[ j ] );
        }

      }

    }
    localStorage.setItem( "arrayDetailCreate", JSON.stringify( objArray ) );
    this.resultData = objArray;

  }

  public uploadParamaNosisDet(event){
    let tipoParamReq = $( ".data-tab.tab-active" ).attr( "id" );
    let fileList: FileList = this.fileListGlob;
    this.focusTab = tipoParamReq //variable global para hacer focus en el tab
    //si no hay un archivo seleccionado
    if(fileList.length == 0) {      
      this.alertMessage = new AlertModel("Seleccione solo un archivo.", this.title, true, ALERT_TYPE.WARNING);
      return;
    }
    
    let file: File = fileList[0];
    let extension = file.name.split(".").pop().toLowerCase();
    let extensiones = ["csv"];
    //si no es un archivo csv
    if(extensiones.indexOf(extension) === -1){
      this.alertMessage = new AlertModel("Seleccione un archivo con extención .csv ", this.title, true, ALERT_TYPE.WARNING);
      return;
   }else{
      this.loading = true;
      let isNew = ( $("#new-set-options").length ) ? "S" : "N";
      this.cancelUploadParamsDetail();
      this.parametersIncomeNseService.uploadMassiveNormalizePatterns(file, this.parametersIncomeNseHeadModelCurrent.instanciaId,tipoParamReq,isNew).subscribe(
        data => {
          if (data['code']== 0) {
            if( isNew == "N" ){
              this.alertMessage = new AlertModel("La carga se procesó con exito", this.title, true, ALERT_TYPE.SUCCESS);
              this.loadParamIncNseResult(false);
            }else{
              this.initialTab = 0;
              this.resultData = data['data'];
              this.resultTabs = data['tabs'];


              if( localStorage.getItem( "arrayDetailCreate" ) != null ){
                this.mergeData( this.resultData );
              }else{
                localStorage.setItem( "arrayDetailCreate", JSON.stringify( data['data'] ) );
              }

              this.setContentTabs();
              // this.focusTab = "";
              

            }
            this.loading = false;
            
          }
          else {
            let message  = data['message'] ? data['message'] :"Ocurrió un error en la carga, inténtalo nuevamente.";
            this.alertMessage = new AlertModel(message, this.title, true, ALERT_TYPE.WARNING);
            this.loading = false;
          }

         
        },
        error => {
          console.error("Error");
          this.alertMessage = new AlertModel("Ocurrió un error en la carga, inténtalo nuevamente.", this.title, true, ALERT_TYPE.ERROR);
          this.loading = false;
        }
      );


    }

  }

  succesCreate(){
    this.alertMessage = new AlertModel("Los datos se guardaron con exito",this.title,true,"SUCCESS");
    this.loadCabParametersIncomeNse();
    this.cancelNewParamsSet();
  }

  showNewParamsSet(){
    this.showInitOptions = false;
    this.showNewSetOptions = true;
    this.showResultsTabs = false;
    this.minDate = this.setDateMinNewCab( this.parametersIncomeNseHeadList[0].vigenciaDesde );
  }

  setDateMinNewCab( date:String ):string{
    let dateSplit = date.split( "/" );
    let dateFromat = dateSplit[2]+"-"+dateSplit[1]+"-"+dateSplit[0];
    
    let dateNew = new Date( dateFromat+"T00:00:00" );
    //sumo un dia al calendario para el desde
    dateNew.setDate( dateNew.getDate() + 1 );
    let dateNewSplit = dateNew.toISOString().split( "T" )[0];

    $("#min-new-cab").val("");
    $( "#min-new-cab" ).attr( "min", dateNewSplit );
    return dateNewSplit;
  }

  cancelNewParamsSet(){
    localStorage.removeItem("arrayDetailCreate");
    this.loadCabParametersIncomeNse();
    this.showInitOptions = true;
    this.showNewSetOptions = false;
    this.showResultsTabs = false;
    this.headValue = "";
    this.focusTab = "";
  }

  createNewSetParams(){
    
    this.paramNosisCompleteRequest = new ParamNosisCompleteRequest();
    this.paramNosisCompleteRequest.paramNosisCab = this.parametersIncomeNseHeadList[0];
    //this.paramNosisCompleteRequest.paramNosisCab.vigenciaDesde = this.parametersIncomeNseHeadList[0].vigenciaDesde;
    let dateVal = $( "#min-new-cab" ).val();
    let dateSplit = dateVal.split( "-" );
    $( "#min-new-cab-text" ).val( `${dateSplit[2]}/${dateSplit[1]}/${dateSplit[0]}` );
    this.paramNosisCompleteRequest.paramNosisCab.vigenciaHasta = $( "#min-new-cab-text" ).val();
    
    this.paramNosisCompleteRequest.listParamNosisDet = this.getParametersData( true );

    if(!this.newSetParamsValidate())
        return;

    this.loading = true;
    this.parametersIncomeNseService.createNewSetParams( this.paramNosisCompleteRequest ).subscribe(
      data => {
        if (data && data["code"] == "0") {
          this.loading = false;
          this.focusTab = "";
          this.succesCreate();

        }
        else {
          this.loading = false;
          this.focusTab = "";
          this.alertMessage = new AlertModel("Hubo un inconveniente al guardar la información, inténtalo nuevamente.",this.title,true,"ERROR");
        }
      },
      error => {
        console.error(error);
        this.loading = false;
      }
    );


  }

  newSetParamsValidate():boolean{

    let validations = "";
    let arrayErrors = [];

    if($("#min-new-cab").val() == null || $("#min-new-cab").val() == ""){
      arrayErrors.push( "Debe seleccionar una fecha de inicio." );
     }
    
    if( arrayErrors.length ) {

      for( let i = 0; i < arrayErrors.length; i++ ){
        let conc = ( i == 0 ) ? "<i class='fa fa-warning'></i> " : "<br><i class='fa fa-warning'></i> ";
        validations += conc + arrayErrors[ i ];
      }

        this.validateMessage = validations;
        this.alertMessage = new AlertModel(this.validateMessage,this.title,true,"WARNING");
        return false;
    }

    this.validateMessage = "";
    return true;
  }

  paramNosisDeleteConfirm() {
    this.alertMessage = new AlertModel("Está seguro de eliminar esta matriz",this.title,true, "CONFIRM");

  }

  onConfirmEvent(confirm:any){
    if(confirm.confirm){
      this.paramNosisDelete();
    }
  }

  succesDelete(){
    this.alertMessage = new AlertModel("Los parámetros se eliminaron correctamente",this.title,true,"SUCCESS");
    this.loadCabParametersIncomeNse();;
    this.cancelNewParamsSet();
    //this.showBtnDelete = false;
  }

  paramNosisDelete(){
    /////////
    this.loading = true;
    this.parametersIncomeNseService.deleteParamNosis( this.parametersIncomeNseHeadModelCurrent.instanciaId ).subscribe(
      data => {
        console.log("data",data);
        if (data && data["code"] == "0") {
          this.loading = false;
          this.succesDelete();
        }
        else {
          this.loading = false;
          this.alertMessage = new AlertModel("Hubo un inconveniente al intentar eliminar los parámetros.",this.title,true,"ERROR");
        }
      },
      error => {
        console.error(error);
        this.loading = false;
      }
    );
    ///////////
  }

  

   refactorNonNumeric(evento:any){
    const entrada= String.fromCharCode(evento.keyCode);
    const expresion=/\D/g
    
    if(expresion.test(entrada)){
      evento.preventDefault();
    }
   
  }

}
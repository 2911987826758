export class InternalSourceCodesModel {
    constructor(){
        this.idProd = 0;
    }
    
    id: string;
    canal: string;
    descripcion: string;
    sid: string;
    estado: string;
    comercializadora: string;
    idPromotor: string;
    idProd: number;
    idOriginalProd: number;
    ciclo: string;
    source_code: string;
    source_code_completo: string;
    tipo_aplicacion: string;
    delivery_1: string;
    delivery_2: string;
    prod_name: string;
    prod_mns: string;
    ingreso: string;
    prod: string;
    created_timestamp: string;
    created_user: string;
    last_updated: string;
    initial_effective_date: string;
    final_effective_date: string;
    originalProdName: string;
    last_user_modified: string;
    tipo_de_venta: string;
}


export class Vendor{
    id: string;
    name: string;
}

export class VendorList {

    constructor(){
        this.data = new Array<Vendor>();
    }

    data: Array<Vendor>;
}

export class Status{
    code: string;
    name: string;
}

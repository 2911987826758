import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import * as _ from 'lodash';
import { Environment } from '../../config/environment';

import { AlertComponent, AlertModel, ALERT_TYPE } from '../../component/shared/alert/alert.component';
import { InternalMassiveTelemarketingService } from '../../services_internal/internalMassiveTelemarketing/internalMassiveTelemarketing.service';
import { SessionStorageService } from 'src/app/auth/services/session.service';
import { extend } from 'jquery';
import { BaseComponent } from '../../component/shared/base.component';

@Component({
  selector: 'app-internalMassiveTelemarketing',
  templateUrl: './internalMassiveTelemarketing.component.html',
  styleUrls: ['./internalMassiveTelemarketing.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class InternalMassiveTelemarketingComponent extends BaseComponent implements OnInit{

  public title: string = "Procesamiento Masivo - Telemarketing Internal";
  public loading: boolean;
  public displayError: boolean;
  public msgError: string;
  public displayCombo: boolean;
  public showBackButton: any = Environment.SHOW_BACK_BUTTON;

  public alertMessage: AlertModel = new AlertModel;
  public alertConfirmEvent: Function;

  public table = null;
  public files = [];


  constructor(private sessionStorageService: SessionStorageService,
              private internalMassiveTelemarketingService: InternalMassiveTelemarketingService) { 

            super();
            this.sessionStorageService.rolValidate("internalMassiveTelemarketing");

            this.loading = true;
            this.displayError = false;
            
  }

  ngOnInit(): void {
    this.loading = false;
    this.displayError = false;
    this.msgError = null;

    this.loadTable();
  }


  public uploadFile(event){
        
    let fileList: FileList = event.target.files;

    if(fileList.length == 0) {      
      this.alertMessage = new AlertModel("Seleccione solo un archivo.", this.title, true, ALERT_TYPE.WARNING);
      return;
    }

    let file: File = fileList[0];

    if(!file.name.toUpperCase().includes("DBI2VRZ_CUS_")) {
      this.alertMessage = new AlertModel('El nombre de archivo debe contener la palaba "DBI2VRZ_CUS_".', this.title, true, ALERT_TYPE.WARNING);
      return;
    }

    var extension = file.name.split(".").pop().toLowerCase();
    var extensiones = ["txt"];
   
    if(extensiones.indexOf(extension) === -1){
      this.alertMessage = new AlertModel("Seleccione un archivo con extención .txt ", this.title, true, ALERT_TYPE.WARNING);
      return;
   }else{
      this.loading = true;
      let usuario = this.sessionStorageService.getCurrentUser().name;

      this.internalMassiveTelemarketingService.aprobacionInternalMasivaFileUpload(file, usuario).subscribe(
        response => {
          if( response.status == 200 ){
            this.alertMessage = new AlertModel("La carga se procesó con exito", this.title, true, ALERT_TYPE.SUCCESS);
          }else{
            this.alertMessage = new AlertModel( "Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title, true,"WARNING");
          }
          this.loadTable();
   
        },error => {
          this.loading = false;
          this.loadTable();
          let message = this.concatenateErrorMessages(error);
          this.alertMessage = new AlertModel(message, this.title, true, ALERT_TYPE.WARNING);
        }
      );   

    }
    $("#importFile").val('');
  }



  concatenateErrorMessages(error) {
    let errorMessage = error.error.length ? error.error.join("<br/>") : "Hubo un inconveniente en la carga del archivo";
    return errorMessage;
  }
 

  public downloadFile(name){

    let request = {"name" : name};

    this.internalMassiveTelemarketingService.aprobacionMasivaFileDownload(request).subscribe(
      data => {

        if (data) {
          
          const blob = new Blob([data], { type: 'text/csv' });
          const url = window.URL.createObjectURL(blob);
          
          var filelink = document.createElement("a");
          filelink.download = name;
          filelink.href = url;
          filelink.click();
          
        }
        else {
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true, ALERT_TYPE.ERROR);
        }

        this.loading = false;
      },
      error => {
        console.error(error);
        this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true, ALERT_TYPE.ERROR);
        this.loading = false;
      }
    );

  }

 
  public loadTable(){
      this.loading = true;
      
      this.internalMassiveTelemarketingService.getListIngresoArchivo({nombre:"DBI2VRZ_CUS_TLMK"}).subscribe(//Busca archivos de nomenclatura DBI2VRZ_CUS_TLMK
        data => {
          if (data['code']== 0) {
            this.files = data['response'];
            this.files.forEach(i=> i.estado = i.estado.replace("PROCESANDO", "PROCESADO"))
            this.setupTable();
          
          }
          else {
            console.error("Error");
            this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true, ALERT_TYPE.WARNING);
            this.loading = false;
          }

         
        },
        error => {
          console.error("Error");
          this.alertMessage = new AlertModel("Hubo un inconveniente al obtener la información, inténtalo nuevamente.", this.title, true, ALERT_TYPE.WARNING);
          this.loading = false;
        }
      );   
  }

  setupTable(){
    
    this.loading = false;
    if (this.table != null) {
      this.table.clear().destroy();
    } 

    $(document).ready( () => {
      this.table =   $("#main-table").DataTable({
                  paging: true, 
                  lengthChange: false,
                  pageLength: 10,
                  pagingType: 'full_numbers',
                  order: [[0, 'desc']],
                  // columnDefs: [
                  //   { targets: 'no-sort', orderable: false }
                  // ],        
                  language: Environment.DataTableLanguageSetting
                });
     });

}

}

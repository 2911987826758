import {  Component, OnInit } from '@angular/core';
import { AlertModel } from '../../component/shared/alert/alert.component';
import { Utils } from 'src/app/commons/utils';
import { ReportApplicantsSearchModel,VendorFilterModel } from './models/models';
import { Environment } from 'src/app/config/environment';
import { SessionStorageService } from 'src/app/auth/services/session.service';
import { ExcelExportService } from 'src/app/services/excelExport/excel.export.service';
import { BaseComponent } from '../../component/shared/base.component';
import { InternalReportApplicantsService } from 'src/app/services_internal/internalReportApplicants/internalReportApplicants.service';
declare var $ : any;

@Component({
    selector: 'app-internal-report-applicants',
    templateUrl: './internalReportApplicants.component.html',
    styleUrls: ['./internalReportApplicants.component.css']
  })

  export class InternalReportApplicantsComponent extends BaseComponent implements OnInit {
    
    public vendorsList :Array<VendorFilterModel> = new Array<VendorFilterModel>(); 
    public loading: boolean;
    public displayError: boolean;
    public alertMessage: AlertModel = new AlertModel;
    public title: string = "Reporte de Cuentas Aprobadas - Customers";
    public titleFile: string = "Reporte_de_Cuentas_Aprobadas";
    public es: any;
    public busquedafiltros: ReportApplicantsSearchModel = new ReportApplicantsSearchModel();
    public showResult : boolean;
    public resultData = [];
    public tablereports = null;
    public arrayTipoVentas: {} = {"SUP":"Venta Isolada","RCO":"Reverse Companion","CON":"Conversion (Upgrade)","COM":"Companion"};

    constructor(private sessionStorageService: SessionStorageService,
      private excelExportService: ExcelExportService,
      private reportApplicantsService: InternalReportApplicantsService) { 
  
      super();
      this.sessionStorageService.rolValidate("internalReportApplicants");

    }

    ngOnInit(): void {
      this.busquedafiltros.promotor ="";
      this.loadVendorsList();
      this.setCalendarLocale(); 
    }

    setCalendarLocale(){
      this.es = Environment.CalendarLanguageSetting;
    }

    loadVendorsList(){
  
      this.reportApplicantsService.getAllPromotores().subscribe(
        response => {
    
          if( response.status == 200 && response.body != null){
            this.vendorsList = Object.assign(this.vendorsList, response.body);
          }else if( response.status == 204 ){
            console.log( "no se encontraron promotores" );
          }else{
            console.log( "Error 1 obteniendo los promotores" );
          }
    
        }, err => {
          console.log( "Error 2 obteniendo los promotores" );
          this.loading = false;
          throw err;
        }
      );
      
  
    }

    loadResultData(){

      if( !this.validateForm() ){
        return;
      }

      this.loading = true;
      let request = { 
        "fechaAprobadoDesde": Utils.isNullOrEmpty(this.busquedafiltros.fecha_procesamiento_inicio) ? null : this.busquedafiltros.fecha_procesamiento_inicio, 
        "fechaAprobadoHasta": Utils.isNullOrEmpty(this.busquedafiltros.fecha_procesamiento_fin) ? null : this.busquedafiltros.fecha_procesamiento_fin, 
        "promotor": Utils.isNullOrEmpty(this.busquedafiltros.promotor) ? null : this.busquedafiltros.promotor,
      };  

      this.reportApplicantsService.getApprovedAccountsReport(request).subscribe(
        response => {

          if( response.status == 200 && response.body != null){
            
            this.resultData = Object.assign(this.resultData, response.body["data"]);
            
            for( var j = 0; j < this.resultData.length; j++ ){
              var obj = this.resultData[j];
              obj.tipoVentaDescription = ( typeof this.arrayTipoVentas[ obj.tipoVenta ] != "undefined" ) ? this.arrayTipoVentas[ obj.tipoVenta ] : obj.tipoVenta;
            }
            
          }else if( response.status == 204 ){
            this.alertMessage = new AlertModel( "No se encontraron registros con los datos ingresados.",this.title, true);
          }else{
            this.alertMessage = new AlertModel( "Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title, true,"WARNING");
          }
          this.setupTable();
          this.loading = false;
  
        }, err => {
          this.alertMessage = new AlertModel( "Hubo un inconveniente al obtener la información, inténtalo nuevamente.",this.title, true,"WARNING");
          this.loading = false;
          throw err;
        }
      );

  
    }

    validateForm():boolean{
      let validations = "";
      let arrayErrors = [];
  
      if(Utils.isNullOrEmpty( this.busquedafiltros.fecha_procesamiento_inicio ) || Utils.isNullOrEmpty(this.busquedafiltros.fecha_procesamiento_fin) ){
        arrayErrors.push( "Seleccione <b>Fecha Aprobado Inicio</b> y <b>Fecha Aprobado Fin</b>.");
      }
  
      if( arrayErrors.length ) {
        for( var i = 0; i < arrayErrors.length; i++ ){
          var conc = ( i == 0 )? "" : "<br>";
          validations += conc + arrayErrors[ i ];
        }
        this.alertMessage = new AlertModel(validations,this.title,true,"WARNING");
        return false;
      }
      
      return true;
    }

    setupTable(){

      this.showResult = true;
  
      if (this.tablereports != null) {
        this.tablereports.clear().destroy();
      } 
      
      $(document).ready( () => {
          this.tablereports =   $("#result-table").DataTable({
  
          searching: false, 
          paging: true, 
          
          lengthChange: false,
          pageLength: 10,
          pagingType: 'full_numbers',
          columnDefs: [
            { targets: 'no-sort', orderable: false },
            {
              targets: 2,
              render: (data, type, row) => {
                if (type === 'sort') {
                  return Utils.dateForm(data);
                }
                return data;
              }
            },
            {
              targets: 3,
              render: (data, type, row) => {
                if (type === 'sort') {
                  return Utils.dateForm(data);
                }
                return data;
              }
            },
            {
              targets: 9,
              render: (data, type, row) => {
                if (type === 'sort') {
                  return Utils.dateForm(data);
                }
                return data;
              }
            },
          ],    
          deferRender: true,
          language: Environment.DataTableLanguageSetting,
          initComplete: (setting, json) => {
            this.loading = false;
          }
        });
      });
  
    }

    exportar (){

      let reportDataExport = new Array<any>();
  
      this.resultData.forEach(item => {      
        reportDataExport.push({
          "PCN": item.pcn,
          "CUIL": item.cuil,
          "Documento": item.docNum,
          "Fecha Captura": item.fechaCapturaPrecheck,
          "Fecha Aprobado IC": item.fechaAprobado,
          "Tipo de Venta": item.tipoVenta,
          "Promotor": item.informedPromotor,
          "Producto": item.internalSourceCode.prod + " - " +item.internalSourceCode.nombreProducto,
          "Sourcecode": item.internalSourceCode.sourceCodeCompleto,
          "refNaps": item.refNaps,
          "Fecha Lanas": item.fechaAprobacionLanas,
          "Cant. Suples": item.intPersonaSuples.length
        });
      }); 
  
      this.excelExportService.exportAsExcelFile(reportDataExport, this.titleFile);
  
    }

    onConfirmEvent(confirm:any){
      console.log(confirm);
    }

  }